<script setup>
import { useModal } from 'vue-final-modal';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import InventoryStockStatusForm from '~/inventory/components/inventory-settings/inventory-stock-status-form.vue';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const emit = defineEmits(['edit', 'delete']);
const { $t, $toast } = useCommonImports();

const COLUMNS = [
  {
    accessorKey: 'name',
    header: $t('Name'),
    id: 'name',
    static: true,
  },
  {
    accessorKey: 'description',
    header: $t('Description'),
    id: 'description',
    static: true,
    size: 400,
    render_as: {
      field_type: 'text',
      options: {
        classes: 'text-gray-600',
      },
    },
  },
  {
    accessorKey: 'show_in_stock',
    header: $t('Show in stock'),
    id: 'show_in_stock',
    static: true,
    render_as: {
      field_type: 'boolean',
    },
  },
  {
    accessorKey: 'context_menu',
    header: '',
    id: 'context_menu',
    size: '5',
    show_on_hover: 'true',
  },
];

const inventory_store = useInventoryStore();

const freeze_table = ref('-1');

const data = computed(() => Object.values(inventory_store.statuses_map));

const { open: openStockStatusForm, close: closeStockStatusForm, patchOptions } = useModal({
  component: InventoryStockStatusForm,
});
function editHandler(stock_status) {
  patchOptions(
    {
      attrs: {
        stock_status,
        onClose() {
          closeStockStatusForm();
        },
        on_save: async (data) => {
          try {
            const response = await inventory_store.update_stock_status({ payload: data, uid: stock_status.uid });
            if (response)
              $toast({
                text: 'Stock status updated successfully',
                type: 'success',
              });
          }
          catch (error) {
            $toast({ text: (error?.data?.message || error?.message), type: 'error', title: 'Error' });
          }
        },
      },
    },
  );
  openStockStatusForm();
}

const { open: openDeletePopup, close: closeDeletePopup, patchOptions: patchDeletePopup } = useModal({
  component: HawkDeletePopup,
});
function deleteHandler(stock_status) {
  patchDeletePopup(
    {
      attrs: {
        header: $t('Delete stock status'),
        content: `Are you sure you want to delete stock status ${stock_status.name}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            const response = await inventory_store.delete_stock_status({ uid: stock_status.uid });
            if (response)
              $toast({
                text: $t('Stock status deleted successfully'),
                type: 'success',
              });
          }
          catch (error) {
            $toast({ text: (error?.data?.message || error?.message), type: 'error', title: 'Error' });
          }
          closeDeletePopup();
        },
      },
    },
  );
  openDeletePopup();
}
</script>

<template>
  <div>
    <div class="flex items-center w-full justify-between">
      <div>
        <p class="text-lg font-bold">
          {{ $t('Stock statuses') }}
        </p>
        <p class="text-xs text-gray-600">
          {{ $t('The statuses in which the stock can exist. These statuses can be customized as per your business requirements. Get in touch with our team for help.') }}
        </p>
      </div>
    </div>
    <div v-if="!data?.length" class="text-sm text-gray-500 mt-3">
      {{ $t('No stock statuses added') }}
    </div>
    <TableWrapperVue v-else :height="300" container_class="border mt-3">
      <HawkTable
        :data="data"
        :columns="COLUMNS"
        is_gapless
        cell_height="40px"
        :non_sortable_columns="['context_menu']"
        :show_menu_header="false"
        :freeze_table="freeze_table"
        @rowClicked="e => editHandler(e)"
      >
        <template #name="name">
          <HawkBadge :custom_color="name.data.row.original.color">
            {{ name.data.row.original.name }}
          </HawkBadge>
        </template>
        <template #context_menu="stock_status">
          <hawk-menu
            position="fixed"
            additional_trigger_classes="!ring-0 hover:bg-gray-50"
            :items="[
              {
                uid: 'edit',
                label: $t('Edit'),
                disabled: false,
                on_click: () => editHandler(stock_status.data.row.original),
              },
              {
                uid: 'delete',
                label: $t('Delete'),
                disabled: false,
                on_click: () => deleteHandler(stock_status.data.row.original),
              },
            ]"
            @click.stop=""
            @open="freeze_table = stock_status?.data?.row?.id"
            @close="freeze_table = '-1'"
          />
        </template>
      </HawkTable>
    </TableWrapperVue>
  </div>
</template>
