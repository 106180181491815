<script setup>
import { computed, ref } from 'vue';

import { useModal } from 'vue-final-modal';
import { cloneDeep, omit } from 'lodash-es';
import { useTerraStore } from '../store/terra.store';
import MapToolIcons from './map-tool-icons.vue';
import TerraSearchbar from './terra-searchbar.vue';
import CustomViews from '~/common/components/organisms/custom-views/custom-views.vue';
import IconHawkVolumeCalculation from '~icons/hawk/volume-calculation.svg';
import IconHawkElevation from '~icons/hawk/elevation.svg';
import IconHawkPlus from '~icons/hawk/plus.svg';

import IconHawkPoint from '~icons/hawk/point.svg';
import IconHawkMinus from '~icons/hawk/minus.svg';
import IconHawkRefreshCcwOne from '~icons/hawk/refresh-ccw-one.svg';
import IconHawkRuler from '~icons/hawk/ruler.svg';
import IconHawkPolygon from '~icons/hawk/polygon.svg';
import IconHawkRectangle from '~icons/hawk/rectangle.svg';
import IconHawkCircle from '~icons/hawk/circle.svg';
import IconHawkSearch from '~icons/hawk/search.svg';
import IconHawkCompare from '~icons/hawk/compare.svg';
import SaveView from '~/terra/components/map-tools/save-view.vue';
import GalleryView from '~/terra/molecules/gallery/gallery-view.vue';
import GalleryFeatureDetails from '~/terra/components/feature-details/feature-gallery-view-details.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable';

const props = defineProps({
  active_tool: {
    type: String,
  },
  active_tools: {
    type: Object,
    default: () => ({}),
  },
  type: {
    type: String,
    default: 'map-view',
  },
  draw_tools_expanded: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Array,
    default: () => [],
  },
  save_view: {
    type: Boolean,
    default: false,
  },
  sidebar_state: {
    type: Object,
    default: () => ({
      active_menu: '',
      is_table_active: false,
    }),
  },
});

const emit = defineEmits(['toolActivated', 'scaleToggled', 'update:active_tool', 'toggleFilters', 'toggleSettings', 'toggleMode', 'create', 'toggleCreate', 'compare', 'closeSaveView', 'changeView', 'filterGlDrawConvertedFeatures', 'openCreationPopup']);

const { auth_store, $t, common_store, route, router } = useCommonImports();
const terra_store = useTerraStore();
const custom_view_instance = ref(null);
// methods
function activateTool(tool) {
  emit('toolActivated', tool.event);
  emit(tool.event);
}

const { open: openSearchModal, close: closeSearchModal } = useModal({
  component: TerraSearchbar,
  attrs: {
    onClose() {
      activateTool({ event: '' });
      closeSearchModal();
    },
  },
});

// Computed properties
const draw_tools = computed(() => {
  return [
    {
      tooltip: $t('Volume calculation'),
      icon: IconHawkVolumeCalculation,
      event: 'volume',
      action: () => activateTool({ event: 'volume' }),
    },
    {
      tooltip: $t('Elevation profile'),
      icon: IconHawkElevation,
      event: 'elevation',
      action: () => activateTool({ event: 'elevation' }),
    },
    // {
    //   tooltip: $t('Yield estimator'),
    //   icon: IconTablerLetterY,
    //   event: 'yield_estimator',
    //   custom_class: 'text-[16px] !w-auto !h-auto',
    //   action: () => {
    //     activateTool({ event: 'yield_estimator' });
    //     emit('toggleYieldEstimator');
    //   },
    // },
    {
      tooltip: $t('Scale'),
      icon: IconHawkRuler,
      event: 'scale',
      action: () => {
        activateTool({ event: 'scale' });
      },
    },

    // ...(props.type === 'terra'
    //   ? [
    //       {
    //         tooltip: $t('Circle'),
    //         icon: IconHawkCircle,
    //         event: 'add-circle',
    //         action: () => activateTool({ event: 'add-circle' }),
    //       },
    //     ]
    //   : []),

  ];
});

const zoom_tools = computed(() => {
  return [
    {
      tooltip: $t('Zoom in'),
      icon: IconHawkPlus,
      event: 'zoom-in',

      action: () => activateTool({ event: 'zoom-in' }),
    },
    {
      tooltip: $t('Zoom out'),
      icon: IconHawkMinus,
      event: 'zoom-out',

      action: () => activateTool({ event: 'zoom-out' }),
    },
    {
      tooltip: $t('Reset'),
      icon: IconHawkRefreshCcwOne,
      event: 'reset-north',

      action: () => activateTool({ event: 'reset-north' }),
    },
  ];
});
const filtered_draw_tools = computed(() => {
  return [{
    tooltip: $t('Tools'),
    icon: IconHawkToolOne,
    event: 'edit',
    sub_tools: draw_tools.value,
    action: () => {
      activateTool({ event: ['volume', 'elevation', 'scale', 'edit'].includes(props.active_tool) ? '' : 'edit' });
    },
  }];
});

const feature_tools = computed(() => {
  return [
    {
      tooltip: $t('Line'),
      icon: IconHawkLine,
      event: 'add-line',
      action: () => activateTool({ event: 'add-line' }),
    },
    {
      tooltip: $t('Rectangle'),
      icon: IconHawkRectangle,
      event: 'add-rectangle',
      action: () => activateTool({ event: 'add-rectangle' }),
    },
    {
      tooltip: $t('Polygon'),
      icon: IconHawkPolygon,
      event: 'add-polygon',
      action: () => activateTool({ event: 'add-polygon' }),
    },
    {
      tooltip:
              props.type === 'terra'
                ? $t('Point')
                : $t('Add marker'),
      icon: IconHawkPointThree,
      event: props.type === 'terra' ? 'add-point' : 'add-marker',
      action: () =>
        activateTool({
          event: props.type === 'terra' ? 'add-point' : 'add-marker',
        }),
    },
    ...(props.type === 'terra'
      ? [
          {
            tooltip: $t('Circle'),
            icon: IconHawkCircle,
            event: 'add-circle',
            action: () => activateTool({ event: 'add-circle' }),
          },
        ]
      : []),
  ];
});
const filter_tools = computed(() => {
  return [
    {
      tooltip: $t('Compare'),
      icon: IconHawkCompare,
      event: 'compare',
      action: () => {
        emit('compare');
        activateTool({ event: 'compare' });
        terra_store.terra_track_events('Compare enabled');
      },
    },
    ...filtered_draw_tools.value,
    {
      tooltip: $t('Search'),
      icon: IconHawkSearch,
      event: 'search',
      action: () => {
        openSearchModal();
        activateTool({ event: 'search' });
      },
    },
  ];
});
const creation_tool = computed(() => {
  return [
    {
      tooltip: $t('Create'),
      icon: IconHawkPoint,
      event: 'create',
      action: () => {
        if (props.active_tools.create) {
          emit('toggleCreate');
        }
        else {
          emit('create');
          activateTool({ event: 'create' });
        }
      },
    },

  ];
});
const get_current_view = computed(() => route.query.view_uid || 'default');
const get_active_groups_and_projects = computed(() => {
  const active_groups = Object.keys(terra_store.active_group_map).filter(key => terra_store.active_group_map[key].features_enabled || terra_store.active_group_map[key].ortho_enabled);
  const filtered_active_projects = Object.keys(terra_store.active_projects_map).filter((key) => {
    const project = terra_store.active_projects_data_map({ all_projects: true })[key];
    return !active_groups.includes(project?.group) && (terra_store.active_projects_map[key].features_enabled || terra_store.active_projects_map[key].ortho_enabled);
  });
  return {
    active_groups,
    filtered_active_projects,
  };
});
const current_view_payload = computed(() => {
  let data = {};
  if (terra_store.map) {
    data = {
      inactive_feature_types: terra_store.inactive_feature_types,
      zoom_level: terra_store.map.getZoom(),
      center: terra_store.map.getCenter(),
      active: true,
      tasks_cluster: terra_store.tasks_cluster,
      forms_cluster: terra_store.forms_cluster,
      feature_config: terra_store.settings,
      filters_state: getFormattedFIltersState(),
      table_state: terra_store.table_state,
      sidebar_menu: props.sidebar_state,
      pivot_config: null,
      ...get_active_groups_and_projects.value,
    };
    if (terra_store.pivot_config) {
      const pivot_instance_config = terra_store.pivot_config?.table_settings?.getReport();
      if (pivot_instance_config)
        delete pivot_instance_config.dataSource;
      data.pivot_config = { ...terra_store.pivot_config, table_settings: pivot_instance_config };
    }
  }
  return {
    service: 'terra',
    feature: 'map_view',
    resource_id: route.params.id,
    resource_type: 'views',
    data,
  };
});
function getFormattedFIltersState() {
  let filter_state = cloneDeep(terra_store.filters_state);
  filter_state = omit(filter_state, 'features_on_map');
  const key_values_data = cloneDeep(filter_state.key_values_data);
  const updated_key_values_data = Object.entries(key_values_data).reduce((acc, [key, values]) => {
    const updated_values = Object.entries(values).reduce((acc, [value_key, value]) => {
      if (value.selected || value.color)
        acc[value_key] = value;
      return acc;
    }, {});
    if (Object.keys(updated_values).length)
      acc[key] = updated_values;
    else
      filter_state.filter_forms = omit(filter_state.filter_forms, key);
    return acc;
  }, {});
  filter_state.key_values_data = updated_key_values_data;
  return filter_state;
}
function changeCurrentView(val, event = 'switched') {
  router.replace({
    name: 'terra-viewer',
    params: {
      asset_id: route.params.asset_id,
      id: terra_store.container.uid,
    },
    query: (val !== 'default'
      ? {
          view_uid: val,
        }
      : {}),

  });
  handleViewEvents(event);
  emit('changeView');
  emit('closeSaveView');
}
function handleViewEvents(event) {
  terra_store.terra_track_events(`View ${event}`);
}
</script>

<template>
  <div class="h-auto gap-2.5 w-full mt-3">
    <div class="absolute left-0 flex flex-row gap-2.5">
      <MapToolIcons
        style="position: relative"
        :tools="zoom_tools"
        :active_tool="active_tool"
        :disabled="disabled"
        :active_tools="active_tools"
      />
    </div>
    <CustomViews
      :key="get_current_view"
      :payload="current_view_payload"
      :show_default="true"
      :selected_view="get_current_view"
      @customViewsInstance="custom_view_instance = $event"
      @selectedView="changeCurrentView"
      @deletedView="get_current_view === $event ? changeCurrentView('default', 'deleted') : null"
      @createdView="handleViewEvents('created')"
      @updatedView="handleViewEvents('updated')"
      @closeSaveView="emit('closeSaveView')"
    />
    <div v-if="type === 'terra'" class="absolute right-0 flex">
      <div class="relative flex flex-col mr-2.5">
        <SaveView
          v-if="auth_store.check_permission('modify_terra_views', $route.params.asset_id) && terra_store.show_save_view"
          :custom_view_instance="custom_view_instance"
          :current_view_payload="current_view_payload"
          @closeSaveView="emit('closeSaveView')"
        />
      </div>
      <GalleryView
        :store="terra_store"
        @filterGlDrawConvertedFeatures="emit('filterGlDrawConvertedFeatures')"
        @openCreationPopup="emit('openCreationPopup', $event)"
      >
        <template #feature_details>
          <GalleryFeatureDetails
            @filterGlDrawConvertedFeatures="emit('filterGlDrawConvertedFeatures')"
            @openCreationPopup="emit('openCreationPopup', $event)"
          />
        </template>
      </GalleryView>
      <div class="relative flex flex-col  mr-2.5">
        <MapToolIcons
          style="position: relative"
          :tools="creation_tool"
          :active_tool="active_tool"
          :disabled="disabled"
          :active_tools="active_tools"
          :horizontal_icons="true"
        />
      </div>
      <div class="relative flex flex-col mr-2.5">
        <MapToolIcons
          style="position: relative"
          :tools="feature_tools"
          :active_tool="active_tool"
          :disabled="disabled"
          :active_tools="active_tools"
          :horizontal_icons="true"
        />
      </div>
      <div class="relative flex flex-col">
        <MapToolIcons
          style="position: relative"
          :tools="filter_tools"
          :active_tool="active_tool"
          :disabled="disabled"
          :active_tools="active_tools"
          :horizontal_icons="true"
        />
      </div>
    </div>
  </div>
</template>
