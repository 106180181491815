<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useModal } from 'vue-final-modal';
import { computed } from 'vue';
import { debounce } from 'lodash-es';
import FormBulkUpdateDropdown from './form-bulk-update-dropdown.vue';
import { FORM_PERMISSIONS } from '~/forms/constants';
import IconFilter from '~icons/hawk/filter-icon';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import HawkStickyHeader from '~/common/components/atoms/hawk-sticky-header.vue';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';

import { useFormsStore } from '~/forms/store/forms.store.js';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import formBulkUpdate from '~/forms/components/form-bulk-update.vue';
import SecondaryPageHeader from '~/common/components/organisms/hawk-page-secondary-header.vue';
import FiltersPopupForms from '~/forms/components/form-filter.vue';
import { useFormFilterStore } from '~/forms/store/form-filter-store';
import FormStatus from '~/forms/atoms/form-status.vue';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useExportPDF } from '~/forms/composables/form-detail-composable';

const props = defineProps({
  store_key: {
    type: String,
  },
  asset_id: {
    type: String,
    default: null,
  },
  query: {
    type: Object,
    default: () => {},
  },
  has_sticky_header: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Object,
    default: () => ({}),
  },
  table_options: {
    type: Object,
    default: () => {},
  },
  is_widget: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();
const route = useRoute();
const search = ref('');
const $t = inject('$t');
const $toast = inject('$toast');
const $date = inject('$date');
const forms_store = useFormsStore(props.store_key);
const filter_store = useFormFilterStore(props.store_key);
const form_template_detail_store = inject('form_template_detail_store');

const state = reactive({
  page_size: 25,
  page_number: 1,
});

const query = props.query;

const form_listing_types = [
  {
    label: $t('All forms'),
    value: 'all',
  },
  {
    label: $t('Archived forms'),
    value: 'archived',
  },
];

const columns = computed(() => {
  const options = [
    {
      accessorKey: 'select',
      header: '',
      id: 'select',
      size: 100,
      enableSorting: false,
      enableResizing: false,
      show_on_hover: 'true',
    }, {
      header: $t('Form name'),
      accessorKey: 'name',
      id: 'name',
      size: 200,
      enableResizing: false,
    },
    {
      header: $t('Category'),
      accessorKey: 'category',
      id: 'category',
      size: 200,
      enableResizing: false,
    },
    {
      accessorKey: 'assignees',
      header: 'Assignees',
      id: 'assignees',
      size: 100,
      enableResizing: false,
    },
    {
      header: $t('Due date'),
      accessorKey: 'due_date',
      id: 'due_date',
      size: 100,
      enableResizing: false,
    },
    {
      header: 'Status',
      accessorKey: 'status',
      id: 'status',
      enableResizing: false,
    },
  ];
  if (props.table_options?.visible_columns?.length)
    return options.filter(column => props.table_options.visible_columns.includes(column.id));
  return options;
});

if (props.options.is_pm) {
  columns.value.splice(0, 1);
  columns.value.push({
    accessorKey: 'context_menu',
    header: '',
    id: 'context_menu',
    size: '5',
    show_on_hover: 'true',
  });
}

const loading = ref(false);
const table_loading = ref(false);
const table_instance = ref(null);
const is_pdf_exporting = ref(false);
const selected_forms = ref([]);
const selected_listing_type = ref(form_listing_types[0]);

const auth_store = useAuthStore();
const { formsPDFExportHandler } = useExportPDF();

const is_loading = ref(false);
const common_store = useCommonStore();

function getDueDateStatus(form) {
  const due_date = form.step_due_date || form.due_date;
  if (form?.status?.submission_status === 'submitted')
    return $t('Submitted');
  else if (!due_date)
    return $t('No due date');
  else if (new Date(due_date).getTime() > new Date().getTime())
    return $t('Upcoming');
  else if (new Date().getTime() - new Date(due_date).getTime() < 8.64e+7)
    return $t('Today');
  else
    return $t('Overdue');
}

function onListingSelect(selected_option) {
  selected_listing_type.value = selected_option;
  getData();
}

function getArchiveOption() {
  return selected_listing_type.value.value === 'all'
    ? [
        {
          uid: 3,
          label: $t('Archive'),
          on_click: () => {
            forms_store.archiveForms(selected_forms.value.map(form => form.uid), (data) => {
              const { archived, errors } = data.forms;

              if (!archived.length && errors.length)
                $toast({
                  title: $t('Unable to archive'),
                  type: 'error',
                  position: 'bottom-right',
                  text: `${$t(errors.length > 1 ? 'Forms' : 'Form')} ${$t('could not be archived, please check submission status and block type')}`,
                });

              if (archived.length === selected_forms.value.length)
                $toast({
                  title: `${$t(archived.length > 1 ? 'Forms' : 'Form')} ${$t('archived')}`,
                  type: 'success',
                  position: 'bottom-right',
                  text: `${$t(`You will be able to view ${archived.length > 1 ? 'these forms' : 'this form'} in the archived forms section.`)}`,
                });

              if (archived.length && errors.length)
                $toast({
                  title: `${archived.length}/${selected_forms.value.length} ${$t('forms have been archived')}`,
                  type: 'warning',
                  position: 'bottom-right',
                  timeout: false,
                  has_dismiss_button: true,
                  dismiss_button_text: $t('Ok, got it'),
                  text: `${$t('Only')} ${archived.length}/${selected_forms.value.length} ${$t('forms have been archived as the rest are in different blocks.')}`,
                });

              clearBulkSelection();
            });
          },
        },
      ]
    : [
        {
          uid: 4,
          label: $t('Unarchive'),
          on_click: () => {
            forms_store.unarchiveForms(selected_forms.value.map(form => form.uid), (data) => {
              const { unarchived, errors } = data.forms;

              if (!unarchived.length && errors.length)
                $toast({
                  title: $t('Unable to unarchive'),
                  type: 'error',
                  position: 'bottom-right',
                  text: `${$t(errors.length > 1 ? 'Forms' : 'Form')} ${$t('could not be archived, please check submission status and block type')}`,
                });

              if (unarchived.length === selected_forms.value.length)
                $toast({
                  title: `${$t(unarchived.length > 1 ? 'Forms' : 'Form')} ${$t('unarchived')}`,
                  type: 'success',
                  position: 'bottom-right',
                  text: `${$t(unarchived.length > 1 ? 'Forms' : 'Form')} ${$t('has been successfully unarchived.')}`,
                });

              if (unarchived.length && errors.length)
                $toast({
                  title: `${unarchived.length}/${selected_forms.value.length} ${$t('forms have been unarchived')}`,
                  type: 'warning',
                  position: 'bottom-right',
                  timeout: false,
                  has_dismiss_button: true,
                  dismiss_button_text: $t('Ok, got it'),
                  text: `${$t('Only')} ${unarchived.length}/${selected_forms.value.length} ${$t('has been successfully unarchived')}`,
                });

              clearBulkSelection();
            });
          },
        },
      ];
}

const prefilled_data = computed(() => forms_store?.forms.map(form => ({ ...form, 'Due Date': getDueDateStatus(form) })));

const has_data = computed(() => !!prefilled_data.value.length);
const has_filter_applied = computed(() => filter_store.filters_count > 0 || search.value);
const has_no_results = computed(() => !has_data.value && has_filter_applied.value);

function getMembersAndTeams(members = [], teams = []) {
  return [...members.map(member => member.uid), ...teams.map(team => team.uid)];
}

async function getData(options) {
  table_loading.value = true;
  if (options?.pagination_state?.pageIndex && options?.pagination_state?.pageSize) {
    state.page_number = options?.pagination_state.pageIndex || state.page_number;
    state.page_size = options?.pagination_state.pageSize || state.page_size;
  }
  await forms_store.set_forms({
    query: {
      page_number: state.page_number,
      page_size: state.page_size,
      ...(options?.sort_by
        ? { sort: options.sort_by.desc ? `-${options.sort_by.id}` : options.sort_by.id }
        : {}),
      ...(search?.value ? { q: search.value } : {}),
      ...query,
      archived: selected_listing_type.value.value === 'archived',
    },
  });
  table_loading.value = false;
}

onMounted(async () => {
  loading.value = true;
  if (route?.query?.group_by) {
    const group_by_query = route?.query?.group_by;
    const filter_key_map = {
      status: () => {
        const status_id = route?.query?.status_type === 'submission_status' ? [route?.query?.open.toLowerCase()] : route?.query?.open;
        return { [route?.query?.status_type]: status_id };
      },
      due_date: () => {
        return {
          submission_status: ['open', 'draft'],
          due_date_end: new Date().toISOString(),
          due_date_start: new Date(0).toISOString(),
        };
      },
    };
    filter_store.set_filters({ payload: { ...filter_store.filter_payload, ...filter_key_map[group_by_query]() } });
  }
  await getData();
  loading.value = false;
});
// when asset changes call form list api
watch(() => props.asset_id, () => {
  getData();
});
// when search value is present reset the pagination
const debouncedGetData = debounce(getData, 500);
watch(() => search?.value?.length, () => {
  state.page_number = 1;
  debouncedGetData();
});
function openFormSubmissionComponent(row) {
  router.push({ query: { form: btoa(JSON.stringify({ form_uid: row.uid, store_key: props.store_key, archived: !!row.archived })) } });
}
function handleFormSelection(e) {
  selected_forms.value = e.map(forms_list_row => forms_list_row.original);
}
const { open, close } = useModal({
  component: formBulkUpdate,
  attrs: {
    forms_store,
    onClose() {
      close();
      clearBulkSelection();
    },
    selected_forms,
  },
});
// Filters Popup Handler
const { open: openFiltersPopup, close: closeFiltersPopup, patchOptions: patchFiltersPopup } = useModal({
  component: FiltersPopupForms,
  attrs: {
    extra_query: {
      page_number: 1,
      page_size: state.page_size,
      archived: selected_listing_type.value.value === 'archived',
    },
    forms_store,
    onClose() {
      closeFiltersPopup();
    },
    onSave() {
      state.page_number = 1;
    },
  },
});

async function clearFilters() {
  is_loading.value = true;
  filter_store.set_filters({ reset: true });
  state.page_number = 1;
  await forms_store.set_forms({ query: { ...query, page_number: state.page_number, page_size: state.page_size, archived: selected_listing_type.value.value === 'archived' } });
  is_loading.value = false;
}
function clearBulkSelection() {
  table_instance.value.clearSelect();
}
onUnmounted(() => {
  filter_store?.set_filters({ });
  forms_store.$reset();
});

function get_location_name(form) {
  let location_name = [];
  if (
    form?.target_element?.asset && common_store.get_asset(form.target_element.asset)
  ) {
    if (form.target_element.asset)
      location_name = [
        common_store.get_asset(form.target_element.asset).name,
      ];
    if (form.properties?.reference_name)
      location_name.push(form.properties?.reference_name);
    return location_name.length ? location_name.join(' > ') : null;
  }
  return null;
}

async function formBulkDeleteHandler() {
  try {
    is_loading.value = true;
    const selected_forms_uids = selected_forms.value.map(form => form.uid);
    await forms_store.remove_forms(selected_forms_uids);
    clearBulkSelection();
    is_loading.value = false;
  }
  catch (error) {
    is_loading.value = false;
  }
}
function deletePopup() {
  const { open: openDeletePopup, close: closeDeletePopup } = useModal({
    component: HawkDeletePopup,
    attrs: {
      header: $t('Delete Forms'),
      content: `Are you sure you want to delete ${selected_forms.value.length} forms ? This action cannot be undone.`,
      onClose() {
        closeDeletePopup();
      },
      confirm: async () => {
        try {
          await formBulkDeleteHandler();
          closeDeletePopup();
        }
        catch (err) {
          $toast({
            title: 'Something went wrong',
            text: 'Please try again',
            type: 'error',
          });
        }
      },
    },
  });
  openDeletePopup();
}
function exportCTAHandler() {
  if (is_pdf_exporting.value)
    is_pdf_exporting.value = false;
  nextTick(() => {
    is_pdf_exporting.value = true;
  });
}
const bulk_update_options = computed(() => [
  ...((selected_forms.value.length === selected_forms.value.filter(form => form?.status.submission_status === 'submitted').length)
    ? []
    : [
        {
          uid: 0,
          label: $t('Bulk Update'),
          on_click: open,
        },
      ]),
  ...(auth_store.check_permission(FORM_PERMISSIONS.V2_MODIFY_FORMS, route.params.asset_id)
    ? [
        { uid: 1, label: $t('Delete'), on_click: deletePopup },
      ]
    : []),
  ...(auth_store.check_split('forms_export_pdf')
    ? [
        { uid: 2, label: $t('Export PDF'), on_click: exportCTAHandler, disabled: selected_forms.value.length > 100, tooltip: selected_forms.value.length > 100 ? $t('Can not export more than 100 forms') : '' },
      ]
    : []
  ),
  ...(auth_store.check_permission(FORM_PERMISSIONS.V2_MODIFY_FORMS, route.params.asset_id)
    ? getArchiveOption()
    : []),
]);
</script>

<template>
  <div>
    <component :is="props.has_sticky_header ? HawkStickyHeader : 'div'" v-if="!props.options.disable_header" :class="{ 'mb-5': !props.has_sticky_header }">
      <div class="items-center">
        <div class="flex justify-between">
          <div>
            <slot name="top-left-controls">
              <div v-if="selected_forms.length">
                <SecondaryPageHeader>
                  <template #left>
                    <div class="items-center">
                      <FormBulkUpdateDropdown :selected_items="selected_forms" :bulk_action_options="bulk_update_options" @clear="clearBulkSelection()" />
                    </div>
                  </template>
                </SecondaryPageHeader>
              </div>
              <div v-else class="flex">
                <hawk-search-input v-model="search" :placeholder="$t('Search')" />
                <div v-if="!props?.options?.is_pm" class="flex items-center ml-4">
                  <hawk-button
                    class="mr-4"
                    type="outlined"
                    @click="() => {
                      patchFiltersPopup({
                        attrs: {
                          extra_query: {
                            page_number: 1,
                            page_size: state.page_size,
                            archived: selected_listing_type.value === 'archived',
                          },
                        },
                      });
                      openFiltersPopup()
                    }"
                  >
                    <IconFilter class="text-gray-600 w-[15px] h-2.5" />
                    <span class="text-sm text-[#667085]">{{ $t('filters') }}</span>
                  </hawk-button>
                  <p
                    v-if="filter_store.filters_count"
                    class="flex items-center rounded-md p-2 text-sm font-semibold text-primary-700 bg-primary-50"
                  >
                    {{ `${filter_store.filters_count} ${$t('Applied')}` }}
                    <Icon-hawk-x-close class="cursor-pointer ml-2 " @click="clearFilters" />
                  </p>
                </div>
              </div>
            </slot>
          </div>
          <div>
            <slot name="top-right-controls">
              <div class="flex justify-end">
                <hawk-menu
                  v-if="!selected_forms.length"
                  :items="form_listing_types"
                  @select="onListingSelect"
                >
                  <template #trigger>
                    <hawk-button type="outlined">
                      {{ selected_listing_type.label }}
                      <icon-hawk-chevron-down class="text-gray-600" />
                    </hawk-button>
                  </template>
                </hawk-menu>
                <new-form-button :forms_store="forms_store" class="ml-3" />
              </div>
            </slot>
          </div>
        </div>
        <div>
          <div class="flex" />
        </div>
      </div>
    </component>
    <div v-if="loading">
      <hawk-loader />
    </div>
    <HawkIllustrations v-else-if="has_no_results" type="no-results" for="forms" />
    <HawkIllustrations v-else-if="!has_data" :variant="props.is_widget ? 'mini' : 'default'" type="no-data" for="forms" />
    <TableWrapperVue v-else class="max-w-[calc(100vw-40px)] !max-h-[calc(100vh-120px)]" container_class="mt-0">
      <HawkTable
        :key="state.page_number"
        :is_loading="table_loading"
        :pagination_config="{ totalRows: forms_store.total_form_count, pageIndex: state.page_number, pageSize: state.page_size }"
        :non_sortable_columns="['context_menu']"
        :data="prefilled_data"
        :columns="columns"
        :is_gapless="true"
        :sticky_group_label="true"
        :disable_resize="true"
        :show_menu_header="false"
        :manual_pagination="true"
        :manual_sorting="true"
        :table_header_sticky="props.has_sticky_header"
        @pagination="getData($event)"
        @sort="getData($event)"
        @tableInstance="table_instance = $event"
        @row-clicked="openFormSubmissionComponent"
        @select-row="handleFormSelection"
      >
        <template #group-label="{ data }">
          <div class="text-sm font-semibold">
            <span class="capitalize mr-1">{{ data.value }}</span> ({{ data.count }} {{ $t('forms') }})
          </div>
        </template>
        <template #name="form">
          <div class="whitespace-nowrap">
            <hawk-text :length="60" class="text-xs text-gray-600 mb-0.5 block" :content="get_location_name(form.data.row.original)" />
            <hawk-text :length="60" class="text-gray-900 font-medium" :content="form.data.getValue()" />
          </div>
        </template>
        <template #assignees="assignees">
          <HawkMembers v-if="assignees.data.getValue()?.length" :members="assignees.data.getValue()" type="badge" />
          <span v-else>-</span>
        </template>
        <template #category="category">
          <HawkCategoryName v-if="category.data.getValue()" :uid="category.data.getValue()" />
          <p v-else>
            -
          </p>
        </template>
        <template #due_date="due_date">
          <span class="text-sm whitespace-nowrap" :class="[due_date.data.getValue() && new Date(due_date.data.getValue()) <= new Date() ? 'text-[#B42318]' : 'text-[#475467]']">
            {{ due_date.data.getValue() ? $date(due_date.data.getValue(), 'DD MMMM YYYY') : '-' }}
          </span>
        </template>
        <template #status="status">
          <FormStatus :form="status.data.row.original" />
        </template>
        <template #context_menu="form">
          <slot name="context_menu" :element="form" />
        </template>
      </HawkTable>
    </TableWrapperVue>
    <HawkExportToast
      v-if="is_pdf_exporting"
      v-bind="{
        ...(selected_forms.length > 1 ? {
          completed_text: $t('Export request received'),
          completed_subtitle: $t('You will receive an email to download once the export is completed.'),
        } : {}),
      }"
      :submit="() => formsPDFExportHandler(selected_forms, route.params?.template_uid, () => is_pdf_exporting = false, forms_store)"
      @cancel="is_pdf_exporting = false"
      @close="is_pdf_exporting = false"
    />
  </div>
</template>
