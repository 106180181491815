<script setup>
import { useRoute, useRouter } from 'vue-router';

import DOMPurify from 'dompurify';
import tippy from 'tippy.js';

import { orderBy } from 'lodash-es';
import FamNormalFlowFilters from '~/forms-as-module/components/fam-normal-flow-filters.vue';

import { useFamCustomView } from '~/forms-as-module/composables/fam-custom-view.composable.js';

import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import { useFormDetailStore } from '~/forms/store/form-detail.store.js';
import { useFormsStore } from '~/forms/store/forms.store';
import { useCommonStore } from '~/common/stores/common.store';

import { load_js_css_file } from '~/common/utils/load-script.util';
import { truncate, truncateMiddle } from '~/common/filters/truncate.js';

import {
  addTransparency,
  formattedDate, getLocationName,
  getPriorityObject, hawkCategory,
  hawkUserTeamBadgeGroup, sanitizeLocations,
} from '~/common/composables/kanban-helpers.js';

import { changeIconDimensions } from '~/common/utils/common.utils';
import ChevronRight from '~icons/hawk/chevron-right?raw';

const $t = inject('$t');
const $services = inject('$services');

const { getAdvanceDataFilters, display_filters, setActiveViewID, handleDisplayFilterConfiguration } = useFamCustomView({ feature: 'kanban_view' });
setActiveViewID();
const route = useRoute();
const router = useRouter();

const common_store = useCommonStore();

const forms_store = useFormsStore();
const form_detail_store = useFormDetailStore();
const form_template_detail_store = useFormTemplateDetailStore();

const kanban_ref = ref(null);
const kanban_board = ref(null);
const $additional_filter = ref(null);

const state = reactive({
  is_loading: false,
  page_size: 15,
  page_number: {},
  status_values: [],
  forms: [],
  count: {},
  normal_flow_filters: null,
});

const columns = computed(() => {
  return state.status_values.map(val => ({
    ...val,
    id: val.uid,
    label: `${truncate(val.name, 25)} (${state.count[val.key] || 0})`,
  }));
});

const cards = computed(() => {
  return state.forms.map(form => ({
    ...form,
    label: form.name,
    column: form.status,
    id: form.uid,
  }));
});

const cardShape = computed(() => {
  return {
    label: true,
    description: true,
    progress: true,
    start_date: true,
    end_date: true,
    users: {
      show: false,
    },
    menu: false,
    color: true,
    cover: true,
    attached: false,
  };
});

forms_store.$onAction(async ({ name, after }) => {
  after(async () => {
    if (['create_form', 'delete_form', 'archiveForms'].includes(name)) {
      await getData();
      refreshView();
    }
  });
});

form_detail_store.$onAction(async ({ args, name, after }) => {
  after(() => {
    if (name === 'update_form_details') {
      const form_updates = args[0].body;
      kanban_board.value.updateCard({
        id: form_updates.uid,
        card: form_updates,
        replace: false,
      });
    }
    else if (['approve_form', 'reopen_form', 'save_form', 'submit_form'].includes(name)) {
      const form = form_detail_store.form_detail;
      const status_uid = state.status_values.find(status => status.name === form.status.name)?.uid;
      kanban_board.value.updateCard({
        id: form.uid,
        card: { column: status_uid },
        replace: false,
      });
    }
    // reinitialize tippy for changes
    setTimeout(() => {
      tippy('[data-tippy-content]');
    }, 100);
  });
});

onMounted(async () => {
  state.is_loading = true;
  await getData();
  initialize();
});

onUnmounted(() => {
  kanban_ref.innerHTML = '';
});

async function getData(status = null) {
  /*
    FLOW:
    1. Get data for a particular status if received in argument otherwise fetch for all
    2. If fetching for all clear forms and status values
    3. For each status object received from backend
      3.1. If status was not provided
        3.1.1. Add status object into status values
        3.1.2. Set state count, Reset page_number/column_collapsed of that status
      3.2. Set forms by extracting data from status object
    4. If status was not provided reorder the status values in the state

  */
  const { data } = await $services.forms.get_kanban_data(
    {
      body: {
        scope: 'user',
        page_size: state.page_size,
        page_number: status ? state.page_number[status] : 1,
        group_by: {
          key: 'status',
          uid: status,
        },
        filters: {
          ...(route.params.asset_id && { asset_uid: route.params.asset_id }),
          ongoing: true,
          rolled_back: false,
          template: form_template_detail_store?.form_template_detail?.uid,
          ...(form_template_detail_store.is_template_flow
            ? { advanced_filters: getAdvanceDataFilters($additional_filter) }
            : state.normal_flow_filters),
        },
      },
    });
  if (!status) {
    state.status_values = [];
    state.forms = [];
  }

  Object.keys(data.forms).forEach((key) => {
    const status_obj = data.forms[key];
    if (!status) {
      state.status_values = [...state.status_values, { ...status_obj, key }];
      state.count = data.total_count;
      state.page_number[status_obj.uid] = 1;
    }

    const forms = status_obj.data.map(form => ({ ...form, status: status_obj.uid }));
    state.forms = [...state.forms, ...forms];
  });
  if (!status)
    state.status_values = orderBy(state.status_values, 'order');

  state.is_loading = false;
}

async function applyFilters(data) {
  state.is_loading = true;

  if (!form_template_detail_store.is_template_flow)
    state.normal_flow_filters = data;
  await getData();
  refreshView();
}

function cardTemplate({ cardFields }) {
  const { label, priority } = cardFields;
  const form_priority = getPriorityObject('name')[priority || 'not_set'];
  const location_name = getLocationName(cardFields);
  const category_name = common_store.get_category(cardFields.category)?.name || null;
  const due_date_class = cardFields.due_date && new Date(cardFields.due_date) <= new Date() ? 'text-error-700' : 'text-gray-700';

  const users = common_store.filter_users(cardFields.assignees);
  const teams = common_store.filter_teams(cardFields.assignees);
  const user = common_store.get_user(users?.[0]);

  const sanitized_locations = sanitizeLocations(location_name);

  return `
    <div class="p-3">
        <div class="relative flex justify-between pb-2">
          <div class="flex flex-col">
            <div
              class="text-xs font-regular text-gray-600 flex items-center"
              data-tippy-content="${sanitized_locations.map((name, idx) => {
                  return `${name} ${idx === sanitized_locations.length - 1 ? '' : '&gt; '}`;
              }).join(' ')}"
            >
              <span>${sanitized_locations.length ? sanitized_locations[0] : ''}</span>
              ${sanitized_locations.length > 1
                ? `<span class="flex items-center text-gray-300 mx-1">${changeIconDimensions(ChevronRight, 16, 16)}</span>
                <span>${truncateMiddle(sanitized_locations.at(-1))}</span>`
                : ''}
            </div>
            <div class="flex justify-between break-word text-sm font-medium text-gray-900 pt-1 label-class">
              ${DOMPurify.sanitize(label, { ALLOWED_TAGS: [] }) || $t('Invalid form name')}
            </div>
          </div>
        </div>
        ${
          cardFields.due_date || category_name
          ? `
            <div class="flex justify-between pb-2 pt-2 text-sm font-regular">
              <span class="text-gray-900">
                ${hawkCategory(category_name)}
              </span>
              <span class="${due_date_class}">
                ${formattedDate(cardFields)}
              </span>
            </div>
            `
          : ''
        }
        <div class="flex justify-between">
          <span class="flex items-center ${form_priority.priority_class_icon}">
            ${form_priority.IconToBeDisplayed}
            <span class="pl-1 text-xs ${form_priority.priority_class_label} !flex !items-center">
              ${form_priority?.label}
            </span>
          </span>
          <span class="flex items-center">
            ${hawkUserTeamBadgeGroup(cardFields, users, teams, user) || ''}
          </span>
        </div>
    </div>`;
}

async function onColumnScroll(e) {
  const element = e.target;
  const status = element.parentElement.dataset.dropArea;
  const status_obj = state.status_values.filter(status_item => status_item.uid === status)[0];

  if (state.page_number[status] * state.page_size <= state.count[status_obj.key])
    if (Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) < 1) {
      state.page_number[status] = state.page_number[status] + 1;
      await getData(status);
      refreshView();
    }
}

async function initialize() {
  if (!kanban_ref.value)
    return;

  await load_js_css_file('https://cdn.jsdelivr.net/gh/sensehawk/cdn/dhtmlx-kanban/kanban.js', 'kanban_js', 'js');
  await load_js_css_file('https://cdn.jsdelivr.net/gh/sensehawk/cdn/dhtmlx-kanban/kanban.css', 'kanban_css', 'css');

  kanban_board.value = new kanban.Kanban(kanban_ref.value, {
    cards: cards.value,
    columns: columns.value,
    cardShape: cardShape.value,
    cardTemplate: kanban.template(cardTemplate),
    scrollType: 'column',
    readonly: {
      edit: false,
      add: false,
      select: true,
      dnd: false,
    },
  });

  state.status_values.forEach((status) => {
    const element = document.querySelector(`div[data-column-header="${status.uid}"]`);
    if (element) {
      element.style.backgroundColor = addTransparency(status.color, 0.1);
      element.style.color = status.color;
      element.style.display = 'flex';
      element.style.flexDirection = 'row-reverse';
      element.style.border = '0px';
      if (status.name.length > 25 && element.children?.[1])
        element.children[1].dataset.tippyContent = status.name;
    }
  });

  kanban_board.value.api.on('select-card', ({ id, groupMode }) => {
    if (!id || groupMode)
      return;

    kanban_board.value.api.exec('unselect-card', {});
    router.push({ query: { ...route.query, form: btoa(JSON.stringify({ form_uid: id })) } });
  });

  kanban_board.value.api.on('update-column', ({ id, column }) => {
    if (!(id || col))
      return;

    const status_object = state.status_values.filter(status_obj => status_obj.uid === id)[0];
    const element = document.querySelector(`div[data-drop-area="${id}"]`);
    if (column.collapsed) {
      element.style.backgroundColor = addTransparency(status_object.color, 0.1);
      element.style.color = status_object.color;
      element.style.display = 'flex';
      element.style.flexDirection = 'column';
      element.style.justifyContent = 'center';
      element.style.height = '75vh';
      element.style.marginTop = '-10px';
      element.style.borderTopLeftRadius = '0px';
      element.style.borderTopRightRadius = '0px';
      element.style.borderBottomLeftRadius = '4px';
      element.style.borderBottomRightRadius = '4px';
    }
    else {
      // Reattach column scroll handler and remove collapsed styles
      setTimeout(() => {
        const column_wrapper = document.querySelector(`[data-drop-area="${id}"]`);
        column_wrapper.childNodes?.[0]?.addEventListener('scroll', onColumnScroll);
        element.style = { minHeight: 'auto' };
      }, 0);
    }
  });

  const columns_x = document.getElementsByClassName('wx-list-wrapper');
  columns_x.forEach((column) => {
    column.addEventListener('scroll', onColumnScroll);
  });

  tippy('[data-tippy-content]');
}

function refreshView() {
  kanban_board.value.parse({ cards: cards.value, columns: columns.value });
}
</script>

<template>
  <div class="flex flex-col h-[calc(100vh-136px)] p-4 gap-4 w-screen">
    <div class="flex justify-between">
      <HawkDisplayFilters
        v-if="form_template_detail_store.is_template_flow"
        ref="$additional_filter"
        :display_filters="display_filters"
        @apply="applyFilters"
      />
      <FamNormalFlowFilters v-else @save="applyFilters" />
      <HawkMenu
        v-if="form_template_detail_store?.form_template_detail?.can_modify_template"
        :items="[
          { label: $t('Configure filters'), on_click: () => handleDisplayFilterConfiguration() },
        ]"
      >
        <template #trigger>
          <hawk-button icon type="outlined">
            <IconHawkSettingsOne />
          </hawk-button>
        </template>
      </HawkMenu>
    </div>
    <hawk-loader v-if="state.is_loading" />
    <div v-show="!state.is_loading" class="flex-1">
      <div ref="kanban_ref" class="wx-material-theme w-full h-[calc(100vh-205px)] bg-gray-50" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "~/tasks/styles/kanban-skin.scss";
</style>
