<script setup>
import Dashboard from '~/dashboard/pages/dashboard.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';

const form_template_detail_store = useFormTemplateDetailStore();
const can_modify = form_template_detail_store.form_template_detail.can_modify_template;
const template_name = form_template_detail_store.form_template_detail.name;
</script>

<template>
  <Dashboard :options="{ hide_side_bar: true, hide_dashboard_options: false, can_modify_resource: can_modify, show_top_bar: true }" :template_name="template_name" />
</template>
