<script setup>
import { useModal } from 'vue-final-modal';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import InventoryVendorForm from '~/inventory/components/inventory-settings/inventory-vendor-form.vue';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const emit = defineEmits(['edit', 'delete']);

const { $t, $toast } = useCommonImports();
const inventory_store = useInventoryStore();

const COLUMNS = [
  {
    accessorKey: 'name',
    header: $t('Name'),
    id: 'name',
    static: true,
    render_as: {
      field_type: 'text',
      options: {
        classes: 'font-medium',
      },
    },
  },
  {
    accessorKey: 'description',
    header: $t('Description'),
    id: 'description',
    static: true,
    size: 400,
    render_as: {
      field_type: 'text',
      options: {
        classes: 'text-gray-600',
      },
    },
  },
  {
    accessorKey: 'context_menu',
    header: '',
    id: 'context_menu',
    size: '5',
    show_on_hover: 'true',
  },
];

const freeze_table = ref('-1');

const data = computed(() => inventory_store.vendors);

const { open: openVendorForm, close: closeVendorForm, patchOptions } = useModal({
  component: InventoryVendorForm,
});
function editHandler(vendor) {
  patchOptions(
    {
      attrs: {
        vendor,
        onClose() {
          closeVendorForm();
        },
        on_save: async (data) => {
          try {
            const response = await inventory_store.update_vendor({ payload: data, uid: vendor.uid });
            if (response)
              $toast({
                text: $t('Vendor updated successfully'),
                type: 'success',
              });
          }
          catch (error) {
            $toast({ text: (error?.data?.message || error?.message), type: 'error', title: 'Error' });
          }
        },
      },
    },
  );
  openVendorForm();
}
function createVendor() {
  patchOptions(
    {
      attrs: {
        vendor: null,
        onClose() {
          closeVendorForm();
        },
        on_save: async (data) => {
          try {
            const response = await inventory_store.create_vendor({
              body: {
                vendor: { ...data, custom_fields: [] },
              },
            });
            if (response)
              $toast({
                text: $t('Vendor created successfully'),
                type: 'success',
              });
          }
          catch (error) {
            $toast({ text: (error?.data?.message || error?.message), type: 'error', title: 'Error' });
          }
        },
      },
    },
  );
  openVendorForm();
}

const { open: openDeletePopup, close: closeDeletePopup, patchOptions: patchDeletePopup } = useModal({
  component: HawkDeletePopup,
});
function deleteHandler(vendor) {
  patchDeletePopup(
    {
      attrs: {
        header: $t('Delete vendor'),
        content: `Are you sure you want to delete vendor ${vendor.name}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            const response = await inventory_store.delete_vendor({ uid: vendor.uid });
            if (response)
              $toast({
                text: $t('Vendor deleted successfully'),
                type: 'success',
              });
          }
          catch (error) {
            $toast({ text: (error?.data?.message || error?.message), type: 'error', title: 'Error' });
          }
          closeDeletePopup();
        },
      },
    },
  );
  openDeletePopup();
}
</script>

<template>
  <div>
    <div class="flex items-center w-full justify-between">
      <p class="text-lg font-bold">
        {{ $t('Vendors') }}
      </p>
      <hawk-button @click="createVendor">
        {{ $t('New vendor') }}
      </hawk-button>
    </div>
    <div v-if="!data?.length" class="text-sm text-gray-500 mt-3">
      {{ $t('No vendors added') }}
    </div>
    <TableWrapperVue v-else :height="500" container_class="border mt-3">
      <HawkTable
        :data="data"
        :columns="COLUMNS"
        is_gapless
        cell_height="40px"
        :non_sortable_columns="['context_menu']"
        :show_menu_header="false"
        :freeze_table="freeze_table"
        @rowClicked="e => editHandler(e)"
      >
        <template #context_menu="vendor">
          <hawk-menu
            position="fixed"
            additional_trigger_classes="!ring-0 hover:bg-gray-50"
            :items="[
              {
                uid: 'edit',
                label: $t('Edit'),
                disabled: false,
                on_click: () => editHandler(vendor.data.row.original),
              },
              {
                uid: 'delete',
                label: $t('Delete'),
                disabled: false,
                on_click: () => deleteHandler(vendor.data.row.original),
              },
            ]"
            @click.stop=""
            @open="freeze_table = vendor?.data?.row?.id"
            @close="freeze_table = '-1'"
          />
        </template>
      </HawkTable>
    </TableWrapperVue>
  </div>
</template>
