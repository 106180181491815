<script setup>
import dayjs from 'dayjs';
import { useRoute } from 'vue-router';
import { computed, onMounted, watch } from 'vue';
import { ModalsContainer, useModal } from 'vue-final-modal';
import { v4 as uuidv4 } from 'uuid';
import { useAuthStore } from '~/auth/stores/auth.store';
import { getCookie, getHostName } from '~/common/utils/common.utils.js';
import { useCommonStore } from '~/common/stores/common.store';
import { loadAnalytics, loadAtlas, loadMarker, startAtlas } from '~/common/utils/load-script.util.js';
import { accessTokenCookie, currentOrganizationCookie, purgeCommonStore } from '~/common/utils/common.utils';

import AuthLayout from '~/common/layouts/auth.layout.vue';
import PublicLayout from '~/common/layouts/public.layout.vue';
import DefaultLayout from '~/common/layouts/default.layout.vue';
import Maintenance from '~/common/pages/maintenance.vue';

import TaskDetailsPopupNew from '~/tasks/pages/task-details-new/task-details-popup.vue';
import DetectTimezoneChange from '~/profile-settings/components/detect-timezone-change.vue';
import HawkZipDownloadTrigger from '~/common/components/organisms/hawk-zip-download/hawk-zip-download-trigger.vue';

const route = useRoute();
const common_store = useCommonStore();
const auth_store = useAuthStore();
const is_loading_initial_data = ref(true);

const layout = computed(() => {
  if (auth_store.is_maintenance_enabled())
    return Maintenance;
  else if (route?.meta?.layout === 'public')
    return PublicLayout;
  else if (route?.meta?.layout === 'auth' || !auth_store.access_token)
    return AuthLayout;
  else
    return DefaultLayout;
});

// Can be removed if we call this when clicking in/out of a asset,
// which technically should result in the more or less same memory consumption.
watch(() => common_store.active_asset, () => {
  common_store.add_custom_data_to_marker_io();
});

watch(() => route?.name, setHead, { immediate: true });

auth_store.load_split();

function setHead() {
  const title_prefix = common_store?.active_asset
    ? common_store?.active_asset?.name
    : (auth_store?.current_organization?.name || 'TaskMapper');
  let title = title_prefix;
  if (route?.meta?.title)
    title += ` - ${route.meta.title}`;
  document.title = title;
}

async function initialize() {
  try {
    is_loading_initial_data.value = true;
    loadMarker();

    if (getHostName() !== 'localhost') {
      loadAtlas();
      startAtlas();
      auth_store.setup_idle_tracker();
      if (!sessionStorage.getItem('segment_session_id'))
        sessionStorage.setItem('segment_session_id', uuidv4());
      loadAnalytics();
    }
    if (!accessTokenCookie()) {
      is_loading_initial_data.value = false;
      return;
    }

    if (route?.query?.organization) {
      purgeCommonStore();
      currentOrganizationCookie('set', route.query.organization);
    }

    await common_store.initialize({
      forceUpdate: true,
      toast: false,
    });

    const { open: openTimezonePopup, close: closeTimezonePopup } = useModal({
      component: DetectTimezoneChange,
      attrs: {
        onClose() {
          closeTimezonePopup();
        },
      },
    });
    if (window.Marker)
      window.Marker.on('capture', () => {
        common_store.add_custom_data_to_marker_io();
      });
    const guessed_tz = dayjs.tz.guess();
    const deferred_tz_date = getCookie('defer_tz_change') || 0;
    const user_tz = auth_store?.logged_in_user_details?.timezone;
    if (auth_store?.logged_in_user_details?.user_id && (user_tz !== guessed_tz) && (deferred_tz_date < dayjs().valueOf()))
      openTimezonePopup();
  }
  catch (e) {
    logger.error(e);
  }
  is_loading_initial_data.value = false;
}

onMounted(initialize);
</script>

<template>
  <component :is="layout" />
  <ModalsContainer />
  <template v-if="!is_loading_initial_data && auth_store?.logged_in_user_details?.user_id">
    <HawkZipDownloadTrigger />
    <TaskDetailsPopupNew
      v-if="route?.query?.task || route?.query?.template"
      :key="route?.query?.task || route?.query?.template"
    />

    <FormDetailPopup v-if="route?.query?.form || route?.query?.form_id" :key="route?.query?.form" />
  </template>
</template>
