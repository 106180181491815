<script setup>
import { computed } from 'vue';
import { useModal } from 'vue-final-modal';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';
import { useTransmittalsStore } from '~/dms/store/transmittals.store.js';
import { useTransmittalPermissions } from '~/dms/composables/transmittal-permissions.composable';
import DmsNewIssuePurposeModal from '~/dms/components/settings/dms-new-issue-purpose-modal.vue';

const props = defineProps({
  documents: {
    type: Object,
    default: () => ({}),
  },
  transmittal: {
    type: Object,
  },
});

const route = useRoute();
const form$ = inject('form$');
const transmittals_store = useTransmittalsStore();
const dms_settings_store = useDMSSettingsStore();

const custom_fields = computed(() => dms_settings_store.custom_fields.filter(field => !field.deleted && field.active));

const supporting_documents = computed(() => form$?.value?.data?.supporting_documents);
const transmittal_permissions = useTransmittalPermissions();

const { open: open_new_issue_purpose_popup, close: close_new_issue_purpose_popup, patchOptions: patch_new_issue_purpose_popup } = useModal({
  component: DmsNewIssuePurposeModal,
  attrs: {
    onClose() {
      close_new_issue_purpose_popup();
    },
  },
});

function openIssuePurposeModal() {
  patch_new_issue_purpose_popup({
    attrs: {
      is_edit: false,
      form_values: null,
      onSave(value) {
        form$.value?.elements$?.issue_purpose.update(value?.[0]?.name);
      },
    },
  });
  open_new_issue_purpose_popup();
}

const name$ = ref(null);

function setCreatedCategory(e) {
  form$.value.elements$.category.load(e.uid);
}
</script>

<template>
  <div class="grid gap-5">
    <TextElement
      ref="name$"
      name="name"
      label="Subject"
      :rules="['required']"
    />
    <SelectElement name="issue_purpose" :rules="['required']" :label="$t('Issue purpose')" :placeholder=" $t('Select issue purpose')" value-prop="name" label-prop="name" :items="dms_settings_store?.issue_purposes || []" :can-clear="false" :can-deselect="false" :native="false">
      <template #before-list>
        <p v-if="transmittal_permissions.checkTransmittalPermission({ permission: 'add_document_status' })" class="text-primary hover:bg-gray-100 font-medium rounded-md m-1 mb-0 py-2 px-1.5" @click="openIssuePurposeModal">
          + {{ $t('Create new') }}
        </p>
      </template>
    </SelectElement>
    <!-- Priority -->
    <SelectElement
      v-bind="{
        name: 'priority',
        label: $t('Priority'),
        valueProp: 'name',
        labelProp: 'label',
        placeholder: $t('Select Priority'),
        default: 'not_set',
        items: transmittals_store.priority_values,
        canClear: false,
        canDeselect: false,
        native: false,
      }"
    >
      <template #single-label="{ value }">
        <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
          <TaskPriority :priority="value.value" />
        </div>
      </template>
      <template #option="{ option }">
        <TaskPriority :priority="option.value" />
      </template>
    </SelectElement>
    <hawk-category-input
      :options="{
        name: 'category',
        label: $t('Category'),
        placeholder: $t('Select Category'),
        multi: false,
        create: true,
        from: 'Transmittal',
        canClear: false,
      }"
      :setter_function="setCreatedCategory"
    />
    <ObjectElement name="message">
      <WysiwygEditorElement
        name="body"
        :label="$t('Message')"
        :rules="['required']"
        :options="{
          editor_enabled: true,
          menu_enabled: true,
          plugins: ['tables', 'tags'],
          placeholder_text: $t('Enter info here'),
        }"
      />
      <DmsUploadElement
        name="attachments"
        class="mt-3"
        :label="$t('Supporting documents')"
      />
    </ObjectElement>
    <!-- } -->
    <hawk-custom-fields-input
      name="field_values"
      :configuration="custom_fields"
      :columns="{
        default: { container: 12, wrapper: 12, label: 3 },
        sm: { container: 12, label: 3, wrapper: 12 },
        md: { container: 12, label: 3, wrapper: 12 },
      }"
      :disable_edit="true"
    />
  </div>
</template>
