<script setup>
import axios from 'axios';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  transaction: { type: Object, default: () => ({}) },
});

const { $t, $services, $toast, $track_event } = useCommonImports();
const export_loading = ref(false);

function downloadFile(url, title) {
  axios({ method: 'get', url, responseType: 'arraybuffer' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
      export_loading.value = false;
    })
    .catch(() => {
      export_loading.value = false;
      $toast({
        title: 'Something went wrong',
        text: 'Please try again',
        type: 'error',
      });
    });
}

async function exportTransactions() {
  try {
    export_loading.value = true;
    const { data } = await $services.inventory_adjustments.export_transaction({
      id: props.transaction.uid,
    });

    if ((typeof data.url) === 'string') {
      downloadFile(data.url, `${props?.transaction?.number}.pdf`);
    }
    else {
      export_loading.value = false;
      $toast({
        title: 'Something went wrong',
        text: 'Please try again',
        type: 'error',
      });
    }
  }
  catch (error) {
    export_loading.value = false;
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
    });
  }
}
</script>

<template>
  <div>
    <HawkButton icon type="text" :loading="export_loading" @click="export_loading = true">
      <IconHawkDownloadTwo />
    </HawkButton>
  </div>
  <HawkExportToast
    v-if="export_loading" :submit="exportTransactions"
    :progress_text="$t('Exporting PDF')" :completed_text="$t('Exported PDF')"
  />
</template>
