export const container_schedule_map = {
  'RZVw28YBh9': '0XX8Ov_ka',
  'C3JA1HFQh4': 'w4pzx8WIU',
  '8DtjXYIJzS': 'f9P1fx2j8',
  '63J53GayIz': 'Z65opTLin',
  'ptrNkXvosG': 'RNKCCehu7',
  'YdCAJi2zeX': 'UNMfnod9o',
  'ZnaWAwHOiw': 'pZig1r6Re',
};

export const color_by_menu = [
  {
    label: 'Percentage',
    value: 'Percentage',
  },
  {
    label: 'Status',
    value: 'Status',
  },

  {
    label: 'Work rate',
    value: 'Work rate',
  },

];

export const range_dates = [
  {
    label: 'Last 120 days',
    value: 120,
  },
  {
    label: 'Last 90 days',
    value: 90,
  },
  {
    label: 'Last 60 days',
    value: 60,
  },
  {
    label: 'Last 45 days',
    value: 45,
  },
  {
    label: 'Last 30 days',
    value: 30,
  },
  {
    label: 'Last 14 days',
    value: 14,
  },
];
export const status_color_map = {

  'Not Started': 'gray',
  'On Track': 'yellow',
  'On pace': 'yellow',
  'In Progress': 'yellow',
  'Ahead': 'blue',
  'Completed': 'green',
  'Delayed': 'red',
  'Behind': 'red',

};
export const color_by_status_menu = [
  {
    name: 'Not Started',
    color: `bg-${status_color_map['Not Started']}-500`,
  },
  {
    name: 'Behind',
    color: `bg-${status_color_map.Behind}-500`,
  },
  {
    name: 'On pace',
    color: `bg-${status_color_map['On pace']}-500`,
  },
  {
    name: 'Ahead',
    color: `bg-${status_color_map.Ahead}-500`,
  },
  {
    name: 'Completed',
    color: `bg-${status_color_map.Completed}-500`,
  },
];
export const color_by_percentage_menu = [
  {
    name: '0 to 20%',
    color: 'bg-red-500',
  },
  {
    name: '20 to 40%',
    color: 'bg-warning-500',
  },
  {
    name: '40 to 60%',
    color: 'bg-yellow-500',
  },
  {
    name: '60 to 80%',
    color: 'bg-lime-500',
  },
  {
    name: '80 to 100%',
    color: 'bg-success-500',
  },
];
export const color_by_work_rate_menu = [
  {
    name: 'Not Started',
    color: `bg-${status_color_map['Not Started']}-500`,
  },
  {
    name: 'Behind',
    color: `bg-${status_color_map.Behind}-500`,
  },
  {
    name: 'Ahead',
    color: `bg-${status_color_map.Ahead}-500`,
  },
  {
    name: 'Completed',
    color: `bg-${status_color_map.Completed}-500`,
  },
];

export const sheet_column_titles = ['Number', 'Activity', 'Workflow', 'Layer', 'UOM', 'Scope', 'Completed', 'Remaining', '% Progress', 'Status', 'Planned Start', 'Planned Finish', 'Expected Work rate', 'Actual Start', 'Actual Finish', 'Est. Finish date'];

export const sheet_status_map = {
  'behind': 'f4cccc',
  'completed': 'd9ead3',
  'ahead': 'c9daf8',
  'on track': 'fce5cd',
  'on pace': 'fce5cd',
  'not started': 'd9d9d9',
};
