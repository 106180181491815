<script setup>
import { compact } from 'lodash-es';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import HawkAssigneeInput from '~/common/components/vueform/hawk-assignee-input.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  item: { type: Object, default: () => {} },
  aggregation_fields: { type: Array, default: () => ([]) },
});

const emit = defineEmits(['close', 'update']);
const inventory_store = useInventoryStore();
const { $t, $services, $toast } = useCommonImports();
const form$ = ref({});

const item_details = computed(() => inventory_store.get_active_item_details);

const can_edit_field = computed(() => !!compact(props.aggregation_fields.map(field => field.can_edit)).length);

async function save() {
  try {
    const values = {};
    const form_value = form$.value.data;

    for (const property in form_value)
      if (['batch_number', 'location_id', 'stock_status'].includes(property))
        delete form_value[property];
      else if (form_value[property] === props.item[property])
        delete form_value[property];
      else
        values[property] = form_value[property];

    const payload = {
      filters: props.item,
      values,
    };
    const { data } = await $services.inventory_items.update_stock_item_aggregate({
      id: item_details.value.uid,
      body: payload,
    });
    if (data === 'Successfully Updated')
      emit('update');
    $toast({
      title: 'Success',
      text: 'Updated successfully',
      type: 'success',
    });
    emit('close');
  }
  catch (error) {
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
    });
  }
}

function get_component(field) {
  if (!field)
    return;

  switch (field?.type) {
    case 'text':
    case 'number':
    case 'email':
    case 'url':
    case 'phone_number':
      return 'TextElement';
    case 'member':
      return HawkAssigneeInput;
    case 'members':
      return HawkAssigneeInput;
    case 'dropdown':
      return 'SelectElement';
    case 'checkboxes':
      return 'CheckboxgroupElement';
    case 'date':
      return 'DateTimeElement';
    case 'signature':
      return 'SignatureElement';
  }
}

function getValue(value, field) {
  if (field.id === 'location_id')
    return inventory_store.get_location_details({
      uid: value,
      type: props.item.location_type,
    })?.name || '';
  else if (field.id === 'stock_status')
    return inventory_store.statuses_map[value]?.name;
  return value;
}

function getProps(field) {
  if (!field)
    return;

  const attrs = {
    name: field.id,
    label: field.header,
    placeholder: `Enter ${field.name}`,
    rules: [],
    description: field.description,
    disabled: !field?.can_edit,
    default: getValue(props.item[field.id], field),
  };

  if (field.config)
    attrs.items = field.config.map(item => ({ value: item, label: item }));

  switch (field?.type) {
    case 'text':
      return attrs;
    case 'number':
      attrs.inputType = 'number';
      attrs.rules.push(...['nullable', 'numeric']);
      return attrs;
    case 'dropdown':
      attrs.placeholder = `Select ${field.name}`;
      attrs.search = true;
      attrs.native = false;
      attrs.inputType = 'search';
      attrs.autocomplete = 'off';
      return attrs;
    case 'date':
      return attrs;
    default:
      return attrs;
  }
}
</script>

<template>
  <HawkModalContainer content_class="w-[400px] rounded-lg">
    <Vueform
      ref="form$"
      size="sm"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { label: 4 },
        md: { label: 4 },
        lg: { label: 4 },
      }"
      :format-load="(data) => data"
      :endpoint="save"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            {{ $t('Aggregate') }}
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <div class="grid gap-4">
            <div v-for="field in aggregation_fields" :key="field.id">
              <component
                :is="get_component(field)"
                v-if="get_component(field)"
                v-bind="getProps(field)"
              />
            </div>
          </div>
        </HawkModalContent>
        <HawkModalFooter v-if="can_edit_field">
          <template #right>
            <div class="flex justify-end gap-4">
              <HawkButton type="outlined" @click="emit('close')">
                {{ $t('Close') }}
              </HawkButton>
              <ButtonElement submits size="sm" name="submit" :button-label="$t('Save')" button-class="vf-btn-primary" />
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
