<script setup>
const props = defineProps({
  quantity: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['cancel', 'save']);

const $t = inject('$t');
</script>

<template>
  <HawkModalContainer>
    <HawkModalHeader @close="emit('cancel')">
      <template #title>
        <HawkFeaturedIcon theme="light-circle-outline" color="warning" size="md">
          <IconHawkInfoCircle class="text-error-600" />
        </HawkFeaturedIcon>
        <div>
          {{ $t('Serial / Pallet numbers not available') }}
        </div>
      </template>
      <template #subtitle>
        {{ `You entered ${props.quantity} invalid serial number${props.quantity === 1 ? '' : 's'}. Would you like to store invalid serial numbers and proceed?` }}
      </template>
    </HawkModalHeader>
    <HawkModalFooter class="flex justify-between items-center">
      <template #right>
        <!-- Footer -->
        <div class="flex justify-end items-center">
          <hawk-button class="mr-2" type="outlined" color="gray" @click="emit('cancel')">
            {{ $t('Cancel') }}
          </hawk-button>
          <HawkButton @click="emit('save')">
            {{ $t('Proceed') }}
          </HawkButton>
        </div>
      </template>
    </HawkModalFooter>
  </HawkModalContainer>
</template>
