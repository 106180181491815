<script setup>
import { useRoute, useRouter } from 'vue-router';

// composables
import { useTransmittalPermissions } from '~/dms/composables/transmittal-permissions.composable';

// stores
import { useAuthStore } from '~/auth/stores/auth.store';

const route = useRoute();
const router = useRouter();
const transmittal_permissions = useTransmittalPermissions();
const auth_store = useAuthStore();

// Form List page navigation
const tabs = computed(() => [{
  uid: 'files-documents',
  label: 'Documents',
  to: { name: 'files-documents', params: { asset_id: route.params.asset_id } },
  disabled: false,
}, ...(auth_store.check_split('files_v2_advanced')
  ? [{
      uid: 'files-transmittals',
      label: 'Transmittals',
      to: { name: 'files-transmittals', params: { asset_id: route.params.asset_id } },
      disabled: false,
    }]
  : []),
]);

const active_tab = computed(() => route.matched.findIndex(route => route.name === 'files-documents') >= 0 ? 'files-documents' : 'files-transmittals');
</script>

<template>
  <div class="flex min-h-full flex-col justify-center">
    <HawkPageHeader v-if="!route.path.includes('/settings')" :title="$t('Files')">
      <template #left>
        <hawk-page-header-tabs :tabs="tabs" :active_item="active_tab" />
      </template>
      <template #right>
        <HawkButton v-if="auth_store.check_split('files_v2_advanced') && transmittal_permissions.checkTransmittalPermission({ permission: 'setting_icon' })" type="outlined" @click="router.push({ name: 'dms-settings', params: { asset_id: route.params.asset_id } });">
          <IconHawkSettingsOne class="h-5 w-5" />
        </HawkButton>
        <HawkOnboardingInfo on_boarding_for="files" />
      </template>
    </HawkPageHeader>
    <div class="px-4">
      <router-view />
    </div>
  </div>
</template>
