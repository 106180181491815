<script setup>
import { isEqual, startsWith, trim } from 'lodash-es';

import { storeToRefs } from 'pinia';

import { watch } from 'vue';
import { convertTableUnitsData } from '~/common/utils/common.utils.js';
import { useDashboardScheduleStore } from '~/dashboard/store/dashboard-schedule.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  content_height: {
    type: Number,
  },
});
const $services = inject('$services');
const loading = ref(false);
const activities = ref([]);
const columns = ref([]);
const payload = ref(null);
const dashboard_schedule_store = useDashboardScheduleStore();
const dashboard_store = useDashboardStore();
const no_data = ref(false);
const prevent_watcher = ref(false);
const widget_data = computed(() => props.data?.data || {});

const {
  schedule_configuration,
} = storeToRefs(dashboard_schedule_store);

async function getReports() {
  loading.value = true;
  payload.value = dashboard_schedule_store.parse_schedule_form_to_server_format(widget_data.value);

  try {
    const { data } = await $services.project_management.get_graph({ body: payload.value });
    if (data && Object.keys(data)) {
      activities.value = generateActivities(data.data);
      columns.value = generateColumns();
      no_data.value = false;
    }
    else {
      no_data.value = true;
      activities.value = [];
      columns.value = [];
      loading.value = false;
    }
    loading.value = false;
  }
  catch (err) {
    loading.value = false;
  }
}

function generateActivities(data) {
  const array = [];
  for (const [key, value] of Object.entries(data)) {
    const current_item = {};

    current_item.activity_id = key;
    const field_names = ['name', 'actual_progress', 'planned_progress', 'absolute_remaining_duration', 'status'];
    for (const [nestedKey, nestedValue] of Object.entries(value))
      if (nestedKey && !field_names.includes(nestedKey))
        current_item[`${nestedKey}-${nestedValue}`] = convertTableUnitsData(nestedKey, nestedValue);

      else if (nestedKey === 'name')
        current_item.name = nestedValue;

      else if (nestedKey === 'id')
        current_item.id = nestedValue;
      else if (nestedKey === 'absolute_remaining_duration')
        current_item[nestedKey] = value.status === 'completed' ? '-' : startsWith(nestedValue, '-') ? `${trim(nestedValue, '-')} days overdue` : `${nestedValue} days remaining`;

      else current_item[nestedKey] = convertTableUnitsData(nestedValue, null);

    array.push(current_item);
  }
  return array;
}

const columns_widths_map = computed(() => widget_data.value.columns_widths || {});

function generateColumns() {
  const defined_columns = {
    name: 'Project name',
    actual_progress: 'Actual progress',
    planned_progress: 'Planned progress',
    absolute_remaining_duration: 'Duration',
    status: 'Status',
  };
  const column_array = [];
  for (const [key, value] of Object.entries(defined_columns))
    column_array.push({
      accessorKey: key,
      id: key,
      header: value,
      size: columns_widths_map.value?.[key] || '400',
    });

  return column_array;
}

function forceUpdate() {
  const copy = markRaw(activities.value);
  activities.value = [...copy];
}

const status_color = {
  'On track': 'blue',
  'Delayed': 'red',
  'Completed': 'green',
  'Not Started': 'gray',
};

const height = computed(() => {
  return ((props.data.h || 22) * 20) - 44;
});

// function formatTable(cell) {
//   // this is failing when expanding rows, might be the issue for the flickering
//   if (startsWith(cell.getValue(), '-'))
//     return { color: '#DC2626' };
//   return {};
// }

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: widget_data.value.type,
    renderAt: `chart-container-${props?.id}`,
    renderType: 'table',
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: widget_data.value.name,
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      columns: columns.value,
      activities: activities.value,
      dataset: activities.value,
      is_transpose: widget_data.value.transpose === true,
      dashboard_index: props.data.i,
      is_new_pivot_chart: widget_data.value.chart === 'workflow_pivot_table',
    },
  });
}

function columnResized(_resized_column, columns_widths) {
  // prevents the table from rerendering
  prevent_watcher.value = true;
  dashboard_store.set_table_column_widths(
    props?.id,
    columns_widths,
  );
}

const force_update = ref(0);

watch(() => props.data.data, async (new_val, old_val) => {
  if (
    new_val && !isEqual(new_val, old_val)
  ) {
    if (prevent_watcher.value) {
      prevent_watcher.value = false;
      return;
    }
    await getReports();

    force_update.value += 1;
    if (props?.id !== 'preview')
      updatePrintMap();
  }
}, { immediate: true }, { deep: true });
</script>

<template>
  <div>
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>

    <hawk-loader v-if="loading" />
    <div v-else-if="no_data || !activities?.length" class="text-sm font-semiBold w-full" :class="dashboard_store.is_mobile_view ? 'h-[240px] grid place-items-center' : 'mt-8 flex justify-center'">
      {{ $t('No data present') }}
    </div>
    <a v-if="activities && columns?.length && activities?.length && !loading">

      <div class="w-full scrollbar" :style="{ height: `${content_height || height}px` }">
        <HawkTable
          :key="force_update"
          :data="activities"
          :columns="columns"
          freeze_column_id="name"
          :show_menu_header="false"
          :show_column_borders="true"
          additional_table_classes=""
          :container_class="`h-[${content_height || height}px]`"
          :striped="true"
          is_gapless
          :disable_resize="!dashboard_store.is_editing_dashboard"
          cell_height="30px"
          @column-resized="columnResized"
        >
          <template #status="status">
            <HawkBadge :color="status_color[status.data.getValue()]">
              {{ status.data.getValue() }}
            </HawkBadge>
          </template>
        </HawkTable>
      </div>
    </a>
  </div>
</template>
