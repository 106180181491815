<script setup>
import { debounce } from 'lodash-es';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { useModal } from 'vue-final-modal';
import { RRule } from 'rrule';
import { useRoute } from 'vue-router';

import { useAuthStore } from '~/auth/stores/auth.store';

import TaskSchedule from '~/tasks/components/organisms/task-schedule.vue';
import TaskSheetThumbnail from '~/tasks/components/organisms/task-sheet-thumbnail.vue';
import EmptyStaticElement from '~/tasks/components/atoms/empty-static-element.vue';
import AssociatedResourceDetails from '~/tasks/components/organisms/associated-resource-details.vue';

import { getDurationAndPeriod, useTasksPermissions } from '~/tasks/composables/task-composables.js';

const HawkWysiwygEditorComponent = defineAsyncComponent(() => import('~/common/components/organisms/hawk-wysiwyg-editor/hawk-wysiwyg-editor.vue'));

dayjs.extend(isSameOrAfter);

const task_store = inject('task_store');
const { task_uid } = inject('task-uid');
const is_template = inject('is-template');
const updateFunction = inject('updateFunction');

const authStore = useAuthStore();
const route = useRoute();

const { checkTaskPermission } = useTasksPermissions();

const date_time_element_classes = {
  ElementLayout: {
    container: 'relative',
    innerWrapper: 'hover:bg-gray-50 rounded-md',
  },
};

const input_toggle_status = ref({
  start_date: false,
  due_date: false,
  category: false,
  assignees: false,
  tags: false,
});

const form$ = ref(null);
const additional_resource_details = ref({});

const wysiwyg_ctx = ref(null);
const new_description_text = ref('');
const initial_description_text = ref('');
const description_editor_enabled = ref(false);

const fields = computed(() => {
  if (is_template)
    return [
      'assignees',
      'priority',
      'category',
      'tags',
      'description',
    ];
  return [
    'assignees',
    'start_date',
    'due_date',
    'priority',
    'category',
    'tags',
    'description',
  ];
});

const task_details = computed(() => {
  return task_uid.value ? task_store.get_current_task(task_uid.value) : {};
});

const sheet_data = computed(() => {
  if (task_details.value?.target_element?.type === 'sheet')
    return task_details.value.target_element;
  return null;
});

const recurrence_text = computed(() => {
  if (task_details.value.schedule?.rrule) {
    return `Schedule: ${RRule.fromString(task_details.value.schedule.rrule).toText()}`;
  }
  else if (task_details.value.duration && is_template) {
    const { value, period } = getDurationAndPeriod(task_details.value.duration);
    return `${value} ${period}`;
  }
  return null;
});

const can_modify_task = computed(() => {
  return checkTaskPermission({ permission: 'can_modify', instance: task_details.value });
});

const cursor_classes = computed(() =>
  ({
    TagsElement: {
      select: {
        wrapper: can_modify_task.value ? '' : 'cursor-pointer',
      },
    },
    SelectElement: {
      select: {
        wrapper: can_modify_task.value ? '' : 'cursor-pointer',
      },
    },
  }),
);

onMounted(() => {
  form$.value.load(task_details.value, true);
  initial_description_text.value = task_details.value?.description || '';
});

watch(task_details, (newVal) => {
  form$.value.load(newVal, true);
  initial_description_text.value = newVal?.description || '';
});

// SCHEDULE POPUP
const { open: openRecurrencePopup, close: closeRecurrencePopup, patchOptions: patchRecurrencePopup } = useModal({
  component: TaskSchedule,
});
function setRecurrence() {
  patchRecurrencePopup({
    attrs: {
      task_uid: task_uid.value,
      is_template,
      duration: task_details.value.duration,
      schedule: task_details.value.schedule,
      on_update: updateFunction,
      onClose() {
        closeRecurrencePopup();
      },
    },
  });
  openRecurrencePopup();
}

function formatLoadedData(data) {
  const load_data = {};
  if (data)
    fields.value.forEach((key) => {
      if (data[key])
        if (key === 'start_date' || key === 'due_date')
          load_data[key] = new Date(data[key]);
        else
          load_data[key] = data[key];
    });
  return load_data;
}

// SET/UPDATE/DELETE/REVERT METHODS
async function deleteRecurrence() {
  const payload = {};
  if (task_details.value.duration)
    payload.duration = null;
  else
    payload.schedule = null;
  await updateFunction(payload);
}

async function updateLocation(payload) {
  try {
    const update_payload = payload;
    if (task_details.value?.properties)
      update_payload.properties = { ...task_details.value.properties, ...payload.properties };
    await updateFunction(update_payload);
    task_store.task_track_events('Location changed', {}, task_details.value?.uid, ['associated_with']);
  }
  catch (error) {
    logger.log(error);
    return error;
  }
}

const updateDescription = debounce((e) => {
  if (e !== initial_description_text.value) {
    new_description_text.value = e;
    update('description');
  }
}, 750);

async function updateDate(key) {
  // check if dates have changed
  const oldValue = task_details.value[key] ? dayjs(task_details.value[key]).format('YYYY-MM-DD') : null;
  const newValue = form$.value.data[key] ? dayjs(form$.value.data[key]).format('YYYY-MM-DD') : null;
  if (oldValue !== newValue)
    await update(key);
}

function setCreatedCategory(e) {
  form$.value.elements$.category.load(e.uid);
  update('category');
}
function setCreatedTag(e) {
  form$.value.elements$.tags.load([...form$.value.data.tags, e.uid]);
  update('tags');
}

async function update(key) {
  const payload = {};

  if (key === 'description') {
    payload.description = new_description_text.value;
    initial_description_text.value = new_description_text.value;
  }
  else if (key === 'assignees') {
    payload.assignees = form$.value.data.assignees?.map(obj => obj.uid);
  }
  else if (key === 'start_date') {
    if (form$.value.data[key] !== null)
      payload[key] = dayjs(form$.value.data[key]).startOf('day').toISOString();
  }
  else if (key === 'due_date') {
    if (form$.value.data[key] !== null)
      payload[key] = dayjs(form$.value.data[key]).endOf('day').toISOString();
  }
  else {
    payload[key] = form$.value.data[key];
  }
  if (Object.keys(payload).length)
    updateFunction(payload);

  // After update cleanup
  if (Object.keys(input_toggle_status.value).includes(key))
    input_toggle_status.value[key] = false;
}
</script>

<template>
  <div>
    <Vueform
      v-if="task_details"
      ref="form$" size="sm"
      :format-load="formatLoadedData"
      :presets="['static_display']"
      :override-classes="{
        ElementLayout: {
          innerContainer: '',
          innerWrapper: '',
          outerWrapper: '',
        },
        ElementLabel: {
          container: 'text-gray-600 text-xs',
          wrapper: 'ml-[12px]',
        },
      }"
      :add-classes="{ ElementLabel: { container_horizontal_sm: 'text-type:form-pt-input-border-sm', container_horizontal_sm_SM: 'sm:text-type:form-pt-input-border-sm', container_horizontal_sm_MD: 'md:text-type:form-pt-input-border-sm' } }"
    >
      <!-- Assignees -->
      <hawk-assignee-input
        v-if="can_modify_task || task_details.assignees.length"
        v-click-outside="() => input_toggle_status.assignees && update('assignees')"
        :options="{
          name: 'assignees',
          label: $t('Assignees'),
          canClear: false,
          placeholder: 'Select assignees',
          size: 'sm',
          disabled: !can_modify_task,
          has_teams: true,
        }"
        :multi="true"
        :value="task_details.assignees"
        :asset_id="task_details.target_element.asset"
        :tags_removable="input_toggle_status.assignees"
        :format_load="true"
        :add-classes="{
          TagsElement: {
            select: {
              tagsSearch: input_toggle_status.assignees ? '' : 'cursor-pointer',
              wrapper: 'px-2',
            },
          },
        }"
        :override-classes=" {
          ElementLayout: {
            container_sm: '!col-span-4',
          },
        }"
        :remove-classes="cursor_classes"
        @open="input_toggle_status.assignees = true"
      />
      <EmptyStaticElement
        v-else
        :options="{
          overrideClasses: {
            ElementLayout: {
              container_sm: '!col-span-4',
            },
          },
        }"
        :label="$t('Assignees')"
      />

      <!-- Category -->
      <hawk-category-input
        v-if="can_modify_task || task_details.category"
        v-click-outside="() => input_toggle_status.category = false"
        :options="{
          name: 'category',
          label: $t('Category'),
          placeholder: $t('Select Category'),
          multi: false,
          canClear: false,
          create: can_modify_task,
          from: 'Tasks',
        }"
        :truncate_width="12"
        :asset_id="task_details.target_element.asset"
        :disabled="!can_modify_task"
        :override-classes=" {
          ElementLayout: {
            container_sm: '!col-span-2',
          },
        }"
        :add-classes="{
          ElementLayout: {
            container: 'relative',
          },
          SelectElement: {
            select: {
              search: input_toggle_status.category ? '' : 'cursor-pointer',
            },
          },
        }"
        :remove-classes="cursor_classes"
        :setter_function="setCreatedCategory"
        @select="update('category')"
        @deselect="update('category')"
        @open="input_toggle_status.category = true"
      >
        <template #after>
          <div v-if="input_toggle_status.category && task_details.category" class="flex items-center justify-center absolute right-8 top-8">
            <IconHawkTrashThree
              class="cursor-pointer text-gray-600"
              @click.stop="() => { updateFunction({ category: null }); input_toggle_status.category = false }"
            />
          </div>
        </template>
      </hawk-category-input>
      <EmptyStaticElement
        v-else
        :options="{
          overrideClasses: {
            ElementLayout: {
              container_sm: '!col-span-2',
            },
          },
        }"
        :label="$t('Category')"
      />

      <!-- Priority -->
      <SelectElement
        v-if="can_modify_task || task_details.priority !== 5"
        v-bind="{
          name: 'priority',
          label: $t('Priority'),
          placeholder: $t('Select Priority'),
          items: task_store.priority_values,
          canClear: false,
          canDeselect: false,
          native: false,
          disabled: !can_modify_task,
        }"
        :override-classes=" {
          ElementLayout: {
            container_sm: '!col-span-2',
          },
        }"
        :remove-classes="cursor_classes"
        @select="update('priority')"
      >
        <template #single-label="{ value }">
          <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
            <TaskPriority :priority="value.value" />
          </div>
        </template>
        <template #option="{ option }">
          <TaskPriority :priority="option.value" />
        </template>
      </SelectElement>
      <EmptyStaticElement
        v-else
        :options="{
          overrideClasses: {
            ElementLayout: {
              container_sm: '!col-span-2',
            },
          },
        }"
        :label="$t('Priority')"
      />

      <!-- Start Date -->
      <DateTimeElement
        v-if="!is_template && (can_modify_task || task_details.start_date)"
        :key="task_details.due_date"
        v-click-outside="() => input_toggle_status.start_date = false"
        class="date_time"
        name="start_date"
        :label="$t('Start Date')"
        :add-classes="date_time_element_classes"
        :override-classes=" {
          ElementLayout: {
            container_sm: '!col-span-2',
          },
        }"
        :options="{
          clearable: false,
          format: 'dd MMMM yyyy',
          placeholder: $t('Select Start Date'),
          disabled: !can_modify_task,
          startTime: { hours: 0, minutes: 0 },
          maxDate: task_details.due_date ? new Date(task_details.due_date) : null,
        }"
        @update:model-value="updateDate('start_date')"
        @open="input_toggle_status.start_date = true"
      >
        <template #after>
          <div
            v-if="input_toggle_status.start_date && task_details.start_date"
            class="flex items-center justify-center absolute right-2 top-8"
          >
            <IconHawkTrashThree
              class="cursor-pointer text-gray-600"
              @click="() => { updateFunction({ start_date: null }); input_toggle_status.start_date = false }"
            />
          </div>
        </template>
      </DateTimeElement>
      <EmptyStaticElement
        v-else-if="!is_template && !(can_modify_task || task_details.start_date)"
        :options="{
          overrideClasses: {
            ElementLayout: {
              container_sm: '!col-span-2',
            },
          },
        }"
        :label="$t('Start Date')"
      />

      <!-- Due Date -->
      <DateTimeElement
        v-if="!is_template && (can_modify_task || task_details.due_date)"
        :key="task_details.start_date"
        v-click-outside="() => input_toggle_status.due_date = false"
        class="date_time"
        :class="{ overdue: dayjs().isSameOrAfter(dayjs(task_details.due_date)) }"
        name="due_date"
        :label="$t('Due Date')"
        :add-classes="date_time_element_classes"
        :override-classes=" {
          ElementLayout: {
            container_sm: '!col-span-2',
          },
        }"
        :options="{
          clearable: false,
          format: 'dd MMMM yyyy',
          placeholder: $t('Select Due Date'),
          disabled: !can_modify_task,
          startTime: { hours: 23, minutes: 59 },
          minDate: task_details.start_date ? new Date(task_details.start_date) : null,
        }"
        @update:model-value="updateDate('due_date')"
        @open="input_toggle_status.due_date = true"
      >
        <template #after>
          <div class="flex items-center justify-center absolute right-2 top-8">
            <IconHawkSchedule
              v-if="recurrence_text && !input_toggle_status.due_date"
              v-tippy="recurrence_text"
              class="cursor-pointer"
              @click.stop="can_modify_task ? setRecurrence() : () => {}"
            />
            <div v-if="input_toggle_status.due_date" class="flex items-center">
              <IconHawkTrashThree
                v-if="task_details.due_date"
                class="cursor-pointer text-gray-600"
                @click="() => { updateFunction({ due_date: null }); input_toggle_status.due_date = false }"
              />
            </div>
          </div>
        </template>
      </DateTimeElement>
      <EmptyStaticElement
        v-else-if="!is_template && !(can_modify_task || task_details.due_date)"
        :options="{
          overrideClasses: {
            ElementLayout: {
              container_sm: '!col-span-2',
            },
          },
        }"
        :label="$t('Due Date')"
      />

      <StaticElement
        v-if="is_template && (can_modify_task || recurrence_text)"
        name="schedule"
        :label="$t('Duration')"
        :disabled="!can_modify_task"
        :override-classes=" {
          ElementLayout: {
            container_sm: '!col-span-4',
          },
        }"
      >
        <template #default>
          <div class="min-h-[36px] px-3 flex items-center justify-between hover:bg-gray-50 rounded-lg group">
            <div class="w-full" @click="setRecurrence">
              <div v-if="recurrence_text" class="flex items-center text-sm">
                <p class="text-ellipsis py-1 cursor-pointer">
                  {{ recurrence_text }}
                </p>
              </div>
              <p v-else class="text-gray-500 text-sm cursor-pointer">
                {{ is_template ? $t('Set duration') : $t('Set schedule') }}
              </p>
            </div>
            <div v-if="recurrence_text" class="flex items-center invisible group-hover:visible cursor-pointer" @click="deleteRecurrence">
              <IconHawkTrashThree class="text-gray-600" />
            </div>
          </div>
        </template>
      </StaticElement>
      <EmptyStaticElement
        v-else-if="is_template && !(can_modify_task || recurrence_text)"
        :options="{
          overrideClasses: {
            ElementLayout: {
              container_sm: '!col-span-4',
            },
          },
        }"
        :label="$t('Duration')"
      />

      <!-- Description -->
      <StaticElement
        v-if="can_modify_task || task_details.description"
        v-click-outside="() => description_editor_enabled = false"
        name="description"
        :has-label="false"
        :add-classes="{
          ElementLayout: {
            container: description_editor_enabled ? '' : '!cursor-pointer',
          },
        }"
        @click="task_details.can_modify && (description_editor_enabled = true)"
      >
        <template #default>
          <HawkWysiwygEditorComponent
            :placeholder_text="$t('Add Description')"
            :initial_content="initial_description_text"
            :editor_enabled="description_editor_enabled"
            :class="{ 'border-primary-500 outline outline-4 outline-primary-100': description_editor_enabled }"
            class="flex items-center border border-gray-300 rounded-lg w-full"
            focus_position="end"
            @input="updateDescription"
            @ctrlEnter="updateDescription"
            @initialized="$event => wysiwyg_ctx = $event"
          />
        </template>
      </StaticElement>
      <EmptyStaticElement v-else :label="$t('Description')" />

      <!-- Location -->
      <element-hierarchy
        v-if="!is_template"
        name="location"
        :label="`${$t('Location')}: `"
        :target_element="task_details.target_element"
        :update_function="updateLocation"
        :disabled="!can_modify_task"
        :vueform_classes="{
          'add-classes': {
            ElementLayout: {
              innerContainer: 'block w-full',
              innerWrapperAfter: 'relative',
              outerWrapper: 'flex',
            },
            ElementLabel: {
              container: 'mr-6 mt-[7.6px]',
            },
          },
        }"
        @hierarchyData="$event => additional_resource_details = $event"
      />

      <!-- Sheet -->
      <StaticElement
        v-if="!is_template && sheet_data"
        name="sheet"
        :label="`${$t('Sheet')}: `"
        :add-classes="{
          ElementLabel: {
            container: 'mr-12',
          },
          ElementLayout: {
            innerWrapper: 'min-w-[160px]',
            outerWrapper: 'flex',
          },
        }"
        :override-classes=" {
          ElementLayout: {
            container_sm: `!col-span-6`,
          },
        }"
      >
        <template #default>
          <TaskSheetThumbnail
            :task_uid="task_details.uid"
            :sheet="sheet_data"
            :location="task_details.properties"
          />
        </template>
      </StaticElement>

      <!-- Tags -->
      <hawk-tags-input
        v-if="can_modify_task || task_details.tags.length"
        v-click-outside="() => input_toggle_status.tags && update('tags')"
        :options="{
          name: 'tags',
          label: `${$t('Tags')}: `,
          placeholder: $t('Select Tags'),
          canClear: false,
          create: can_modify_task,
          disabled: !can_modify_task,
        }"
        :tags_removable="input_toggle_status.tags"
        :value="task_details.tags"
        :add-classes="{
          ElementLayout: {
            innerContainer: 'w-full',
            outerWrapper: 'flex',
          },
          ElementLabel: {
            container: 'mr-12',
          },
        }"
        :remove-classes="cursor_classes"
        :setter_function="setCreatedTag"
        @open="input_toggle_status.tags = true"
      />
      <EmptyStaticElement v-else :label="$t('Tags')" />
    </Vueform>
    <AssociatedResourceDetails
      class="mt-2 pl-3"
      :resource_details="additional_resource_details"
    />
  </div>
</template>

<style lang="scss" scoped>
.date_time {
  :deep(.dp__input){
    border: 0 solid #3b82f6;
    background-color: transparent;
    padding-left: 12px;
  }
  :deep(.dp__input:focus){
    border: 1px solid;
  }

  :deep(.dp__icon) {
    display: none;
  }
}
.overdue {
  :deep(.dp__input){
    @apply text-error-700
  }
}
</style>

<style lang="scss">
.static-display {
  border: 1px solid transparent !important;

  .caret {
    visibility: hidden;
  }
  .select-caret {
    visibility: hidden;
  }
  &:hover {
    border: 1px solid transparent !important;
    background: #F9FAFB !important;
    .caret {
      visibility: visible;
    }
    .select-caret {
      visibility: visible;
    }
  }

}

.static-display-focused{
  border: 1px solid transparent !important;

  &:focus-within {
    border: 1px solid #1570EF;
    border-radius: 8px;
    .caret {
      visibility: visible;
    }
    .select-caret {
      visibility: visible;
    }
  }
}

.static-display-disabled{
  cursor: default;
  .caret {
    visibility: hidden !important;
  }
  .select-caret {
    visibility: hidden !important;
    }
  &:hover{
    background-color: white !important;
    cursor: default;
    .caret {
      visibility: hidden;
    }
    .select-caret {
      visibility: hidden;
    }
  }
}
</style>
