<script setup>
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import InventoryWarehouseType from '~/inventory/components/inventory-warehouse/inventory-warehouse-type.vue';

const props = defineProps({
  warehouse_details: { type: Object, default: null },
});

const emit = defineEmits(['close', 'save']);

const { $t, $toast, common_store, route } = useCommonImports();
const inventory_store = useInventoryStore();
const form$ = ref(null);

async function save() {
  try {
    const payload = {
      ...form$.value.data,
    };
    if (route.params.asset_id && !props.warehouse_details)
      payload.assets = [route.params.asset_id];

    let response = null;
    if (props.warehouse_details)
      response = await inventory_store.update_warehouse({
        id: props.warehouse_details.uid,
        body: { warehouse: { ...payload } },
      });

    else
      response = await inventory_store.create_warehouse({
        body: { warehouse: payload },
      });
    if (response?.warehouse?.uid) {
      $toast({
        title: props.warehouse_details?.uid ? $t('Warehouse updated') : $t('Warehouse created'),
        text: props.warehouse_details?.uid ? $t('Changes to the warehouse saved successfully.') : $t('The warehouse has been successfully created.'),
        type: 'success',
      });
      emit('close');
    }
  }
  catch (error) {
    logger.log(error);
    $toast({
      title: props.warehouse_details?.uid ? $t('Failed to update warehouse') : $t('Failed to create warehouse'),
      text: props.warehouse_details?.uid ? $t('Unable to update the warehouse. Please try again') : $t('Unable to create the warehouse. Please try again'),
      type: 'error',
    });
  }
}

onMounted(() => {
  form$.value.load({
    ...props.warehouse_details,
  }, true);
});
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      size="sm"
      :display-errors="false"
      :endpoint="save"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
    >
      <div class="col-span-12 max-w-[700px]">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <div class="mb-1">
              {{ warehouse_details ? $t(`Edit warehouse`) : $t(`New warehouse`) }}
            </div>
            <div class="text-sm font-normal">
              {{ common_store?.active_asset?.name }}
            </div>
          </template>
        </hawk-modal-header>
        <HawkModalContent class="grid gap-4">
          <TextElement
            name="name"
            :label="$t('Name')"
            :placeholder="$t('Enter warehouse name')"
            :rules="['required']"
          />
          <TextElement
            name="number"
            :label="$t('Number')"
            :placeholder="$t('Enter warehouse number')"
            :rules="['required']"
            :addons="{ before: '#' }"
          />
          <InventoryWarehouseType
            :options="{
              name: 'warehouse_type',
              label: $t('Type'),
              placeholder: $t('Select warehouse type'),
              create: true,
            }"
            :asset_id="route.params.asset_id || null"
          />
          <HawkAssetsInput
            v-if="!route.params.asset_id"
            name="assets"
            multi
            has_select_all
            :placeholder="$t('Select assets')"
            :rules="['required']"
            :options="{
              object: false,
            }"
          >
            <template #label>
              <div class="flex items-center gap-2">
                <span>{{ $t('Assets') }}</span>
                <span v-if="form$?.data?.assets?.length > 1" v-tippy="$t('If multiple assets are selected, BOM feature will not be supported.')">
                  <IconHawkInfoCircle class="h-4 w-4" />
                </span>
              </div>
            </template>
          </HawkAssetsInput>
          <TextareaElement
            :label="$t('Description')"
            name="description"
            :placeholder="$t('Enter description')"
            :rules="['max:200']"
          />
          <HawkAssigneeInput
            :options="{
              name: 'managers',
              label: $t('Managers'),
              placeholder: $t('Select managers'),
              has_teams: true,
            }"
            :multi="true"
          />
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end gap-3">
              <HawkButton type="outlined" @click="emit('close')">
                {{ $t('Close') }}
              </HawkButton>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
