<script setup>
import { snakeCaseToWord } from '~/common/utils/common.utils.js';

const props = defineProps({
  activity: {
    type: Object,
    default: () => {},
  },
});

const FEATURE_VERB_MAP = {
  CREATED: activity => `added ${activity.meta.features?.count} features in ${getContainerDetails(activity)}`,
  UPDATED: activity => `updated ${activity.meta.features?.count} features in ${getContainerDetails(activity)}`,
  DELETED: activity => `deleted ${activity.meta.features?.count} features in ${getContainerDetails(activity)}`,
  PROGRESS_UPDATED: activity => `updated progress for ${activity.meta.features?.count} features in ${getContainerDetails(activity)}`,
  PROGRESS_CLEARED: activity => `cleared the progress for ${activity.meta.features?.count} features in ${getContainerDetails(activity)}`,
  WORKFLOW_ASSOCIATED: activity => `associated the workflow ${activity.meta.workflow?.name} for ${activity.meta.features?.count} features in ${getContainerDetails(activity)}`,
  WORKFLOW_DISASSOCIATED: activity => `disassociated the workflow ${activity.meta.workflow?.name} for ${activity.meta.features?.count} features in ${getContainerDetails(activity)}`,
  INTG_101_SYNCED: (activity) => {
    if (activity.meta.form.status === 'submitted')
      return `finished inspection for ${activity.meta.feature?.name} in ${getContainerDetails(activity)}`;
    return `filled inspection for ${activity.meta.feature?.name} in ${getContainerDetails(activity)}`;
  },
};

function getContainerDetails(activity) {
  return `${activity.meta.container?.name}/${activity.meta.group?.name}/${activity.meta.project?.name}`;
}

function getVerbMap(resource) {
  if (resource === 'FEATURE') {
    return FEATURE_VERB_MAP;
  }
  else {
    const resource_name_formatted = snakeCaseToWord(resource).toLowerCase();
    return {
      CREATED: `created the ${resource_name_formatted}`,
      UPDATED: `updated the ${resource_name_formatted}`,
      DELETED: `deleted the ${resource_name_formatted}`,
    };
  }
}

function getActivityText(activity) {
  const verb_map = getVerbMap(activity.resource_name);

  if (typeof verb_map[activity.verb] === 'function')
    return verb_map[activity.verb](activity);
  return verb_map[activity.verb];
}
</script>

<template>
  <div class="flex flex-wrap">
    <div v-if="Object.keys(getVerbMap(activity.resource_name)).includes(activity.verb)" class="text-sm">
      {{ getActivityText(activity) }}
    </div>
  </div>
</template>
