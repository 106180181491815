<script setup>
import { computed, inject, onBeforeUnmount } from 'vue';
import { isString, keyBy } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { useTasksStore } from '~/tasks/store/tasks.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

import { useFormsStore } from '~/forms/store/forms.store';
import TaskForm from '~/tasks/components/molecule/task-form/task-form.vue';
import NewFormModal from '~/forms/components/new-form/new-form-modal.vue';
import TasksView from '~/tasks/pages/tasks/tasks-view.vue';
import FormCompactView from '~/forms/components/form-compact-view.vue';
import HawkAttach from '~/common/components/organisms/hawk-attach/hawk-attach.vue';

const props = defineProps({
  active_item_detail: {
    type: Object,
    default: null,
  },
  location: {
    type: Object,
    default: null,
  },
  feature: {
    type: Object,
    default: null,
  },
  task_store_name: {
    type: String,
    default: 'sm_tasks',
  },
  form_store_name: {
    type: String,
    default: 'sm_forms',
  },
});

const emit = defineEmits(['updateFeature', 'updateElement']);

const $form_create_permission = inject('$form_create_permission');

const current_organization = inject('current_organization');
const task_store = useTasksStore(props.task_store_name);
const form_store = useFormsStore(props.form_store_name);
const { auth_store, common_store, $t, $services, route } = useCommonImports();

const state = reactive({
  show_tasks: true,
  show_forms: true,
});

onBeforeUnmount(() => {
  form_store.$reset();
  task_store.$reset();
});

const is_terra_viewer = computed(() => route.name === 'terra-viewer');
const asset_id = computed(() => route.params.asset_id);
const element = computed(() => {
  if (props.feature)
    return props.feature?.properties?.element || null;
  return props.active_item_detail?.element || null;
});
const reference_name = computed(() => props?.feature?.properties?.name || props?.active_item_detail?.name);
const instance_properties = computed(() => {
  if (props.feature)
    return {
      reference_name: props.feature.properties.name,
      project_uid: props.feature.properties.project || props.feature.properties.projectUid,
    };
  return {
    reference_name: props.active_item_detail.name,
  };
});

const { open: openTaskForm, close: closeTaskForm } = useModal({
  component: TaskForm,
  attrs: {
    task_type: 'task',
    prefix_feature_name: is_terra_viewer.value,
    onClose() {
      closeTaskForm();
    },
    async on_submit(data) {
      try {
        const target_element = await updateFeatureAndElement(element.value);
        const payload = {
          ...data,
          target_element,
          properties: instance_properties.value,
          asset: asset_id.value,
          ...(props.location ? { location: props.location } : {}),
        };
        if (payload.prefix_feature_name && props.feature?.properties?.name)
          payload.name = [props.feature.properties?.name || '', payload.name].join(' ');
        const tasks = await task_store.create_tasks({ tasks: [payload] }, { where: 'System Model view', how: 'Quick action' });
        if (is_terra_viewer.value) {
          const terra_task_store = useTasksStore('terra_task_store');
          terra_task_store.tasks_map = { ...tasks, ...terra_task_store.tasks_map };
        }
      }
      catch (err) {
        logger.error(err);
      }
      finally {
        closeTaskForm();
      }
    },
  },
});

const { open: openAttach, close: closeAttach, patchOptions: patchAttach } = useModal({
  component: HawkAttach,
  attrs: {
    asset_id: asset_id.value,
    additional_table_options: { select_item_on_row_click: true },
    onClose() {
      closeAttach();
    },
    is_modal: true,
  },
});

const { open: openFormModal, close: closeFormModal } = useModal({
  component: NewFormModal,
  attrs: {
    ...(is_terra_viewer.value && { fields: ['Form Name', 'Template', 'Assignees', 'Checkbox', 'Start Date', 'Due Date', 'Tags', 'Category', 'Prefix feature name'] }),
    task_data: {},
    onClose() {
      closeFormModal();
    },
    async on_submit(data) {
      try {
        const target_element = await updateFeatureAndElement(element.value);
        data.forms.add[0] = {
          ...data.forms.add[0],
          target_element,
          properties: instance_properties.value,
          asset: asset_id.value,
          ...(props.location ? { location: props.location } : {}),
        };
        if (data.forms.add?.[0]?.prefix_feature_name && props.feature?.properties?.name)
          data.forms.add[0].name = [props.feature.properties?.name || '', data.forms.add[0].name].join(' ');
        const { forms } = await form_store.create_form({ body: data }, true);
        if (is_terra_viewer.value) {
          const terra_form_store = useFormsStore('terra_form_store');
          if (forms?.added?.length)
            terra_form_store.forms_map = Object.assign(terra_form_store.forms_map, keyBy(forms.added, 'uid'));
        }
      }
      catch (err) {
        logger.error(err);
      }
      finally {
        closeFormModal();
      }
    },
  },
});

const task_menu_items = computed(() => ([
  {
    label: $t('New Task'),
    uid: 'new',
    icon: IconHawkPlus,
    disabled: !auth_store.check_permission('create_tasks', asset_id.value),
    on_click: () => {
      openTaskForm();
    },
  },
  {
    label: $t('Choose existing task'),
    uid: 'existing',
    icon: IconHawkClipboard,
    disabled: !auth_store.check_permission('create_tasks', asset_id.value),
    on_click: () => {
      patchAttach({
        attrs: {
          type: 'Tasks',
          on_attach: async (data) => {
            try {
              const terra_task_store = useTasksStore('terra_task_store');
              const payload = await getFeaturePayload();
              const uids = data.map(val => val.uid);
              const error = await terra_task_store.update_tasks(uids, payload, true);
              if (error)
                throw error;
              updateStoreMaps(data, payload, task_store, 'tasks_map');
            }
            catch (error) {
              logger.log('🚀 ~ file: sm-activities.vue:184 ~ on_attach: ~ error:', error);
            }
          },
        },
      });
      openAttach();
    },
  },
]));

const form_menu_items = computed(() => ([
  ...($form_create_permission?.value?.state === 'hidden'
    ? []
    : [{
        label: $t('New form'),
        uid: 'new',
        icon: IconHawkPlus,
        on_click: () => {
          openFormModal();
        },
        disabled: $form_create_permission?.value?.state === 'disabled',
        tooltip: $form_create_permission?.value?.tooltip,
      }]
  ),
  {
    label: $t('Choose existing form'),
    uid: 'existing',
    icon: IconHawkClipboard,
    disabled: $form_create_permission?.value?.state !== 'enabled',
    on_click: () => {
      patchAttach({
        attrs: {
          type: 'Forms',
          additional_queries: {
            submission_status: ['open', 'draft'],
          },
          on_attach: async (data) => {
            try {
              const terra_form_store = useFormsStore('terra_form_store');
              const payload = await getFeaturePayload();
              const error = await terra_form_store.update_forms({
                body: {
                  forms: {
                    update: data.map(val => ({ uid: val.uid, ...payload })),
                  },
                },
                disable_toast: true,
              }, 'bulk');
              if (error)
                throw error;
              updateStoreMaps(data, payload, form_store, 'forms_map');
            }
            catch (error) {
              logger.log('🚀 ~ file: sm-activities.vue:228 ~ on_attach: ~ error:', error);
            }
          },
        },
      });
      openAttach();
    },
  },
]));

async function getFeaturePayload() {
  const turf = (await import('@turf/turf'));
  const payload = {};

  const centroid = turf.centroid(props.feature);
  payload.location = {
    type: 'Point',
    coordinates: centroid.geometry.coordinates,
  };
  payload.target_element = props.feature?.properties?.element;
  if (!payload?.target_element) {
    const { data } = await $services.terra_view_service.get_elements({
      body: {
        uids: [props.feature?.properties?.uid],
      },
    });
    payload.target_element = data[props.feature?.properties?.uid];
  }
  payload.properties = {
    projectUid: props.feature.properties.project,
    reference_name: String(props.feature?.properties?.name),
  };
  return payload;
}

function updateStoreMaps(data, payload, store, map_key) {
  const updated_map = data.reduce((acc, curr) => {
    acc[curr.uid] = { ...curr, ...payload };
    return acc;
  }, {});
  store[map_key] = Object.assign(store[map_key], updated_map);
}

async function updateFeatureAndElement(element) {
  if (isString(element)) {
    const new_element = await common_store.get_target_element(asset_id.value, element.value);
    if (props.feature)
      emit('updateFeature', { ...props.feature, properties: { ...props.feature.properties, element: new_element } });

    if (props.active_item_detail)
      emit('updateElement', new_element);

    return new_element;
  }
  return element;
}

watch(() => task_store.tasks(), (data) => {
  if (is_terra_viewer.value && !data.length)
    state.show_tasks = false;
}, { deep: true });
watch(() => form_store.forms, (data) => {
  if (is_terra_viewer.value && !data.length)
    state.show_forms = false;
}, { deep: true });
</script>

<template>
  <div class="my-6">
    <TasksView
      v-if="state.show_tasks"
      :key="active_item_detail?.uid"
      is_widget
      :is_compact_view="true"
      class="!px-0"
      :store_key="task_store_name"
      :options="{
        query: {
          reference_name,
          element,
        },
        element,
      }"
      :table_options="{
        column_config: {
          name: {},
          due_date: {},
          status: {},
        },
        height: 'max-h-96',
      }"
    >
      <template #top-panel>
        <HawkPageSecondaryHeader class="mb-6">
          <template #left>
            <div class="text-gray-900 font-semibold text-lg">
              {{ $t('Tasks') }}
            </div>
          </template>
          <template #right>
            <template v-if="is_terra_viewer">
              <HawkMenu
                v-if="task_menu_items.some(menu => !menu.disabled)"
                :items="task_menu_items"
                additional_dropdown_classes="w-52"
                position="fixed"
              >
                <template #trigger>
                  <HawkButton type="outlined" class="!border-gray-300 text-gray-700 font-semibold">
                    <IconHawkPlusWhite class="h-3 w-3" /> {{ $t('New') }}
                  </HawkButton>
                </template>
                <template #item="{ item }">
                  <div class="flex items-center text-sm font-medium text-gray-700">
                    <component :is="item.icon" class="inline mr-2 text-gray-500 w-4 h-4" />
                    <span>
                      {{ item.label }}
                    </span>
                  </div>
                </template>
              </HawkMenu>
            </template>
            <HawkButton
              v-else-if="auth_store.check_permission('create_tasks', asset_id)"
              type="outlined"
              class="!border-gray-300 text-gray-700 font-semibold"
              @click="openTaskForm"
            >
              <IconHawkPlusWhite class="h-3 w-3" /> {{ $t('New Task') }}
            </HawkButton>
          </template>
        </HawkPageSecondaryHeader>
      </template>
    </TasksView>
    <div v-if="state.show_forms" class="scrollbar">
      <div class="flex items-center justify-between my-6">
        <div class="text-gray-900 font-semibold text-lg">
          {{ $t('Forms') }}
        </div>
        <template v-if="is_terra_viewer">
          <HawkMenu
            v-if="form_menu_items.some(menu => !menu.disabled)"
            :items="form_menu_items"
            additional_dropdown_classes="w-52"
            position="fixed"
          >
            <template #trigger>
              <HawkButton type="outlined" class="!border-gray-300 text-gray-700 font-semibold">
                <IconHawkPlusWhite class="h-3 w-3" /> {{ $t('New') }}
              </HawkButton>
            </template>
            <template #item="{ item }">
              <div class="flex items-center text-sm font-medium text-gray-700">
                <component :is="item.icon" class="inline mr-2 text-gray-500 w-4 h-4" />
                <span>
                  {{ item.label }}
                </span>
              </div>
            </template>
          </HawkMenu>
        </template>
        <HawkButton
          v-else-if="auth_store.check_permission('v2_create_forms', asset_id)"
          type="outlined"
          class="!border-gray-300 text-gray-700 font-semibold"
          @click="openFormModal"
        >
          <IconHawkPlusWhite class="h-3 w-3" /> {{ $t('New Form') }}
        </HawkButton>
      </div>
      <FormCompactView
        :store_key="form_store_name"
        wrapper_class="max-h-96 scrollbar"
        :options="{
          show_no_data: true,
          query: {
            all_access: true,
            asset_uid: asset_id,
            reference_name,
            is_child: true,
            status: 'published',
          },
        }"
      />
    </div>
  </div>
</template>
