<script setup>
import dayjs from 'dayjs';
import { orderBy } from 'lodash-es';
import { useInfiniteScroll } from '@vueuse/core';
import useAbortController from '~/common/composables/abort-controller.js';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import InventoryTransactionDetails from '~/inventory/components/inventory-transaction/inventory-transaction-details.vue';
import InventoryTransactionFilter from '~/inventory/components/inventory-transaction/inventory-transaction-filter.vue';
import InventoryTransactionList from '~/inventory/components/inventory-transaction/inventory-transaction-list.vue';
import HawkIllustrations from '~/common/components/organisms/hawk-illustrations/hawk-illustrations.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { downloadBlob } from '~/plans/utils/helper.js';

const props = defineProps({
  is_list_view: { type: Boolean, default: false },
  show_only_list: { type: Boolean, default: false },
  is_recent_transactions: { type: Boolean, default: false },
  warehouse_id: { type: String, default: '' },
  filters: { type: Object, default: () => {} },
});
const { $t, $toast, route, $services } = useCommonImports();

const inventory_store = useInventoryStore();
const transactions_list = ref(null);
const controllers = useAbortController();
const state = reactive({
  transactions: [],
  filters: {},
  search: '',
  is_loading: false,
  export_loading: false,
  pagination_config: { pageSize: 25, pageIndex: 1 },
});

const new_item_options = computed(() => {
  const menu_items = inventory_store.workflows.reduce((items, workflow) => {
    if (workflow.permissions?.draft)
      items.push({
        uid: workflow.uid,
        label: workflow.name,
        order_index: workflow.order_index,
        to: {
          name: 'inventory-transaction-form',
          params: { workflow_id: workflow.uid, asset_id: route.params.asset_id },
        },
      });
    return items;
  }, []);
  return orderBy(menu_items, ['order_index'], ['asc']);
});

const filtered_transactions = computed(() => {
  if (props.filters?.uid) {
    if (props.filters?.type === 'draft')
      return inventory_store.adjustments?.filter(item => item.status === 'draft');
    else if (props.filters?.type === 'workflows')
      return inventory_store.adjustments.filter(item => item.workflow === props.filters?.uid);
    return inventory_store.adjustments;
  }
  return inventory_store.adjustments;
});

async function getAdjustments({ pagination_state, search = '', active_transaction_uid = null }) {
  state.is_loading = true;
  state.pagination_config = { ...state.pagination_config, ...pagination_state };
  if (props.is_recent_transactions)
    state.filters = {
      start_date: dayjs().subtract(7, 'day').format(),
      end_date: dayjs().format(),
    };

  const query = {
    page: pagination_state.pageIndex || 1,
    limit: pagination_state.pageSize,
    asset: route.params.asset_id,
    ...(search ? { search } : {}),
    ...(props?.warehouse_id?.length ? { warehouse: props?.warehouse_id } : {}),
    ...state.filters,
  };
  await inventory_store.set_adjustments({ query, append: true, is_recent_transactions: props.is_recent_transactions, active_transaction_uid });

  if (inventory_store.adjustments?.length && !inventory_store.items?.length)
    await inventory_store.set_items({ query: { asset: route.params.asset_id } });

  state.is_loading = false;
}

useInfiniteScroll(transactions_list, async () => {
  if (state.is_loading)
    return;

  state.is_loading = true;
  if ((state.pagination_config.pageIndex * state.pagination_config.pageSize) < inventory_store?.total_adjustments_count) {
    state.pagination_config.pageIndex = state.pagination_config.pageIndex + 1;
    await getAdjustments({ pagination_state: state.pagination_config });
  }
  state.is_loading = false;
}, { distance: 1000 });

function onSearch(value) {
  inventory_store.adjustments_map = {};
  state.search = value;
  getAdjustments({ pagination_state: { pageIndex: 1, pageSize: 25 }, search: value });
}

function onFilterApply(filter) {
  inventory_store.adjustments_map = {};
  state.filters = {
    ...filter,
    ...(filter?.date ? { start_date: dayjs(filter.date?.[0]).format('YYYY-MM-DD'), end_date: dayjs(filter.date?.[1]).format('YYYY-MM-DD') } : {}),
  };
  delete state.filters.date;
  getAdjustments({ pagination_state: { pageIndex: 1, pageSize: 25 } });
}

function onReset() {
  inventory_store.adjustments_map = {};
  inventory_store.total_adjustments_count = 0;
  getAdjustments({ pagination_state: { pageIndex: 1, pageSize: 25 } });
}

function abortExport() {
  controllers.abort('export_history');
}

async function export_history() {
  const signal = controllers.add('export_history');
  try {
    const res = await $services.inventory_reports.export_transaction_report({
      query: {
        asset: route?.params?.asset_id,
        status: 'published',
        ...(state?.search ? { search: state.search } : {}),
        ...(props?.warehouse_id?.length ? { warehouse: props?.warehouse_id } : {}),
        ...state.filters,
      },
      extraOptions: { responseType: 'blob' },
      toast: true,
      signal,
    });
    const blob = new Blob([res.data], {
      type: res.headers['content-type'],
    });
    await downloadBlob(blob, 'xlsx', 'transactions');
  }
  catch (error) {
    state.export_loading = false;
    if (error.status === 401)
      $toast({ type: 'error', title: $t('No permission'), text: $t('You don\'t have enough permissions.') });
    else
      $toast({ type: 'error', title: 'Error', text: 'Try again' });
  }
}

onMounted(() => {
  if (route?.query.transaction_number) {
    onSearch(route.query.transaction_number);
  }
  else {
    inventory_store.adjustments_map = {};
    inventory_store.total_adjustments_count = 0;
    getAdjustments({
      pagination_state: state.pagination_config,
      search: route.query.transaction_number,
      active_transaction_uid: route.query.transaction_id,
    });
  }
});
</script>

<template>
  <div>
    <template v-if="!(show_only_list)">
      <div>
        <HawkPageHeader v-if="!is_list_view">
          <template #title>
            {{ $t('Transactions') }} ({{ inventory_store?.total_adjustments_count }})
          </template>
          <template #right>
            <div class="flex gap-3">
              <HawkButton v-tippy="{ content: $t('Export transactions') }" icon type="text" @click="state.export_loading = true;">
                <IconHawkUploadOne />
              </HawkButton>

              <HawkMenu v-if="route.params.asset_id && new_item_options?.length" :items="new_item_options">
                <template #trigger="{ open }">
                  <HawkButton>
                    {{ $t('New Transaction') }}
                    <IconHawkChevronUp v-if="open" />
                    <IconHawkChevronDown v-else />
                  </HawkButton>
                </template>
              </HawkMenu>
            </div>
          </template>
        </HawkPageHeader>
      </div>
      <HawkPageSecondaryHeader
        v-if="filtered_transactions.length || (!filtered_transactions.length && (Object.keys(state.filters).length || state.search))"
        class="pb-4"
        :class="{ 'px-4': !is_list_view }"
      >
        <template #left>
          <InventoryTransactionFilter :warehouse_id="warehouse_id" @apply="onFilterApply($event)" />
        </template>
        <template #right>
          <HawkSearchInput
            v-model="state.search"
            :placeholder="$t('Search')"
            @update:modelValue="onSearch"
          />
        </template>
      </HawkPageSecondaryHeader>
    </template>
    <HawkLoader v-if="state.pagination_config.pageIndex === 1 && state.is_loading" />
    <div v-else-if="!filtered_transactions?.length">
      <div v-if="show_only_list" class="text-sm text-gray-500 h-96">
        <div class="pt-6">
          {{ is_recent_transactions ? $t('No transactions found in the last 7 days') : $t('No transactions found') }}
        </div>
      </div>
      <template v-else>
        <HawkIllustrations v-if="state.search || Object.keys(state.filters).length" type="no-results" />
        <HawkIllustrations v-else type="no-data" for="inventory-transactions">
          <HawkMenu v-if="route.params.asset_id && !filtered_transactions.length && !(Object.keys(state.filters).length || state.search)" :items="new_item_options" class="mt-3">
            <template #trigger="{ open }">
              <HawkButton>
                {{ $t('New Transaction') }}
                <IconHawkChevronUp v-if="open" />
                <IconHawkChevronDown v-else />
              </HawkButton>
            </template>
          </HawkMenu>
        </HawkIllustrations>
      </template>
    </div>
    <div v-else class="grid gap-4" :class="[{ 'px-4': !(is_list_view || show_only_list) }, { 'grid-cols-12': !show_only_list }]">
      <div
        ref="transactions_list"
        :class="{ 'col-span-12 md:col-span-3 h-40 md:h-[calc(100vh-198px)] scrollbar md:sticky md:top-20 border rounded-lg': !show_only_list, 'max-h-96 scrollbar': show_only_list }"
      >
        <div>
          <template v-if="filtered_transactions?.length">
            <InventoryTransactionList :transactions="filtered_transactions" :show_only_list="show_only_list" />
          </template>
          <HawkLoader v-if="state.pagination_config.pageIndex !== 1 && state.is_loading" container_class="m-2" />
        </div>
      </div>
      <div v-if="!show_only_list" class="col-span-12 md:col-span-9 h-40 md:h-[calc(100vh-198px)] scrollbar md:sticky md:top-20 border rounded-lg">
        <InventoryTransactionDetails v-if="inventory_store?.active_transaction_uid?.length" @reset="onReset" />
      </div>
    </div>
  </div>
  <HawkExportToast
    v-if="state.export_loading"
    :submit="export_history"
    :progress_text="$t('Exporting History')"
    :completed_text="$t('History Exported')"
    @complete="state.export_loading = false"
    @cancel="state.export_loading = false; abortExport();"
  />
</template>
