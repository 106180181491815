<script setup>
import { ref } from 'vue';
import { Validator } from '@vueform/vueform';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import parser from 'ua-parser-js';
import { useAuthStore } from '~/auth/stores/auth.store';
import { accessTokenCookie } from '~/common/utils/common.utils';

const auth_store = useAuthStore();
const toggle_current_password = ref(false);
const toggle_new_password = ref(false);
const toggle_new_password_confirm = ref(false);
const $services = inject('$services');
const $toast = inject('$toast');
const form_values = ref(null);
const is_loading = ref(false);
const login_history = ref([]);
const form$ = ref(null);

// Password should contain one lower and one upper
const oneLowerAndUpperRule = class extends Validator {
  get message() {
    return 'Choose a password with atleast 8 characters containing a upper case, a lower case, a number and a special character.';
  }

  check(value) {
    return /(?=.*[a-z])(?=.*[A-Z])/.test(value);
  }
};

// Password should contain a number and special characters
const oneNumberAndSymbolRule = class extends Validator {
  get message() {
    return 'Choose a password with atleast 8 characters containing a upper case, a lower case, a number and a special character.';
  }

  check(value) {
    return /(?=.*[/\W|_])(?=.*\d)/.test(value);
  }
};

// Password should not contain first and last name of the logged-in user
const noFirstLastNameRule = class extends Validator {
  get message() {
    return 'Choose a password with atleast 8 characters containing a upper case, a lower case, a number and a special character.';
  }

  check(value) {
    const current_logged_in_user = `${auth_store.logged_in_user_details?.firstname?.trim()
                ?? ''} ${auth_store.logged_in_user_details?.lastname?.trim() ?? ''}`;
    const password = current_logged_in_user.split(' ');

    const testNames = new RegExp(
      `\\${
        password.length === 1 ? password[0] : `${password[0]}|${password[1]}`
      }`,
      'gi',
    );
    return !testNames.test(value);
  }
};

const oneLowerAndUpper = ref(oneLowerAndUpperRule);
const oneNumberAndSymbol = ref(oneNumberAndSymbolRule);
const noFirstLastName = ref(noFirstLastNameRule);

function getDeviceIcon(type) {
  if (type === 'mac' || type === 'linux' || type === 'ubuntu' || type === 'windows')
    return 'desktop';
  else if (type === 'android')
    return 'phone';
}

async function getLoginHistory() {
  try {
    is_loading.value = true;
    const ua_parser = new parser.UAParser();

    const decodedJwt = jwtDecode(accessTokenCookie());
    const { data } = await $services.users.get({
      id: auth_store.logged_in_user_details?.user_id,
      attribute: 'ua-info',
    });
    data.map(async (item) => {
      const loggedInfo = { thisDevice: false, activeNow: false };
      ua_parser.setUA(item.ua);
      if (item.jti === decodedJwt.jti)
        loggedInfo.thisDevice = true;
      login_history.value.push({
        ...item,
        ...ua_parser.getResult(),
        ...(await ipLookUp(item.ip_address)),
        ...loggedInfo,
      });
      login_history.value.sort((a, b) => new Date(b.last_active) - new Date(a.last_active));
    });
    is_loading.value = false;
  }
  catch (err) {
    is_loading.value = false;
  }
}

async function ipLookUp(ip) {
  try {
    const { data } = await axios.get(`https://ipapi.co/${ip}/json/`);
    return {
      location: `${data?.city}, ${data?.country_name}`,
    };
  }
  catch (err) {
    logger.log(err);
  }
}

async function logoutAllSessions() {
  try {
    const { data } = await $services.users.get(
      {
        id: auth_store.logged_in_user_details?.user_id,
        attribute: 'logout-all-devices',
        query: {
          exclude_current_session: true,
        },
      },
    );

    if (data?.message === 'success')
      $toast({
        text: 'Logged out from all devices!',
        type: 'success',
      });

    else
      $toast({
        title: 'Something went wrong',
        text: 'Please try again',
        type: 'error',
      });
    const decodedJwt = jwtDecode(accessTokenCookie());
    login_history.value = login_history.value.filter(
      item => item.jti === decodedJwt.jti,
    );
  }
  catch (err) {
    logger.log(err);
  }
}

async function logoutDevice(jti) {
  try {
    const { data } = await $services.users.post(
      {
        id: auth_store.logged_in_user_details?.user_id,
        attribute: 'logout-device',
        body: {
          jti_key: jti,
        },
      },
    );

    if (data?.message === 'success')
      $toast({
        text: 'Session logout successful!',
        type: 'success',
      });

    else
      $toast({
        title: 'Something went wrong',
        text: 'Please try again',
        type: 'error',
      });

    const index = login_history.value.findIndex(item => item.jti === jti);
    login_history.value.splice(index, 1);
  }
  catch (err) {
    logger.log(err);
  }
}

async function save() {
  try {
    const data = await $services.users.change_password({
      id: auth_store.logged_in_user_details?.user_id,
      body: {
        old_password: form_values.value.old_password,
        new_password: form_values.value.new_password,
        strict: false,
      },
    });
    if (data.status === 200)
      $toast({
        text: 'Password updated successfully',
        type: 'success',
      });

    else
      $toast({
        title: data?.data?.message || 'Something went wrong',
        text: 'Please try again',
        type: 'error',
      });
  }
  catch (err) {
    $toast({
      title: err?.data?.message || 'Something went wrong',
      text: 'Please try again',
      type: 'error',
    });
  }
}

function clearData() {
  form$.value.load(
    {
      old_password: null,
      new_password: null,
      new_password_confirmation: null,
    },
    true,
  );
}

onMounted(() => {
  getLoginHistory();
});
</script>

<template>
  <div>
    <div class="mb-5 text-lg font-semibold">
      {{ $t('Change Password') }}
    </div>
    <div>
      <Vueform
        ref="form$"
        v-model="form_values"
        size="sm"
        :display-errors="false"
        :format-load="(data) => data"
        :endpoint="save"
      >
        <div class="col-span-12">
          <div class="max-w-[700px] grid gap-4">
            <TextElement
              name="old_password"
              :label="$t('Current password')"
              rules="required"
              :placeholder="$t('Enter current password')"
              :input-type="toggle_current_password ? 'text' : 'password'"
              :messages="{
                required: 'Please enter current password',
              }"
            >
              <template #addon-after>
                <div class="cursor-pointer" @click.stop="toggle_current_password = !toggle_current_password">
                  <IconHawkEyeOff v-if="toggle_current_password" class="h-4 w-4" />
                  <IconHawkEye v-else class="h-4 w-4" />
                </div>
              </template>
            </TextElement>
            <TextElement
              name="new_password"
              :label="$t('New password')"
              :rules="['required', 'confirmed', oneLowerAndUpper, oneNumberAndSymbol, noFirstLastName]"
              :placeholder="$t('Enter new password')"
              :input-type="toggle_new_password ? 'text' : 'password'"
              :messages="{
                confirmed: 'Passwords didn’t match',
                required: 'Please enter new password',
              }"
            >
              <template #addon-after>
                <div class="cursor-pointer" @click.stop="toggle_new_password = !toggle_new_password">
                  <IconHawkEyeOff v-if="toggle_new_password" class="h-4 w-4" />
                  <IconHawkEye v-else class="h-4 w-4" />
                </div>
              </template>
            </TextElement>
            <TextElement
              name="new_password_confirmation"
              :label="$t('Confirm new password')"
              :placeholder="$t('Enter new password again')"
              :rules="['required']"
              :input-type="toggle_new_password_confirm ? 'text' : 'password'"
              :messages="{
                required: 'Please enter confirm new password',
              }"
            >
              <template #addon-after>
                <div class="cursor-pointer" @click.stop="toggle_new_password_confirm = !toggle_new_password_confirm">
                  <IconHawkEyeOff v-if="toggle_new_password_confirm" class="h-4 w-4" />
                  <IconHawkEye v-else class="h-4 w-4" />
                </div>
              </template>
            </TextElement>
          </div>
          <hr class="my-5">
          <div class="flex justify-end gap-3">
            <HawkButton type="outlined" @click="clearData">
              {{ $t('Clear') }}
            </HawkButton>
            <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
              {{ $t('Change Password') }}
            </ButtonElement>
          </div>
        </div>
      </Vueform>
    </div>
    <hr class="my-5">
    <div>
      <div class="font-semibold mb-6">
        <span class="text-lg mr-4">{{ $t('Active Sessions') }}</span>
        <HawkButton class="text-sm text-primary-700" type="link" @click="logoutAllSessions">
          {{ $t('Log out of other sessions') }}
        </HawkButton>
      </div>

      <div class="w-[400px] pb-10">
        <HawkLoader v-if="is_loading" />
        <div v-else class="text-sm grid gap-4">
          <div v-for="item in login_history" :key="item.ip_address" class="flex gap-4 text-gray-600 group">
            <div>
              <IconHawkMonitorFour v-if="getDeviceIcon(item.os.name.split(' ')[0].toLowerCase()) === 'desktop'" class="text-gray-500 h-6 w-6" />
              <IconHawkPhoneOne v-else-if="getDeviceIcon(item.os.name.split(' ')[0].toLowerCase()) === 'phone'" class="text-gray-500 h-6 w-6" />
            </div>
            <div class="flex items-center justify-between w-full">
              <div>
                <div class="mb-1 flex items-center gap-2">
                  <span class="font-medium">{{ item.os.name }}</span>
                  <HawkBadge v-if="item.thisDevice" color="green" type="outlined">
                    <div class="w-1.5 h-1.5 mr-1 bg-green-700 rounded-full" />
                    {{ $t('This Device') }}
                  </HawkBadge>
                </div>
                <div class="flex gap-2 items-center font-normal mb-1">
                  {{ item.browser.name }} <div class="w-1 h-1 bg-gray-500 rounded-full" /> {{ $date_relative(item.last_active) }}
                </div>
                <div class="font-normal">
                  {{ item.location }}
                </div>
              </div>
              <HawkButton v-if="!item?.thisDevice" v-tippy="'Logout'" icon type="text" class="hidden group-hover:flex" @click="logoutDevice(item.jti)">
                <IconHawkLogOutOne />
              </HawkButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
