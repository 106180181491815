<script setup>
const props = defineProps({
  columns: {
    type: Array,
    default: () => [],
  },
  generate_sample_file_name: {
    type: Function,
    default: () => 'sample_data',
  },
});

const table_columns = [
  {
    accessorKey: 'label',
    header: 'Column name',
    id: 'label',
  },
  {
    accessorKey: 'data_format',
    header: 'Type',
    id: 'data_format',
  },
  {
    accessorKey: 'example',
    header: 'Example',
    id: 'example',
  },
  {
    accessorKey: 'required',
    header: 'Required',
    id: 'required',
  },
  {
    accessorKey: 'description',
    header: 'Description',
    id: 'description',
  },
];
function downloadSampleCSV() {
  const header_line = props.columns.map(item => (`${item.label ? item.label : item.field}${item.required ? '*' : ''}`)).join(',');
  const content_line = props.columns.map(item => (item.example ? item.example : '')).join(',');
  const csv_content = `data:text/csv;charset=utf-8,${header_line}\r\n${content_line}`;
  const encoded_uri = encodeURI(csv_content);
  const link = document.createElement('a');
  link.setAttribute('href', encoded_uri);
  const file_name = props.generate_sample_file_name();
  link.setAttribute('download', `${file_name}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
</script>

<template>
  <div class="flex items-center justify-between px-8">
    <div>
      <p class="text-md font-semibold">
        {{ $t('Get ready to import your data') }}
      </p>
      <p class="text-xs">
        {{ $t('The following columns are supported. Some may be required, rest are optional') }}
      </p>
    </div>
    <hawk-button type="outlined" @click="downloadSampleCSV">
      {{ $t('Download sample csv') }}
    </hawk-button>
  </div>
  <hawk-table class="mt-4" :data="columns" :columns="table_columns">
    <template #required="row">
      {{ row.data.row.original?.required ? 'Yes' : 'No' }}
    </template>
  </hawk-table>
</template>
