<script setup>
import { onMounted } from 'vue';
import Draggable from 'vuedraggable';

const props = defineProps({
  visible_columns: {
    type: Array,
    default: () => [],
  },
  hidden_columns: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['close', 'save']);
const hidden_columns = ref([]);
const show_columns = ref([]);

const all_columns = ref([]);
const selected_visible_columns = ref([]);
const selected_hidden_columns = ref([]);

onMounted(() => {
  show_columns.value = [...props.visible_columns];
  show_columns.value.forEach((item) => {
    item.active = true;
    all_columns.value.push(item);
  });
  hidden_columns.value = [...props.hidden_columns];
  hidden_columns.value.forEach((item) => {
    item.active = false;
    all_columns.value.push(item);
  });
});

const compute_hidden_columns = computed(() => {
  const hidden_columns_list = [];
  all_columns.value.forEach((item) => {
    if (!item.active)
      hidden_columns_list.push(item);
  });
  return hidden_columns_list;
});

function handleListSave() {
  const columns = show_columns.value.map((item, index) => ({
    ...item,
    order_index: index,
  }));
  hidden_columns.value = compute_hidden_columns.value;
  emit('save', columns, hidden_columns.value, show_columns.value);
  emit('close');
}

function selectHiddenColumns(event, name) {
  const does_include = selected_hidden_columns.value?.includes(name);

  if (does_include) {
    if (!event.ctrlKey)
      selected_hidden_columns.value = [];

    else
      selected_hidden_columns.value = selected_hidden_columns.value.filter(
        n => n !== name,
      );

    return;
  }
  if (event.ctrlKey)
    selected_hidden_columns.value.push(name);
  else
    selected_hidden_columns.value = [name];
}

function selectVisibleColumns(event, name) {
  const does_include = selected_visible_columns.value?.includes(name);

  if (does_include) {
    if (!event.ctrlKey)
      selected_visible_columns.value = [];

    else
      selected_visible_columns.value = selected_visible_columns.value.filter(
        n => n !== name,
      );

    return;
  }
  if (event.ctrlKey)
    selected_visible_columns.value.push(name);
  else
    selected_visible_columns.value = [name];
}

function onVisibleColumnsReorder(event) {
  if (event.added) {
    event.added.element = true;
  }
  else if (event.removed) {
    const target = all_columns.value.find(
      col => col.name === event.removed.element.name,
    );
    target.active = false;
  }
  show_columns.value.forEach(column => (column.active = true));
}

function activateColumns() {
  for (const name of selected_hidden_columns.value) {
    const column = all_columns.value.find(col => col.name === name);
    if (column.active)
      continue;
    column.active = true;
    const new_column = { ...column };
    show_columns.value.push(new_column);
  }
  selected_visible_columns.value = [];
  selected_hidden_columns.value = [];
}

function deactivateColumns() {
  for (const name of selected_visible_columns.value) {
    const column = all_columns.value.find(column => column.name === name);
    if (!column.active)
      continue;
    column.active = false;
    show_columns.value = show_columns.value.filter(
      col => col.name !== name,
    );
  }
  selected_visible_columns.value = [];
  selected_hidden_columns.value = [];
}

function changeIndex(val) {
  const arr = show_columns.value;
  for (const name of selected_visible_columns.value) {
    const from = arr.findIndex(col => col.name === name);
    const to = from + val;
    if (to <= 0 || to > selected_visible_columns.value)
      continue;
    const element = arr[from];
    arr.splice(from, 1);
    arr.splice(to, 0, element);
  }
}
</script>

<template>
  <hawk-modal-container content_class="min-w-[calc(100vw-400px)]">
    <hawk-modal-header @close="emit('close')">
      <template #title>
        <p class="font-semibold">
          {{ $t('Choose columns to display') }}
        </p>
        <p class="mt-2 font-medium text-sm">
          {{ $t('table-settings-modal-description') }}
        </p>
      </template>
    </hawk-modal-header>
    <hawk-modal-content>
      <div>
        <div class="flex items-center gap-6">
          <div class="w-full">
            <div class="text-sm font-semibold mb-2 text-gray-900">
              {{ $t('Hidden Columns') }}
            </div>
            <div class="border border-gray-300 rounded-lg py-3 px-4 overflow-auto h-[25rem]">
              <Draggable
                :list="compute_hidden_columns"
                :group="{ name: 'columns' }"
                item-key="name"
              >
                <template #item="{ element }">
                  <div
                    class="h-8 flex items-center cursor-pointer hover:bg-gray-50"
                    :class="{ 'bg-gray-100 hover:!bg-gray-100': selected_hidden_columns.includes(element.name) }"
                    @click="selectHiddenColumns($event, element.name)"
                  >
                    <span class="text-sm font-medium text-gray-700 capitalize ml-2">
                      {{ element.name }}
                    </span>
                  </div>
                </template>
              </Draggable>
            </div>
          </div>
          <div class="w-8">
            <div
              class="w-8 h-8 rounded-full flex items-center justify-center bg-gray-100 cursor-pointer mb-6"
              @click="activateColumns"
            >
              <IconHawkArrowRight
                class="text-gray-600"
                :class="{ 'text-primary': selected_hidden_columns.length }"
              />
            </div>
            <div
              class="w-8 h-8 rounded-full flex items-center justify-center bg-gray-100 cursor-pointer"
              @click="deactivateColumns"
            >
              <IconHawkArrowLeft
                class="text-gray-600"
                :class="{ 'text-primary': selected_visible_columns.length }"
              />
            </div>
          </div>
          <div class="w-full">
            <div class="text-sm font-semibold mb-2 text-gray-900">
              {{ $t('Visible Columns') }}
            </div>
            <div class="border border-gray-300 rounded-lg py-3 px-4 overflow-auto h-[25rem]">
              <Draggable
                :list="show_columns"
                :group="{ name: 'columns' }"
                item-key="name"
                @change="onVisibleColumnsReorder"
              >
                <template #item="{ element }">
                  <div
                    v-if="!element.hidden_in_settings"
                    class="h-8 flex items-center cursor-pointer hover:bg-gray-50"
                    :class="{ 'bg-gray-100 hover:!bg-gray-100': selected_visible_columns.includes(element.name) }"
                    @click="selectVisibleColumns($event, element.name)"
                  >
                    <span class="text-sm font-medium text-gray-700 capitalize ml-2">
                      {{ element.name }}
                    </span>
                  </div>
                </template>
              </Draggable>
            </div>
          </div>
          <div class="w-8">
            <div
              class="w-8 h-8 rounded-full flex items-center justify-center bg-gray-100 cursor-pointer mb-6"
              @click="changeIndex(-1)"
            >
              <IconHawkArrowUp class="text-primary" />
            </div>
            <div
              class="w-8 h-8 rounded-full flex items-center justify-center bg-gray-100 cursor-pointer"
              @click="changeIndex(+1)"
            >
              <IconHawkArrowDown class="text-primary" />
            </div>
          </div>
        </div>
      </div>
    </hawk-modal-content>
    <hawk-modal-footer class="flex justify-between items-center">
      <template #right>
        <div class="flex justify-end items-center">
          <hawk-button
            class="mr-5"
            type="outlined"
            @click="$emit('close')"
          >
            {{ $t('Cancel') }}
          </hawk-button>
          <hawk-button
            color="primary"
            @click="handleListSave"
          >
            {{ $t('Save') }}
          </hawk-button>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>
