<script setup>
import { useModal } from 'vue-final-modal';
import { isArray } from 'lodash-es';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import InventoryCustomFieldForm from '~/inventory/components/inventory-custom-fields/inventory-custom-field-form.vue';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const emit = defineEmits(['edit', 'delete']);
const { $t, $toast } = useCommonImports();
const force_update = ref(0);
const COLUMNS = [
  {
    accessorKey: 'name',
    header: $t('Name'),
    id: 'name',
    static: true,
    render_as: {
      field_type: 'text',
      options: {
        classes: 'font-medium',
      },
    },
  },
  {
    accessorKey: 'description',
    header: $t('Description'),
    id: 'description',
    static: true,
    maxSize: 100,
    render_as: {
      field_type: 'text',
      options: {
        classes: 'text-gray-600',
      },
    },
  },
  {
    accessorFn: row => formatString(row.type),
    header: $t('Type'),
    id: 'type',
    render_as: {
      field_type: 'text',
      options: {
        classes: 'text-gray-600',
      },
    },
  },
  {
    accessorKey: 'config',
    header: $t('Configuration'),
    id: 'config',
    static: true,
    render_as: {
      field_type: 'text',
      options: {
        classes: 'text-gray-600',
      },
    },
  },
  {
    accessorKey: 'context_menu',
    header: '',
    id: 'context_menu',
    size: '5',
    show_on_hover: 'true',
  },
];

const inventory_store = useInventoryStore();

const freeze_table = ref('-1');
const filtered_cfs = computed(() => inventory_store?.custom_fields?.filter(item => item.active) || []);
const { open: openDeletePopup, close: closeDeletePopup, patchOptions: patchDeletePopup } = useModal({
  component: HawkDeletePopup,
});
function deleteHandler(custom_field) {
  patchDeletePopup(
    {
      attrs: {
        header: $t('Delete custom field'),
        content: `Are you sure you want to delete custom field ${custom_field.name}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            const response = await inventory_store.delete_custom_field({
              id: custom_field.uid,
            });
            if (response)
              $toast({
                text: $t('Custom field deleted successfully'),
                type: 'success',
              });
          }
          catch (error) {
            $toast({ text: (error?.data?.message || error?.message), type: 'error', title: 'Error' });
          }
          closeDeletePopup();
        },
      },
    },
  );
  openDeletePopup();
}

function formatString(str) {
  const words = str.split('_');
  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  return words.join(' ');
}

const { open: openCustomFieldPopup, close: closeCustomFieldPopup, patchOptions } = useModal({
  component: InventoryCustomFieldForm,
  attrs: {
    onClose() {
      closeCustomFieldPopup();
    },
    onCancel() {
      closeCustomFieldPopup();
    },
  },
});

function editHandler(custom_field) {
  patchOptions({
    attrs: {
      uid: custom_field?.uid,
      onClose() {
        closeCustomFieldPopup();
        force_update.value++;
      },
      onCancel() {
        closeCustomFieldPopup();
      },
    },
  });
  openCustomFieldPopup();
}

function getConfigData(field) {
  if (isArray(field))
    return field.join(', ');
  return field;
}
</script>

<template>
  <div>
    <div class="flex items-center w-full justify-between">
      <p class="text-lg font-bold">
        {{ $t('Custom Fields') }}
      </p>
      <hawk-button @click="editHandler(null)">
        {{ $t('New field') }}
      </hawk-button>
    </div>
    <div v-if="!filtered_cfs?.length" class="text-sm text-gray-500 mt-3">
      {{ $t('No custom fields added') }}
    </div>
    <TableWrapperVue v-else :height="300" container_class="border mt-3">
      <HawkTable
        :key="force_update"
        :data="filtered_cfs"
        :columns="COLUMNS"
        is_gapless
        cell_height="40px"
        :non_sortable_columns="['context_menu']"
        :show_menu_header="false"
        :freeze_table="freeze_table"
        @rowClicked="e => editHandler(e)"
      >
        <template #description="{ data }">
          {{ data.getValue() || '-' }}
        </template>
        <template #config="{ data: config }">
          {{ getConfigData(config.getValue()) || "-" }}
        </template>
        <template #context_menu="custom_field">
          <hawk-menu
            position="fixed"
            additional_trigger_classes="!ring-0 hover:bg-gray-50"
            :items="[
              {
                uid: 'edit',
                label: $t('Edit'),
                disabled: false,
                on_click: () => editHandler(custom_field.data.row.original),
              },
              {
                uid: 'delete',
                label: $t('Delete'),
                disabled: false,
                on_click: () => deleteHandler(custom_field.data.row.original),
              },
            ]"
            @click.stop=""
            @open="freeze_table = custom_field?.data?.row?.id"
            @close="freeze_table = '-1'"
          />
        </template>
      </HawkTable>
    </TableWrapperVue>
  </div>
</template>
