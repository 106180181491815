<script setup>
import { useRoute, useRouter } from 'vue-router';
import { keyBy, pick } from 'lodash-es';
import dayjs from 'dayjs';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store.js';
import { isFileExtensionAllowed } from '~/common/utils/common.utils.js';
import AssetCustomFieldInput from '~/assets/components/asset-custom-field-input.vue';
import { compressAndUploadImage } from '~/common/composables/compress-and-upload-image.composable.js';

const $services = inject('$services');
const $track_event = inject('$track_event');
const $toast = inject('$toast');
const $t = inject('$t');
const router = useRouter();
const route = useRoute();
const auth_store = useAuthStore();
const common_store = useCommonStore();
const form$ = ref(null);

const state = reactive({
  is_loading: false,
  form: {},
  fieldvalues: {},
  custom_field_data: [],
});

const asset_detail = computed(() => common_store.active_asset || {});

watch(() => route.params.asset_id, () => {
  initializeForm(true);
}, { immediate: true });

async function initializeForm(loading = false) {
  try {
    state.is_loading = loading;
    await initializeMetadata();
    state.form = route.params.asset_id ? pick(asset_detail.value, ['name', 'code', 'location', 'address', 'cover_image', 'metadata']) : {};
    state.is_loading = false;
    await form$.value.update({
      name: asset_detail.value?.name,
      code: asset_detail.value?.code,
      location: (route?.params?.asset_id && asset_detail.value?.location?.coordinates)
        ? {
            lng: asset_detail.value?.location?.coordinates?.[0],
            lat: asset_detail.value?.location?.coordinates?.[1],
            formatted_address: asset_detail.value?.address?.formatted_address || asset_detail.value?.address?.name,
          }
        : {},
      cover_image: asset_detail.value?.cover_image || null,
      address_name: asset_detail.value?.address?.name,
    });

    state.custom_field_data = common_store?.assets_custom_fields;
  }
  catch (error) {
    state.is_loading = false;
    logger.error(error);
  }
}

async function initializeMetadata() {
  const fields = common_store?.assets_custom_fields.map(item => ({
    field: item?.uid,
    config: item?.config,
    value: item?.type === 'checkbox' ? [] : null,
    organization: auth_store?.current_organization?.uid,
    slug: item?.slug,
    type: item?.type,
    properties: item?.properties,
    name: item?.name,
  }));
  state.fieldvalues = keyBy(fields, 'field');
  Object.values(state.fieldvalues).forEach((item) => {
    const field = common_store.active_asset?.metadata.find(meta => meta.field === item.field);
    state.fieldvalues[item.field] = {
      ...item,
      value: (item.type === 'percentage'
        ? field?.value * 100
        : field?.value) || null,
    };
  });
}

async function save() {
  try {
    const payload = await generatePayload();
    if (!payload)
      return;

    if (!asset_detail.value?.uid)
      $track_event('create_project', { where: 'Default' });

    const response = await common_store.update_data({
      type: asset_detail.value?.uid ? 'patch_update' : 'add',
      ...(asset_detail.value?.uid ? { id: asset_detail.value.uid } : {}),
      data: payload,
      service: 'assets',
      append_data: true,
      state_prop: 'assets_map',
      update_state: false,
    });
    if (response.data.asset) {
      await common_store.update_global_data({ assets: true });
      $toast({
        title: 'Update',
        text: 'Asset updated successfully',
        type: 'success',
      });
      router.push({ name: 'home' });
    }
  }
  catch (error) {
    logger.log('error', error);
    $toast({
      title: error?.data?.title || 'Something went wrong',
      text: error?.data?.message || 'Please try again',
      type: 'error',
    });
  }
}

async function generatePayload() {
  if (state.form?.cover_image?.__file__) {
    delete state.form?.cover_image?.__file__;

    if (!isFileExtensionAllowed(state.form.cover_image?.name))
      return null;

    const cover_image_response = await compressAndUploadImage(
      state.form.cover_image,
      $services.organizations.generate_upload_url,
      { maxWidth: 300 },
    );

    state.form.cover_image = cover_image_response?.url ? cover_image_response : state.form.cover_image;
    $track_event('default_files', { source: 'Device' });
  }
  const payload = {
    asset: {
      ...state.form,
      ...(!asset_detail.value?.uid
        ? { members: [] }
        : { uid: asset_detail.value.uid }),
      location: (state.form?.location?.lng && state.form?.location?.lat)
        ? {
            coordinates: [state.form.location.lng, state.form.location.lat],
            type: 'point',
          }
        : null,
      organization: state.form.organization || auth_store.current_organization?.uid,
      cover_image: state.form.cover_image || null,
      metadata: Object.values(state.fieldvalues).map((field) => {
        const field_value = formatFieldsValue(field);
        return { field: field.field, value: state.form.custom_field[field.field] === '' ? null : field_value };
      }),
    },
  };
  if (state.form?.location?.formatted_address)
    payload.asset.address = { name: state.form.address_name, formatted_address: state.form.location.formatted_address };
  delete payload.asset.custom_field;
  return payload;
}

function formatFieldsValue(field) {
  if (field?.type === 'phone' || field?.type === 'members')
    return field?.value;

  else if (field?.type === 'percentage')
    return +Number(field?.value / 100).toFixed(2);

  else if (field?.type === 'money' || field?.type === 'number')
    return +Number(field?.value).toFixed(2);

  else if (field?.type === 'date' && state.form.custom_field[field.field] !== null)
    return dayjs(state.form.custom_field[field.field]).format('YYYY-MM-DD');

  else if (field?.type === 'date_range' && state.form.custom_field[field.field] !== null)
    return { start: dayjs(state.form.custom_field[field.field][0]), end: dayjs(state.form.custom_field[field.field][1]) };

  else if (field?.type === 'files')
    return (state?.form?.custom_field?.[field.field]?.length && state?.form?.custom_field?.[field.field].map((item) => {
      if (!item?.service_object)
        return item;
      return {
        service: item.service_object,
        file_name: item?.name || item?.fname,
        file_size: item?.size || item?.file_size,
        mime_type: item?.type || item?.mime_type,
        owner: { uid: auth_store.logged_in_user_details?.user_id },
        created_at: new Date().toISOString(),
      };
    })) || [];

  else if (field?.type === 'planned_actual')
    return {
      actual: state.form.custom_field[field.field]?.actual !== null ? +Number(state.form.custom_field[field.field]?.actual).toFixed(2) : null,
      planned: state.form.custom_field[field.field]?.planned !== null ? +Number(state.form.custom_field[field.field]?.planned).toFixed(2) : null,
    };

  return state.form.custom_field[field.field];
}

function deleteCoverImage() {
  form$.value.load({ ...state.form, cover_image: null }, true);
}

function updateAddress(e) {
  form$.value.update({
    ...state.form,
    location: {
      lng: e.geometry.location.lng,
      lat: e.geometry.location.lat,
      formatted_address: e.formatted_address,
    },
    address_name: e.formatted_address,
  });
}
</script>

<template>
  <div>
    <HawkPageHeader v-if="route.name === 'create-asset'" :title="$t('New asset')" />
    <div class="my-5" :class="{ 'mx-4': route.name === 'create-asset' }">
      <div v-if="route.params.asset_id" class="text-lg font-semibold mb-5">
        {{ $t('Basic details') }}
        <p class="text-xs text-gray-600 font-normal">
          {{ $t('Update asset details') }}
        </p>
      </div>
      <div>
        <Vueform
          ref="form$"
          v-model="state.form"
          size="sm"
          :display-errors="false"
          :endpoint="save"
          :attachment_config="{ meta: { service: 'vault', id: 'upload' } }"
        >
          <div class="col-span-12">
            <div class="max-w-[700px]">
              <TextElement
                :label="$t('Asset Name')"
                name="name"
                :placeholder="$t('Enter name')"
                rules="required"
                class="mb-4"
                :attrs="{ autofocus: true }"
              />
              <TextElement
                :label="`${$t('Code')} (${$t('Optional')})`"
                name="code"
                :placeholder="$t('Enter code')"
                class="mb-4"
                :addons="{ before: `<span class='text-gray-400'>#</span>` }"
              />
              <div class="grid grid-cols-12 mb-4">
                <div class="col-span-6 text-sm font-medium">
                  {{ $t('Location') }}
                </div>
                <div class="w-[344px] col-span-6">
                  <HawkLocationPicker @update-location-marker="updateAddress($event)" />
                </div>
              </div>
              <TextareaElement
                name="address_name"
                label="Address"
                :placeholder="$t('Address')"
                class="mb-4"
              />
              <FileElement
                :label="$t('Cover Image')"
                name="cover_image"
                accept="image/*"
                view="image"
                :drop="true"
                class="mb-4"
                :url="false"
                :auto="true"
                :use_uppy="false"
              >
                <template #preview>
                  <div
                    v-if="state.form?.cover_image?.url"
                  >
                    <img
                      :src="state.form.cover_image?.url"
                      alt="asset logo"
                      title="profile picture"
                      class="object-cover w-full h-32 mb-2 rounded-lg mr-9"
                    >
                    <div class="flex justify-end">
                      <HawkButton
                        type="plain"
                        color="error"
                        @click="deleteCoverImage()"
                      >
                        {{ $t("Remove") }}
                      </HawkButton>
                    </div>
                  </div>
                </template>
              </FileElement>

              <div v-if="!state.is_loading && state.custom_field_data?.length">
                <AssetCustomFieldInput
                  :data="state.custom_field_data"
                  :fieldvalues_map="state.fieldvalues"
                  :options="{ name: 'custom_field' }"
                  class="mb-4"
                  @update="state.fieldvalues[$event.field.uid].value = $event.value;"
                />
              </div>
            </div>
            <div class="sticky bottom-0 bg-white z-1">
              <hr class="my-5">
              <div class="flex justify-end gap-3 pb-6">
                <HawkButton type="outlined" @click="router.push({ name: 'home' })">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement
                  button-class="w-full bg-blue-600"
                  name="submit"
                  :submits="true"
                  :disabled="!auth_store.check_permission('create_assets', route.params.asset_id) && !auth_store.check_permission('modify_assets', route.params.asset_id)"
                >
                  {{ $t("Save") }}
                </ButtonElement>
              </div>
            </div>
          </div>
        </Vueform>
      </div>
    </div>
  </div>
</template>
