<script setup>
// --------------------------------- Imports -------------------------------- //
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { DynamicScroller } from 'vue-virtual-scroller';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import { debounce, groupBy, sortBy } from 'lodash-es';
import { useFormsStore } from '~/forms/store/forms.store';
import { useFormDetailStore } from '~/forms/store/form-detail.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { FORM_PERMISSIONS } from '~/forms/constants';

import CustomCalendar from '~/common/components/organisms/custom-views/custom-calendar.vue';
import FormStatus from '~/forms/atoms/form-status.vue';
import FormsSearchAndFilter from '~/forms/forms-temp/components/forms-search-and-filter.vue';

dayjs.extend(advancedFormat);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);

// ---------------------------------- Props --------------------------------- //

// ---------------------------------- Emits --------------------------------- //

// ---------------------------- Injects/Provides ---------------------------- //

// ----------------------- Variables - Pinia - consts ----------------------- //
const { $t, $date, $date_relative, $services, $toast, auth_store, common_store, route, router, $track_event } = useCommonImports();
const forms_store = useFormsStore('calendar');
const form_detail_store = useFormDetailStore();

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //
const current_date = dayjs().startOf('day');
const accordion_header_elem_map = {};

// ------------------------ Variables - Local - refs ------------------------ //
const loading = ref(false);
const search = ref('');
const available_forms = ref([]);
const weekly_progress = ref(null);
const applied_filters = ref(null);

// ---------------------- Variables - Local - reactives --------------------- //
const week_page_state = reactive({
  next: 0,
  previous: 0,
  toggled_week_date: current_date,
});

// --------------------------- Computed properties -------------------------- //
const has_data = computed(() => !!available_forms.value.length);
const has_filter_applied = computed(() => applied_filters.value || search.value);
const is_boarding = computed(() => !has_data.value && !has_filter_applied.value);
const has_no_results = computed(() => !has_data.value && has_filter_applied.value);
const forms_grouped_by_due_dates = computed(() => groupBy(available_forms.value, form => dayjs(form.due_date).isBefore(current_date) ? 'overdue' : form.calendar_due_date));
const get_formatted_week_dates = computed(() => {
  const week_dates = getDaysInWeekFromDate(week_page_state.toggled_week_date);
  return week_dates.map((date) => {
    const due_date_forms_map = groupBy(available_forms.value, 'calendar_due_date');
    const forms_assigned_to_date = due_date_forms_map[date.format('YYYY-MM-DD')];

    return {
      date,
      is_before: date.isBefore(current_date),
      is_current: date.isSame(current_date),
      progress: {
        total: forms_assigned_to_date?.length || 0,
        completed: (forms_assigned_to_date || []).filter(form => form.status.submission_status === 'submitted').length,
      },
    };
  });
});
const is_current_week = computed(() => week_page_state.next - week_page_state.previous === 0);

// -------------------------------- Functions ------------------------------- //
async function getProgress(options) {
  const { start_date, due_date } = options;
  try {
    const week_dates = getDaysInWeekFromDate(week_page_state.toggled_week_date);
    const { data } = await $services.forms.post({
      attribute: 'progress',
      body: {
        filters: {
          ongoing: true,
          list_view: true,
          templates: [],
          start_date: start_date || week_dates[0].startOf('day'),
          due_date: due_date || week_dates.at(-1).endOf('day'),
        },
      },
    });

    weekly_progress.value = data;
  }
  catch (e) {
    logger.error(e);
  }
}

async function getData(options, append = true) {
  const { start_date, due_date, extra_filters } = options;
  loading.value = true;

  try {
    const week_dates = getDaysInWeekFromDate(week_page_state.toggled_week_date);
    const { data } = await $services.forms.post({
      attribute: 'calendar-view',
      body: {
        filters: {
          ongoing: true,
          list_view: true,
          templates: [],
          due_date: due_date || week_dates.at(-1).endOf('day'),
          start_date: start_date || dayjs(new Date(0)).toISOString(),
          ...(search?.value ? { q: search.value } : {}),
          ...(
            applied_filters.value
              ? {
                  advanced_filters: { logic: { type: 'AND' }, rules: applied_filters.value.filters },
                  [applied_filters.value.user_filter.value]: !!applied_filters.value.user_filter.value,
                }
              : {}
          ),
          ...extra_filters,
        },
      },
    });
    if (!append)
      available_forms.value = [];

    available_forms.value = [...available_forms.value, ...data.forms.map(form => ({ ...form, calendar_due_date: form.due_date ? dayjs(form.due_date).format('YYYY-MM-DD') : null }))];
  }
  catch (e) {
    logger.error(e);
  }
  loading.value = false;
}

function getDaysInWeekFromDate(date) {
  const startOfWeek = date.startOf('week').add(1, 'day'); // Get the start of the current week (Monday)

  // Generate an array of 7 days starting from weekStart provided
  const days_in_week = [];
  for (let i = 0; i < 7; i++)
    days_in_week.push(startOfWeek.add(i, 'day'));

  return days_in_week;
}

function formatAccordionHeaderText(date) {
  const dayjs_date = dayjs(date);
  const is_today = dayjs_date.isToday();
  const is_tomorrow = dayjs_date.isTomorrow();

  return [
    dayjs_date.format('D MMMM'),
    dayjs_date.format('dddd'),
    ...(is_today ? ['Today'] : []),
    ...(is_tomorrow ? ['Tomorrow'] : []),
  ];
}

function getAccordionItems() {
  const week_dates = getDaysInWeekFromDate(week_page_state.toggled_week_date).map(date => dayjs(date).format('YYYY-MM-DD'));

  const due_date_accordion_items = week_dates
    .filter(week_date => dayjs(week_date).isSame(current_date) || dayjs(week_date).isAfter(current_date))
    .map((sorted_due_date) => {
      const forms_to_display = sortBy((forms_grouped_by_due_dates.value[sorted_due_date] || []).filter(form => !(form.status.submission_status === 'submitted' && current_date.isAfter(dayjs(form.submission.submitted_at).endOf('day').toISOString()))), form => new Date(form.due_date));

      return {
        uid: sorted_due_date,
        header: formatAccordionHeaderText(sorted_due_date),
        forms: forms_to_display,
      };
    });

  return [
    ...(is_current_week.value
      ? [{
          uid: 'overdue',
          header: ['Overdue'],
          forms: [
            ...sortBy(forms_grouped_by_due_dates.value.overdue.filter(form => !(form.status.submission_status === 'submitted' && current_date.isAfter(dayjs(form.submission.submitted_at).endOf('day').toISOString()))), overdue_form => new Date(overdue_form.due_date)),
          ],
        }]
      : []),
    ...due_date_accordion_items.filter(accordion_item => !!accordion_item),
  ];
}

function get_location_name(form) {
  let location_name = [];

  if (form?.target_element?.asset && common_store.get_asset(form.target_element.asset)) {
    if (form.target_element.asset)
      location_name = [
        common_store.get_asset(form.target_element.asset).name,
      ];
    if (form.properties?.reference_name_aliases)
      location_name.push(form.properties.reference_name_aliases);
  }

  if (form.template?.name)
    location_name.push(form.template.name);

  return location_name.length ? location_name.join(' > ') : null;
}

function openFormDetailsPage(form) {
  router.push({ query: { form: btoa(JSON.stringify({ form_uid: form.uid })) } });
}

function toggleWeek(type) {
  if (type === 'previous') {
    week_page_state.previous += 1;
    const week_page_state_difference = Math.abs(week_page_state.previous - week_page_state.next);

    week_page_state.toggled_week_date = dayjs(current_date).add(week_page_state_difference, 'week');
    getData({ }, false);
    getProgress({ });
  }

  if (type === 'next') {
    week_page_state.next += 1;
    const week_page_state_difference = Math.abs(week_page_state.previous - week_page_state.next);

    week_page_state.toggled_week_date = dayjs(current_date).add(week_page_state_difference, 'week');
    getData({ }, false);
    getProgress({ });
  }

  if (week_page_state.next - week_page_state.previous === 0) {
    week_page_state.next = 0;
    week_page_state.previous = 0;
  }
}

async function onFilterApply({ filters, user_filter }) {
  applied_filters.value = { filters, user_filter };

  await getData({ }, false);
}

function onSearch(val) {
  search.value = val;
}

function onDateSelect(selected_date) {
  accordion_header_elem_map[selected_date.format('YYYY-MM-DD')]?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'nearest',
  });
}

// when search value is present reset the pagination
const debouncedGetData = debounce(getData, 500);

form_detail_store.$onAction(async ({ args, name, after }) => {
  after(() => {
    if (['update_form_details', 'save_form', 'submit_form'].includes(name))
      getData({}, false);

    if (name === 'submit_form')
      getProgress({});
  });
});

// -------------------------------- Watchers -------------------------------- //
watch(() => route.params.asset_id, async () => {
  await getData({}, false);
  await getProgress({});
});

// -------------------------------- Watchers -------------------------------- //
watch(() => search?.value?.length, () => {
  debouncedGetData({}, false);
});

// ----------------------------- Lifecycle Hooks ---------------------------- //
onMounted(async () => {
  await getData({}, false);
  await getProgress({});
});
</script>

<template>
  <!-- Filters and Search -->
  <FormsSearchAndFilter :quick_filters="['current_user_access_filters', 'templates', 'priority', 'category', 'assignees']" @search="onSearch" @apply="onFilterApply" />
  <div v-if="!has_data && loading">
    <hawk-loader />
  </div>
  <HawkIllustrations v-else-if="has_no_results" type="no-results" for="forms" />
  <HawkIllustrations v-else-if="!has_data" variant="default" type="no-data" for="forms" />
  <HawkIllustrations v-else-if="is_boarding" type="on-boarding" for="forms" :is_create_indicator="auth_store.check_permission(FORM_PERMISSIONS.V2_CREATE_FORMS, route.params.asset_id)" />
  <template v-else>
    <div>
      <CustomCalendar
        :weekly_progress="{ total: weekly_progress?.totalForms || 0, completed: weekly_progress?.completed || 0 }"
        :formatted_week_dates="get_formatted_week_dates"
        :weekly_progress_tooltip="$t('Metrics reflect forms submitted within the week displayed.')"
        @previous_week="() => { toggleWeek('previous') }"
        @today="() => {
          if (is_current_week) return;

          const week_dates = getDaysInWeekFromDate(current_date);
          week_page_state.next = 0;
          week_page_state.previous = 0;
          week_page_state.toggled_week_date = current_date
          getData({ due_date: week_dates.at(-1).endOf('day') }, false)
          getProgress({ due_date: week_dates.at(-1).endOf('day') })
        }"
        @next_week="() => { toggleWeek('next') }"
        @date_select="onDateSelect"
      >
        <template #default>
          <div v-if="loading">
            <hawk-loader />
          </div>
          <HawkAccordion
            v-else
            class="w-full"
            :items="getAccordionItems()"
            type="plain"
          >
            <template #header="{ item, is_open }">
              <div :ref="(el) => el && (accordion_header_elem_map[item.uid] = el)" class="flex justify-between items-center my-4" :class="{ 'pb-4 border-b border-gray-200': is_open }">
                <div class="flex gap-2 items-center font-semibold">
                  <IconHawkChevronUp v-if="is_open" />
                  <IconHawkChevronDown v-else />
                  <div class="flex">
                    <span v-for="(head_text, index) in item.header" :key="head_text" class="text-lg font-semibold  border-red-500">
                      {{ head_text }}
                      <IconHawkFilledCircle v-if="index < item.header.length - 1" class="inline text-gray-300 w-2 ml-1 mr-2" />
                    </span>
                  </div>
                </div>
                <HawkBadge v-if="item.forms?.length" :color="item.uid === 'overdue' ? 'red' : 'blue' ">
                  {{ item.forms.length }}
                </HawkBadge>
              </div>
            </template>
            <template #content="{ content: item }">
              <div v-if="!item.forms.length" class="text-sm text-gray-600 mb-4 px-3">
                {{ $t('No forms available') }}
              </div>
              <template v-else>
                <DynamicScroller
                  id="dynamic_scroller"
                  class="max-h-[400px] scrollbar"
                  :items="item.forms"
                  key-field="uid"
                  :prerender="10"
                  :item-secondary-size="184"
                  :item-size="130"
                  :min-item-size="130"
                >
                  <template #default="{ item: form, index }">
                    <div
                      :key="form.uid"
                      class="py-4 cursor-pointer hover:bg-gray-50 p-2 rounded-md calendar-form-instance h-[120px]"
                      @click="() => openFormDetailsPage(form)"
                    >
                      <hawk-text :length="60" class="text-xs text-gray-600 mb-0.5" :content="get_location_name(form)" />
                      <div class="flex items-center justify-between">
                        <div class="flex gap-2 items-center">
                          <hawk-text :length="60" class="text-gray-900 font-medium" :content="form.name" />
                          <IconHawkCheckCircleGreen v-if="form.status.submission_status === 'submitted'" />
                        </div>
                        <div class="flex gap items-center">
                          <FormStatus :form="form" />
                          <HawkBadge v-if="form.status.submission_status !== 'submitted'" custom_classes="!bg-opacity-0" :color="dayjs(form.due_date).isBefore(current_date) ? 'red' : 'gray' ">
                            {{ dayjs(form.due_date).format('DD MMMM YYYY') }}
                          </HawkBadge>
                        </div>
                      </div>
                      <div class="flex justify-between">
                        <div>
                          <HawkBadge v-if="form.category" color="gray">
                            {{ common_store.get_category(form.category)?.name }}
                          </HawkBadge>
                        </div>
                        <div class="flex gap-2 items-center">
                          <template v-if="form.status.submission_status === 'submitted'">
                            <span class="text-xs gray-600">Submitted at
                              {{ $date(form.submission.submitted_at, 'L_TIME_SHORT') }}
                            </span>
                          </template>
                          <template v-else>
                            <span class="text-xs gray-600">Assigned to</span>
                            <HawkMembers class="pointer-events-none" type="group" size="badge" :members="form.assignees" :max_badges_to_display="1" />
                          </template>
                        </div>
                      </div>
                    </div>
                    <div v-if="index < item.forms.length - 1" class="border border-gray-200 my-1" />
                  </template>
                </DynamicScroller>
              </template>
            </template>
          </HawkAccordion>
          <div v-if="!loading" class="flex justify-between items-center py-2 mt-1 border-t">
            <HawkButton :disabled="is_current_week" type="link" @click="() => { toggleWeek('previous') }">
              <icon-hawk-chevron-left class="w-5 h-5" />
              {{ $t('Previous week') }}
            </HawkButton>
            <HawkButton type="link" @click="() => { toggleWeek('next') }">
              {{ $t('Next week') }}
              <icon-hawk-chevron-right class="w-5 h-5" />
            </HawkButton>
          </div>
        </template>
      </CustomCalendar>
    </div>
  </template>
</template>
