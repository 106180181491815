<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useFormsStore } from '~/forms/store/forms.store';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import { useFormTemplatesStore } from '~/forms/store/form-templates.store.js';

const $t = inject('$t');

const router = useRouter();
const route = useRoute();
const form_store = useFormsStore();
const active_tab = computed(() => route.name);
const form_template_detail_store = useFormTemplateDetailStore();
const form_templates_store = useFormTemplatesStore();

const form_template_from_list_page = form_templates_store.form_templates_map[route.params.template_uid];

const form_responses_count = form_template_from_list_page?.metrics?.submitted?.value;

const tabs = computed(() => [{
  uid: 'form-template-instances',
  label: $t('Forms'),
  on_click: () => {
    router.push({ name: 'form-template-instances', params: { asset_id: route.params.asset_id } });
  },
  disabled: false,
}, {
  uid: 'form-template-responses',
  label: $t('Responses'),
  on_click: () => {
    router.push({ name: 'form-template-responses', params: { asset_id: route.params.asset_id } });
  },
  disabled: false,
},
{
  uid: 'form-template-reports',
  label: $t('Reports'),
  on_click: () => {
    router.push({ name: 'form-template-reports', params: { asset_id: route.params.asset_id } });
  },
  disabled: false,
},
]);
</script>

<template>
  <div>
    <div class="mt-4 pb-1">
      <hawk-tabs :tabs="tabs" :active_item="active_tab" />
    </div>
    <router-view />
  </div>
</template>
