import Forms from '~/forms/pages/forms.vue';
import FormsInstancesList from '~/forms/pages/form-instances-list-page.vue';

// Form Overview list pages
import FormOverviewList from '~/forms/pages/form-overview-list/form-overview-list.vue';
import FormOverviewFormsList from '~/forms/pages/form-overview-list/form-overview-forms-list.vue';
import FormOverviewSubmissionsList from '~/forms/pages/form-overview-list/form-overview-submissions-list.vue';

// Form Template list pages
import FormsTemplatesList from '~/forms/pages/form-templates-list/form-templates-list.vue';

// Template detail overview pages
import FormsTemplateDetail from '~/forms/pages/form-template-detail/form-template-detail.vue';
import FormsTemplateDetailOverview from '~/forms/pages/form-template-detail/overview/form-template-detail-overview.vue';
import FormTemplateResponses from '~/forms/pages/form-template-detail/overview/form-template-detail-responses-overview.vue';
import FormTemplateReports from '~/forms/pages/form-template-detail/overview/form-template-detail-reports-overview.vue';
import FormTemplateDetailInstancesOverview from '~/forms/pages/form-template-detail/overview/form-template-detail-instances-overview.vue';

// Builder pages
import FormTemplateBuilder from '~/forms/pages/form-template-detail/builder/form-template-detail-builder.vue';
import FormTemplateFormBuilder from '~/forms/pages/form-template-detail/builder/form-template-detail-form-builder.vue';
import FormTemplateWorkflowBuilder from '~/forms/pages/form-template-detail/builder/form-template-detail-workflow-builder.vue';

// settings
import FormTemplateDetailSettings from '~/forms/pages/form-template-detail/settings/form-template-detail-settings.vue';
import FormTemplateDetailGeneral from '~/forms/pages/form-template-detail/settings/form-template-detail-settings-general.vue';
import FormsTemplateDetailMail from '~/forms/pages/form-template-detail/settings/form-template-detail-settings-mail.vue';
import FormTemplateDetailQuickAcess from '~/forms/pages/form-template-detail/settings/form-template-detail-settings-quick-access.vue';
import FormTemplateDetailSharing from '~/forms/pages/form-template-detail/settings/form-template-detail-settings-sharing.vue';

// Approval Redirect
import FormApproval from '~/forms/pages/form-approval.vue';

const routes = [
  {
    path: '/:asset_id?/forms',
    component: Forms,
    name: 'forms',
    redirect: { name: 'form-overview-list' },
    meta: {

    },
    children: [
      {
        path: 'overview',
        component: FormOverviewList,
        name: 'form-overview-list',
        redirect: { name: 'form-overview-forms-list' },
        meta: {
          module: 'forms',

        },
        children: [{
          path: 'forms-overview',
          component: FormOverviewFormsList,
          name: 'form-overview-forms-list',
          props: route => ({ asset_id: route.params.asset_id }),
        }, {
          path: 'submissions-overview',
          component: FormOverviewSubmissionsList,
          name: 'form-overview-submission-list',
          props: route => ({ asset_id: route.params.asset_id }),
        },
        ],
      },
      {
        path: 'templates',
        component: FormsTemplatesList,
        name: 'form-templates',
        props: route => ({ asset_id: route.params.asset_id }),
        meta: {
          module: 'forms',

        },
      },
    ],
  },
  {
    path: '/:asset_id?/form/:template_uid/:form_name?',
    component: FormsInstancesList,
    name: 'form-instances',
    meta: {
      module: 'forms',

    },
  },
  {
    path: '/:asset_id?/forms/template/:template_uid/',
    component: FormsTemplateDetail,
    name: 'form-template-detail',
    redirect: { name: 'form-template-instances' },
    props: route => ({ asset_id: route.params.asset_id }),
    meta: {
      module: 'forms',

    },
    children: [
      {
        path: 'overview',
        component: FormsTemplateDetailOverview,
        name: 'form-template-overview',
        redirect: { name: 'form-template-instances' },
        meta: {
          module: 'forms',

        },
        children: [
          {
            path: 'forms',
            component: FormTemplateDetailInstancesOverview,
            name: 'form-template-instances',
            props: route => ({ asset_id: route.params.asset_id }),
          }, {
            path: 'responses',
            component: FormTemplateResponses,
            name: 'form-template-responses',
          }, {
            path: 'reports',
            component: FormTemplateReports,
            name: 'form-template-reports',
          },
        ],
      },
      {
        path: 'builder/',
        meta: {
          module: 'forms',

        },
        component: FormTemplateBuilder,
        name: 'form-template-detail-builder',
        redirect: { name: 'form-template-detail-form-builder' },
        children: [{
          path: 'form',
          component: FormTemplateFormBuilder,
          name: 'form-template-detail-form-builder',
        }, {
          path: 'workflow',
          component: FormTemplateWorkflowBuilder,
          name: 'form-template-detail-workflow-builder',
        }],
      },
      {
        path: 'settings/',
        meta: {
          module: 'forms',

        },
        component: FormTemplateDetailSettings,
        name: 'form-template-detail-settings',
        redirect: { name: 'form-template-detail-settings-general' },
        children: [{
          path: 'general/',
          component: FormTemplateDetailGeneral,
          name: 'form-template-detail-settings-general',
        },
        {
          path: 'mail/',
          component: FormsTemplateDetailMail,
          name: 'form-template-detail-settings-mail',
        },
        {
          path: 'quickacess/',
          component: FormTemplateDetailQuickAcess,
          name: 'form-template-detail-settings-quickAccess',
        },
        {
          path: 'sharing/',
          component: FormTemplateDetailSharing,
          name: 'form-template-detail-settings-sharing',
        }],
      },

    ],
  },
  {
    path: '/:asset_id?/approvals/:approval_id',
    name: 'form-approval',
    component: FormApproval,
    meta: {
      module: 'forms',

    },
  },

];
export default routes;
