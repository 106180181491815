<script setup>
import { useModal } from 'vue-final-modal';
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';
import { useThermStore } from '~/therm/store/therm.store.js';
import DefectStatus from '~/therm/components/atoms/defects-status.vue';
import DefectPriority from '~/therm/components/atoms/defects-priority.vue';
import DefectAttachmentAndNotes from '~/therm/components/defect-details/defect-attachment-and-notes.vue';
import DefectAssigneesPopup from '~/therm/components/defect-details/defect-assignees-popup.vue';
import TaskCard from '~/tasks/components/molecule/task-details-card.vue';
import FormCard from '~/forms/molecules/form-details-card.vue';
import DefectTypePopup from '~/therm/components/defect-details/defect-type-popup.vue';

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
  feature_types: {
    type: Object,
  },
  // eslint-disable-next-line vue/prop-name-casing
  updateIssue: {
    type: Function,
  },
  use_tasks: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['update']);
const router = useRouter();

const therm_store = useThermStore();

const { open: openAssigneesModal, close: closeAssigneesModal, patchOptions: assigneesPatchOptions } = useModal({
  component: DefectAssigneesPopup,
  attrs: {
    onClose: () => {
      closeAssigneesModal();
    },
  },
});

const permissions = computed(() => therm_store.features_hash[props.data?.uid]);
const feature_type = computed(() => {
  return props.feature_types[props.data?.featureTypeId];
});
const task_details = computed(() => {
  const task = therm_store.tasks_hash?.[props.data.uid];
  return task;
});
const tasks_list = computed(() => {
  const tasks = (therm_store.tasks_list_hash?.[props.data.uid] || []);
  if (therm_store.filters?.filter?.associated_with_defects)
    return tasks.filter(task => therm_store.check_task_filters(task));
  return tasks;
});
const forms_list = computed(() => therm_store.forms_list_hash?.[props.data.uid]);

function openAssigneesPopup() {
  assigneesPatchOptions({
    attrs: {
      assignees: (props.data.assignees || []),
      on_update: props.updateIssue,
    },
  });
  openAssigneesModal();
}
function openDefectTypePopup() {
  const { open: openDefectTypeModal, close: closeDefectTypeModal } = useModal({
    component: DefectTypePopup,
    attrs: {
      defect_type_id: feature_type.value.id,
      on_update: props.updateIssue,
      onClose: () => {
        closeDefectTypeModal();
      },
    },
  });
  openDefectTypeModal();
}
function openCoordinatesOnMap() {
  const coordinates = props.data.center[0];
  const map_url = `https://www.google.com/maps?q=${coordinates.toReversed().join(',')}`;
  window.open(map_url, '_blank');
}
function viewTaskDetails() {
  router.push({
    query: {
      task: btoa(JSON.stringify({
        id: task_details.value.uid,
        store_key: 'therm_tasks_store',
      })),
    },
  });
}
</script>

<template>
  <div class="mx-6 mb-6">
    <div class="text-sm font-medium text-gray-500">
      {{ $t('Name') }}
    </div>
    <div class="text-sm font-medium text-gray-900 mt-1 mb-4">
      {{
        `${data.string_number} ${
          feature_types[data.featureTypeId]
            ? ` : ${feature_types[data.featureTypeId].name}`
            : ""
        }`
      }}
    </div>
    <div v-if="data?.description" class="mb-4">
      <div class="text-sm font-medium text-gray-500">
        {{ $t('Description') }}
      </div>
      <div class="text-sm font-medium text-gray-900 mt-1">
        {{ data.description }}
      </div>
    </div>
    <div class="mb-4">
      <div class="flex items-center justify-between">
        <div class="text-sm font-medium text-gray-500">
          {{ $t("Defect type") }}
        </div>
        <HawkButton v-if="permissions.modify_type" type="link" class="!p-0 !h-auto" @click="openDefectTypePopup()">
          {{ $t('Edit') }}
        </HawkButton>
      </div>
      <div class="mt-1 text-sm font-medium text-gray-900">
        {{ feature_type ? feature_type.name : "-" }}
      </div>
    </div>
    <div v-if="data?.inverter?.name" class="mb-4">
      <div class="text-sm font-medium text-gray-500">
        {{ $t('Inverter') }}
      </div>
      <div class="text-sm font-medium text-gray-900 mt-1">
        {{ data.inverter.name }}
      </div>
    </div>
    <div class="text-sm font-medium text-gray-500 mb-1">
      {{ $t("Status") }}
    </div>

    <template v-if="use_tasks">
      <TaskStatus v-if="task_details?.status" :status="task_details.status" />
      <div v-else>
        -
      </div>
    </template>
    <DefectStatus v-else :status="data.status" :type="permissions.modify_status ? 'dropdown' : 'badge'" @update="emits('update', { status: $event.uid })" />
    <div class="mt-4">
      <div class="text-sm font-medium text-gray-500 mb-1">
        {{ $t("Priority") }}
      </div>
      <template v-if="use_tasks">
        <TaskPriority v-if="task_details?.priority" :priority="task_details.priority" />
        <div v-else>
          -
        </div>
      </template>
      <div v-else-if="!permissions.modify_properties && !data.priority">
        -
      </div>
      <DefectPriority v-else :priority="data.priority" :type="permissions.modify_properties ? 'dropdown' : 'badge'" @update="emits('update', { priority: $event.uid })" />
    </div>
    <div class="mt-2">
      <div class="flex items-center justify-between mb-1">
        <div class="text-sm font-medium text-gray-500">
          {{ $t("Assignees") }}
        </div>
        <HawkButton v-if="!use_tasks && permissions.modify_properties" type="link" class="!p-0 !h-auto" @click="openAssigneesPopup()">
          {{ $t('Edit') }}
        </HawkButton>
      </div>
      <HawkMembers v-if="(use_tasks ? task_details?.assignees?.length : data.assignees?.length)" class="font-medium" :members="use_tasks ? task_details?.assignees : data.assignees" type="badge" :max_badges_to_display="3" />
      <div v-else-if="use_tasks || !permissions.modify_properties">
        -
      </div>
    </div>
    <div class="mt-4">
      <div class="text-sm font-medium text-gray-500">
        {{ $t("Location") }}
      </div>
      <div class="text-sm text-gray-900 font-medium mt-1">
        {{ data.string_number || "NA" }}
      </div>
    </div>
    <div v-if="data.temperature_difference" class="mt-4">
      <div class="text-sm font-medium text-gray-500">
        {{ $t("Δ Temperature") }}
      </div>
      <div class="text-sm text-gray-900 font-medium mt-1">
        {{ `${data.temperature_difference.toFixed(2)} °C` }}
      </div>
    </div>
    <div v-if="data?.center?.[0]" class="mt-4">
      <div class="text-sm font-medium text-gray-500">
        {{ $t("Coordinates") }}
      </div>

      <div class="mt-1 break-all">
        <HawkButton type="link" @click="openCoordinatesOnMap">
          {{ data.center[0]?.toReversed().join(',') }}
        </HawkButton>
      </div>
    </div>
    <div v-if="data?.serial_number" class="mt-4">
      <div class="text-sm font-medium text-gray-500">
        {{ $t("Module serial number") }}
      </div>
      <div class="text-sm text-gray-900 font-medium mt-1">
        {{ data.serial_number }}
      </div>
    </div>
    <div v-if="data?.timestamp" class="mt-4">
      <div class="text-sm font-medium text-gray-500">
        {{ $t("Timestamp") }}
      </div>
      <div class="text-sm text-gray-900 font-medium mt-1">
        {{ $date(dayjs(data.timestamp.slice(0, 10), 'YYYY:MM:DD'), 'DD MMM YYYY') }}  {{ data.timestamp.slice(10) }}
      </div>
    </div>
    <div
      v-if="data.adjusted_delta_temperature"
      class="mt-4"
    >
      <div class="text-sm font-medium text-gray-500">
        {{ $t("Adjusted Δ Temp") }}
      </div>

      <div class="text-sm text-gray-900 font-medium mt-1">
        {{
          `${data.adjusted_delta_temperature?.toFixed(2)} °C`
        }}
      </div>
    </div>
    <div
      v-if="data.yield_loss_estimate"
      class="flex w-full text-sm font-semibold pb-4"
    >
      <div>
        {{ $t("Yield Loss Estimate") }}:
      </div>
      <div class="flex-grow text-left">
        {{ data.yield_loss_estimate }}
      </div>
    </div>
    <div
      v-if="data.power_loss_estimate"
      class="flex w-full text-sm font-semibold mb-4"
    >
      <div class="grow-0">
        {{ $t("Power Loss Estimate") }}:
      </div>
      <div class="flex-grow text-left ml-2">
        {{ data.power_loss_estimate }}
      </div>
    </div>
    <HawkButton v-if="use_tasks && task_details" class="mt-4" block @click="viewTaskDetails">
      {{ $t('View details') }}
    </HawkButton>

    <div v-if="!use_tasks && tasks_list?.length" class="mt-4">
      <TaskCard v-for="task in tasks_list" :key="task.uid" :task="task" />
    </div>

    <div v-if="forms_list?.length" class="mt-4">
      <FormCard v-for="form in forms_list" :key="form.uid" :form="form" />
    </div>
    <DefectAttachmentAndNotes
      class="mt-5"
      :data="data"
      :feature_types="feature_types"
    />
  </div>
</template>
