<script setup>
import { useTerraStore } from '~/terra/store/terra.store.js';

const emit = defineEmits(['close']);
const state = reactive({
  loading: false,
});
const $services = inject('$services');
const $toast = inject('$toast');
const terra_store = useTerraStore();
async function requestMbTiles() {
  try {
    state.loading = true;
    await $services.common.post({
      url: `${import.meta.env.VITE_APP_CORE_API_HOST}/containers/${terra_store.container?.uid}/generate-mbtiles`,
      body: {
        reports: Object.values(terra_store.show_request_mbtiles_popup.requested_reports),
      },
    });
    terra_store.show_request_mbtiles_popup.requested_reports = {};
    emit('close');

    state.loading = false;
  }
  catch (error) {
    logger.log('🚀 ~ requestMbTiles ~ error:', error.data);
    $toast({
      type: 'error',
      text: error.data.message,
    });
    state.loading = false;
  }
}
</script>

<template>
  <hawk-modal-container :width="600" content_class="rounded-lg max-w-[600px]">
    <hawk-modal-header @close="emit('close')">
      <template #title>
        <p class="text-gray-900 text-lg font-semibold">
          {{ $t('Maps archived') }}
        </p>
      </template>
    </hawk-modal-header>
    <hawk-modal-content>
      <div class="flex gap-20 items-center text-sm">
        {{ $t('The selected maps are archived due to inactivity. You can request to process and generate maps using the button below. It might take about 2 hours to process. Do you want to continue?') }}
      </div>
    </hawk-modal-content>
    <hawk-modal-footer>
      <template #right>
        <div class="flex justify-end gap-2">
          <HawkButton :loading="state.loading" @click="requestMbTiles">
            {{ $t('Request') }}
          </HawkButton>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>
