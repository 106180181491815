<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useFormTemplatesStore } from '~/forms/store/form-templates.store';
import { useFormsStore } from '~/forms/store/forms.store';
import { useFormFilterStore } from '~/forms/store/form-filter-store';
import { useFormSubmission } from '~/forms/store/form-submission-store';
import { getPageNameByRouteName } from '~/common/utils/common.utils';

const props = defineProps({
  asset_id: {
    type: String,
  },
  fields: {
    type: Array,
    default: () => ['Template', 'Submitted on', 'Category'],
  },
});

const emit = defineEmits(['close']);

const $track_event = inject('$track_event');
const route = useRoute();

const form$ = ref(null);
const forms_store = useFormsStore();
const form_submission_store = useFormSubmission();
const forms_template_store = useFormTemplatesStore();
const filter_store = useFormFilterStore();
const is_loading = ref(false);
const submitted_date_filter = ref([{ name: 'Today', value: 'today', label: 'Today' }, { value: 'week', name: 'This Week', label: 'This Week' }, { value: 'month', label: 'This Month', name: 'This Month' }, { value: 'custom', label: 'Custom Range', name: 'Custom Range' }]);
function formatLoadedData(data) {
  const load_data = {};
  Object.keys(data).forEach((key) => {
    load_data[key] = data[key];
  });
  return load_data;
}

onMounted(() => {
  if (forms_store.filters_count)
    form$.value.load(forms_store.filters, true);
});

async function handleSave() {
  const payload = {};
  const form_data = form$.value.data;

  Object.keys(form_data).forEach((key) => {
    if (form_data[key]?.length)
      payload[key] = form_data[key];
    else if (Object.values(form_data[key]).filter(v => v).length)
      payload[key] = form_data[key];
  });
  await filter_store.set_filters({ payload });
  if (Object.keys(payload).length)
    $track_event('Filtered', {
      view: getPageNameByRouteName(route.name),
    });
  emit('close');
}

const query = { all_access: true, is_child: false, step_metrics: true };

onMounted(async () => {
  if (filter_store.filters_count)
    form$.value.load(filter_store.filters, true);
});

function clearFilters() {
  form$.value.reset();
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      :endpoint="handleSave"
      size="sm"
      :format-load="formatLoadedData"
      :columns="{
        default: { container: 12, wrapper: 12 },
        sm: { container: 7, label: 12 },
        md: { container: 12, label: 4 },
      }"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('filters') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content class="min-h-[300px]">
          <div class="col-span-12">
            <template v-for="field in fields">
              <!-- Templates -->
              <TagsElement
                v-if="field === 'Template'"
                :key="field"
                class="mb-4"
                name="template"
                :label="`${$t('template')}`"
                :placeholder="$t('Select template')"
                :items="form_submission_store?.form_templates"
                :tags_removable="false"
                label-prop="name"
                value-prop="uid"
                track-by="name"
                :search="true"
                :can-clear="false"
                :loading="is_loading"
              />
              <!-- Submitted on -->

              <ObjectElement
                v-if="field === 'Submitted on'"
                :key="field"
                class="mb-4"
                name="date_group"
                :label="$t('Submitted on')"
              >
                <SelectElement
                  v-if="field === 'Submitted on'"
                  name="due_date"
                  :placeholder="$t('Select submitted date')"
                  :items="submitted_date_filter"
                  :tags_removable="true"
                  search="true"
                />
                <DateTimeElement
                  :key="field"
                  :options="{
                    'format': 'dd/MM/yyyy',
                    'placeholder': $t('Select start date'),
                    'model-type': 'yyyy-MM-dd',
                  }"
                  class="mb-4"
                  :conditions="[
                    ['date_group.due_date', 'custom'],
                  ]"
                  name="start_due_date"
                />
                <DateTimeElement
                  :key="field"
                  :options="{
                    'format': 'dd/MM/yyyy',
                    'placeholder': $t('Select end date'),
                    'model-type': 'yyyy-MM-dd',
                  }"
                  class="mb-4"
                  name="end_due_date"
                  :conditions="[
                    ['date_group.due_date', 'custom'],
                  ]"
                />
              </ObjectElement>
              <!-- Category -->
              <hawk-category-input
                v-if="field === 'Category'"
                :key="field" class="mb-4" :options="{
                  name: 'category',
                  label: $t('Category'),
                  placeholder: $t('Select category'),
                  tags_removable: true,
                }" :multi="true"
              />
            </template>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #left>
            <hawk-button type="text" @click="clearFilters">
              {{ $t('Clear filters') }}
            </hawk-button>
          </template>
          <template #right>
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-3"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                button-class="w-full bg-blue-600" name="submit" :submits="true"
              >
                {{ $t('Apply') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>

<style>
  .vfm__content {
    min-width: 600px;
  }
</style>
