<script setup>
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import SmAddInventoryItemForm from '~/system-model/components/sm-inventory-items/sm-add-inventory-item-form.vue';
import SmNoResultsIllustration from '~/system-model/atoms/sm-no-results-illustration.vue';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { $t } = useCommonImports();

const system_model_store = useSystemModelStore();
const { can_modify_resources, active_instance } = storeToRefs(system_model_store);

const table_columns = [
  {
    accessorKey: 'name',
    header: $t('Name'),
    id: 'name',
  },
  {
    accessorKey: 'quantity',
    header: $t('Quantity'),
    id: 'quantity',
  },
];

const state = reactive({
  items_details: null,
});

const items_list = computed(() => state.items_details?.items || []);
const measurement_units = computed(() => state.items_details?.measurement_units || []);

const inventory_items = computed(() => {
  if (active_instance.value)
    return system_model_store.active_instance_details?.materials || [];
  return system_model_store.active_component_details?.materials || [];
});

async function getData(items) {
  state.items_details = await system_model_store.set_inventory_items_list({
    body: {
      filters: {
        item_uids: items,
      },
    },
    query: {
      'include[]': 'uom.*',
    },
  });
}

onMounted(async () => {
  if (inventory_items.value.length)
    getData(inventory_items.value.map(item => item.uid));
});

const add_item_modal = useModal({
  component: SmAddInventoryItemForm,
  attrs: {
    onClose() {
      add_item_modal.close();
    },
    onFetchItems: (items) => {
      getData(items);
    },
  },
});

function get_uom_name(uid) {
  const item_uom = items_list.value.find(item => item.uid === uid)?.uom || null;
  const item = measurement_units.value.find(unit => unit.uid === item_uom);
  if (item)
    return `${item?.symbol || ''}`;
  return '';
}

onBeforeUnmount(() => {
  add_item_modal?.destroy();
});
</script>

<template>
  <div>
    <HawkPageSecondaryHeader class="my-6">
      <template #left>
        <div class="text-gray-900 font-semibold text-lg">
          {{ $t('Items') }}
        </div>
      </template>
      <template #right>
        <template v-if="can_modify_resources">
          <hawkButton v-if="inventory_items.length" color="primary" type="outlined" class="font-semibold" @click="add_item_modal.open()">
            {{ $t('Update items') }}
          </hawkButton>
          <hawkButton v-else type="outlined" class="font-semibold" @click="add_item_modal.open()">
            <IconHawkPlusGray class="h-3 w-3" /> {{ $t('Add items') }}
          </hawkButton>
        </template>
      </template>
    </HawkPageSecondaryHeader>
    <SmNoResultsIllustration v-if="!inventory_items.length" title="No #Items found" />
    <TableWrapperVue v-else class="border-0" height="384">
      <HawkTable
        class="!px-0"
        :data="inventory_items"
        :pagination_config="{ totalRows: inventory_items.length, pageSize: 25 }"
        :columns="table_columns"
        :show_menu_header="false"
        :enable_infinite_scroll="true"
        :non_sortable_columns="['quantity', 'name', 'measurement_units']"
      >
        <template #quantity="{ data: { row } }">
          <div> {{ `${row.original.quantity} ${get_uom_name(row.original.uid)}` }}</div>
        </template>
      </HawkTable>
    </TableWrapperVue>
  </div>
</template>
