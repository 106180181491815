<script setup>
import { useModal } from 'vue-final-modal';
import { omit } from 'lodash-es';
import { storeToRefs } from 'pinia';
import SmSidebar from '~/system-model/components/sm-sidebar/sm-sidebar.vue';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import SmFieldTable from '~/system-model/components/sm-field-table.vue';
import SmInstances from '~/system-model/components/sm-instances.vue';
import SmComponentGraph from '~/system-model/components/sm-component-graph.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import SmComponentForm from '~/system-model/components/forms/sm-component-form.vue';
import SmConfigForm from '~/system-model/components/forms/sm-config-form.vue';
import SmInventoryItems from '~/system-model/components/sm-inventory-items/sm-inventory-items.vue';
import SmComponentDuplicateForm from '~/system-model/components/forms/sm-component-duplicate-form.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const system_model_store = useSystemModelStore();
const { route, router, $t } = useCommonImports();

const { active_tab, active_component, active_component_details, can_modify_resources } = storeToRefs(system_model_store);

const state = reactive({
  is_loading: false,
  total_instance_count: null,
});

const component_category_name = computed(() => {
  return system_model_store.categories_map[active_component_details.value?.category]?.name || '';
});

const { open: openDeletePopup, close: closeDeletePopup } = useModal({
  component: HawkDeletePopup,
  attrs: {
    header: $t('Delete Component'),
    content: 'Are you sure you want to delete this component?',
    onClose() {
      closeDeletePopup();
    },
    confirm: async () => {
      try {
        const options = {
          component_id: active_component.value?.uid,
          type: 'delete',
        };
        await system_model_store.delete_component(options);
        await system_model_store.set_active_component_hierarchy({ template_id: route?.params?.template_id });
        closeDeletePopup();
      }
      catch (err) {
        logger.error(err);
      }
    },
  },
});

const duplicate_component_modal = useModal({
  component: SmComponentDuplicateForm,
});

const connections = computed(() => {
  if (active_component_details.value) {
    const { parents, name, uid } = active_component_details.value;
    if (parents?.length)
      return parents.map(parent => ({
        ...parent,
        ...{
          child_name: name,
          child_uid: uid,
          layouts: {
            layout_x: parent.layouts.layout_x || 0,
            layout_y: parent.layouts.layout_y || 0,
          },
        },
      }));
  }
  return [];
});

const instance_count_str = computed(() => {
  return state.total_instance_count ? `(${state.total_instance_count})` : '';
});
const tabs = computed(() => [
  {
    uid: 'SmComponentDetails',
    label: 'Details',
    on_click: () => {
      router.replace({ query: { ...route.query, active: 'details', component: active_component.value.uid } });
      system_model_store.set_active_tab('SmComponentDetails');
    },
    disabled: false,
  },
  {
    uid: 'SmInstances',
    label: `Instances ${instance_count_str.value}`,
    on_click: () => {
      router.replace({ query: { ...route.query, active: 'instance', component: active_component.value.uid } });
      system_model_store.set_active_tab('SmInstances');
    },
    disabled: false,
  },
]);

watch(active_component, async (value) => {
  const { uid, only_update } = value || {};
  if (uid && !only_update)
    await getData();
}, { immediate: true });

watch(active_tab, async () => {
  state.total_instance_count = null;
  await getData(false);
});

const config_modal = useModal({
  component: SmConfigForm,
  attrs: {
    onClose() {
      config_modal.close();
    },
  },
});

const edit_modal = useModal({
  component: SmComponentForm,
});

const component_context_menu = computed(() => {
  return [
    { label: $t('Duplicate'), on_click: () => openDuplicateComponentModal() },
    { label: $t('Config'), on_click: () => config_modal.open() },
    { label: $t('Edit'), on_click: () => openEditModal() },
    { label: $t('Delete'), on_click: () => openDeletePopup() },
  ];
});

async function getData(loader = true) {
  if (active_component.value) {
    state.is_loading = loader;
    await system_model_store.set_active_component_details({
      component_id: active_component.value.uid,
    });
    state.is_loading = false;
  }
}

function openDuplicateComponentModal() {
  duplicate_component_modal.patchOptions({
    attrs: {
      onClose() {
        duplicate_component_modal.close();
      },
      component_uid: active_component.value.uid,
    },
  });
  duplicate_component_modal.open();
}

function openEditModal() {
  edit_modal.patchOptions({
    attrs: {
      onClose() {
        edit_modal.close();
      },
      onClosed() {
        const attrs = omit(edit_modal.options.attrs, ['parent_id', 'component_detail']); // reset attrs
        edit_modal.options.attrs = attrs;
      },
      component_detail: active_component_details.value,
    },
  });
  edit_modal.open();
}
</script>

<template>
  <div v-if="active_component" class="flex">
    <div class="w-1/6">
      <SmSidebar />
    </div>
    <div class="w-5/6 flex border-t border-gray-200">
      <div
        id="sm_properties_detail"
        class="mt-6 px-4"
        :class="active_tab === 'SmComponentDetails' && !state.is_loading ? 'w-1/2' : 'w-full'"
      >
        <div class="flex items-center justify-between">
          <div v-if="active_component">
            <div class="text-lg font-semibold break-all">
              {{ active_component_details.name }}
            </div>
            <span class="text-sm">
              {{ component_category_name }}
            </span>
          </div>
          <hawk-menu
            v-if="active_tab === 'SmComponentDetails' && can_modify_resources"
            class="flex items-center mr-3"
            :items="component_context_menu"
            additional_trigger_classes="!ring-0"
          >
            <template #trigger>
              <HawkButton type="outlined" size="sm" icon>
                <IconHawkDotsVertical class="text-gray-600 h-5" />
              </HawkButton>
            </template>
          </hawk-menu>
        </div>

        <hawk-tabs :tabs="tabs" class="my-5" :active_item="active_tab" />
        <HawkLoader v-if="state.is_loading" />
        <template v-else>
          <div v-if="active_tab === 'SmComponentDetails'" class="mb-5">
            <SmFieldTable />
            <SmConnections
              :connections="connections"
            />
            <SmInventoryItems />
          </div>
          <div v-else>
            <SmInstances @totalInstance="state.total_instance_count = $event" />
          </div>
        </template>
      </div>
      <div v-if="active_tab === 'SmComponentDetails' && !state.is_loading" class="w-1/2 flex-auto">
        <SmComponentGraph />
      </div>
    </div>
  </div>
</template>
