<script setup>
import { useCustomFields } from '~/inventory/composables/inventory-custom-fields.composable.js';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import InventoryItemStockUnitEdit from '~/inventory/components/inventory-items/inventory-item-stocks/inventory-item-stock-unit-edit.vue';
import InventoryItemStockStatus from '~/inventory/components/inventory-items/inventory-item-stock-status.vue';
import InventoryCustomField from '~/inventory/components/inventory-custom-fields/inventory-custom-field.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import InventoryUom from '~/inventory/components/inventory-uom/inventory-uom.vue';

const props = defineProps({
  item: { type: Object, default: () => ({}) },
  value: { type: Object, default: () => ({}) },
});
const emit = defineEmits(['close']);

const { $t, $date, $date_relative, $services, $toast, auth_store, common_store, route, router, track_event } = useCommonImports();
const { map_custom_fields } = useCustomFields();

const inventory_store = useInventoryStore();
const form$ = ref({});
const header_tabs = [
  { uid: 'details', label: $t('Details') },
  { uid: 'transactions', label: $t('Transactions') },
];

const is_edit = ref(false);
const table_loading = ref(false);
const table_items = ref([]);
const adjustments = ref([]);
const columns = ref([
  { id: 'number', accessorKey: 'number', header: $t('Number') },
  // { id: 'type', accessorKey: 'type', header: 'Type' },
  { id: 'date', accessorKey: 'date', header: $t('Date') },
  { id: 'total_quantity', accessorKey: 'total_quantity', header: $t('Quantity') },
  { id: 'from_stock', accessorKey: 'from', header: $t('From') },
  { id: 'to_stock', accessorKey: 'to', header: $t('To') },
]);

const state = reactive({
  active_tab: 'details',
});

const stock_unit_data = ref(props.value);

const get_stock_custom_fields = computed(() => inventory_store.get_custom_fields({ uid: props.item.uid }, true));
const custom_fields_config = ref([]);

const get_custom_field_name = computed(() => {
  return field => field?.item_meta?.properties?.label || field.name;
});

const get_custom_field_value = computed(() => {
  return uid => stock_unit_data.value.custom_fields.find(cf => cf.uid === uid)?.value;
});

async function saveCustomFields() {
  if (form$?.value?.hasErrors)
    return;
  const payload = {
    id: stock_unit_data.value.uid,
    body: { stock_unit: form$.value.requestData },
    query: { item: props.item.uid },
  };
  try {
    const res = await inventory_store.$services.inventory_items.update_stock_unit(payload);
    stock_unit_data.value.custom_fields = res.data.stock_unit.custom_fields;
    is_edit.value = false;
    map_custom_fields(custom_fields_config, get_stock_custom_fields.value, stock_unit_data.value.custom_fields);
  }
  catch (error) {
    $toast({ text: (error?.data?.message || error?.message), type: 'error', title: 'Error' });
    logger.log(error);
  }
}

async function getData() {
  table_loading.value = true;
  const response = await inventory_store.$services.inventory_items.get_stock_history({
    query: {
      'stock': props.value.uid,
      'include[]': ['adjustment_line.*', 'adjustment.*'],
      'asset': route.params.asset_id,
    },
  });
  table_items.value = (response?.data?.stock_histories || []).map((item) => {
    const transaction = response.data.adjustments.find(adj => adj.uid === item.adjustment);
    return {
      ...transaction,
      number: transaction.number || '-',
      type: transaction.workflow || '-',
      date: transaction.created_at || '-',
      from: transaction.from_stock || '-',
      to: transaction.to_stock || '-',
      uid: transaction.uid,
    };
  });

  table_loading.value = false;
}
watch(get_stock_custom_fields, () => {
  map_custom_fields(custom_fields_config, get_stock_custom_fields.value, form$.value.data.custom_fields);
});
function edit() {
  is_edit.value = true;
  nextTick(() => {
    form$.value.load({
      custom_fields: custom_fields_config.value.map(f => ({
        uid: f.uid,
        value: f.value,
      })),
    });
  });
}

onMounted(async () => {
  await getData();
  map_custom_fields(custom_fields_config, get_stock_custom_fields.value, stock_unit_data.value.custom_fields);
});
</script>

<template>
  <HawkModalContainer content_class="w-[900px] rounded-lg">
    <Vueform
      ref="form$"
      size="sm"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
      }"
      :sync="true"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div class="flex flex-col">
              <div class="flex flex-col justify-start">
                {{ props.item.name }}
              </div>
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent class="flex flex-col !p-0" :is_scroll="false">
          <div class="grid">
            <div class="flex items-center justify-between pt-6 px-6">
              <div>
                <HawkPageHeaderTabs :tabs="header_tabs" :active_item="state.active_tab" @tabClick="state.active_tab = $event.uid;is_edit = false" />
              </div>
              <HawkButton v-if="(state.active_tab === 'details') && !is_edit" icon type="outlined" @click="edit">
                <IconHawkEditTwo />
              </HawkButton>
            </div>
            <div v-if="state.active_tab === 'details'" class="pt-6">
              <div class="text-sm grid gap-4 font-normal max-h-[60vh] scrollbar px-6" :class="{ 'pb-6': !is_edit }">
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-4 text-gray-500">
                    {{ $t('Serial No') }}:
                  </div>
                  <div class="col-span-8">
                    {{ stock_unit_data?.serial_number }}
                  </div>
                </div>
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-4 text-gray-500">
                    {{ $t('Pallet No') }}:
                  </div>
                  <div class="col-span-8">
                    {{ stock_unit_data?.batch_number || '-' }}
                  </div>
                </div>
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-4 text-gray-500">
                    {{ $t('Location') }}:
                  </div>
                  <div class="col-span-8">
                    {{ inventory_store.get_location_details({
                      uid: stock_unit_data?.location_id,
                      type: stock_unit_data?.location_type,
                    })?.name || '-' }}
                  </div>
                </div>
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-4 text-gray-500">
                    {{ $t('Status') }}:
                  </div>
                  <div class="col-span-8">
                    <InventoryItemStockStatus :status="stock_unit_data?.stock_status" />
                  </div>
                </div>
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-4 text-gray-500">
                    {{ $t('Last Updated') }}:
                  </div>
                  <div class="col-span-8">
                    {{ $date(stock_unit_data?.updated_at, 'DATETIME_MED') }}
                  </div>
                </div>
                <template v-if="!is_edit">
                  <div
                    v-for="custom_field in get_stock_custom_fields"
                    :key="custom_field.uid"
                    class="grid grid-cols-12 gap-4"
                  >
                    <div class="col-span-4 text-gray-500 break-all">
                      {{ `${get_custom_field_name(custom_field)}:` }}
                    </div>
                    <div class="col-span-8">
                      <InventoryCustomField
                        :field="custom_field"
                        :value="get_custom_field_value(custom_field?.uid)"
                      />
                    </div>
                  </div>
                </template>
                <div v-if="is_edit">
                  <InventoryItemStockUnitEdit
                    :key="`SU-InventoryItemStockUnitEdit${custom_fields_config?.length}`"
                    :custom_fields_config="custom_fields_config"
                    :uid="props.item.uid"
                    :form$="form$"
                    is_sticky_footer
                    :on_save="saveCustomFields"
                    @cancel="is_edit = false"
                  />
                </div>
              </div>
            </div>
            <div v-else-if="state.active_tab === 'transactions'" class="grid gap-4 p-6">
              <div v-if="table_loading" class="text-sm text-gray-500 mt-3">
                {{ $t('Loading') }}...
              </div>
              <div v-else-if="!table_items?.length" class="text-sm text-gray-500 mt-3">
                {{ $t('No transactions found') }}
              </div>
              <TableWrapperVue v-else :height="500" container_class="">
                <HawkTable
                  :data="table_items"
                  :columns="columns"
                  cell_height="40px"
                  is_gapless
                  :show_menu_header="false"
                  @rowClicked="e => router.push({ name: 'inventory-transactions', query: { transaction_number: e.number }, params: { ...route.params } })"
                >
                  <template #number="{ data }">
                    <div class="flex items-center gap-2">
                      <span>{{ data.getValue() }}</span>
                      <HawkBadge :custom_color="inventory_store.workflows_map[data.row.original.type]?.color" custom_classes="whitespace-nowrap">
                        {{ inventory_store?.workflows_map?.[data.row.original.type].name }}
                      </HawkBadge>
                    </div>
                  </template>
                  <template #type="{ data }">
                    <HawkBadge>
                      {{ inventory_store.workflows_map[data.getValue()]?.name }}
                    </HawkBadge>
                  </template>
                  <template #date="{ data }">
                    {{ $date(data.getValue(), 'DATE_MED') }}
                  </template>
                  <template #total_quantity="{ data }">
                    <div class="mr-1">
                      {{ data.row.original?.adjustment_line_item_quantity?.[0]?.quantity }}
                    </div>
                    <InventoryUom :uom="data.row.original?.adjustment_line_item_quantity?.[0]?.uom" is_symbol />
                  </template>
                  <template #from_stock="{ data }">
                    {{ inventory_store.get_location_details({
                      uid: data.row.original.from.uid,
                      type: data.row.original.from.type,
                    })?.name || '-' }}
                  </template>
                  <template #to_stock="{ data }">
                    {{ inventory_store.get_location_details({
                      uid: data.row.original.to.uid,
                      type: data.row.original.to.type,
                    })?.name || '-' }}
                  </template>
                  <template #created_by="{ data }">
                    <HawkMembers :members="data.getValue()" type="badge" />
                  </template>
                </HawkTable>
              </TableWrapperVue>
            </div>
          </div>
        </HawkModalContent>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
