<script setup>
import { useModal } from 'vue-final-modal';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import InventoryItemTypeForm from '~/inventory/components/inventory-settings/inventory-item-type-form.vue';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const emit = defineEmits(['edit', 'delete']);
const { $t, $toast } = useCommonImports();

const COLUMNS = [
  {
    accessorKey: 'name',
    header: $t('Name'),
    id: 'name',
    static: true,
    render_as: {
      field_type: 'text',
      options: {
        classes: 'font-medium',
      },
    },
  },
  {
    accessorKey: 'description',
    header: $t('Description'),
    id: 'description',
    static: true,
    size: 400,
    render_as: {
      field_type: 'text',
      options: {
        classes: 'text-gray-600',
      },
    },
  },
  {
    accessorKey: 'context_menu',
    header: '',
    id: 'context_menu',
    size: '5',
    show_on_hover: 'true',
  },
];

const inventory_store = useInventoryStore();

const freeze_table = ref('-1');

const data = computed(() => Object.values(inventory_store.item_types));

const { open: openItemTypeForm, close: closeItemTypeForm, patchOptions } = useModal({
  component: InventoryItemTypeForm,
});
function editHandler(item_type) {
  patchOptions(
    {
      attrs: {
        item_type,
        onClose() {
          closeItemTypeForm();
        },
        on_save: async (data) => {
          try {
            const response = await inventory_store.update_item_type({ payload: data, uid: item_type.uid });
            if (response)
              $toast({
                text: $t('Item type updated successfully'),
                type: 'success',
              });
          }
          catch (error) {
            $toast({ text: (error?.data?.message || error?.message), type: 'error', title: 'Error' });
          }
        },
      },
    },
  );
  openItemTypeForm();
}
function createItemType() {
  patchOptions(
    {
      attrs: {
        item_type: null,
        onClose() {
          closeItemTypeForm();
        },
        on_save: async (data) => {
          try {
            const response = await inventory_store.create_item_type({ payload: [data] });
            if (response)
              $toast({
                text: $t('Item type created successfully'),
                type: 'success',
              });
          }
          catch (error) {
            $toast({ text: (error?.data?.message || error?.message), type: 'error', title: 'Error' });
          }
        },
      },
    },
  );
  openItemTypeForm();
}

const { open: openDeletePopup, close: closeDeletePopup, patchOptions: patchDeletePopup } = useModal({
  component: HawkDeletePopup,
});
function deleteHandler(item_type) {
  patchDeletePopup(
    {
      attrs: {
        header: $t('Delete item type'),
        content: `Are you sure you want to delete item type ${item_type.name}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            const response = await inventory_store.delete_item_type({ uid: item_type.uid });
            if (response)
              $toast({
                text: $t('Item type deleted successfully'),
                type: 'success',
              });
          }
          catch (error) {
            $toast({ text: (error?.data?.message || error?.message), type: 'error', title: 'Error' });
          }
          closeDeletePopup();
        },
      },
    },
  );
  openDeletePopup();
}
</script>

<template>
  <div>
    <div class="flex items-center w-full justify-between">
      <p class="text-lg font-bold">
        {{ $t('Item types') }}
      </p>
      <hawk-button @click="createItemType">
        {{ $t('New item type') }}
      </hawk-button>
    </div>
    <div v-if="!data?.length" class="text-sm text-gray-500 mt-3">
      {{ $t('No item types added') }}
    </div>
    <TableWrapperVue v-else :height="300" container_class="border mt-3">
      <HawkTable
        :data="data"
        :columns="COLUMNS"
        is_gapless
        cell_height="40px"
        :non_sortable_columns="['context_menu']"
        :show_menu_header="false"
        :freeze_table="freeze_table"
        @rowClicked="e => editHandler(e)"
      >
        <template #context_menu="item_type">
          <hawk-menu
            position="fixed"
            additional_trigger_classes="!ring-0 hover:bg-gray-50"
            :items="[
              {
                uid: 'edit',
                label: 'Edit',
                disabled: false,
                on_click: () => editHandler(item_type.data.row.original),
              },
              {
                uid: 'delete',
                label: 'Delete',
                disabled: false,
                on_click: () => deleteHandler(item_type.data.row.original),
              },
            ]"
            @click.stop=""
            @open="freeze_table = item_type?.data?.row?.id"
            @close="freeze_table = '-1'"
          />
        </template>
      </HawkTable>
    </TableWrapperVue>
  </div>
</template>
