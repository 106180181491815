<script setup>
import { reactive } from 'vue';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';

const props = defineProps({
  name: {
    type: String,
  },
  label: {
    type: String,
  },
  placeholder: {
    type: String,
    default: '',
  },
  data: {
    type: Array,
    default: () => [],
  },
  value: {
    type: Array,
    default: () => [],
  },
});

const state = reactive({
  force_update: 0,
});

watch(() => props.data, () => {
  state.force_update += 1;
});
</script>

<template>
  <HawkTreeSelect
    :key="state.force_update"
    :options="{
      name,
      label,
      placeholder: placeholder || $t('Select Layer'),
    }"
    :data="data"
    :multi_select="false"
    :disable_branch_nodes="true"
    :initial_state="value"
    :add_classes="{
      ElementLayout: {
        outerWrapper: '!block',
      },
      ElementLabel: {
        container: '!mb-2.5',
      },
    }"
    select_type="LEAF_PRIORITY"
    @updateForm="$emit('updateForm', $event)"
    @inputValue="$emit('inputValue', $event)"
  />
</template>
