<script setup>
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useTransmittalsStore } from '~/dms/store/transmittals.store.js';

const emit = defineEmits(['close', 'appliedFilters']);
const $t = inject('$t');

const route = useRoute();

const state = reactive({
  filters: {},
  filters_count: 0,
  open_filters_popup: false,
});

const auth_store = useAuthStore();

const form$ = ref(null);
const transmittals_store = useTransmittalsStore();

const status_values = computed(() => [
  {
    label: $t('Waiting for upload'),
    value: 'waiting_for_upload',
  },
  {
    label: $t('Waiting for review'),
    value: 'waiting_for_review',
  },
  {
    label: $t('Completed'),
    value: 'completed',
  },
]);

function progressFormat(value) {
  return `${value}%`;
}

function clearFilters() {
  form$.value.reset();
  state.filters = {};
}

function formatLoadedData(data) {
  const load_data = {};
  const user_id = auth_store?.logged_in_user_details?.user_id;

  Object.entries(data).forEach((entry) => {
    const [key, value] = entry;
    if (key === 'assignees') {
      const assigned = value[0] === user_id ? 'Assigned to me' : 'Others';
      load_data.assignees_group = {
        [key]: value,
        assigned,
      };
    }
    else if (['start_date_start', 'start_date_end', 'due_date_start', 'due_date_end'].includes(key)) {
      if (!load_data.start_date)
        load_data.start_date = [];

      if (!load_data.due_date)
        load_data.due_date = [];

      if (key.startsWith('start'))
        if (key.endsWith('start'))
          load_data.start_date.unshift(new Date(value).toISOString().split('T')[0]);
        else
          load_data.start_date.push(new Date(value).toISOString().split('T')[0]);

      if (key.startsWith('due'))
        if (key.endsWith('start'))
          load_data.due_date.unshift(new Date(value).toISOString().split('T')[0]);
        else
          load_data.due_date.push(new Date(value).toISOString().split('T')[0]);
    }
    else if (key === 'progress_start' || key === 'progress_end') {
      if (!load_data.progress)
        load_data.progress = [];
      load_data.progress[key === 'progress_start' ? 0 : 1] = value * 100;
    }
    else {
      load_data[key] = value;
    }
  });
  // Setting default progress for SliderElement
  if (!load_data.progress)
    load_data.progress = [0, 100];

  return load_data;
}

function onApply() {
  const payload = {};
  const form_data = form$.value.data;
  const user_id = auth_store?.logged_in_user_details?.user_id;
  Object.keys(form_data).forEach((key) => {
    if (key === 'assignees_group') {
      if (form_data[key].assigned === 'Assigned to me')
        payload.assignees = [user_id];
      else if (form_data[key].assigned === 'Others' && form_data[key].assignees?.length)
        payload.assignees = form_data[key].assignees.map(assignee => assignee.uid);
    }
    else if ((key === 'start_date' || key === 'due_date') && form_data[key]?.length > 0) {
      payload[`${key}_start`] = new Date(form_data[key][0]).toISOString();
      payload[`${key}_end`] = new Date(form_data[key][1]).toISOString();
    }
    else if (key === 'progress') {
      if (!(form_data[key][0] === 0 && form_data[key][1] === 100)) {
        payload.progress_start = form_data[key][0] / 100;
        payload.progress_end = form_data[key][1] / 100;
      }
    }
    else if (key !== 'undefined' && form_data[key] && `${form_data[key]}`?.length) {
      payload[key] = form_data[key];
    }
  });
  state.filters = payload;
  transmittals_store.set_filters({ payload });
  emit('appliedFilters', payload);
  emit('close');
}
</script>

<template>
  <hawk-modal-container content_class="rounded-lg">
    <Vueform
      ref="form$"
      size="sm"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :format-load="formatLoadedData"
      @submit="onApply"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('filters') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <SelectElement
            :items="transmittals_store.priority_values"
            :can-clear="false"
            :tags_removable="true"
            name="priority"
            value-prop="name"
            label-prop="label"
            :label="$t('Priority')"
            :placeholder="$t('Select Priority')"
            class="mb-4"
            :native="false"
          />
          <hawk-category-input
            class="mb-4"
            :options="{
              'name': 'category',
              'label': $t('Category'),
              'placeholder': $t('Select Category'),
              'tags_removable': true,
              'can-clear': false,
            }"
            :asset_id="route.params.asset_id"
          />
          <SelectElement
            :items="status_values"
            :search="false"
            :native="false"
            :can-clear="false"
            :can-deselect="false"
            name="status"
            :label="$t('Status')"
            :placeholder="$t('Select Status')"
            class="mb-4"
          />
          <DatesElement
            display-format="Do MMM YYYY"
            mode="range"
            name="start_date"
            :label="$t('Start Date')"
            :placeholder="$t('Select Start Date')"
            class="mb-4"
          />
          <DatesElement
            display-format="Do MMM YYYY"
            mode="range"
            name="due_date"
            :label="$t('Due Date')"
            :placeholder="$t('Select Due Date')"
            class="mb-4"
          />
          <ObjectElement
            class="mb-4"
            name="assignees_group"
            :label="$t('Assignees')"
          >
            <RadiogroupElement
              name="assigned"
              :items="[
                { value: 'Assigned to me', label: $t('Assigned to me') },
                { value: 'Others', label: $t('Others') },
              ]"
            />
            <hawk-assignee-input
              :options="{
                name: 'assignees',
                has_teams: true,
                conditions: [
                  ['assignees_group.assigned', 'Others'],
                ],
                placeholder: $t('Select Assignees'),
                columns: {
                  default: { container: 12, label: 12, wrapper: 12 },
                  sm: { container: 12, label: 12, wrapper: 12 },
                  md: { container: 12, label: 12, wrapper: 12 },
                },
              }"
              :multi="true"
              :format_load="true"
            />
          </ObjectElement>
          <SliderElement
            name="progress"
            :default="[0, 100]"
            :label="$t('Progress')"
            class="mb-4"
            tooltip-position="bottom"
            :format="progressFormat"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-between">
              <div>
                <HawkButton type="text" @click="clearFilters">
                  {{ $t('Clear filters') }}
                </HawkButton>
              </div>
              <div class="flex justify-end">
                <HawkButton type="outlined" text="Cancel" class="mr-3" @click="emit('close')">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement
                  submits
                  size="sm"
                  name="submit"
                  :button-label="$t('Apply')"
                />
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
