<script setup>
import { useClipboard } from '@vueuse/core';
import { omit } from 'lodash-es';
import EmailInviteInput from '~/acct-settings/components/acct-settings-invitation-popup/acct-settings-invitation-popup-email-input.vue';
import UserPermission from '~/acct-settings/components/acct-settings-invitation-popup/acct-settings-invitation-popup-user-type-form.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const emit = defineEmits(['close', 'update']);

const { $t, $date, $date_relative, $services, $toast, auth_store, common_store, route, router, $track_event } = useCommonImports();

const form$ = ref(null);
const form = ref({
  emails: '',
  user_type: null,
  paid: true,
});
const state = reactive({
  is_loading: false,
  sharable_link: '',
  current_step: 1,
});

const header_details = computed(() => {
  const details = { title: '', description: '' };
  switch (state.current_step) {
    case 1:
      details.title = $t('Invite Members');
      details.description = $t('Work with your team by inviting them to your workspace.');
      break;
    case 2:
      details.title = $t('Permissions for users');
      details.description = $t('Set permissions for the users Or assign a role accordingly. You can also later access and set the permissions.');
      break;
  }
  return details;
});
async function getLink(method = 'get') {
  try {
    state.is_loading = true;
    const response = await $services.organizations[method]({
      id: auth_store.current_organization?.uid,
      attribute: 'generate-invite-link',
      query: { asset_id: route?.params?.asset_id },
    });
    state.sharable_link = response?.data?.url;
    state.is_loading = false;
  }
  catch (error) {
    state.is_loading = false;
    $toast({
      title: 'Something went wrong',
      text: 'Unable to get link. Please try after some time',
      type: 'error',
    });
  }
}
async function getSharableLink() {
  if (state.sharable_link?.length) {
    const { copy } = useClipboard({ source: state.sharable_link });
    copy();
    $toast({
      title: 'Success',
      text: 'Url copied!',
      type: 'success',
    });
  }
  else {
    $toast({
      title: 'Something went wrong',
      text: 'Unable to get link. Please try after some time',
      type: 'error',
    });
  }
}

async function save() {
  if (state.current_step === 1) {
    state.current_step++;
    return;
  }

  try {
    const invitations = form.value.emails.map(email => ({
      email,
      user_type: form.value.user_type,
      paid: true,
    }));

    // Removing unused keys
    const omit_keys = ['emails', 'user_type'];
    switch (form.value.user_type) {
      case 'global_admin':
        omit_keys.push('assets', 'teams', 'roles');
        break;
      case 'asset_admin':
        omit_keys.push('teams', 'roles');
        break;
    }

    const body = {
      invitations,
      ...omit(form.value, omit_keys),
    };
    if (route?.params?.asset_id)
      body.asset = route?.params?.asset_id;
    const response = await $services.organizations.invite_users({
      organization_id: auth_store.current_organization?.uid,
      body,
    });
    if (response?.data?.length || response.status === 200) {
      if (route?.params?.asset_id)
        $toast({
          title: $t('Success'),
          text: $t('An email invitation has been sent to the users to join the asset'),
          type: 'success',
        });
      else
        $toast({
          title: $t('Success'),
          text: $t('An email invitation has been sent to the users to join the organization'),
          type: 'success',
        });
      $track_event('invite_user', {
        count: response.data.length,
        members: response.data.filter(val => val.user_type === 'member').length,
        paid: response.data.filter(val => val.paid === true).length,
        new_to_org: false,
        where: 'Default',
      });
      emit('close');
      emit('update');
    }
    else {
      $toast({
        title: 'Something went wrong',
        text: $t('Unable to invite users. Please double-check your input and try again'),
        type: 'error',
      });
    }
  }
  catch (error) {
    $toast({
      title: 'Something went wrong',
      text: $t('Unable to invite users. Please double-check your input and try again'),
      type: 'error',
    });
  }
}

onMounted(async () => await getLink());
</script>

<template>
  <hawk-modal-container content_class="rounded-lg !w-[700px]">
    <Vueform
      ref="form$"
      v-model="form"
      size="sm"
      :display-errors="false"
      :format-load="(data) => data"
      :endpoint="save"
    >
      <div class="col-span-12">
        <hawk-modal-header class="items-center" @close="$emit('close')">
          <template #left>
            <div>
              <div class="mb-1">
                {{ header_details.title }}
              </div>
              <div class="text-xs text-gray-600 font-normal">
                <p>
                  {{ header_details.description }}
                </p>
              </div>
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <div class="text-sm font-semibold text-gray-500 mb-6">
            {{ $t('STEP') }} {{ state.current_step }}/2
          </div>
          <div v-show="state.current_step === 1">
            <div>
              <div class="mb-4">
                <div class="text-sm font-semibold text-gray-900">
                  {{ $t('Invite users via email') }}
                </div>
                <div class="text-xs text-gray-600">
                  {{ $t('Invite users via email and set permissions accordingly') }}
                </div>
              </div>
              <EmailInviteInput />
            </div>
            <div class="flex justify-center items-center h-full my-6">
              <div class="border-t border-gray-200 w-full" />
              <div class="bg-white mx-4 text-sm font-medium text-gray-500">
                {{ $t('OR') }}
              </div>
              <div class="border-t border-gray-200 w-full" />
            </div>
            <div>
              <div class="mb-4">
                <div class="text-sm font-semibold text-gray-900">
                  {{ $t('Invite users via link') }}
                </div>
                <div class="text-xs text-gray-600">
                  {{ $t('Invite users via direct link') }}
                </div>
              </div>
              <div class="flex item-center gap-4">
                <TextElement
                  :key="state.sharable_link"
                  name="sharable_link"
                  class="w-full"
                  readonly
                  :default="state.sharable_link"
                >
                  <template #addon-after>
                    <HawkLoader v-if="state.is_loading" container_class="m-0" :height="5" :width="5" />
                    <IconHawkRefreshCwTwo v-else v-tippy="{ content: $t('Regenerate link'), placement: 'bottom' }" class="w-4 h-4 cursor-pointer" @click="getLink('post')" />
                  </template>
                </TextElement>
                <HawkButton :disabled="state.is_loading" type="outlined" class="flex-shrink-0" @click="getSharableLink">
                  <IconHawkCopyOne />
                  {{ $t('Copy Link') }}
                </HawkButton>
              </div>
            </div>
          </div>
          <UserPermission v-show="state.current_step === 2" :asset_id="route.params.asset_id" />
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template v-if="state.current_step > 1" #left>
            <HawkButton type="text" @click="state.current_step -= 1">
              {{ $t('Previous') }}
            </HawkButton>
          </template>
          <template #right>
            <div class="flex justify-end gap-4">
              <HawkButton type="outlined" @click="$emit('close')">
                {{ $t('Cancel') }}
              </HawkButton>
              <ButtonElement name="submit" button-class="w-full bg-blue-600" :submits="true">
                {{ state.current_step === 1 ? $t('Continue') : $t('Invite') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
