<script setup>
import draggable from 'vuedraggable';
import { useModal } from 'vue-final-modal';
import { onMounted } from 'vue';
import HawkModalContainer from '~/common/components/hawk-modal/hawk-modal-container.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';
import CustomFilter from '~/forms/pages/form-template-detail/overview/form-template-detail-responses-overview-molecules/custom-filter-popup.vue';

const props = defineProps({
  visible_columns: {
    type: Array,
    default: () => [],
  },
  hidden_columns: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['close', 'save']);
const $track_event = inject('$track_event');

const form_template_detail_store = useFormTemplateDetailStore();
const conditions = ref([]);

const filter_popup = useModal({
  component: CustomFilter,
  attrs: {
    onClose() {
      filter_popup.close();
    },
  },
});

function openFilterModal(filter = null, index = -1) {
  filter_popup.patchOptions({
    attrs: {
      filter: filter ? filter.value : null,
      columns: props.visible_columns,
      onClose() {
        filter_popup.close();
      },
      onSave(condition) {
        if (index === -1)
          conditions.value.splice(0, 0, condition);

        else
          conditions.value.splice(index, 1, condition);
        filter_popup.close();
        $track_event('grid_view_rule_added');
      },
    },
  });

  filter_popup.open();
}

async function handleSaveConditions() {
  await form_template_detail_store.update_form_details({
    body: {
      filters: {
        ...form_template_detail_store.form_template_detail.filters,
        conditional_formatting: conditions.value,
      },
    },
    suppressToast: true,
  });
  emit('save', conditions.value);
  emit('close');
}

function init() {
  conditions.value = form_template_detail_store.form_template_detail.filters.conditional_formatting || [];
}

onMounted(() => {
  init();
});
</script>

<template>
  <HawkModalContainer>
    <Vueform
      :endpoint="handleSaveConditions"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('Conditional Formatting') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content class="max-h-[calc(100vh-40vh)] scrollbar">
          <draggable
            :list="conditions"
            class="min-w-full"
            tag="div"
            draggable=".is_draggable"
            handle=".move"
          >
            <template #item="{ element: filter, index: i }">
              <div
                class="group flex items-center justify-between mb-4 px-2 text-sm is_draggable cursor-pointer w-min-full rounded-lg hover:bg-gray-100"
              >
                <div class="flex items-center text-sm cursor-pointer" @click="openFilterModal(filter, i)">
                  <icon-hawk-drag-icon class="cursor-move move flex move text-lg text-gray-800 h-4 w-4 mr-1.5 invisible group-hover:visible" />
                  <div class="text-[14px] font-medium pointer-events-auto">
                    <HawkText :content="filter.name" length="50" />
                  </div>
                </div>
                <HawkMenu
                  :items="[
                    {
                      label: 'Edit',
                      on_click: () => {
                        openFilterModal(filter, i);
                      },
                    },
                    {
                      label: 'Duplicate',
                      on_click: () => {
                        conditions.splice(0, 0, {
                          ...filter,
                          name: `${filter.name} copy`,
                        })
                      },
                    },
                    {
                      label: 'Delete',
                      on_click: () => {
                        conditions.splice(i, 1);
                      },
                    },
                  ]" position="bottom-left" additional_trigger_classes="!ring-0 !border-0"
                />
              </div>
            </template>
          </draggable>
          <div class="flex items-center cursor-pointer p-2 text-[14px] font-semibold text-primary-700 mb-2" @click="openFilterModal()">
            <icon-hawk-plus class="mr-1" />
            {{ $t('Add Condition') }}
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <ButtonElement
                :button-label="$t('Cancel')"
                class="mr-3"
                size="sm"
                :secondary="true"
                @click="emit('close')"
              />
              <ButtonElement button-class="w-full bg-blue-600" size="sm" name="submit" :submits="true">
                {{ $t("Save") }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </vueform>
  </HawkModalContainer>
</template>
