<script setup>
import { Options } from './custom-fields.composable.js';
import SelectBlockColor from '~/forms/atoms/select-block-color.vue';

const props = defineProps({
  uid: {
    type: String,
    default: '',
  },
  types: {
    type: Array,
  },
  // eslint-disable-next-line vue/prop-name-casing
  modelValue: {
    type: Object,
  },
  validators: {
    type: Object,
  },
  options: {
    type: Object,
    default: () => ({
      has_description: true,
    }),
  },
});

const emit = defineEmits(['update:modelValue']);

const form$ = ref({});

const form = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const getSelectedType = form => props.types.find(type => type.value === form.type);
const extra_options = computed(() => getSelectedType(form.value)?.extra_options || []);

const has_options = computed(() => {
  return form.value.type === 'checkboxes' || form.value.type === 'dropdown' || form.value.type === 'single_select' || form.value.type === 'multi_select' || form.value.type === 'radio' || form.value.type === 'labels' || form.value.type === 'checkbox';
});

const has_options_color = computed(() => {
  return form.value.type === 'single_select' || form.value.type === 'multi_select';
});

function generate_uid() {
  return crypto.randomUUID();
};
defineExpose({
  form$,
});
</script>

<template>
  <Vueform
    ref="form$"
    v-model="form"
    :sync="true"
    :attachment_config="{ meta: { service: 'vault', id: 'upload' } }"
    :display-errors="false"
    size="sm"
  >
    <GroupElement
      name="custom_field_group"
      :add-classes="{
        GroupElement: {
          wrapper: '!gap-4 ',
        },
      }"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
    >
      <TextElement
        name="label"
        :label="$t('Name')"
        :placeholder="$t('Enter field name')"
        :rules="['required', ...(validators?.label || [])]"
      />
      <TextareaElement
        v-if="options?.has_description"
        name="description"
        :label="$t('Description')"
        placeholder="Enter description"
      />
      <SelectElement
        name="type"
        :items="types"
        :label="$t('Type')"
        placeholder="Select type"
        :native="false"
        :disabled="form.exist"
        :rules="[
          'required',
        ]"
        :extend-options="{
          appendToBody: true,
        }"
      />

      <GroupElement
        v-if="has_options"
        name="options_group"
      >
        <ListElement
          name="items"
          label="Options"
          :add-text="`+ ${$t('Add')} ${$t('option')}`"
          :initial="1"
          :min="1"
          :rules="[
            'required',
            'distinct',
          ]"
          :default="[{ value: undefined, label: 'default' }]"
          :sort="true"
          class="mb-4"
          :columns="{
            default: { container: 12, label: 0, wrapper: 12 },
            sm: { container: 12, label: 0, wrapper: 12 },
            md: { container: 12, label: 0, wrapper: 12 },
          }"
          :presets="['repeatable_list', 'repeatable_drag_icon']"
          :add-classes="{
            ListElement: {
              add: [
                'text-primary-700', 'text-sm',
              ],
              list: ['ml-4', 'mt-4'],
              removeIcon: ['!h-4'],
            },
          }"
        >
          <template #default="{ index }">
            <ObjectElement
              :name="index"
              :columns="{
                default: { container: 11, label: 12, wrapper: 12 },
                sm: { container: 11, label: 12, wrapper: 12 },
                md: { container: 11, label: 12, wrapper: 12 },
              }"
            >
              <div class="col-span-12 flex">
                <SelectBlockColor v-if="has_options_color" :color="modelValue?.items?.[index]?.color" wrapper_class="w-56 pr-3" :index="index" :additional_options="{ label: null }" />
                <TextElement
                  placeholder="Enter option value"
                  name="label"
                  :rules="[
                    'required',
                  ]"
                  :messages="{ required: 'Field is required.' }"
                  :columns="{
                    default: { container: 11, label: 12, wrapper: 12 },
                    sm: { container: 11, label: 12, wrapper: 12 },
                    md: { container: 11, label: 12, wrapper: 12 },
                  }"
                  :add-classes="{
                    TextElement: {
                      inputContainer: ['pr-8'],
                    },
                  }"
                />
              </div>
              <HiddenElement
                name="value"
                :default="generate_uid()"
              />
            </ObjectElement>
          </template>
        </ListElement>
      </GroupElement>
      <ObjectElement
        name="rules"
      >
        <TextElement v-if="extra_options.includes(Options.MIN_CHARS)" label="Minimum characters" :name="Options.MIN_CHARS" />
      </ObjectElement>
      <ToggleElement
        label="Required"
        name="required"
      />
      <HiddenElement
        name="uid"
      />
      <HiddenElement
        name="exist"
        :default="false"
        :submit="false"
      />
    </GroupElement>
  </Vueform>
</template>
