<script setup>
import { computed } from 'vue';
import { keyBy } from 'lodash-es';
import { checkUser } from '~/common/utils/common.utils';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import AcctSettingsUserContextMenu from '~/acct-settings/components/acct-settings-user-context-menu.vue';
import { useJsonRuleEngine } from '~/common/composables/json-engine.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  users: {
    type: Array,
    default: () => ([]),
  },
});
const emit = defineEmits(['updateCount']);

const router = useRouter();
const { display_filters, display_filters$, filtered_data, onApplyDisplayFilters } = useDisplayFilters();
const { auth_store, common_store, $t, $services, route } = useCommonImports();

const freeze_table = ref('-1');

const state = reactive({
  search: '',
  is_loading: false,
});

const table_columns = [
  { id: 'uid', header: $t('Name'), accessorKey: 'uid' },
  { header: $t('Status'), accessorKey: 'active', id: 'active' },
  { header: $t('Teams'), accessorKey: '', id: 'teams' },
  { header: $t('Roles'), accessorKey: '', id: 'roles' },
  { header: $t('Organization'), accessorKey: 'organization', id: 'organization' },
  { header: '', id: 'context_menu', accessorKey: 'context_menu', size: 5, show_on_hover: true },
];

const can_modify_users = computed(() => auth_store.check_permission('modify_users', route.params.asset_id));
const users = computed(() => {
  return common_store
    ?.members_scope_users(route?.params?.asset_id)
    ?.filter(user => user?.status !== 'invited' && checkUser(user)?.toLowerCase());
});

const team_members = computed(() => common_store?.active_asset?.team_members.map(user => user.uid) || []);

const filtered_users = computed(() => {
  return filtered_data.value;
});

function getUserType(user) {
  const { uid, is_owner, is_manager, admin_assets } = user;
  if (team_members.value.includes(uid))
    return { type: 'team_member', title: $t('Team member'), color: 'cyan' };
  if (is_owner)
    return { type: 'owner', title: $t('Owner'), color: 'orange' };
  if (is_manager)
    return { type: 'global_admin', title: $t('Global admin'), color: 'yellow' };
  if (admin_assets?.length)
    return { type: 'asset_admin', title: $t('Asset admin'), color: 'blue' };
  return { type: 'member', title: $t('Member'), color: 'primary' };
}

function goToUserDetail(data) {
  if (team_members.value.includes(data.uid))
    return;
  router.push({
    name: 'account-settings-user-details',
    params: {
      id: data.uid,
    },
  });
}
function getScopeRoles(roles) {
  return roles?.reduce((acc, uid) => {
    if ((route.params.asset_id && common_store.roles_map[uid]?.asset === route.params.asset_id)
      || (!route.params.asset_id && !common_store.roles_map[uid]?.asset))
      acc.push(uid);
    return acc;
  }, []);
}
function getScopeTeams(teams) {
  return teams?.reduce((acc, uid) => {
    if ((route.params.asset_id && common_store.teams_map[uid]?.asset === route.params.asset_id)
      || (!route.params.asset_id && !common_store.teams_map[uid]?.asset))
      acc.push(uid);
    return acc;
  }, []);
}

function useDisplayFilters() {
  const { loadJsonEngine, runJsonEngine } = useJsonRuleEngine();
  loadJsonEngine();
  const display_filters = computed(() => {
    return [
      {
        uid: 'active',
        name: $t('Status'),
        is_static: true,
        data_type: 'single_select',
        operators: ['isAnyOf'],
        options: [
          { name: $t('Deactivated'), uid: false },
          { name: $t('Active'), uid: true },
        ],
        type: 'select',
        search: false,
      },
      {
        uid: 'teams',
        name: $t('Teams'),
        is_static: true,
        data_type: 'single_select',
        operators: ['isAnyOf'],
        options: [
          ...common_store.scope_teams(route.params?.asset_id || null),
        ],
        type: 'select',
      },
      {
        uid: 'roles',
        name: $t('Roles'),
        is_static: true,
        data_type: 'single_select',
        operators: ['isAnyOf'],
        options: [
          ...common_store.scope_roles(route.params?.asset_id || null),
        ],
        type: 'select',
      },
    ];
  });
  const display_filters$ = ref();
  function getJSONRules() {
    return {
      all: [
        {
          any: state.search
            ? [
                { fact: 'first_name', operator: 'stringContains', value: state.search },
                { fact: 'last_name', operator: 'stringContains', value: state.search },
                { fact: 'email', operator: 'stringContains', value: state.search },
                { fact: 'username', operator: 'stringContains', value: state.search },
              ]
            : [],
        },
        {
          all: (display_filters$.value?.filters || [])
            .map(item => ({
              fact: item.field,
              operator: item.operator,
              value: item.value,
            })),
        },
      ],
    };
  }

  const filter_loading = ref();
  const filtered_data = ref([]);
  const facts_hash = computed(() => {
    return keyBy(users.value, 'uid');
  });

  async function onApplyDisplayFilters() {
    filter_loading.value = true;
    try {
      filtered_data.value = await runJsonEngine(getJSONRules(), facts_hash.value, users.value);
    }
    catch (err) {
      logger.error(err);
    }
    filter_loading.value = false;
  }
  return {
    display_filters,
    display_filters$,
    onApplyDisplayFilters,
    filtered_data,
  };
}

watch(() => route?.params?.asset_id, () => {
  onApplyDisplayFilters();
  emit('updateCount', { users: users.value?.length });
}, { immediate: true });
</script>

<template>
  <div>
    <HawkPageSecondaryHeader class="my-4">
      <template #left>
        <div class="flex gap-3">
          <HawkDisplayFilters
            ref="display_filters$"
            :display_filters="display_filters"
            @apply="onApplyDisplayFilters"
          />
        </div>
      </template>
      <template #right>
        <HawkSearchInput
          v-model="state.search"
          :placeholder="$t('Search by name or email')"
          @update:modelValue="onApplyDisplayFilters"
        />
      </template>
    </HawkPageSecondaryHeader>
    <div v-if="!auth_store.check_permission('view_users', $route.params.asset_id)">
      <HawkIllustrations type="no-permission" for="users" />
    </div>
    <div v-else-if="!filtered_users?.length">
      <HawkIllustrations v-if="state.search" type="no-results" for="users" />
      <HawkIllustrations v-else type="no-data" for="users" />
    </div>
    <div v-else-if="filtered_users.length">
      <TableWrapperVue container_class="!mt-0 border-0">
        <HawkTable
          :key="filtered_users?.length"
          :pagination_config="{ totalRows: filtered_users?.length, pageSize: 25 }"
          :data="filtered_users"
          :columns="table_columns"
          :show_menu_header="false"
          is_gapless
          :freeze_table="freeze_table"
          :additional_row_classes="[!can_modify_users ? '!cursor-not-allowed' : null]"
          @rowClicked="goToUserDetail"
        >
          <template #uid="{ data }">
            <div class="flex items-end">
              <HawkMembers
                :members="data?.getValue()"
                size="sm"
                type="label"
                has_email
                :name_truncate_length="100"
                :email_truncate_length="100"
              />
              <HawkBadge
                v-if="getUserType(data.row.original)?.type !== 'member'"
                v-tippy="getUserType(data.row.original)?.type === 'team_member'
                  ? { content: 'The user is granted access to the asset through one/more organization level teams', placement: 'right' }
                  : {}"
                :color="getUserType(data.row.original)?.color"
              >
                {{ getUserType(data.row.original)?.title }}
              </HawkBadge>
            </div>
          </template>
          <template #roles="{ data: { row: { original: { roles } } } }">
            <HawkBadgeGroup :items="getScopeRoles(roles)" color="blue">
              <template #badge="{ item }">
                <HawkRoleName :uid="item" class="text-xs" />
              </template>
              <template #popover_content="{ item }">
                <div class="p-1.5">
                  <HawkBadge color="blue">
                    <HawkRoleName :uid="item" class="text-xs" />
                  </HawkBadge>
                </div>
              </template>
              <template #no_data>
                <div>-</div>
              </template>
            </HawkBadgeGroup>
          </template>
          <template #teams="{ data: { row: { original: { teams } } } }">
            <HawkMembers
              :members="getScopeTeams(teams)"
              type="badge"
              :has_avatar="false"
            />
          </template>
          <template #organization="organization">
            <span class="text-sm text-gray-600">
              {{ common_store.get_organization(organization.data.getValue())?.name }}
            </span>
          </template>
          <template #active="active">
            <HawkBadge :color="active.data?.getValue() ? 'green' : 'gray'">
              {{ active.data.getValue() ? "Active" : "Deactivated" }}
            </HawkBadge>
          </template>
          <template #context_menu="{ data }">
            <AcctSettingsUserContextMenu
              v-if="can_modify_users && !team_members.includes(data.row.original?.uid)"
              :user="data.row.original"
              @open="freeze_table = data?.row?.id"
              @close="freeze_table = '-1'"
              @update="onApplyDisplayFilters"
              @click.stop
            />
          </template>
        </HawkTable>
      </TableWrapperVue>
    </div>
  </div>
</template>
