<script setup>
import dayjs from 'dayjs';
import { toRaw } from 'vue';
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import HawkDatePickerModal from '~/common/components/organisms/hawk-datepicker-modal.vue';

const emit = defineEmits(['close']);

const $t = inject('$t');

const project_management_store = useProjectManagementStore();
const { active_view, views, active_schedule, is_fullscreen } = storeToRefs(project_management_store);
const { set_view_dirtiness, modify_filter, set_active_task_uid } = project_management_store;

const status_options = [
  ['Not started', 'not-started'],
  ['Started', 'started'],
  ['Finished', 'finished'],
].map((item) => {
  return {
    label: $t(item[0]),
    name: item[0],
    value: item[1],
  };
});

const timerange_options = [
  ['This week', 'week', dayjs().format('YYYY-MM-DD'), dayjs().add(7, 'day').format('YYYY-MM-DD')],
  ['This month', 'month', dayjs().format('YYYY-MM-DD'), dayjs().add(30, 'day').format('YYYY-MM-DD')],
  ['This year', 'year', dayjs().format('YYYY-MM-DD'), dayjs().add(365, 'day').format('YYYY-MM-DD')],
  ['Custom', 'custom', null, null],
].map(item => ({
  name: item[0],
  label: $t(item[0]),
  value: item[1],
  range: [item[2], item[3]],
}));

const state = reactive({
  form_values: {},
  date_range_cache: [null, null],
  selected_date_range: [null, null],
  prevent_custom_date_popup: false,
});

const form$ = ref(null);

const date_range_modal = useModal({
  component: HawkDatePickerModal,
  attrs: {
    options: { teleportTo: is_fullscreen.value ? '#pm-fullscreen-container' : 'body' },
    onClose() {
      state.prevent_custom_date_popup = true;
      form$.value.el$('timerange').update(findInTimeRange(state.date_range_cache)?.value ?? null);
      state.selected_date_range = state.date_range_cache;
      setTimeout(() => {
        state.prevent_custom_date_popup = false;
      }, 300);
      date_range_modal.close();
    },
    onOkay(range) {
      state.selected_date_range = range;
      date_range_modal.close();
    },
  },
});

const default_filters = computed(() => {
  const state = gantt.getState();
  return {
    ...views.value[0].filters,
    date_range: [state.min_date, state.max_date],
  };
});

const activity_codes = computed(() => {
  return active_schedule.value.activity_codes.filter(code => code.values.length);
});

const date_range_text = computed(() => {
  const range = state.selected_date_range;
  const found = findInTimeRange(range);
  if (!found)
    return '';

  let text = found.label;
  if (found.value === 'custom')
    text += `: ${dayjs(range[0]).format('YYYY-MM-DD')} - ${dayjs(range[1]).format('YYYY-MM-DD')}`;
  return text;
});

onMounted(setInitialFilters);

function setTimerangeFilter(type) {
  if (type !== 'custom') {
    const item = timerange_options.find(i => i.value === type);
    state.selected_date_range = [...item?.range ?? [null, null]];
  }
  else if (!state.prevent_custom_date_popup) {
    state.selected_date_range = [];
    date_range_modal.open();
  }
}

function areDateRangesEqual(range_a, range_b) {
  return (dayjs(range_a[0]).format('YYYY-MM-DD') === dayjs(range_b[0]).format('YYYY-MM-DD'))
    && (dayjs(range_a[1]).format('YYYY-MM-DD') === dayjs(range_b[1]).format('YYYY-MM-DD'));
}

function findInTimeRange(range) {
  if (!range || !dayjs(range[0]).isValid() || !dayjs(range[1]).isValid() || areDateRangesEqual(range, default_filters.value.date_range))
    return null;
  const found = timerange_options.find(item => areDateRangesEqual(item.range, range));
  return found || timerange_options[3];
}

function mapFilterOptions(options) {
  return options.map(o => ({
    value: o.name,
    label: o.description,
    name: o.description,
  }));
}

function onCustomClicked() {
  state.date_range_cache = [...(toRaw(state.selected_date_range) ?? [])];
  date_range_modal.open();
}

function saveFilters() {
  set_view_dirtiness(true);
  const filters = { ...state.form_values, date_range: state.selected_date_range ?? [null, null] };
  delete filters.timerange;
  modify_filter(filters);
  set_active_task_uid(null);
  emit('close');
}

function setInitialFilters() {
  const filters = { ...active_view.value.data.filters };
  state.selected_date_range = filters.date_range || default_filters.value.date_range;
  delete filters.date_range;
  state.prevent_custom_date_popup = true;
  form$.value.load({
    ...filters,
    timerange: findInTimeRange(state.selected_date_range)?.value ?? null,
  }, true);
  setTimeout(() => {
    state.prevent_custom_date_popup = false;
  }, 300);
}
</script>

<template>
  <HawkModalContainer :options="{ teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body' }">
    <Vueform
      ref="form$"
      v-model="state.form_values"
      size="sm"
      :columns="{
        default: {
          container: 12,
          label: 4,
          wrapper: 12,
        },
        sm: {
          label: 4,
        },
        md: {
          label: 4,
        },
        lg: {
          label: 4,
        },
      }"
    >
      <div class="col-span-12">
        <HawkModalHeader class="mb-6" @close="emit('close')">
          <template #title>
            <div>
              {{ $t('Filter activities') }}
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent class="overflow-auto max-h-[60vh] !py-0">
          <div class="flex w-full col-span-full mb-6">
            <div class="w-40 min-w-[160px] text-gray-700 text-sm mr-5">
              {{ $t('Activity') }}
            </div>
            <div>
              <CheckboxElement
                name="critical"
                :text="$t('Critical')"
                class="mb-3"
                :default="state.form_values.critical"
              />
              <CheckboxElement
                name="milestone"
                :text="$t('Milestone')"
                class="mb-3"
                :default="state.form_values.milestone"
              />
            </div>
          </div>
          <SliderElement
            name="progress"
            :label="$t('Progress')"
            :default="state.form_values.progress"
            class="mb-6"
          />
          <TagsElement
            :items="status_options"
            :default="state.form_values.statuses"
            :label="$t('Status')"
            :can-clear="false"
            name="statuses"
            :tags_removable="true"
            :extend-options="{
              appendToBody: !is_fullscreen,
            }"
            class="mb-6"
          />
          <SelectElement
            :items="timerange_options"
            :search="false"
            :native="false"
            :can-clear="true"
            :can-deselect="false"
            :remove-class="{
              select: {
                option: '!px-2 !min-h-[40px]',
              },
            }"
            :add-class="{
              select: {
                dropdown: '!overflow-visible',
                option: '!p-0',
              },
            }"
            autocomplete="off"
            class="mb-6"
            :label="$t('Date range')"
            name="timerange"
            :extend-options="{
              appendToBody: !is_fullscreen,
            }"
            @change="setTimerangeFilter($event)"
          >
            <template #single-label>
              <div class="w-full p-2">
                {{ date_range_text }}
              </div>
            </template>
            <template #option="{ option }">
              <div v-if="option.value !== 'custom'" class="p-2">
                {{ option.label }}
              </div>
              <div v-else class="p-2 w-full" @click="onCustomClicked">
                {{ $t('Custom') }}
              </div>
            </template>
          </SelectElement>
          <template
            v-for="activity_code of activity_codes"
            :key="activity_code.uid"
          >
            <TagsElement
              :items="mapFilterOptions(activity_code.values)"
              :default="state.form_values[`__activity_code_${activity_code.name}`]"
              :label="activity_code.name"
              :can-clear="false"
              :name="`__activity_code_${activity_code.name}`"
              :tags_removable="true"
              :extend-options="{
                appendToBody: !is_fullscreen,
              }"
              class="w-full mb-6"
            />
          </template>
        </HawkModalContent>
        <HawkModalFooter class="mt-6">
          <template #right>
            <div class="flex justify-end w-full col-span-full">
              <ButtonElement
                name="cancel"
                class="mr-4"
                :secondary="true"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </ButtonElement>
              <ButtonElement
                name="save"
                @click="saveFilters"
              >
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
