<script setup>
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { $t, common_store, route, router, auth_store } = useCommonImports();

const inventory_store = useInventoryStore();

const form$ = ref(null);
const form_data = ref(null);
const is_loading = ref(false);

const members = ref([]);

const transaction_type = computed(() => inventory_store.workflows_map[route.params.transaction_type_uid]);

const from_to = computed(() => {
  const from = transaction_type.value.from_status.map(uid => inventory_store.statuses_map[uid]?.name);
  const to = inventory_store.statuses_map[transaction_type.value.to_status]?.name || 'NA';

  if (from.length === 0)
    return `NA -> ${to}`;
  else if (from.length === 1)
    return `${from[0]} -> ${to}`;
  return `(${from.join(', ')})-> ${to}`;
});

const goBack = () => router.back();

async function handleSave() {
  try {
    is_loading.value = true;
    const transaction_type_data = form_data.value;

    const payload = {
      name: transaction_type_data.name,
      description: transaction_type_data.description,
      color: transaction_type_data.color,
      quantities_label: transaction_type_data.quantities_label,
      plural_label: transaction_type_data.plural_label,
      members: members.value,
      notify_members: transaction_type_data.notify_members,
    };

    await inventory_store.update_workflow({
      uid: transaction_type.value.uid,
      item_workflow: payload,
    });

    is_loading.value = false;
    goBack();
  }
  catch (error) {
    is_loading.value = false;
  }
}

function updateMembers(e) {
  members.value = ([...e.users, ...e.teams]).map((member) => {
    return {
      ...member,
      asset: route.params.asset_id,
    };
  });
}

function formatString(str) {
  const words = str.split('_');
  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  return words.join(' ');
}

function getAssetMembers(members) {
  return members?.filter(member => member?.asset && member?.asset === route?.params?.asset_id) || [];
}

onMounted(async () => {
  if (transaction_type.value?.uid) {
    form_data.value = transaction_type.value;
    members.value = getAssetMembers(transaction_type.value.members);
  }
});
</script>

<template>
  <div class="block w-[calc(100vw_-_80px)]">
    <div class="p-4">
      <div class="flex items-center gap-3 mb-4">
        <HawkButton type="text" icon @click="goBack">
          <IconHawkChevronLeft />
        </HawkButton>
        <div class="text-lg font-semibold text-gray-900">
          {{ transaction_type.name }}
        </div>
      </div>
      <div class="w-full md:w-1/2">
        <Vueform
          ref="form$"
          v-model="form_data"
          :float-placeholders="false"
          :display-errors="false"
          :columns="{
            default: { container: 12, label: 4, wrapper: 12 },
            sm: { container: 12, label: 4, wrapper: 12 },
            md: { container: 12, label: 4, wrapper: 12 },
          }"
          :add-classes="{ ElementLabel: { wrapper: 'text-gray-700 font-medium' } }"
          size="sm"
          sync
          :format-load="data => data"
        >
          <TextElement
            name="name"
            :label="$t('Name')"
            rules="required"
            class="mb-4"
            :placeholder="$t('Enter name')"
          />
          <TextareaElement
            name="description"
            :label="$t('Description')"
            class="mb-4"
            :placeholder="$t('Enter description')"
          />
          <HawkColorInput
            name="color"
            class="mb-4 col-span-12"
            :color="transaction_type?.color || null"
          />
          <StaticElement
            name="stock_operation"
            :label="$t('Operation type')"
            class="mb-4"
          >
            {{ formatString(transaction_type.stock_operation) }}
          </StaticElement>
          <StaticElement
            name="source_destination"
            class="mb-4"
          >
            <template #label>
              <div class="flex items-center gap-2">
                {{ $t('From') }} <IconHawkArrowNarrowRight class="w-4 h-4" /> {{ $t('To') }}
              </div>
            </template>
            <div class="flex items-center gap-2">
              {{ formatString(transaction_type.source) }} <IconHawkArrowNarrowRight class="w-4 h-4" /> {{ formatString(transaction_type.destination) }}
            </div>
          </StaticElement>
          <StaticElement
            name="statuses"
            :label="$t('Statuses')"
            :description="$t('The stock will be moved from the above statuses in the same order as they are displayed to the \'Issued\' status on performing the transaction')"
            class="mb-4"
          >
            {{ from_to }}
          </StaticElement>
          <TextElement
            name="quantities_label"
            :label="$t('Quantity label')"
            class="mb-4"
            :placeholder="$t('Enter quantity label')"
          />
          <TextElement
            name="plural_label"
            :label="$t('Plural label')"
            class="mb-4"
            :placeholder="$t('Enter plural label')"
          />
          <HawkAssigneeInput
            v-if="route.params?.asset_id"
            class="w-full mr-5"
            :multi="true"
            :options="{
              name: 'notify_members',
              has_teams: false,
              placeholder: $t('Select users or teams'),
              class: 'mb-4',
              label: $t('Notify'),
              description: $t('Choose users or teams to send notifications on publishing a transaction'),
            }"
          />
        </Vueform>
        <template v-if="route.params?.asset_id">
          <hr class="mb-4">
          <div>
            <p class="font-medium">
              {{ $t('Permissions') }}
            </p>
            <p class="text-xs text-gray-600 mb-4">
              {{ $t('Display various types of transaction workflows with detailed information, along with a few configuration options and access controls.') }}
            </p>
            <HawkShare
              :members="common_store?.filter_users(getAssetMembers(transaction_type?.members))"
              :teams="common_store?.filter_teams(getAssetMembers(transaction_type?.members))"
              :access_levels="[
                ...(common_store.is_development || (auth_store.check_split('inventory_draft_permission') && common_store.is_ril) ? [{ name: 'draft', label: $t('Draft'), description: $t('View own transactions and create drafts only.') }] : []),
                { name: 'write', label: $t('Publish'), description: $t('View own transactions and create/publish transactions.') },
              ]"
              class="mb-5"
              hide_empty
              @input="updateMembers"
            />
          </div>
        </template>
      </div>
      <div class="sticky bottom-0 bg-white">
        <hr>
        <div class="flex justify-end items-center py-5">
          <hawk-button
            class="mr-5"
            type="outlined"
            @click="goBack"
          >
            {{ $t('Cancel') }}
          </hawk-button>
          <hawk-button :loading="is_loading" @click="handleSave">
            {{ $t('Save') }}
          </hawk-button>
        </div>
      </div>
    </div>
  </div>
</template>
