<script setup>
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const emit = defineEmits(['close', 'createdWarehouseType']);
const { $t, $toast } = useCommonImports();
const inventory_store = useInventoryStore();

const form$ = ref(null);
const form = ref({
  name: null,
  description: null,
  color: null,
});
const is_loading = ref(false);

async function save() {
  if (!form.value.name)
    return;
  try {
    is_loading.value = true;
    const response = await inventory_store.create_warehouse_type({ payload: form.value });
    if (response) {
      $toast({
        text: $t('Updated successfully'),
        type: 'success',
      });
      emit('createdWarehouseType', response);
      is_loading.value = false;
      emit('close');
    }
  }
  catch (error) {
    is_loading.value = false;
    $toast({ text: (error?.data?.message || error?.message), type: 'error', title: 'Error' });
  }
}
</script>

<template>
  <hawk-modal-container :width="400">
    <Vueform
      ref="form$"
      v-model="form"
      size="sm"
      :display-errors="false"
      @submit="save"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('Add warehouse type') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <div>
            <TextElement
              name="name"
              :label="$t('Name')"
              :placeholder="$t('Enter warehouse type name')"
              class="mb-4"
              rules="required"
            />
            <TextareaElement
              :label="$t('Description')"
              name="description"
              :rules="['max:200']"
              class="mb-4"
              :placeholder="$t('Enter description')"
            />
            <HawkColorInput />
          </div>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end gap-4">
              <HawkButton type="outlined" @click="emit('close')">
                {{ $t('Close') }}
              </HawkButton>
              <ButtonElement
                button-class="w-full bg-blue-600"
                name="submit"
                :submits="true"
                :loading="is_loading"
              >
                {{ $t("Save") }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
