<script setup>
import HawkActivitiesTemplate from '~/common/components/organisms/hawk-activities/hawk-activities-template.vue';
import DefectPriority from '~/therm/components/atoms/defects-priority.vue';
import DefectStatus from '~/therm/components/atoms/defects-status.vue';

const props = defineProps({
  activity: {
    type: Object,
    default: () => {},
  },
});

const show_from = ref(true);
const show_to = ref(true);
const DEFECT_VERB_MAP = {

  STATUS_CHANGED: 'changed status',
  PRIORITY_CHANGED: 'changed priority',
  START_DATE_CHANGED: (from, to) => {
    if (!from && to)
      return 'set the start date';
    else if (from && !to)
      return 'removed the start date';
    else return 'changed the start date';
  },
  DUE_DATE_CHANGED: (from, to) => {
    if (!from && to)
      return 'set the due date';
    else if (from && !to)
      return 'removed the due date';
    else return 'changed the due date';
  },
  TYPE_CHANGED: 'changed type',
  SERIAL_NUMBER_CHANGED: 'updated the serial number',
  NOTE_ADDED: 'added a note',
  NOTE_UPDATED: 'updated a note',
  NOTE_DELETED: 'deleted a note',
  ASSIGNEES_ADDED: 'added assignees',
  ASSIGNEES_REMOVED: 'removed assignees',
};

function getActivityMessage(verb, from, to) {
  if (typeof DEFECT_VERB_MAP[verb] === 'function') {
    const message = DEFECT_VERB_MAP[verb](from, to);
    return message;
  }

  else { return DEFECT_VERB_MAP[verb]; }
}
</script>

<template>
  <HawkActivitiesTemplate :activity="activity" class="text-sm" :exclude_keys="['defect', 'project', 'group', 'container']">
    <template #action="{ action }">
      <div v-if="getActivityMessage(action.verb, action.has_meta_from, action.has_meta_to)">
        {{ getActivityMessage(action.verb, action.has_meta_from, action.has_meta_to) }}
      </div>
    </template>
    <template #from="{ from }">
      <template v-if="show_from">
        <span class="mx-[5px]">
          {{ $t('from') }}
        </span>
        <DefectPriority
          v-if="from.key === 'priority'"
          :priority="from.value?.slug"
          type="badge"
        />
        <DefectStatus
          v-else-if="from.key === 'status'"
          :status_object="from.value"
        />
        <p v-else-if="from.key === 'type'">
          {{ from.value?.name }}
        </p>
        <div v-else-if="from.key === 'due_date' || from.key === 'start_date'" class="inline-flex">
          {{ $date(from.value) }}
        </div>
      </template>
    </template>
    <template #to="{ to }">
      <template v-if="show_to">
        <span class="mx-[5px]">
          {{ $t('to') }}
        </span>
        <DefectPriority
          v-if="to.key === 'priority'"
          :priority="to.value?.slug"
          type="badge"
        />
        <DefectStatus
          v-else-if="to.key === 'status'"
          :status_object="to.value"
        />
        <p v-else-if="to.key === 'type'">
          {{ to.value?.name }}
        </p>
        <div v-else-if="to.key === 'due_date' || to.key === 'start_date'" class="inline-flex">
          {{ $date(to.value) }}
        </div>
      </template>
    </template>
    <template #meta="{ meta }">
      <div class="mx-[5px]">
        <div v-if="meta.key === 'assignees'">
          <HawkMembers :members="meta.items.added || meta.items.removed" type="badge" :max_badges_to_display="2" />
        </div>
      </div>
    </template>
  </HawkActivitiesTemplate>
</template>
