<script setup>
import dayjs from 'dayjs';
import { debounce, groupBy, isEqual, sortBy } from 'lodash-es';

import { useElementSize } from '@vueuse/core';

import { useAuthStore } from '~/auth/stores/auth.store';
import { useChatStore } from '~/common/stores/chat.store.js';

import TaskCommentInput from '~/tasks/pages/task-details-new/task-comments.vue/task-comment-input.vue';
import ActivityAndComments from '~/tasks/pages/task-details-new/task-comments.vue/activity-comments.vue';
import TaskCommentForm from '~/tasks/pages/task-details-new/task-comments.vue/task-comment-form.vue';

const props = defineProps({
  location: {
    type: String,
    required: true,
    validator: value => ['task_details', 'conversations'].includes(value),
  },
  task_uid: {
    type: String,
  },
});

const comment_uid = inject('comment_uid');
const task_store = inject('task_store');

const $services = inject('$services');
const header_height = inject('header_height');
const taskUpdateFunction = inject('updateFunction');

const chat_store = useChatStore();
const auth_store = useAuthStore();

const user = auth_store?.logged_in_user_details?.user_id;

const ACTIVITY_LIMIT = 1000;

const form$ = ref(null);
const task_update_palette = ref(null);
const comment_input = ref(null);

const activities = ref([]);
const data_loading = ref(false);
const users_typing = ref([]);
const show_task_update_palette = ref(false);

const { height: comment_input_height } = useElementSize(comment_input);

const task_details = computed(() => {
  return task_store.get_current_task(props.task_uid) || {};
});

const activities_and_comments = computed(() => {
  if (props.location === 'conversations')
    return chat_store.comments || [];
  return sortBy(
    [...(chat_store.comments || []), ...(activities.value || [])],
    (d) => {
      return new Date(d.created_at);
    },
  );
});

const grouped_data = computed(() => {
  if (!activities_and_comments.value)
    return {};
  return groupBy(activities_and_comments.value, (i) => {
    return new Date(i.created_at || i.createdAt).getTime() > new Date(task_store.last_read).getTime()
      ? new Date(i.created_at || i.createdAt).getTime() > new Date(task_store.last_read).getTime()
      : new Date(i.created_at || i.createdAt).toDateString();
  });
});

const activity_block_height = computed(() => {
  const location_offset = props.location === 'conversations' ? 50 : 100;
  return `${window.innerHeight - (comment_input_height.value + Number(header_height.value) + location_offset)}`;
});

onMounted(async () => {
  data_loading.value = true;
  await chat_store.initialize();

  await Promise.all([getActivities(), getComments()]);
  if (task_store.task_details_channel && task_store.task_details_channel !== null)
    set_realtime_updates();
  else
    setTimeout(() => {
      set_realtime_updates();
    }, 500);
  data_loading.value = false;
  comment_uid?.value ? scrollToComment(comment_uid.value) : scrollToBottom(500);
  chat_store.chat_channel.on('typing.start', setTypingUser);
  chat_store.chat_channel.on('typing.stop', setTypingUser);
  task_store.update_last_read(task_details.value.uid, user, task_details.value.viewed[user]);
});

// COMMENT/ACTIVITY FETCH
async function getComments() {
  await chat_store.set_data(`ticket-${props.task_uid}`, {
    name: task_details.value.name,
    members: [user],
  });

  chat_store.chat_channel.on('message.new', scrollToBottom);
  chat_store.chat_channel.on('typing.start', setTypingUser);
  chat_store.chat_channel.on('typing.stop', setTypingUser);
}

async function getActivities() {
  const { data } = await $services.common.get_activities({
    module: 'tasks',
    resource_type: 'ticket',
    resource_uid: props.task_uid,
    query: {
      page_size: Number.MAX_SAFE_INTEGER,
    },
  });
  activities.value = data;
}

function set_realtime_updates() {
  if (task_store.task_details_channel)
    task_store.task_details_channel.bind('NEW_ACTIVITY_ADDED', async (e) => {
      activities.value = [...activities.value, ...e];
      scrollToBottom(100);
    });
}

// USER TYPING
const sendTypingEvent = debounce(async () => {
  await chat_store.chat_channel.keystroke();
  await new Promise(_resolve => setTimeout(2000));
  await chat_store.chat_channel.stopTyping();
}, 200);

function setTypingUser(e) {
  if (e.user.id !== user)
    if (e.type === 'typing.start' && !users_typing.value.includes(e.user.id))
      users_typing.value.push(e.user.id);
    else if (e.type === 'typing.stop')
      users_typing.value = users_typing.value.filter(uid => uid !== e.user.id);
}

// SCROLL AND HEIGHT HANDLERS
function scrollToBottom(timeout = 20) {
  setTimeout(() => {
    const element = document.getElementById(`${props.location}-block`);
    if (element)
      element.scrollTo({ top: element.scrollHeight + element.getBoundingClientRect().y, behavior: 'smooth' });
  }, timeout);
}

function scrollToComment(comment_uid) {
  setTimeout(() => {
    const element = document.getElementById(comment_uid);
    if (element)
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, 500);
}

async function handleTaskUpdatePalette() {
  show_task_update_palette.value = !show_task_update_palette.value;
  await nextTick();
}

function onInput() {
  sendTypingEvent();
}

// COMMENT SEND METHOD
async function updateTask() {
  if (!(task_update_palette.value?.form_data && show_task_update_palette.value))
    return;

  const palette_data = task_update_palette.value.form_data;
  const task_data = task_details.value;

  const has_due_date_changed = !dayjs(task_data.due_date || undefined).isSame(dayjs(palette_data.due_date || undefined), 'day');
  const has_status_changed = task_data.status !== palette_data.status;
  const has_assignees_changed = !isEqual(task_data.assignees, palette_data.assignees.map(obj => obj.uid));

  const payload = {
    ...(has_due_date_changed && { due_date: palette_data.due_date }),
    ...(has_status_changed && { status: palette_data.status }),
    ...(has_assignees_changed && { assignees: palette_data.assignees.map(obj => obj.uid) }),
  };

  if (Object.keys(payload).length)
    await taskUpdateFunction(payload);
};

provide('location', props.location);
</script>

<template>
  <div :class="{ relative: location === 'task_details' }">
    <HawkLoader v-if="data_loading" class="h-80" />
    <div v-else>
      <div v-if="location === 'task_details'" class="px-3">
        <Vueform
          ref="form$" size="sm"
        >
          <StaticElement
            name="static"
            :columns="{
              default: 6,
              sm: 6,
            }"
          >
            <p class="text-md text-gray-900 font-semibold">
              {{ $t('Activity') }}
            </p>
          </StaticElement>
          <CheckboxElement
            name="hide_activity"
            :text="$t('Comments only')"
            :columns="{
              default: 6,
              sm: 6,
            }"
            :add-classes="{
              CheckboxElement: {
                text: 'whitespace-nowrap',
                wrapper: 'justify-end',
              },
            }"
          />
        </Vueform>
      </div>
      <ActivityAndComments
        v-if="Object.keys(grouped_data).length"
        :id="`${location}-block`"
        class="!overflow-y-scroll scrollbar"
        :style="{ height: `${activity_block_height}px` }"
        :show_activities="!form$?.data?.hide_activity"
        :data="grouped_data"
        :task_uid="task_uid"
        :asset_id="task_details.target_element?.asset"
      />
      <div
        v-else
        class="w-full flex items-center justify-center"
        :style="{ height: `${activity_block_height}px` }"
      >
        <HawkIllustrations variant="mini" type="no-data" for="comments" />
      </div>
      <div class="absolute z-998 bottom-1 bg-white left-6 right-6">
        <TaskCommentInput
          ref="comment_input"
          :task_uid="task_uid"
          :chat_store="chat_store"
          :user="user"
          :comment_input_config="{
            autofocus: true,
            disabled_controls: location !== 'task_details' ? ['settings'] : [],
          }"
          @input="onInput"
          @send="updateTask"
          @showMoreOptions="handleTaskUpdatePalette"
        >
          <template v-if="show_task_update_palette" #more_options>
            <div class="px-1 pb-1 max-h-[250px] scrollbar overflow-x-hidden">
              <TaskCommentForm
                ref="task_update_palette"
                :task_uid="task_uid"
              />
            </div>
          </template>
        </TaskCommentInput>
        <div class="text-gray-600 text-sm italic flex items-center invisible mt-0.5" :class="users_typing.length ? '!visible' : ''">
          <p v-for="uid in users_typing" :key="uid">
            <HawkMembers :members="uid" :has_avatar="false" />
          </p>
          <p class="ml-1">
            {{ $t('typing') }} ...
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
