<script setup>
import { constant, forEach, orderBy, range, size, times } from 'lodash-es';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useThermStore } from '~/therm/store/therm.store';
import ThermReportGraph from '~/therm/components/therm-report-graph.vue';

const { $t } = useCommonImports();
const therm_store = useThermStore('therm_dashboard');

const menu_items = [
  { uid: 'project', label: $t('Blocks') },
  { uid: 'status', label: $t('Status') },
];
const group_by = ref(menu_items[0]);

const get_defect_count = computed(() => {
  const block_uid_name_map = {};
  const grouped_data = therm_store.report_defects.reduce((acc, f) => {
    if (group_by.value.uid === 'project') {
      block_uid_name_map[f.block] = f.block_name;
      acc[f.block] = acc[f.block] || [];
      acc[f.block].push(f);
    }
    else {
      acc[f.type_id] = acc[f.type_id] || [];
      acc[f.type_id].push(f);
    }
    return acc;
  }, {});
  const feature_types = Object.values(therm_store.feature_types);
  const categories = [
    {
      category: orderBy(Object.keys(grouped_data)
        .reverse()
        .map((uid) => {
          let label = '';
          if (group_by.value.uid === 'project')
            label = block_uid_name_map[uid];
          else
            label = therm_store.feature_types[uid]?.name;
          return { label };
        }), 'label', ['asc']),
    },
  ];
  const data = { categories };
  if (group_by.value.uid === 'project')
    data.dataset = feature_types.map((feature_type) => {
      const feature_count = Object.keys(grouped_data)
        .reverse()
        .map((uid) => {
          return Object({
            value: grouped_data[uid].filter(
              feature =>
                feature.type_id === feature_type.id,
            ).length,
          });
        });
      if (feature_count.filter(cnt => cnt.value > 0).length)
        return {
          seriesname: feature_type.name,
          data: feature_count,
          color: getHexVal(
            feature_type.properties.color.slice(4, -1),
          ),
        };

      return {};
    });
  else
    data.dataset = therm_store.status_configs.map((status) => {
      const feature_count = Object.keys(grouped_data)
        .reverse()
        .map((uid) => {
          return Object({
            value: grouped_data[uid].filter(
              feature =>
                feature.status === status.uid,
            ).length,
          });
        });
      if (feature_count.filter(cnt => cnt.value > 0).length)
        return {
          seriesname: status.name,
          data: feature_count,
          color: status.color,
        };
      return {};
    });
  data.dataset = data.dataset.filter(val => val !== undefined);

  const column_sums = calculateColumnSums(data);
  data.dataset.unshift({
    seriesname: 'Total',
    data: column_sums.map(val => ({
      value: 0,
      tooltext: `Total: ${val}`,
      color: '#000000',
    })),
  });
  return data;
});

const get_barchart_details = computed(() => {
  return {
    type: 'stackedcolumn2d',
    title: $t('Defects count'),
    xAxisName: group_by.value.uid === 'project' ? $t('Inverters') : $t('Defect type'),
    yAxisName: group_by.value.uid === 'project' ? $t('No. of defects') : $t('Defects count'),
    plotToolText: '$seriesName: $value',
    id: 'defect_count_container',
    hide_download: true,
  };
});

function getHexVal(val) {
  val = val.split(',');
  return (
    `#${
           val
            .map((x) => {
              const hex = (+x).toString(16);
              return hex.length === 1 ? `0${hex}` : hex;
            })
            .join('')}`
  );
}
function calculateColumnSums(data) {
  const totals = data.dataset.reduce((acc, val) => {
    if (val.data)
      acc.push(val.data.map(({ value }) => value));
    return acc;
  }, []);

  if (!totals?.length)
    return [];

  const num_columns = size(totals[0]);

  const column_sums = times(num_columns, constant(0));

  forEach(totals, (row) => {
    forEach(range(num_columns), (col) => {
      column_sums[col] += row[col];
    });
  });

  return column_sums;
}
</script>

<template>
  <div class="border rounded-xl h-[540px] p-4">
    <ThermReportGraph
      :data="get_defect_count"
      :graph_details="get_barchart_details"
      :has_borders="false"
      height="460"
    >
      <template #header-right>
        <HawkMenu
          :items="menu_items.map(item => ({ ...item, on_click: () => (group_by = item) }))"
          :has_bordered_trigger="false"
          additional_trigger_classes="!ring-0 p-0 -mt-1"
          additional_dropdown_classes="max-h-60 scrollbar"
          position="fixed"
        >
          <template #trigger="{ open }">
            <hawk-button type="outlined">
              {{ group_by.label }}
              <IconHawkChevronDown
                class="text-white transition-transform"
                :class="{ 'rotate-180': open }"
              />
            </hawk-button>
          </template>
        </HawkMenu>
      </template>
    </ThermReportGraph>
  </div>
</template>
