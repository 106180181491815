<script setup>
import { debounce, filter, includes, isNil, keyBy, sumBy } from 'lodash-es';
import Papa from 'papaparse';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useStockItems } from '~/inventory/composables/inventory-stock-items.composable.js';
import SerialNumberTable from '~/inventory/components/inventory-transaction-form/inventory-serial-number-table.vue';
import InventorySerialNumberValidInvalidTable from '~/inventory/components/inventory-transaction-form/inventory-serial-number-valid-invalid-table.vue';
import IconRollback from '~icons/hawk/rollback';
import { useInventoryStore } from '~/inventory/store/inventory.store';
import InventoryQuantity from '~/inventory/components/inventory-quantity.vue';

const props = defineProps({
  value: {
    type: Object,
    default: null,
  },
  id: {
    type: String,
    default: '',
  },

  type: {
    type: String,
    default: 'create',
  },
  exclude_items: {
    type: Array,
    default: () => [],
  },
  workflow: {
    type: Object,
    default: null,
  },
  prefill_line_data: {
    type: Object,
    default: () => {},
  },
  to_stock: {
    type: String,
    default: '',
  },
  from_stock: {
    type: String,
    default: '',
  },
  disable_serial_numbers: {
    type: Boolean,
    default: false,
  },
  has_fixed_quantity: {
    type: Boolean,
    default: false,
  },
  is_shipment_allocation: {
    type: Boolean,
    default: false,
  },
  validate_data_on_mount: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['saveItem', 'close']);
const { $t, $services, route, $toast } = useCommonImports();
const { validateStocks } = useStockItems();
const state = reactive({
  items: [],
  loading: false,
  invalid_array: [],
  initial_data: {},
  is_edited: false,
  invalid_stocks: [],
  is_validating: false,
  is_validated_table_open: false,
  valid_stocks: [],
  initialize: false,

});
const form$ = ref(null);
const form = ref(null);
const sn_input_table = ref(null);
const inventory_store = useInventoryStore();
const filtered_items = computed(() => {
  return state?.items?.filter(item => !props.exclude_items.includes(item.uid)) || [];
});
const is_not_on_system = computed(() => {
  return !props.workflow?.from_status?.length;
});
const item_custom_fields = computed(() => {
  return inventory_store.get_custom_fields({
    uid: form.value?.item?.uid,
  }).filter(field => field.type === 'text' || field.type === 'number');
});
const max_value = computed(() => {
  const item = form?.value?.item;
  if (!item)
    return 0;
  if (props.workflow?.validate_stock)
    if (props.from_stock && item?.stockstatuses)
      return sumBy(
        filter(
          item.stockstatuses,
          stock_status =>
            props.from_stock === stock_status.resource_id
            && includes(props.workflow?.from_status, stock_status.status),
        ),
        'stock_quantity',
      );
  return Number.MAX_SAFE_INTEGER;
});

const getItems = debounce(async (search) => {
  try {
    state.loading = true;
    const res = await $services.inventory_items.getAll({
      query: {
        warehouse:
        props.workflow.source === 'warehouse'
          ? props.from_stock
          : undefined,
        workflow: props.workflow.uid,
        page: 1,
        limit: 100,
        include_stock: true,
        asset: route.params.asset_id,
        is_active: true,
        search,
      },
    });

    state.items = [...res.data.items.map(item => ({ ...item, disabled: item.allowed_quantity === 0 }))];
    state.loading = false;
  }
  catch (err) {
    state.loading = false;
  }
}, 500);
function getCurrentQuantity(item) {
  if (item?.uid)
    if (props.to_stock === true)
      return item.physical_stock_quantity || 0;
    else if (props.workflow.source === 'asset' && props.workflow?.validate_stock && item?.stockstatuses)
      return sumBy(
        filter(
          item.stockstatuses,
          stock_status =>
            props.from_stock === stock_status.resource_id
            && includes(props.workflow?.from_status, stock_status.status),
        ),
        'stock_quantity',
      );
  return Number(
    item?.warehouse_stock_quantity[
      props.workflow?.stock_operation === 'stock_in'
        ? props.to_stock
        : props.from_stock
    ]?.quantity || 0,
  );
}
async function validateStocksInput() {
  try {
    state.is_validating = true;
    const { serial_numbers } = await sn_input_table.value.getValue('default');
    const table_state = state.initial_data?.stock_input?.data ?? await sn_input_table.value.state;
    const { invalid_stocks, valid_stocks } = await validateStocks(serial_numbers.map(item => item.serial_no), {
      ...props,
      item: form.value.item,
      ...((table_state.track_by && table_state.track_by.uid !== 'serial_no') && { field_uid: table_state.track_by?.uid }),

    });
    state.invalid_stocks = invalid_stocks;
    state.valid_stocks = valid_stocks;
    state.is_validating = false;
    state.is_validated_table_open = true;
  }
  catch (error) {
    $toast({ title: 'Error', text: 'Failed to validate stocks', type: 'error' });
    state.is_validating = false;
  }
}
async function saveItem() {
  try {
    if (form?.value?.item?.is_serial_number) {
      const { serial_numbers } = await sn_input_table.value.getValue('default');
      const response = await sn_input_table.value.state;
      const data = is_not_on_system.value ? response.sn_data.filter(item => item.serial_no) : serial_numbers.filter(item => item.serial_no);
      const validNumbers = [];
      const collectValidNumbers = (item, validNumbers) => {
        if (item.children && item.children.length)
          item.children.forEach((child) => {
            collectValidNumbers(child, validNumbers);
          });
        else
          validNumbers.push(item.number);
      };

      state.valid_stocks.forEach((item) => {
        collectValidNumbers(item, validNumbers);
      });
      form.value.quantity = validNumbers?.length;
      if (is_not_on_system.value) {
        const data_hash = keyBy(data, 'serial_no');

        const payload = [];
        validNumbers.forEach((item) => {
          if (data_hash[item])
            payload.push({
              serial_no: data_hash[item]?.serial_no,
              batch_number: data_hash[item]?.batch_number,
              custom_fields: [],
            });
        });
        form.value.serial_numbers = payload;
      }
      else { form.value.serial_numbers = validNumbers.map(item => ({ serial_no: item, custom_fields: [] })); }
      form.value.stock_input = {
        input: data.map(item => item.serial_no),
        field_uid: response.track_by?.uid !== 'serial_no' ? response.track_by?.uid : null,
        data: {
          data,
          columns: response.sn_columns,
          track_by: response.track_by,
        },
      };
      emit('saveItem', form.value, response);
    }
    else { emit('saveItem', form.value, []); }
  }
  catch (error) { logger.log('🚀 ~ saveItem ~ error:', error); }
}
function downloadSampleCsv() {
  let data = [{
    'Serial number': '',
    ...(form?.value?.item?.is_batch_number && {
      'Pallet number': '',
    }),
  }];
  if (item_custom_fields.value.length && !is_not_on_system.value)
    data = [{
      'Serial/Pallet number': ' ',
    }];

  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${form?.value?.item?.name}_sample.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
onMounted(async () => {
  try {
    state.initialize = true;
    if (props.type !== 'edit')
      await getItems();
    state.initial_data = {
      ...props.value,
    };
    await form$.value.load(state.initial_data);
    if (props.validate_data_on_mount)
      await validateStocksInput();

    state.initialize = false;
  }
  catch (e) {
    state.initialize = false;
  }
});
</script>

<template>
  <div>
    <Vueform
      ref="form$"
      v-model="form"
      :display-errors="false"
      class="grid  gap-4"
      size="sm"
      :columns="{
        default: { container: 12, label: 12, wrapper: 4 },
        sm: { container: 12, label: 12, wrapper: 4 },
        md: { container: 12, label: 12, wrapper: 4 },
      }"
    >
      <div v-show="!state.initialize" class="col-span-12">
        <SelectElement
          :filter-results="false"
          :loading="state.loading"
          name="item"
          :placeholder="$t('Select item')"
          :label="$t('Item name')"
          :search="true"
          track-by="name"
          :object="true"
          :can-deselect="false"
          :can-clear="false"
          label-prop="name"
          value-prop="uid"
          :items="filtered_items"
          :rules="['required']"
          :disabled="type === 'edit'"
          @search-change="getItems"
          @change="state.invalid_array = [];"
        >
          <template #single-label="{ value }">
            <div class="flex items-center w-full overflow-hidden justify-between bg-transparent leading-snug p-3">
              <div>
                <div class="text-sm w-full font-medium whitespace-nowrap text-ellipsis overflow-hidden">
                  {{ value?.name }}
                </div>
                <div class="text-xs font-regular flex items-center">
                  <div class="text-ellipsis overflow-hidden whitespace-nowrap">
                    #{{ value?.number }}
                  </div>
                </div>
              </div>
              <div v-if="!isNil(value.allowed_quantity)">
                <InventoryQuantity :uom="value.uom" :quantity="getCurrentQuantity(value)" />
              </div>
            </div>
          </template>
          <template #option="{ option }">
            <div class="flex flex-col w-full">
              <div class="text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden">
                {{ option.name }}
              </div>
              <div class="text-xs w-full font-regular justify-between flex items-center">
                <div class="text-xs font-regular flex gap-4 items-center text-ellipsis overflow-hidden whitespace-nowrap">
                  #{{ option.number }}
                </div>
                <div v-if="!isNil(option.allowed_quantity)">
                  <InventoryQuantity :uom="option.uom" :quantity="getCurrentQuantity(option)" />
                </div>
              </div>
            </div>
          </template>
        </SelectElement>

        <div class="mt-3">
          <QuantityElement
            v-if="max_value && (!form?.item?.is_serial_number || props.disable_serial_numbers)"
            name="quantity"
            placeholder="0"
            label="Quantity"
            :max="max_value"
            :disabled="!form?.item"
            :default="max_value ? 1 : 0"
            :rules="[
              'min:1',
              'integer',
              'required',
              `max:${max_value}`,
            ]"
            :step="1"
            :add-classes="{
              QuantityElement: {
                inputContainer: ['!w-40'],
              },
            }"
            @input="state.is_edited = true"
          />
        </div>
        <template v-if="form?.item?.is_serial_number && !props.disable_serial_numbers">
          <div class="grid col-span-12 gap-4 mt-3">
            <div class="grid grid-cols-12 ">
              <div class="flex col-span-12 text-sm font-medium text-gray-700">
                Serial Numbers
              </div>
              <div v-show="!state.is_validated_table_open" class="col-span-12">
                <div class="flex items-center justify-between">
                  <div class="text-xs col-span-12">
                    Enter the serial/pallet numbers for the PV Modules. <span v-if="is_not_on_system">You can copy/paste or import additional metadata using an excel file. Download <HawkButton type="link" size="cs" @click="downloadSampleCsv">
                      sample template
                    </HawkButton>.</span>
                  </div>
                </div>

                <SerialNumberTable
                  :id="id"
                  :key="form?.item?.uid"
                  ref="sn_input_table"
                  class="col-span-12 mt-4"
                  :is_batch_number="form?.item?.is_batch_number"
                  :value="state.initial_data?.stock_input?.data"
                  :item="form?.item"
                  :line="value"
                  :quantity="has_fixed_quantity ? value?.quantity : 0"
                  :workflow="workflow"
                  :initial_invalid_array="props.value?.invalid_stock_units?.map(stock_unit => stock_unit?.serial_number || stock_unit)"
                  :populate_batch_numbers="form?.item?.is_batch_number ? workflow?.stock_operation !== 'stock_in' : false"
                  :location="props.from_stock || props.to_stock"
                />
              </div>
              <div v-if="state.is_validated_table_open" class="col-span-12">
                <InventorySerialNumberValidInvalidTable
                  :invalid_stocks_data="state.invalid_stocks"
                  :item="form?.item"
                  :valid_stocks_data="state.valid_stocks"
                  :sn_table_ref="sn_input_table"
                  :is_batch_number="form?.item?.is_batch_number"
                  :workflow="workflow"
                  :stock_input="state.initial_data?.stock_input"
                />
                <CheckboxElement v-show="state.invalid_stocks.length" class="mt-2" name="invalid_data_for_reference">
                  {{ 'Store invalid data for reference' }}
                </CheckboxElement>
              </div>
            </div>
          </div>
        </template>
        <TextareaElement
          class="mt-3"
          name="description"
          placeholder="Add comments here"
          label="Notes"
          :submit="true"
        />
        <div class="flex justify-end ml-auto items-center col-span-12 w-full mt-3">
          <HawkButton type="outlined" color="gray" @click="$emit('close')">
            {{ $t('Cancel') }}
          </HawkButton>
          <HawkSubmitButton v-if="!form?.item?.is_serial_number" :disabled="!form$?.data?.item?.uid" class="ml-4" :form$="form$" @click="saveItem">
            {{ $t('Save') }}
          </HawkSubmitButton>
          <HawkSubmitButton v-if="state.is_validated_table_open" type="outlined" class="ml-4" @click="state.is_validated_table_open = false">
            <IconRollback class="w-4.5 h-4.5" />   {{ 'Back to edit' }}
          </HawkSubmitButton>
          <HawkSubmitButton v-if="form?.item?.is_serial_number" :disabled="state.is_validated_table_open && state.valid_stocks.length === 0" class="ml-4 " :loading="state.is_validating" :form$="form$" @click="state.is_validated_table_open ? saveItem() : validateStocksInput()">
            {{ state.is_validated_table_open ? $t('Confirm') : $t('Continue') }}
          </HawkSubmitButton>
        </div>
      </div>

      <div v-if="state.initialize" class="flex justify-center items-center col-span-12">
        <HawkLoader />
      </div>
    </Vueform>
  </div>
</template>
