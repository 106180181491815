<script setup>
import { computed, inject, onMounted, onUnmounted, watch } from 'vue';
import { cloneDeep } from 'lodash-es';
import { useRoute, useRouter } from 'vue-router';
import { useThermStore } from '~/therm/store/therm.store';
import { useMap } from '~/common/composables/mapbox/maps';
import ThermSidebar from '~/therm/components/therm-left-sidebar.vue';
import ThermLayers from '~/therm/components/therm-layers.vue';

const layers = [
  {
    id: 'point_feature_layer',
    type: 'circle',
    source: 'all_features_source',
    filter: ['==', '$type', 'Point'],
  },
  {
    id: 'polygon_feature_layer',
    type: 'fill',
    source: 'all_features_source',
    paint: {
      'fill-opacity': 0,
    },
  },
  {
    id: 'linestring_feature_layer',
    type: 'line',
    source: 'all_features_source',
    paint: {
      'line-width': 2,
    },
  },
];
const compare_id_left = 'therm_compare_left';
const compare_id_right = 'therm_compare_right';
const therm_compare_left = useThermStore(compare_id_left);
const therm_compare_right = useThermStore(compare_id_right);
const route = useRoute();
const router = useRouter();
const $services = inject('$services');
const last_selected_project_left = computed(() => therm_compare_left.last_selected_project);
const last_selected_project_right = computed(() => therm_compare_right.last_selected_project);
const { initMapbox, loadMapBoxPackage, addMapboxToken, loadMapboxCompare, removeMapboxInstance } = useMap();
watch(() => last_selected_project_left.value, (cur, prev) => {
  if (!prev && cur)
    initialProjectInitialization(compare_id_left);
}, { deep: true });
watch(() => last_selected_project_right.value, (cur, prev) => {
  if (!prev && cur)
    initialProjectInitialization(compare_id_right);
}, { deep: true });
onMounted(async () => {
  try {
    therm_compare_left.loader_enabled = true;
    await loadMapBoxPackage();

    await loadMapboxCompare();

    await init(compare_id_left);
    await init(compare_id_right);
    // eslint-disable-next-line no-new
    new mapboxgl.Compare(
      therm_compare_left.map,
      therm_compare_right.map,
    );
  }
  catch (error) {
    therm_compare_left.loader_enabled = false;
  }
});
onUnmounted(() => {
  removeMapboxInstance(therm_compare_left.map);
  removeMapboxInstance(therm_compare_right.map);
  therm_compare_right.$dispose();
  therm_compare_left.$dispose();
});
async function init(compare_id) {
  const stores = {
    therm_compare_left,
    therm_compare_right,
  };
  const map = await initMapbox({
    container_id: compare_id,
  });
  stores[compare_id].map = map;

  map.on('load', () => {
    if (!map.getSource('all_features_source'))
      map.addSource('all_features_source', {
        type: 'geojson',
        data: {
          features: [],
          type: 'FeatureCollection',
        },
      });

    layers.forEach((layer) => {
      if (!map.getLayer(layer.id))
        map.addLayer(layer);
    });
    therm_compare_left.loader_enabled = false;
  });
  setContainer();
  setFtg();
}

async function setContainer() {
  try {
    const response = await $services.therm.getAll({
      attribute: `views/${route.params.id}`,
      query: {
        hierarchy: true,
        projects: true,
        complete: true,
      },
    });
    response.data = await therm_compare_left.parse_container_data(
      {
        container: cloneDeep(response.data),
      },
    );
    if (response.data?.groups?.length)
    // filter group and project based on # of projects and published/disabled projects.
      response.data.groups = response.data.groups.reduce(
        (groups, group) => {
          group.projects = (group.projects || []).reduce(
            (projects, project) => {
              if (project.published !== false)
                projects.push(project);
              return projects;
            },
            [],
          );
          if (group.projects.length)
            groups.push(group);
          return groups;
        },
        [],
      );

    logger.log(response.data);
    therm_compare_left.set_container({
      container: cloneDeep(response.data),
      initial_load: true,
    });
    therm_compare_right.set_container({
      container: cloneDeep(response.data),
      initial_load: true,
    });

    const status_configs = (await $services.therm.get({
      attribute: `views/view/${route.params.id}/issues/status-configs`,
    })).data;
    therm_compare_left.status_configs = cloneDeep(status_configs);
    therm_compare_right.status_configs = cloneDeep(status_configs);
  }
  catch (error) {
    logger.log(error);
  }
}
async function initialProjectInitialization(store_name) {
  const stores = {
    therm_compare_left,
    therm_compare_right,
  };
  const initial = async (project) => {
    stores[store_name].set_project_ortho({
      project,
    });
    stores[store_name].set_project_ortho({
      project,
    });
    await stores[store_name].fly_to_first_feature({
      project,
    });
    stores[store_name].update_map_features();
    stores[store_name].set_ftg_and_update_features_styles();
  };

  await initial(
    store_name === compare_id_left
      ? last_selected_project_left.value
      : last_selected_project_right.value,
  );
  therm_compare_left.loader_enabled = false;
}
async function setFtg() {
  try {
    if (
      !therm_compare_left.ftg || !therm_compare_right.ftg
    ) {
      therm_compare_left.loader_enabled = true;
      const response = await $services.therm.getAll({
        attribute: `viewer/view/${route.params.id}/config`,
        query: {
          hierarchy: true,
        },
      });
      therm_compare_left.set_ftg_and_update_features_styles({
        ftg: response.data,
      });
      therm_compare_right.set_ftg_and_update_features_styles({
        ftg: response.data,
      });
    }
  }
  catch (error) {
    therm_compare_left.loader_enabled = false;
  }
}

function routeToMap() {
  router.push({
    name: 'therm',
    params: { asset_id: route.params.asset_id, id: route.params.id },
  });
}
</script>

<template>
  <div class="">
    <div v-if="therm_compare_left.loader_enabled || therm_compare_right.loader_enabled" class="absolute w-full h-[calc(100vh-135px)] z-50 bg-gray-100 opacity-[0.9] flex justify-center items-center">
      <HawkLoader />
    </div>
    <div class="flex m-0 p-0 h-full relative">
      <ThermSidebar
        class="flex-grow p-0 z-20 h-full"
        side="left"
        :therm_store_name="compare_id_left"
      />

      <div class="flex-grow relative">
        <ThermProjects
          side="left"
          class="top-3"
          :therm_store_name="compare_id_left"
        />
        <ThermLayers side="left" :therm_store_name="compare_id_left" />
        <div class="exit_compare absolute bottom-2 left-14 z-10" @click="routeToMap">
          <HawkButton type="outlined" class="text-gray-700 text-sm font-semibold pr-3">
            <IconHawkArrowLeft class="text-gray-700" />
            {{ $t('Exit compare') }}
          </HawkButton>
        </div>
      </div>

      <div class="relative compare-map-column">
        <div class="flex">
          <div :id="compare_id_left" />
          <div :id="compare_id_right" />
        </div>
      </div>

      <div class="relative">
        <ThermProjects side="right" class="top-3" :therm_store_name="compare_id_right" />
        <ThermLayers side="right" :therm_store_name="compare_id_right" />
      </div>

      <ThermSidebar
        side="right"
        class="h-full z-20"
        :therm_store_name="compare_id_right"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .compare-map-column,
  #therm_compare_left,
  #therm_compare_right {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: calc(100vh - 133px);
    width: 100%;
  }

  :deep(.mapboxgl-compare) {
    top: 0;
    .compare-swiper {
      background-color:#1570EF !important;
      width: 44px !important;
      height: 44px !important;
      position: absolute !important;
      left: -20px !important;
      border-radius: 8px !important;
      border: 2px solid #fff !important;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
      background-size: cover !important;
    }
  }
</style>
