<script setup>
import { computed } from 'vue';

const props = defineProps({
  is_pagination_enabled: {
    type: Boolean,
    required: true,
  },
  default_pagination_config: {
    type: Object,
    required: true,
  },
  pagination_width: {
    type: Number,
    default: 0,
  },
  pagination_state: {
    type: Object,
    required: true,
  },
  pagination_config: {
    type: Object,
    required: true,
  },
  data: {
    type: Array,
    required: true,
  },
});

const emits = defineEmits(['updatePagination']);

const table_page_index = computed(() => props.pagination_state.pageIndex);
const table_page_size = computed(() => props.pagination_state.pageSize);
const has_previous_page = computed(() => props.pagination_state.pageIndex > 0);
const getPaginationLimit = computed(() => {
  const computedRecords = (table_page_index.value + 1) * table_page_size.value;
  const availableRecords = props.pagination_config.totalRows || props.data?.length;
  return computedRecords > availableRecords ? availableRecords : computedRecords;
});
const getPageSizeOptions = computed(() => {
  return props.default_pagination_config.noOfRows.map((row_count) => {
    return Object({
      label: row_count,
      uid: row_count,
      on_click: () => {
        emits('updatePagination', 'pageSize', Number(row_count));
      },
    });
  });
});

function generatePagesBelowRange(pageCount, currPage) {
  const items = {};

  for (let index = 0; index < pageCount; index++) {
    const page = {
      index,
      content: index + 1,
      selected: index === (currPage - 1),
    };
    items[index] = page;
  }

  return items;
}

function generatePagesAboveRange(currPage, pageCount, pageRange) {
  const marginPages = (currPage < 3 || (pageCount - currPage) < 3) ? 3 : 1;
  const items = {};

  const halfPageRange = Math.floor(pageRange / 2);
  const setPageItem = (index) => {
    const page = {
      index,
      content: index + 1,
      selected: index === (currPage - 1),
    };
    items[index] = page;
  };
  const setBreakView = (index) => {
    const breakView = {
      disabled: true,
      breakView: true,
    };
    items[index] = breakView;
  };

  for (let i = 0; i < marginPages; i++)
    setPageItem(i);

  let selectedRangeLow = 0;
  if (currPage - halfPageRange > 0)
    selectedRangeLow = currPage - 1 - halfPageRange;

  let selectedRangeHigh = selectedRangeLow + pageRange - 1;
  if (selectedRangeHigh >= pageCount) {
    selectedRangeHigh = pageCount - 1;
    selectedRangeLow = selectedRangeHigh - pageRange + 1;
  }
  for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= pageCount - 1; i++)
    setPageItem(i);

  if (selectedRangeLow > marginPages)
    setBreakView(selectedRangeLow - 1);

  if (selectedRangeHigh + 1 < pageCount - marginPages)
    setBreakView(selectedRangeHigh + 1);

  for (let i = pageCount - 1; i >= pageCount - marginPages; i--)
    setPageItem(i);

  return items;
}
const getPages = computed(() => {
  const currPage = props.pagination_state.pageIndex + 1;
  const pageCount = Math.ceil(props.pagination_config.totalRows / props.pagination_state.pageSize);
  const pageRange = 3;

  return pageCount <= pageRange
    ? generatePagesBelowRange(pageCount, currPage)
    : generatePagesAboveRange(currPage, pageCount, pageRange);
});
</script>

<template>
  <div
    v-if="is_pagination_enabled" :style="{ width: pagination_width ? `${pagination_width}px` : '100%' }"
    class="sticky rounded-b-lg left-0 z-[1] flex items-center border-x border-b border-gray-200 justify-between flex-wrap px-6 min-h-16 border-t bg-gray-50"
  >
    <div class="flex items-center py-4">
      <span className="flex items-center gap-1 mr-8">
        <div class="text-sm font-medium">
          {{ (table_page_index * table_page_size) + 1 }} -
          {{ getPaginationLimit }}
        </div>
        <div class="text-sm font-normal text-gray-400">
          of {{ pagination_config.totalRows || data?.length }} items
        </div>
      </span>
      <HawkMenu :items="getPageSizeOptions">
        <template #trigger>
          <div class="flex items-center text-sm font-semibold">
            {{ pagination_state.pageSize }}
            <IconHawkChevronDown class="text-xl text-gray-500 mx-1" />
          </div>
        </template>
      </HawkMenu>
      <span class="text-sm text-gray-400 mb-1">per page</span>
    </div>
    <nav
      class="isolate inline-flex -space-x-px rounded-md shadow-sm h-9"
      aria-label="Pagination"
    >
      <button
        :disabled="!has_previous_page"
        class="relative inline-flex items-center rounded-l-md w-10 border border-gray-300 bg-white p-2 hover:bg-gray-50 focus:z-20"
        @click="emits('updatePagination', 'prev')"
      >
        <IconHawkArrowLeft class="text-lg text-gray-900 disabled:text-gray-300" />
      </button>
      <button
        v-for="(value, name, index) in getPages"
        :key="index"
        aria-current="page"
        :class="[
          value.selected
            ? 'bg-gray-100 z-30 hover:border'
            : 'bg-white',
        ]"
        class="relative inline-flex items-center text-center border border-gray-300 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-50 focus:z-20"
        :style="{
          minWidth: '40px',
        }"
        @click="!value.breakView && emits('updatePagination', 'pageIndex', value.content - 1)"
      >
        <div class="min-w-full px-2">
          {{ value.breakView ? "..." : value.content }}
        </div>
      </button>
      <button
        :disabled="(pagination_state.pageIndex + 1) * pagination_state.pageSize >= pagination_config.totalRows"
        class="relative inline-flex items-center rounded-r-md w-10 border border-gray-300 bg-white p-2 hover:bg-gray-50 focus:z-20"
        @click="emits('updatePagination', 'next')"
      >
        <IconHawkArrowRight class="text-lg text-gray-900 disabled:text-gray-300" />
      </button>
    </nav>
  </div>
</template>
