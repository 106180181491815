<script setup>
import { $toast } from '~/common/utils/common.utils.js';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const emit = defineEmits(['close', 'save']);
const { $t } = useCommonImports();
const form$ = ref(null);
const form = ref({});

const store = useInventoryStore();

async function save() {
  const payload = {
    ...form$.value.requestData,
    custom_fields: [],
  };
  try {
    const vendor = await store.create_vendor({
      body: {
        vendor: payload,
      },
    });
    emit('save', vendor.uid);
    emit('close');
  }
  catch (error) {
    $toast({ text: (error?.data?.message || error?.message), type: 'error', title: 'Error' });
    logger.log(error);
  }
}
</script>

<template>
  <HawkModalContainer>
    <Vueform
      ref="form$"
      v-model="form"
      :display-errors="false"
      class="grid grid-rows-1 grid-flow-col gap-4"
      size="sm"
      :endpoint="save"
      :columns="{
        default: {
          container: 12, label: 4, wrapper: 12,
        },
        sm: {
          container: 12, label: 4, wrapper: 12,
        },
        md: {
          container: 12, label: 4, wrapper: 12,
        },
      }"
    >
      <div class="col-span-12 max-w-[600px]">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div class="flex items-center">
              <div class="flex flex-col justify-start">
                {{ $t(`New vendor`) }}
              </div>
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent class="p-1 max-h-[575px] grid gap-6" :is_scroll="false">
          <TextElement
            name="name"
            :label="$t('Vendor name')"
            :placeholder="$t('Enter vendor name')"
            :rules="['required']"
          />
          <TextareaElement
            name="description"
            :label="$t('Description')"
            :placeholder="$t('Enter vendor description')"
          />
        </HawkModalContent>
        <HawkModalFooter class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center gap-3">
              <HawkButton type="outlined" @click="$emit('close')">
                {{ $t('Cancel') }}
              </HawkButton>
              <HawkSubmitButton :form$="form$">
                {{ $t('Save') }}
              </HawkSubmitButton>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
