<script setup>
import { Validator } from '@vueform/vueform';
import { useModal } from 'vue-final-modal';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import { createListOnPaste } from '~/system-model/utils/helper';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import SmCategoryForm from '~/system-model/components/forms/sm-category-form.vue';

const props = defineProps({
  template: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['close']);

const { route, router } = useCommonImports();
const system_model_store = useSystemModelStore();

const form$ = ref(null);
const is_loading = ref(false);

const category_options = computed(() => {
  return system_model_store?.categories?.map(category => ({
    value: category.uid,
    label: category.name,
  }));
});

const { open: openCategoryModal, close: closeCategoryModal, options: category_modal_options } = useModal({
  component: SmCategoryForm,
  attrs: {
    onClose() {
      closeCategoryModal();
    },
    onSetCategory(category_uid) {
      const component_element = form$.value.el$('component_list');
      const component_list = component_element?.data?.component_list;
      if (component_list?.length) {
        component_list[category_modal_options.category_index].category = category_uid;
        component_element.update(component_list);
        delete category_modal_options.category_index;
      }
    },
  },
});

onMounted(() => {
  form$.value.el$('name').focus();
  if (props.template)
    form$.value.load({
      name: props.template.name,
    });
});

async function submitHandler(form$) {
  try {
    is_loading.value = true;
    const form_data = form$.data;
    if (props.template) {
      if (props.template.name !== form_data.name)
        await system_model_store.update_template({
          body: {
            name: form_data.name,
          },
          uid: props.template.uid,
        });
    }
    else {
      const created_template = await system_model_store.create_template({
        name: form_data.name,
        asset: route.params.asset_id,
        container: null,
      });
      if (created_template && form_data?.component_list?.length) {
        const component_list = form_data?.component_list?.map(component => ({
          name: component.name,
          children: [],
          category: component.category,
          template: created_template.uid,
        }));
        await system_model_store.create_components({ body: component_list });
        await system_model_store.set_templates({ asset_id: route.params.asset_id });
        router.push({ name: 'sm-template-details', params: { asset_id: route.params.asset_id, template_id: created_template.uid } });
      }
    }
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    is_loading.value = false;
    emit('close');
  }
}

async function pasteHandler(event) {
  const columns = createListOnPaste(event);
  const items = columns.map((item) => {
    return {
      name: item.split(/[\t,]+/)[0],
      category: null,
      uid: null,
    };
  });
  const merged_component = [
    ...(form$.value.data.component_list?.filter(c => c.name !== null) || []),
    ...items,
  ];
  setTimeout(() => {
    form$.value.el$('component_list').update(merged_component);
    form$.value.el$('component_list').validate();
  }, 1);
}
function showCategoryModal(index) {
  category_modal_options.category_index = index;
  openCategoryModal();
}
const uniqueName = class extends Validator {
  get message() {
    return 'Name must be unique';
  }

  check(value) {
    const list = form$.value.data.component_list;
    if (list.length > 1 && value) {
      const is_match = list.filter(({ name }) => name?.toLowerCase() === value?.toLowerCase());
      if (is_match.length > 1)
        return false;
    }
    return true;
  }
};
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      :display-errors="false"
      size="sm"
      :add-classes="{
        TextElement: {
          input: ['placeholder:text-gray-500 placeholder:text-sm placeholder:font-normal'],
        },
      }"
      @submit="submitHandler"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #left>
            {{ template ? $t('Rename Template') : $t('New Template') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content class="max-h-96 scrollbar">
          <TextElement
            v-bind="{
              label: `${$t('Template Name')}`,
              name: 'name',
              placeholder: 'Enter field name',
              columns: { container: 12, label: 4, wrapper: 12 },
              rules: ['required'],
            }"
          />
          <StaticElement v-if="!template" name="static">
            <div class="mt-5 font-semibold text-base text-gray-900 mb-3">
              {{ $t('Components') }}
            </div>
          </StaticElement>

          <ListElement
            v-if="!template"
            name="component_list"
            :add-text="`+ ${$t('Add component')}`"
            :presets="['repeatable_list']"
            @paste="pasteHandler"
          >
            <template #default="{ index }">
              <ObjectElement
                v-if="index === 0"
                :name="index"
                :columns="{
                  default: { container: 11, label: 12, wrapper: 12 },
                  sm: { container: 11, label: 12, wrapper: 12 },
                  md: { container: 11, label: 12, wrapper: 12 },
                }"
                :add-classes="{
                  ObjectElement: {
                    wrapper: ['items-center', '!gap-10'],
                  },
                }"
              >
                <GroupElement
                  name="component_group"
                  :columns="{
                    default: { container: 6, label: 12, wrapper: 12 },
                    sm: { container: 6, label: 12, wrapper: 12 },
                    md: { container: 6, label: 12, wrapper: 12 },
                  }"
                >
                  <div class="text-gray-700 font-medium">
                    {{ $t('Name') }}
                  </div>
                </GroupElement>
                <GroupElement
                  name="category_group"
                  :columns="{
                    default: { container: 6, label: 12, wrapper: 12 },
                    sm: { container: 6, label: 12, wrapper: 12 },
                    md: { container: 6, label: 12, wrapper: 12 },
                  }"
                >
                  <div class="text-gray-700 font-medium">
                    {{ $t('Category') }}
                  </div>
                </GroupElement>
              </ObjectElement>
              <ObjectElement
                :name="index"
                :add-classes="{
                  ObjectElement: {
                    wrapper: ['!gap-10'],
                  },
                }"
                :columns="{
                  default: { container: 11, label: 12, wrapper: 12 },
                  sm: { container: 11, label: 12, wrapper: 12 },
                  md: { container: 11, label: 12, wrapper: 12 },
                }"
              >
                <TextElement
                  v-bind="{
                    name: 'name',
                    placeholder: 'Enter name',
                    rules: ['required', uniqueName],
                    columns: {
                      default: { container: 6, label: 12, wrapper: 12 },
                      sm: { container: 6, label: 12, wrapper: 12 },
                      md: { container: 6, label: 12, wrapper: 12 },
                    },
                  }"
                />
                <SelectElement
                  v-bind="{
                    columns: {
                      default: { container: 6, label: 12, wrapper: 12 },
                      sm: { container: 6, label: 12, wrapper: 12 },
                      md: { container: 6, label: 12, wrapper: 12 },
                    },
                    disabled: template,
                    name: 'category',
                    items: category_options,
                    search: true,
                    native: false,
                    placeholder: $t('Select Category'),
                    autocomplete: 'off',
                    inputType: 'search',
                    rules: ['required'],
                  }"
                >
                  <template #before-list>
                    <p
                      class="px-3 pt-1.5 pb-1 text-primary hover:bg-gray-100 font-medium"
                      @click="showCategoryModal(index)"
                    >
                      + {{ $t('Create new') }}
                    </p>
                  </template>
                </SelectElement>
              </ObjectElement>
            </template>
          </ListElement>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end">
              <hawk-button type="outlined" class="mr-3 font-semibold" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                :loading="is_loading"
                size="sm"
                name="submit"
                :submits="true"
              >
                {{ template ? $t('Update') : $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>

<style lang="scss" scoped>
:deep(.list_container) {
  #component_list-0-remove-button {
    @apply mt-7;
  }
}
</style>
