<script setup>
import { useRoute } from 'vue-router';

const props = defineProps({
  query: {
    default: { all_access: true, is_child: true, public: false },
  },
});
const route = useRoute();
const final_query = computed(() => {
  return { ...props.query, parent_form_uid: route.params.template_uid };
});
</script>

<template>
  <div>
    <form-instances-list :query="final_query" :has_sticky_header="true" class="mt-4" />
  </div>
</template>
