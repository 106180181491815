<script setup>
import { cloneDeep } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { nanoid } from 'nanoid';
import { useRoute } from 'vue-router';

import HawkCommentInput from '~/common/components/organisms/hawk-comment-input.vue';
import DocumentUploadForm from '~/dms/components/documents/forms/document-upload-form.vue';

const props = defineProps({
  type: {
    type: String,
    default: 'comment',
    validator: val => ['comment', 'reply'].includes(val),
  },
  comment: {
    type: Object,
    default: null,
  },
  chat_store: {
    type: Object,
    required: true,
  },
  comment_input_config: {
    type: Object,
    default: () => ({}),
  },
  user: {
    type: String,
    required: true,
  },
  asset_id: {
    type: String,
    default: null,
  },
  attachment_config: {
    type: Object,
    required: true,
  },
  append_mentions_to: {
    type: Element,
    default: null,
  },
  add_attachments_click: {
    type: Function,
    default: () => {},
  },
  module: {
    type: String,
    default: 'tasks',
  },
});

const emit = defineEmits([
  'close',
  'input',
  'reply',
  'commentSent',
  'commentUpdated',
  'commentPinned',
  'commentDeleted',
  'showMoreOptions',
  'uploadAttachments',
]);

const $toast = inject('$toast');
const $track_event = inject('$track_event');
const route = useRoute();

const files_to_attach = ref([]);

// COMMENT ATTACHMENT UPLOAD
const attachment_upload_modal = useModal({
  component: DocumentUploadForm,
});

function addFilesToAttach(files) {
  files_to_attach.value.push(...files);
}

function onRemoveAttachment(uuid) {
  files_to_attach.value = files_to_attach.value.filter(att => att.uuid !== uuid);
}

async function onAddAttachmentsClicked() {
  await props.add_attachments_click();
  attachment_upload_modal.patchOptions({
    attrs: {
      fields_list: [{ name: 'File', component: 'MultifileElement', auto: false }],
      attachment_config: props.attachment_config,
      submit: (form) => {
        attachment_upload_modal.close();
        const files = cloneDeep(form.data?.File) || [];
        files.forEach(file => file.uuid = crypto.randomUUID());
        if (files.length)
          addFilesToAttach(files);
      },
      onClose() {
        attachment_upload_modal.close();
      },
    },
  });
  attachment_upload_modal.open();
}

function onPastedFilesUploadComplete(result) {
  const successful_uuids = result.successful.map(thing => thing.data.uuid);
  const failed_uuids = result.failed.map(thing => thing.data.uuid);
  successful_uuids.forEach(onRemoveAttachment);
  failed_uuids.forEach(onRemoveAttachment);
  const files = result.successful.map((res) => {
    res.data.upload_pending = false;
    return res.data;
  });
  addFilesToAttach(files);
}

async function uploadAttachments(files, id) {
  try {
    return files.map((item) => {
      const request_payload = {
        name: item.name || '',
        location: item.location || null,
        file_name: item.name || '',
        file_size: item.size || 0,
        file_type: item.type || null,
        service: item.service_object,
        foreign_object_type: 'comment',
        foreign_object_uid: id,
        uid: item.uid,
      };
      return request_payload;
    });
  }
  catch (error) {
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
      position: 'bottom-right',
    });
    console.error(error);
    return [];
  }
}

async function updateComment({ text, mentions }) {
  const message = { ...props.comment, text, mentioned_user_list: mentions, service: props.module };
  await props.chat_store.updateMessage(message);
  emit('commentUpdated', { text, mentions });
}

async function deleteComment() {
  await props.chat_store.chat.client.deleteMessage(props.comment.id);
  emit('commentDeleted');
}

async function pinComment(pinned) {
  const message = { ...props.comment, pinned, service: props.module };
  await props.chat_store.updateMessage(message);
  emit('commentPinned', { pinned });
  $track_event('update_comment', { type: 'Pin' });
}

async function toggleReaction() {
  if (!props.comment?.own_reactions?.length)
    await props.chat_store.chat_channel.sendReaction(props.comment.id, {
      type: 'like',
      presence_members: { uid: props.user },
      service: props.module,
    });
  else
    await props.chat_store.chat_channel.deleteReaction(props.comment.id, 'like');
  $track_event('update_comment', { type: 'Like' });
}

async function onSend({ text, mentions }) {
  if (props.comment && props.type === 'comment') {
    updateComment({ text, mentions });
    emit('commentSent', { text, mentions });
    return;
  }

  const files = cloneDeep(files_to_attach.value);
  files_to_attach.value = [];
  files.forEach(file => file.uid = nanoid(11));

  const sent_chat = await props.chat_store.sendMessage({
    text,
    service: props.module,
    mentioned_user_list: mentions,
    attachment_uids: files.map(item => item.uid),
    ...(props.type === 'reply' && {
      parent_id: props.comment.id,
    }),
  });

  if (files.length) {
    const attachments = await uploadAttachments(files, sent_chat?.message?.id);
    emit('uploadAttachments', { comment_id: sent_chat?.message?.id, attachments });
  }
  emit('commentSent', { text, mentions, sent_chat, files });
}
</script>

<template>
  <HawkCommentInput
    v-bind="comment_input_config"
    focus_position="end"
    :files_to_attach="files_to_attach"
    :asset_id="asset_id"
    :uppy_attachment_config="attachment_config"
    :append_mentions_to="props.append_mentions_to"
    :is_pinned="props.comment?.pinned"
    @send="onSend"
    @input="$event => emit('input', $event)"
    @addAttachments="onAddAttachmentsClicked"
    @removeAttachment="onRemoveAttachment"
    @pastedFilesUploadComplete="onPastedFilesUploadComplete"
    @filesPaste="addFilesToAttach"
    @delete="deleteComment"
    @pin="pinComment(!props.comment.pinned)"
    @toggle-reaction="toggleReaction"
    @showMoreOptions="emit('showMoreOptions')"
    @close="emit('close')"
    @reply="emit('reply')"
  >
    <template #more_options>
      <slot name="more_options" />
    </template>
  </HawkCommentInput>
</template>
