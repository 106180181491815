<script>
import DOMPurify from 'dompurify';
import { useMap } from '~/common/composables/mapbox/maps';

export default {
  props: {
    assets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      map: null,
    };
  },
  watch: {
    assets() {
      this.reloadMap();
    },
  },
  mounted() {
    this.initMap();
  },
  beforeUnmount() {
    this?.map?.remove();
  },
  methods: {
    async initMap() {
      const layers = [{
        id: 'places',
        type: 'symbol',
        source: 'places',
        layout: {
          'icon-image': 'asset-image',
          'icon-allow-overlap': true,
          'icon-size': 0.6,
        },
      }];
      const { initMapbox, loadMapBoxPackage, addMapboxToken, setLayers, loadImages } = useMap({}, async (event_data, event_name) => {
        try {
          if (event_name === 'loaded') {
            loadImages(this.map, '/img/icons/asset.png');
            const features = this.getFeatures();
            loadImages(this.map, '/img/icons/location-pin-new.png', 'asset-image');
            this.map.addSource('places', {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features,
              },
            });
            setLayers(layers, this.map);
            this.setupPopup();
          }
        }
        catch (error) {
          logger.error('error in asset map view', error);
        }
      });

      await loadMapBoxPackage();
      await addMapboxToken();
      this.map = await initMapbox({
        container_id: 'mapp',
        style: 'mapbox://styles/mapbox/satellite-v8',
        center: [0, 20],
        zoom: 1,
      });
    },
    setupPopup() {
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      this.map.on('mouseenter', 'places', (e) => {
        this.map.getCanvas().style.cursor = 'pointer';
        const feature = e.features[0];
        const coordinates = feature?.geometry.coordinates.slice();
        const description = feature.properties.asset;
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180)
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;

        popup
          .setLngLat(coordinates)
          .setHTML(description)
          .addTo(this.map);
      });

      this.map.on('mouseleave', 'places', () => {
        this.map.getCanvas().style.cursor = '';
        popup.remove();
      });

      this.map.on('click', 'places', (e) => {
        this.$router.push({
          name: 'asset-dashboard',
          params: { asset_id: e.features[0].properties.uid },
        });
      });
    },
    getFeatures() {
      const features = this.assets.map((item) => {
        const coords = item.location?.coordinates;
        return {
          type: 'Feature',
          properties: {
            asset: this.getAssetHtml(item),
            uid: item.uid,

          },
          geometry: {
            type: 'Point',
            coordinates: coords ?? [],
          },
        };
      });
      return features;
    },
    reloadMap() {
      this.map.getSource('places').setData({
        type: 'FeatureCollection',
        features: this.assets.map((item) => {
          const coords = item.location?.coordinates;
          return {
            type: 'Feature',
            properties: {
              description: this.getAssetHtml(item),
              uid: item.uid,
            },
            geometry: {
              type: 'Point',
              coordinates: coords ? [coords[1], coords[0]] : [],
            },
          };
        }),
      });
    },
    getAssetHtml(item) {
      const sanitized_item_name = DOMPurify.sanitize(item.name);
      const sanitized_item_address = DOMPurify.sanitize(item.code) + item?.address?.name ? DOMPurify.sanitize(item?.address?.name) : '';
      const sanitized_item_code = (item.code || item?.address?.name) ? sanitized_item_address : 'NA';
      const sanitized_item_image = item?.cover_image?.url ? DOMPurify.sanitize(item?.cover_image?.url) : '../../../static/images/asset-placeholder.png';
      const item_code = `<p class="text-xs text-gray-500">#${sanitized_item_code}</p>`;
      return `
      <div>
        <img src="${sanitized_item_image}"    class="object-cover w-full h-32" />
        <p class="text-xs text-gray-500">
          ${sanitized_item_name}
        </p>
        <div>${item_code}</div>
        </div>
      `;
    },
  },
};
</script>

<template>
  <div id="mapp" />
</template>

<style scoped lang="scss">
  #mapp {
    height: calc(100vh - 210px);
    width: 100%;
    border-radius: 8px;
  }
</style>
