<script setup>
import { ref } from 'vue';
import Rules from '~/forms/components/form-workflows/sidebar/conditional-block/rule-fields.vue';

const props = defineProps({
  formatting_enabled: {
    type: Boolean,
    default: true,
  },
  filter: {
    type: Object,
    default: null,
  },
  columns: {
    type: Array,
    default: () => [],
  },
  on_save: {
    type: Function,
    default: null,
  },
  texts: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  is_filters_required: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['close', 'save']);

const get_filters_payload = ref({});
const form = ref({});
const rules_invalid = ref(false);
const colors = ref([
  { label: 'Green', value: '#3DD598' },
  { label: 'Yellow', value: '#F8C900' },
  { label: 'Blue', value: '#1676EE' },
  { label: 'Red', value: '#FA5959' },
  { label: 'Grey', value: '#9C9FB1' },
  { label: 'Black', value: '#000000' },
  { label: 'White', value: '#FFFFFF' },
]);

const get_columns = computed(() => {
  return props.columns.filter(val => val.uid !== 'table_checkbox');
});

function init() {
  if (props.filter)
    form.value = props.filter;
  else
    form.value = {
      name: '',
      condition: 'All',
      format: {
        apply_to: 'Entire row',
        font_style: [],
        text_color: '#000000',
        background_color: '#FFFFFF',
      },
      filters: [],
    };
}

async function save() {
  if (props.on_save)
    await props.on_save({
      name: form.value.name,
      value: {
        ...form.value,
        filters: get_filters_payload.value.filters(),
        uid: crypto.randomUUID(),

      },
    });
  else
    emit('save', {
      name: form.value.name,
      value: {
        ...form.value,
        filters: get_filters_payload.value.filters(),
        uid: crypto.randomUUID(),

      },
    });
}

init();
</script>

<template>
  <hawk-modal-container content_class="rounded-lg w-[900px]">
    <Vueform
      v-model="form" sync size="sm" :display-errors="false" :endpoint="save"
      :columns="{
        lg: { container: 12, label: 3, wrapper: 12 },
      }"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ texts?.heading?.length ? texts?.heading : $t('Custom Filter') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content class="max-h-[70vh] scrollbar">
          <TextElement name="name" :label="$t('Filter name')" class="mb-4" rules="required" />
          <RadiogroupElement
            name="condition"
            :label="$t('Filter for records where')"
            :items="[
              { value: 'All', label: $t('All of the following are true') },
              { value: 'Any', label: $t('Any of the conditions are met') },
            ]"
            default="All"
            class="mb-4"
          />
          <Rules
            name="filters"
            :options="{
              label: $t('Filters'),
            }"
            :is_required="is_filters_required"
            :rules="form.filters"
            :fields="get_columns"
            :workflow="false"
            @invalid="($event) => (rules_invalid = $event)"
            @updateRules="form.filters = $event"
            @getFilterPayload="get_filters_payload = $event"
          />
          <template v-if="formatting_enabled">
            <hr class="my-4">
            <div class="text-[16px] font-semibold mb-4">
              {{ $t('Formatting') }}
            </div>
            <ObjectElement
              name="format"
            >
              <div class="col-span-12 ">
                <CheckboxgroupElement
                  name="font_style"
                  class="mb-4"
                  :label="$t('Text style')"
                  :items="{
                    bold: 'B',
                    italic: 'I',
                    underline: 'U',
                  }"
                  :columns="{
                    lg: {
                      container: 12,
                      label: 3,
                      wrapper: 4,
                    },
                  }"
                  view="tabs"
                />
                <SelectElement
                  name="text_color"
                  :label="$t('Text color')"
                  :placeholder="$t('Select color')"
                  :native="false"
                  :items="colors"
                  default="#000000"
                  class="mb-4"
                >
                  <template #single-label="{ value }">
                    <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
                      <div class="w-3 h-3 rounded-full mr-2" :style="{ backgroundColor: value.value, border: `1px solid ${value.label === 'White' ? '#000' : '#fff'}` }" />
                      <div class="text-[14px]">
                        {{ value.label }}
                      </div>
                    </div>
                  </template>
                  <template #option="{ option }">
                    <div class="flex items-center">
                      <span class="w-3 h-3 rounded-full mr-2 ml-1" :style="{ backgroundColor: option.value, border: `1px solid ${option.label === 'White' ? '#000' : '#fff'}` }" />
                      <span class="text-[14px]">{{ option.label }}</span>
                    </div>
                  </template>
                </SelectElement>
                <SelectElement
                  name="background_color"
                  :label="$t('Background color')"
                  :placeholder="$t('Select color')"
                  :native="false"
                  :items="colors"
                  default="#FFFFFF"
                  class="mb-4"
                >
                  <template #single-label="{ value }">
                    <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
                      <div class="w-3 h-3 rounded-full mr-2" :style="{ backgroundColor: value.value, border: `1px solid ${value.label === 'White' ? '#000' : '#fff'}` }" />
                      <div class="text-[14px]">
                        {{ value.label }}
                      </div>
                    </div>
                  </template>
                  <template #option="{ option }">
                    <div class="flex items-center">
                      <span class="w-3 h-3 rounded-full mr-2 ml-1" :style="{ backgroundColor: option.value, border: `1px solid ${option.label === 'White' ? '#000' : '#fff'}` }" />
                      <span class="text-[14px]">{{ option.label }}</span>
                    </div>
                  </template>
                </SelectElement>
                <SelectElement
                  name="apply_to"
                  :label="$t('Apply to')"
                  :placeholder="$t('Select')"
                  :can-clear="false"
                  :can-deselect="false"
                  :native="false"
                  :items="[{ uid: 'Entire row', name: 'Entire row' }, ...get_columns]"
                  default="Entire row"
                  label-prop="name"
                  value-prop="uid"
                  class="mb-4"
                />
              </div>
            </ObjectElement>
          </template>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-4 font-bold"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true" :disabled="rules_invalid">
                {{ $t("Apply") }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
