<script setup>
/*
  Multi: true
    => Accepts asset_id, selected_instances, instances_format
    => Emits input event with a list of instances based on the provided instances_format
  Multi: false
    => Accepts target_element, update_function
    => Executes update_function with payload generated by payloadGenerator
    payload = {
      target_element,
      properties: {
        reference_name: location_name,
      },
      ...(centroid && { location: centroid?.geometry }),
    }
*/
import { useRoute, useRouter } from 'vue-router';
import { keyBy } from 'lodash-es';
import { useCommonStore } from '~/common/stores/common.store';

const props = defineProps({
  target_element: {
    type: Object,
    default: () => ({}),
  },
  asset_id: {
    type: String,
    default: '',
  },
  selected_instances: {
    type: Array,
    default: () => [],
  },
  instances_format: {
    type: String,
    default: 'uid',
    validator: value => ['uid', 'object'].includes(value),
  },
  options: {
    type: Object,
    default: () => ({}),
  },
  vueform_classes: {
    type: Object,
    default: () => ({}),
  },
  multi: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: 'Select locations',
  },
  update_function: {
    type: Function,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  allow_custom_locations: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['input', 'hierarchyData']);
const $services = inject('$services');
const $toast = inject('$toast');
const $t = inject('$t');

const route = useRoute();
const router = useRouter();
const common_store = useCommonStore();

const options = computed(() => {
  return {
    ...props.options,
    'add-classes': {
      ElementLabel: {
        container: 'mt-[7.6px]',
      },
      ElementLayout: {
        innerContainer: 'block',
        innerWrapperAfter: 'relative',
      },
      ...(props.vueform_classes?.['add-classes'] ? props.vueform_classes['add-classes'] : {}),
    },
    'remove-classes': {
      ElementLayout: {
        innerContainer: 'flex items-center',
      },
      ...(props.vueform_classes?.['remove-classes'] ? props.vueform_classes['remove-classes'] : {}),
    },
  };
});

const tree_dropdown = ref(null);
const loading = ref(false);
const name_array = ref([]);
const navigation_metadata = ref({});
const element_data = ref({});
const hierarchy_items = ref([]);
const selected_instances_map = ref({});
const show_dropdown = ref(false);
const data_loading = ref(false);

const show_navigator = computed(() => ['ISSUE', 'FEATURE', 'PROJECT', 'FOLDER', 'FILE'].includes(props.target_element?.type?.toUpperCase()));

const allow_location_change = computed(() => {
  if (route.params.asset_id || props.target_element?.asset || props.asset_id)
    return true;
  else return props.target_element.type === 'folder' || props.target_element.type === 'file';
});

onBeforeMount(async () => {
  data_loading.value = true;
  await getElementHierarchy();

  const asset = props.asset_id || props.target_element?.asset || route.params.asset_id;
  if (asset && !Object.keys(common_store.get_asset_meta(asset) || {}).includes('instances'))
    await common_store.set_asset_instances(asset);

  hierarchy_items.value = common_store.get_asset_meta(asset, 'instances') || [];

  if (props.selected_instances.length)
    if (props.instances_format === 'uid') {
      const selected_instances_list = findInstances();
      const custom_instances = props.selected_instances.filter(uid => uid.split('-')?.[1] === 'custom').map(uid => ({ name: uid.split('-')?.[0], uid }));
      selected_instances_map.value = { ...keyBy(selected_instances_list, 'uid'), ...keyBy(custom_instances, 'uid') };
    }
    else {
      selected_instances_map.value = keyBy(props.selected_instances, 'uid');
    }
  data_loading.value = false;
});

function findInstances() {
  const recursion = (list) => {
    let matchedNodes = [];
    list.forEach((item) => {
      if (props.selected_instances.includes(item.uid))
        matchedNodes.push(item);
      if (item?.children?.length) {
        const child_data = recursion(item.children);
        if (child_data.length)
          matchedNodes = [...matchedNodes, ...child_data];
      }
    });
    return matchedNodes;
  };

  return recursion(hierarchy_items.value);
}
function getName(hierarchy_data, element_asset) {
  if (hierarchy_data?.uid) {
    navigation_metadata.value = { ...navigation_metadata.value, [hierarchy_data.type]: hierarchy_data.uid };
    // Incase of type project we get module info in container data
    if (hierarchy_data.type === 'container')
      navigation_metadata.value.module = hierarchy_data.module;
    if (hierarchy_data.type === 'file' || hierarchy_data.type === 'folder')
      navigation_metadata.value.module = 'dms';
    if (hierarchy_data.type === 'asset' && element_asset?.name)
      name_array.value.push(element_asset.name);
    else
      name_array.value.push(hierarchy_data.name);
  }
  if (hierarchy_data?.child?.uid)
    return getName(hierarchy_data.child, element_asset);
  return null;
}

async function getElementHierarchy() {
  name_array.value = [];
  if (props.target_element?.uid) {
    const { data } = await $services.sm_instances.get_element_hierarchy({
      id: props.target_element.uid,
      stage: props.target_element.stage,
      query: {
        type: props.target_element.type,
      },
    });
    if (data) {
      emit('hierarchyData', data);
      navigation_metadata.value = {};
      element_data.value = data;
      const element_asset = common_store.get_asset(data.uid);
      getName(data, element_asset);
    }
  }
}

// Helper for getting tree items
async function showHierarchyDropdown() {
  if (props.disabled)
    return;

  const asset = props.asset_id || props.target_element?.asset || route.params.asset_id;
  hierarchy_items.value = common_store.get_asset_meta(asset, 'instances') || [];
  show_dropdown.value = true;
}

async function getReferenceVectors(location_name) {
  try {
    const { data } = await $services.features.get_reference_vectors({
      body: { names: [location_name], create_elements: false },
      asset_id: props.target_element?.asset,
    });
    const turf = (await import('@turf/turf'));
    if (data?.features?.length)
      return turf.centroid(data.features[0]);
    return null;
  }
  catch (error) {
    logger.log(error);
    return null;
  }
}

async function payloadGenerator(instance) {
  const { data } = await $services.sm_instances.get_element_for_instance({
    instance_uid: instance.key.split('-')[0],
    stage: 'system_model',
  });
  const location_name = (data.parents && Object.keys(data.parents).length) ? data.parents.name : instance.name;
  const centroid = await getReferenceVectors(location_name);
  return {
    target_element: data.element,
    properties: {
      reference_name: location_name,
    },
    ...(centroid && { location: centroid?.geometry }),
  };
}

function handleInstanceDelete(uid) {
  delete selected_instances_map.value[uid];
  tree_dropdown.value.setActiveValues();
  tree_dropdown.value.deleteCustomLocation(uid);
  emit('input', Object.keys(selected_instances_map.value));
}

async function updateLocation(e) {
  try {
    if (props.update_function) {
      loading.value = true;
      const payload = await payloadGenerator(e);
      await props.update_function(payload);
      await getElementHierarchy();
      loading.value = false;
    }
    else {
      selected_instances_map.value = {};
      e.forEach((instance) => {
        const key = instance.key.split('-')?.[0];
        const is_custom = instance.key.split('-')?.[1] === 'custom';
        if (is_custom)
          selected_instances_map.value[instance.key] = instance;
        else
          selected_instances_map.value[key] = instance;
      });
      if (props.instances_format === 'uid')
        emit('input', Object.keys(selected_instances_map.value));
      else
        emit('input', Object.values(selected_instances_map.value));
    }
  }
  catch (error) {
    logger.log(error);
    loading.value = false;
  }
}

function navigateToLocation() {
  if (navigation_metadata.value.module === 'dms') {
    if (props?.target_element?.stage?.toUpperCase() === 'DMS')
      router.push({
        name: 'dms', params: { asset_id: navigation_metadata.value.asset }, query: { folder: navigation_metadata.value.folder || null, document: navigation_metadata.value.file || null },
      });
    else
      router.push({
        name: 'files', params: { asset_id: navigation_metadata.value.asset }, query: { folder: navigation_metadata.value.folder || null, file: navigation_metadata.value.file || null },
      });
  }

  else if (!navigation_metadata.value?.container) {
    $toast({
      title: $t('Location not found'),
      text: $t('Can not navigate to the location. You don\'t have access, or the location is no longer available'),
      type: 'warning',
      position: 'bottom-right',
    });
  }

  else {
    const route_map = {
      TERRA: 'terra-viewer',
      THERM: 'therm',
    };
    const metadata = btoa(JSON.stringify(navigation_metadata.value));
    const fly_to = route_map[(navigation_metadata.value.module || props.target_element.stage).toUpperCase()];
    router.push({
      name: fly_to,
      params: {
        asset_id: navigation_metadata.value.asset,
        id: navigation_metadata.value.container,
      },
      query: {
        metadata,
      },
    });
  }
}
</script>

<template>
  <StaticElement
    v-if="allow_location_change"
    v-bind="options"
  >
    <template #default>
      <div
        class="min-h-[34px] pl-1.5 pr-1 py-1 grid grid-cols-12 hover:bg-gray-50 rounded-lg cursor-pointer group border border-transparent"
        :class="{
          'outline outline-3 outline-primary-100 !border-primary-500': show_dropdown,
          'cursor-inherit hover:bg-white': disabled,
        }"
        @click="showHierarchyDropdown"
      >
        <div class="col-span-10 flex items-center">
          <div v-if="multi" class="flex items-center flex-wrap">
            <slot name="trigger">
              <div v-if="Object.keys(selected_instances_map).length">
                <div v-for="(value, key) in selected_instances_map" :key="key" class="inline-flex rounded-md border px-1 py-0.5 mr-1 mb-1">
                  {{ value?.name }}
                  <div class="ml-2 hover:bg-gray-100 rounded" @click.stop="handleInstanceDelete(key)">
                    <IconHawkX v-if="show_dropdown" class="text-[17px] text-gray-600" />
                  </div>
                </div>
              </div>
              <p v-else class="text-gray-500 pl-1">
                {{ $t(placeholder) }}
              </p>
            </slot>
          </div>
          <element-hierarchy-breadcrumb
            v-else-if="name_array.length"
            :name_array="name_array"
            :loading="loading"
          />
          <p v-else class="text-gray-500 pl-1">
            {{ $t('Select location') }}
          </p>
        </div>
        <div class="col-span-2 flex items-center justify-end">
          <hawk-loader
            v-if="data_loading"
            container_class="w-6"
            :width="4"
            :height="4"
          />
          <IconHawkChevronDown
            v-else
            class="text-gray-500 transition-transform invisible group-hover:visible mr-1.5"
            :class="{ 'rotate-180 !visible': show_dropdown, '!hidden': disabled }"
          />
        </div>
      </div>
    </template>
    <template #after>
      <element-hierarchy-dropdown
        v-if="show_dropdown"
        ref="tree_dropdown"
        :element_data="element_data"
        :hierarchy_items="hierarchy_items"
        :selected_instances="selected_instances_map"
        :allow_custom_locations="allow_custom_locations"
        :multi="multi"
        @selected="updateLocation"
        @close="show_dropdown = false"
      />
      <p
        v-if="show_navigator"
        class="text-primary-700 text-xs font-semibold ml-2"
        :class="[data_loading ? 'pointer-events-none opacity-60' : 'pointer-events-auto cursor-pointer']"
        @click="navigateToLocation"
      >
        {{ props.target_element.type === 'folder' || props.target_element.type === 'file' ? $t('View location') : $t('View on map') }}  <IconHawkChevronRight aria-hidden="true" class="w-[16px] h-[16px] inline" />
      </p>
    </template>
  </StaticElement>
</template>
