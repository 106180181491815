<script setup>
const props = defineProps({
  fields: { type: Array, default: null },
  aggregation_fields: { type: Array, default: () => ([]) },
  save: { type: Function, default: () => true },
});

const emit = defineEmits(['close']);

const form$ = ref({});

const aggregation_fields = computed(() => {
  return props.fields.filter(col => col.can_aggregate && col.accessorKey !== 'stock_quantity' && ['text', 'number', 'dropdown', 'date'].includes(col.type));
});

async function save() {
  await props.save(form$.value.data);
  emit('close');
}

onMounted(() => {
  form$.value.load({
    fields: props.aggregation_fields,
  }, true);
});
</script>

<template>
  <HawkModalContainer content_class="w-[400px] rounded-lg">
    <Vueform
      ref="form$"
      size="sm"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { label: 4 },
        md: { label: 4 },
        lg: { label: 4 },
      }"
      :format-load="(data) => data"
      :endpoint="save"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            {{ $t('Aggregate') }}
          </template>
        </HawkModalHeader>
        <HawkModalContent :is_scroll="false">
          <TagsElement
            name="fields"
            :label="$t('Fields')"
            input-type="search"
            autocomplete="off"
            label-prop="header"
            value-prop="id"
            object
            :close-on-select="false"
            :track-by="['header']"
            :items="aggregation_fields"
            :search="true"
            :native="false"
            :can-clear="true"
            :can-deselect="false"
            :placeholder="$t('Choose fields to aggregate')"
          />
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end gap-4">
              <HawkButton type="outlined" @click="emit('close')">
                {{ $t('Close') }}
              </HawkButton>
              <ButtonElement submits size="sm" name="submit" :button-label="$t('Save')" button-class="vf-btn-primary" />
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
