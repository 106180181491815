<script>
import { Engine, Rule } from 'json-rules-engine';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { cloneDeep, debounce, isEqual, isNumber, isString, keyBy, pick } from 'lodash-es';
import dompurify from 'dompurify';
import { useRouter } from 'vue-router';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { load_js_css_file } from '~/common/utils/load-script.util.js';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import FormTemplateResponseOverviewHeader from '~/forms/pages/form-template-detail/overview/form-template-detail-responses-overview-molecules/response-overview-header.vue';
import HawkPagination from '~/common/components/molecules/hawk-pagination.vue';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useAuthStore } from '~/auth/stores/auth.store';
import { csvInjectionProtector, getUserFullName } from '~/common/utils/common.utils.js';
import { $date } from '~/common/utils/date.util';
import { useJsonRuleEngine } from '~/common/composables/json-engine.js';
import { useFormsStore } from '~/forms/store/forms.store.js';

const public_attachment_link_url = import.meta.env.VITE_APP_ATTACHMENTS_PUBLIC_URL;

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
export default {
  components: {
    FormTemplateResponseOverviewHeader,
    HawkPagination,
  },
  setup() {
    const form_template_detail_store = useFormTemplateDetailStore();
    const services = inject('$services');
    const forms_store = useFormsStore();
    const common_store = useCommonStore();
    const auth_store = useAuthStore();
    const router = useRouter();
    const {
      stringContainsOperator,
      startsWithOperator, endsWithOperator,
      numberEqualsOperator, numberNotEqualsOperator,
      equalOperator, notEqualOperator, arrayEqualsOperator, arrayNotEqualsOperator,
      doesNotContainOperator, arrayInOperator, arrayNotInOperator,
    } = useJsonRuleEngine();

    return {
      form_template_detail_store,
      services,
      common_store,
      router,
      forms_store,
      auth_store,
      stringContainsOperator,
      startsWithOperator,
      endsWithOperator,
      numberEqualsOperator,
      numberNotEqualsOperator,
      equalOperator,
      notEqualOperator,
      arrayEqualsOperator,
      arrayNotEqualsOperator,
      doesNotContainOperator,
      arrayInOperator,
      arrayNotInOperator,
    };
  },

  data() {
    return {
      action_items: {
        all_submissions: {
          name: 'All submissions',
          action: 'update',
          default: true,

          value: {
            name: 'All submissions',
            value: 'all_submissions',
          },
        },

        read_submissions: {
          name: 'Read submissions',
          action: 'update',
          default: true,
          value: {
            name: 'Read submissions',
            value: 'read_submissions',
          },
        },
        unread_submissions: {
          name: 'Unread submissions',
          action: 'update',
          default: true,
          value: {
            name: 'Unread submissions',
            value: 'unread_submissions',
          },
        },
      },
      info: {
        bottom: {
          header: 'There are currently no submissions for you to see yet.',

          description:
              'You can view all submissions made against your form here',
          icon_size: 'is-40x40',
          icon_name: 'empty-form',
        },
      },
      active_filter: {
        name: 'All submissions',
      },
      last_checked_index: -1,
      rules: [],
      is_modal_active: false,
      settings_action_items: [
        { name: 'Table settings', action: 'table-settings' },
        { name: 'Condition settings', action: 'condition-settings' },
      ],
      hands_on_table: null,
      isAllChecked: false,
      search: '',
      hidden_columns: [],
      data: [],
      visible_columns: [],
      conditional_formatting: [],
      filters: [],
      rule_list: [],
      selected_rows: {},
      json_engine: null,
      is_loading: false,
      unique_column_ids: [],
      open_filter: false,
      is_full_screen_active: false,
      include_ongoing_submission: false,
      pagination_state: {
        pageIndex: 0,
        pageSize: 25,
      },
      pagination_config: {
        totalRows: null,
      },
      is_exporting: false,
      export_type: null,
    };
  },

  computed: {
    form_details() {
      return this.form_template_detail_store?.form_template_detail;
    },
    get_selected_rows() {
      return Object.values(this.selected_rows);
    },
    filtered_data() {
      return this.data.filter(form => form.form_name.toLowerCase().includes(this.search.toLowerCase()));
    },
    has_data() {
      return !!this.filtered_data.length;
    },
    has_filter_applied() {
      return !!this.search;
    },
    is_boarding() {
      return !this.has_data && !this.has_filter_applied;
    },
    has_no_results() {
      return !this.has_data && this.has_filter_applied;
    },
    show_table() {
      return !this.is_loading && !this.has_filter_applied && !this.is_boarding;
    },
    is_pagination_enabled() {
      return this.default_pagination_config && (this.default_pagination_config.totalRows > this.default_pagination_config.noOfRows[0]);
    },
    default_pagination_config() {
      const config = {
        noOfRows: [10, 25, 50, 100, 200],
        rowSize: 10,
        totalRows: null,
      };
      if (this.pagination_config)
        return {
          ...config,
          ...this.pagination_config,
        };

      return null;
    },
  },
  watch: {
    async include_ongoing_submission() {
      let load_table = true;
      if (this.data.length)
        load_table = false;
      this.$set(this.pagination_state, 'pageIndex', 0);

      await this.filterTable(this.active_filter);
      if (this.data.length && load_table)
        this.loadJsonEngine();

      this.destroyTable();
      await this.loadHandsOn();
    },
  },
  beforeUnmount() {
    this.form_template_detail_store.responses_count = 0;
  },
  async mounted() {
    try {
      this.is_loading = true;
      await load_js_css_file(
        'https://cdn.jsdelivr.net/npm/handsontable@13.1.0/dist/handsontable.full.min.js',
        'handsontable-js',
        'js',
      );
      await load_js_css_file(
        'https://cdn.jsdelivr.net/npm/handsontable@13.1.0/dist/handsontable.full.min.css',
        'handsontable-css',
        'css',
      );
      await this.getData();
      if (this.data.length) {
        this.loadJsonEngine();
        this.loadHandsOn();
      }
      this.is_loading = false;
    }
    catch (err) {
      logger.log(err);
      this.is_loading = false;
    }
  },
  methods: {
    onFullScreenClicked(value) {
      this.is_full_screen_active = !this.is_full_screen_active;
      if (value)
        document.querySelector('.is-loader-container').requestFullscreen();
      else document.exitFullscreen();
    },
    destroyTable() {
      if (this.hands_on_table) {
        this.hands_on_table.destroy();
        this.hands_on_table = null;

        const container = document.getElementById('checklist-items-handson');
        container.innerHTML = ''; // Make the element empty
      }
    },
    async getData() {
      const filter_payload = this.generateFilterPayload(this.active_filter);
      const { data, headers } = await this.services.forms.post({
        attribute: 'submissions/responses',
        body: {
          page_number: this.pagination_state.pageIndex + 1,
          page_size: this.pagination_state.pageSize,
          filters: {
            ...filter_payload.default,
            ...(this.search ? { q: this.search } : {}),
            ongoing: this.include_ongoing_submission,
            organization: this.form_details.organization,
            asset_uid: this.$route.params.asset_id || [],
            template: this.$route.params.template_uid,
            advanced_filter: {
              ...filter_payload.advanced,
            },
          },
          fields: [],
        },
      });
      this.pagination_config.totalRows = headers['x-total-count'];
      this.unique_column_ids = [];
      this.data = data.submissions.map(item => ({
        ...item,
        ...keyBy(
          item.values.map((i) => {
            if (!this.unique_column_ids.includes(i.uid))
              this.unique_column_ids.push(i.uid);
            return {
              ...i,
              value: dompurify.sanitize(i.value, { ALLOWED_TAGS: [] }),
            };
          }),
          'uid',
        ),
        select: false,
      }));
      this.form_template_detail_store.responses_count = headers['x-total-count'];
    },
    open_form_submission_route(extra_query = {}) {
      let query = Object.assign({}, this.$route.query);
      query = { ...query, ...extra_query };
      this.router.push({ query });
    },
    async updateColumnsVisibility({ visible_columns, hidden_columns }) {
      this.hidden_columns = hidden_columns;
      this.orderColumns(visible_columns);
      await this.form_template_detail_store.update_form_details({
        body: {
          filters: {
            ...(this.form_details.filters && {
              ...this.form_details.filters,
            }),
            visible_columns: visible_columns.map(item => item.uid),

          },
        },
        suppressToast: true,
      });
    },
    generateFilterPayload(active_filter) {
      const default_filter_map = {
        'All submissions': {},
        'Unread submissions': {
          viewed: false,
        },
        'Read submissions': {
          viewed: true,
        },
        'Rolled back': {
          rolled_back: true,
        },
      };

      const advanced_filters = {};

      if (active_filter.condition && active_filter.filters) {
        const condition_type_map = {
          All: 'and',
          Any: 'or',
        };
        advanced_filters.logic = { type: condition_type_map[active_filter.condition] };
        advanced_filters.rules = active_filter.filters;
      }

      return {
        default: {
          ...(active_filter.name && default_filter_map[active_filter.name] ? default_filter_map[active_filter.name] : {}),
        },
        advanced: {
          ...advanced_filters,
        },
      };
    },
    async getFilteredData(e) {
      this.is_loading = true;
      await this.getData();
      this.is_loading = false;

      return this.data;
    },
    async filterTable(e = {}, format = true) {
      try {
        this.selected_rows = {};
        this.clearCheckbox();
        this.active_filter = e;
        const filteredData = await this.getFilteredData(e);
        this.hands_on_table.loadData(filteredData);
        if (format || e.name === 'All submissions')
          await this.formatConditionally(false, filteredData);
      }
      catch (err) {
        logger.error(err);
      }
    },

    loadJsonEngine() {
      this.json_engine = new Engine([], { allowUndefinedFacts: false });
      const getFormattedDate = (value, format = 'YYYY-MM-DD') => {
        const formats = [
          'YYYY-MM-DDTHH:mm:ss.SSSZ',
          'DD-MM-YYYY HH:mm',
          'DD-MM-YYYY',
          'YYYY-MM-DD HH:mm',
          'YYYY-MM-DD',
          'HH:mm',
          // Add more formats as needed
        ];

        for (const input_format of formats) {
          const parsedDate = dayjs(value, input_format);
          if (parsedDate.isValid())
            return input_format.includes('HH:mm') ? parsedDate : parsedDate.format(format);
        }
      };
      this.json_engine.addOperator('earlierThan', (factValue, jsonValue) => {
        if (factValue && jsonValue) {
          const factDate = getFormattedDate(factValue);
          const jsonDate = getFormattedDate(jsonValue);

          return dayjs(factDate).isBefore(jsonDate);
        }
        else { return false; }
      });
      this.json_engine.addOperator('laterThan', (factValue, jsonValue) => {
        if (factValue && jsonValue) {
          const factDate = getFormattedDate(factValue);
          const jsonDate = getFormattedDate(jsonValue);
          return dayjs(factDate).isAfter(jsonDate);
        }
        else { return false; }
      });
      this.json_engine.addOperator('between', (factValue, jsonValue) => {
        if (factValue && jsonValue) {
          const is_date = Number.isNaN(Number(factValue));
          if (is_date) {
            const factDate = getFormattedDate(factValue);
            const jsonDateStart = getFormattedDate(jsonValue[0]);
            const jsonDateEnd = getFormattedDate(jsonValue[1]);

            return dayjs(factDate).isBetween(jsonDateStart, jsonDateEnd);
          }
          else {
            const num_val = Number(factValue);
            const min_num = Number(jsonValue[0]);
            const max_num = Number(jsonValue[1]);

            return num_val >= min_num && num_val <= max_num;
          }
        }
        else { return false; }
      });
      this.json_engine.addOperator('notBetween', (factValue, jsonValue) => {
        if (factValue && jsonValue) {
          const factDate = getFormattedDate(factValue);
          const jsonDateStart = getFormattedDate(jsonValue[0]);
          const jsonDateEnd = getFormattedDate(jsonValue[1]);

          return !dayjs(factDate).isBetween(jsonDateStart, jsonDateEnd);
        }
        else { return false; }
      });

      this.json_engine.addOperator('stringContains', this.stringContainsOperator);
      this.json_engine.addOperator('stringDoesNotContains', this.doesNotContainOperator);
      this.json_engine.addOperator('startsWith', this.startsWithOperator);
      this.json_engine.addOperator('endsWith', this.endsWithOperator);
      this.json_engine.addOperator('numberEquals', this.numberEqualsOperator);
      this.json_engine.addOperator('numberNotEquals', this.numberNotEqualsOperator);

      const formatValue = (value) => {
        if (value && !Array.isArray(value))
          return value.split(',');
        return value;
      };
      this.json_engine.addOperator('contains', (factValue, jsonValue) => {
        if (factValue && jsonValue)
          return isEqual(formatValue(factValue), formatValue(jsonValue));
        return false;
      });
      this.json_engine.addOperator('doesNotContain', (factValue, jsonValue) => this.doesNotContainOperator(formatValue(factValue), jsonValue));
      this.json_engine.addOperator('arrayIn', (factValue, jsonValue) => this.arrayInOperator(formatValue(factValue), jsonValue));
      this.json_engine.addOperator('arrayNotIn', (factValue, jsonValue) => this.arrayNotInOperator(formatValue(factValue), jsonValue));

      this.json_engine.addOperator('equal', (factValue, jsonValue) => this.equalOperator(formatValue(factValue), jsonValue));
      this.json_engine.addOperator('notEqual', (factValue, jsonValue) => this.notEqualOperator(formatValue(factValue), jsonValue));
      this.json_engine.addOperator('arrayEquals', (factValue, jsonValue) => this.arrayEqualsOperator(formatValue(factValue), jsonValue));
      this.json_engine.addOperator('arrayNotEquals', (factValue, jsonValue) => this.arrayNotEqualsOperator(formatValue(factValue), jsonValue));

      this.json_engine.addOperator('has attachments', (factValue, jsonValue) => {
        return factValue === jsonValue;
      });
    },
    async formatConditionally(
        skipCalculation = false,
        responses_data = this.data,
    ) {
      let rulePassed = false;
      const data = cloneDeep(responses_data);

      const self = this;
      if (!skipCalculation) {
        const rules = {
          entire_row: this.rule_list.filter(
            item => item.value.format?.apply_to === 'Entire row',
          ),
          columns: this.rule_list.filter(
            item => item.value.format?.apply_to !== 'Entire row',
          ),
        };

        const columnSpecificFormatting = async (
          rules,
          formatting_on = '',
        ) => {
          for (let index = 0; index < responses_data.length; index++) {
            const element = responses_data[index];

            rulePassed = false;

            for (let ruleIndex = 0; ruleIndex < rules.length; ruleIndex++) {
              const rule = cloneDeep(rules[ruleIndex].value);
              if (formatting_on === 'column')
                if (
                  data[index].format
                    && data[index].format.hasOwnProperty(
                      rule.format?.apply_to,
                    )
                    && data[index].format[rule.format?.apply_to]?.formatted_by
                      === 'column'
                )
                  continue;
                else
                  rulePassed = false;

              if (!rulePassed) {
                const options = {
                  conditions: {
                    [rule?.condition.toLowerCase()]: rule.filters.map(
                      (item) => {
                        return pick(item, ['fact', 'operator', 'value']);
                      },
                    ),
                  },
                  event: {
                    type: 'filters',
                    params: {
                      message: 'Filter passed',
                    },
                  },
                };

                const ruleJson = new Rule(options);

                this.json_engine.addRule(ruleJson);
                const facts = {
                  form_name: element.form_name.trim(),
                  submitted_by: element.submitted_by,
                  submitted_at: element.submitted_at,
                  reference_name: element.reference_name || '',
                };

                rule.filters.forEach((filter) => {
                  if (
                    !facts.hasOwnProperty(filter.uid)
                      && filter.fact !== 'form_name'
                      && filter.fact !== 'submitted_by'
                      && filter.fact !== 'submitted_at'
                      && filter.fact !== 'reference_name'
                  )
                    if (element?.[filter?.fact]?.type === 'member')
                      facts[filter.fact] = element[filter.fact]?.raw_data || [];
                    else
                      facts[filter.fact] = element[filter.fact]?.value;
                });

                const {
                  results,
                } = await this.json_engine.run(facts);
                this.json_engine.removeRule(ruleJson);

                if (results.length) {
                  rulePassed = true;
                  if (rule.format.apply_to === 'Entire row') {
                    const columnHash = keyBy(self.visible_columns, 'name');

                    Object.keys(columnHash).forEach((key) => {
                      if (key !== 'checkbox_selection')
                        data[index].format = {
                          ...data[index].format,

                          [key]: { ...rule.format, formatted_by: 'row' },
                        };
                    });
                  }
                  else {
                    data[index].format = {
                      ...data[index].format,
                      [rule.format.apply_to]: {
                        ...rule.format,
                        formatted_by: 'column',
                      },
                    };
                  }
                }
                else {
                  continue;
                }
              }
              else {
                rulePassed = false;
                break;
              }
            }
          }
        };
        if (rules.entire_row.length)
          await columnSpecificFormatting(rules.entire_row, 'row');

        if (rules.columns.length)
          await columnSpecificFormatting(rules.columns, 'column');
      }
      const columnHash = keyBy(self.visible_columns, 'uid');

      function dataRenderer(
        instance,
        td,
        row,
        col,
        prop,
        value,
        cellProperties,
      ) {
        // eslint-disable-next-line @typescript-eslint/no-invalid-this, prefer-rest-params
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        const colHeaders = self.hands_on_table.getColHeader(col);
        if (data[row] && data[row].seen)
          td.style.backgroundColor = '#f0f1f3';
        else if (data[row] && !data[row].seen)
          td.style.fontWeight = 'bold';

        if (prop === 'submitted_by') {
          const user = self.common_store.get_org_or_internal_user(value);
          td.innerHTML = dompurify.sanitize(user ? getUserFullName(user) : '-');
        }
        else if (prop === 'submitted_at') {
          td.innerHTML = value
            ? $date(value, 'MMM Do YYYY, h:mm a')
            : '-';
        }
        else if (prop === 'reference_name') {
          const rowData = data[row];

          td.innerHTML
              = dompurify.sanitize(rowData?.attached_to?.reference_name
              || rowData?.attached_to?.name
              || rowData?.reference_name
              || '-');
        }

        else if (
          columnHash[prop.split('.value')[0]]
            && columnHash[prop.split('.value')[0]].type === 'attachment'
        ) {
          const if_signature
              = columnHash[prop.split('.value')[0]].element_type === 'signature';

          td.innerHTML = value
            ? `<div class="attachment-link">View ${
                  if_signature
                    ? 'signature'
                    : `${value} ${value === 1 ? 'attachment' : 'attachments'}`
                }</div>`
            : '-';
        }
        else {
          td.innerHTML = value || '-';
        }
        if (
          data[row]
            && data[row].format
            && data[row].format.hasOwnProperty(colHeaders)
        )
          formatCell(colHeaders);

        function formatCell(columnName) {
          td.style.backgroundColor
              = data[row].format[columnName].background_color ?? '';
          td.style.color = data[row].format[columnName].text_color ?? '';

          if (
            data[row].format[columnName].font_style.includes('underline')
          )
            td.style.textDecoration = 'underline';
          if (data[row].format[columnName].font_style.includes('bold'))
            td.style.fontWeight = 'bold';
          if (data[row].format[columnName].font_style.includes('italic'))
            td.style.fontStyle = 'italic';
        }
      }
      function checkboxRenderer(
        instance,
        td,
        row,
        col,
        prop,
        value,
        cellProperties,
      ) {
        // eslint-disable-next-line @typescript-eslint/no-invalid-this, prefer-rest-params
        Handsontable.renderers.CheckboxRenderer.apply(this, arguments);
        if (data[row] && data[row].seen)
          td.style.backgroundColor = '#f0f1f3';
        else if (data[row] && !data[row].seen)
          td.style.fontWeight = 'bold';
      }
      const dataSchema = {};
      const hashMap = keyBy(this.visible_columns, 'order_index');
      Object.values(hashMap).forEach((item) => {
        dataSchema[item.uid] = null;
      });
      this.hands_on_table.updateSettings({
        cells(row, col) {
          const cellProperties = {};
          if (col > 0)
            cellProperties.renderer = dataRenderer; // uses function directly
          else
            cellProperties.renderer = checkboxRenderer;

          return cellProperties;
        },
      });
    },
    async onSubmissionClick(data, field_uid = '') {
      this.openFormSubmission(
        data.form_uid,
        field_uid,
        data.uid,
        data?.attached_to?.object,
      );
      const clone = cloneDeep(this.data);

      const index = this.data.findIndex(item => item.uid === data.uid);

      if (index !== -1) {
        clone[index].seen = true;
        this.data = [...clone];
      }
      await this.filterTable(this.active_filter, false);
    },
    updatedRuleList(e) {
      this.rule_list = e;
      this.formatConditionally(false, this.data);
    },

    async exportRows() {
      const data = this.get_selected_rows.length
        ? this.get_selected_rows
        : await this.getFilteredData(this.active_filter);

      const static_columns = [
        'Form name',
        'Submitted by',
        'Submitted at',
        'Attached to',
        'Reason',
      ];
      const json = data.map((item) => {
        const user = this.common_store.get_org_or_internal_user(item.submitted_by);
        const csvRow = {
          'Form name': item.form_name,
          'Submitted by': user ? getUserFullName(user) : 'NA',
          'Submitted at': $date(item.submitted_at,
            'MMM Do YYYY, h:mm a',
          ),
          'Attached to':
              item.attached_to?.reference_name
              || item.attached_to?.name
              || item.reference_name
              || '',
          'Reason': item.message,
        };

        this.visible_columns.forEach((value) => {
          if (
            !static_columns.includes(value.name)
              && value.type !== 'attachment'
          )
            csvRow[value.name] = item[value.uid] && item[value.uid].value;
        });

        return csvRow;
      });
      const final = Papa.unparse(json, {
        header: true,
        escapeFormulae: true,
      });
      const csvData = new Blob([final], { type: 'text/csv;charset=utf-8;' });
      saveAs(
        csvData,
          `${this.form_details.name
            .toLowerCase()
            .split(' ')
            .join('_')}`,
      );
    },
    orderColumns(data) {
      const columns = data.filter(val => val.uid);

      const hashMap = keyBy(columns, 'order_index');

      this.visible_columns = columns;

      const dataSchema = {};

      Object.values(hashMap).forEach((item) => {
        dataSchema[item.uid] = null;
      });

      this.hands_on_table.updateSettings({
        columns: (index) => {
          if (index === 0)
            return {
              data: 'select',
              type: 'checkbox',
              columnSorting: {
                indicator: false,
                headerAction: false,
                compareFunctionFactory(sortOrder, columnMeta) {
                  return (value, nextValue) => 0; // Don't sort the first visual column.
                },
              },
            };
          else if (index < columns.length)
            return {
              ...hashMap[index].column,
            };
        },
        dataSchema,
        colHeaders: (index) => {
          if (index === 0)
            return `<input type="checkbox" class="checker" style="appearance:auto;" ${
                this.isAllChecked ? 'checked="checked"' : ''
              }>`;

          return hashMap[index]?.name;
        },
      });
    },
    userNameRenderer(
      hotInstance,
      td,
      row,
      column,
      prop,
      value,
      cellProperties,
    ) {
      // Optionally include `BaseRenderer` which is responsible for
      // adding/removing CSS classes to/from the table cells.
      // eslint-disable-next-line prefer-rest-params
      Handsontable.renderers.HtmlRenderer.apply(this, arguments);
      const user = this.common_store.get_org_or_internal_user(value);
      td.innerHTML = dompurify.sanitize(user ? getUserFullName(user) : 'NA');
    },
    openFormSubmission(uid, field_uid = '', submissionUid, ticketUid) {
      this.open_form_submission_route({
        form: btoa(JSON.stringify({
          form_uid: uid,
          attachment_field: field_uid,
          submission_uid: submissionUid,
          ticket_uid: ticketUid,
        })),
      });
    },
    dateRenderer(hotInstance, td, row, column, prop, value, cellProperties) {
      // eslint-disable-next-line prefer-rest-params
      Handsontable.renderers.DateRenderer.apply(this, arguments);
      td.innerHTML = value ? $date(value, 'DD/MM/YYYY') : 'NA';
    },
    dateTimeRenderer(
      hotInstance,
      td,
      row,
      column,
      prop,
      value,
      cellProperties,
    ) {
      // eslint-disable-next-line prefer-rest-params
      Handsontable.renderers.DateRenderer.apply(this, arguments);
      td.innerHTML = value
        ? $date(value, 'MMM Do YYYY, h:mm a')
        : 'NA';
    },
    attachmentRenderer(
      hotInstance,
      td,
      row,
      column,
      prop,
      value,
      cellProperties,
    ) {
      // eslint-disable-next-line prefer-rest-params
      Handsontable.renderers.HtmlRenderer.apply(this, arguments);
      let sanitized_value;
      if (value)
        sanitized_value = DOMPurify.sanitize(value);
      td.innerHTML = value
        ? `<div class="attachment-link">View ${sanitized_value} ${
              value === 1 ? 'attachment' : 'attachments'
            }</div>`
        : 'NA';
    },
    clearCheckbox() {
      const container = document.getElementById('checklist-items-handson');
      container.querySelectorAll('input[type=checkbox]')
        .forEach(elem => elem.checked = false);
    },
    async loadHandsOn() {
      const container = document.getElementById('checklist-items-handson');
      const self = this;
      const columns = {
        table_checkbox: {
          order_index: 0,
          uid: 'table_checkbox',
          name: 'checkbox_selection',
          type: '',
          hidden_in_settings: true,
          column: {
            data: 'select',

            renderer: 'html',
            columnSorting: {
              indicator: false,
              headerAction: false,
              compareFunctionFactory(sortOrder, columnMeta) {
                return (value, nextValue) => 0; // Don't sort the first visual column.
              },
            },
          },
        },
        form_name: {
          name: 'Form name',
          order_index: 1,
          uid: 'form_name',
          type: 'text',
          column: {
            data: 'form_name',

            renderer: 'html',
          },
        },
        submitted_by: {
          name: 'Submitted by',
          order_index: 2,
          uid: 'submitted_by',
          type: 'users',
          column: {
            data: 'submitted_by',

            renderer: 'user-renderer',
          },
        },
        submitted_at: {
          name: 'Submitted at',
          order_index: 3,
          uid: 'submitted_at',
          type: 'date_time',
          column: {
            data: 'submitted_at',

            renderer: 'date-time-renderer',
          },
        },
        reference_name: {
          name: 'Attached to',
          order_index: 4,
          uid: 'reference_name',
          type: 'text',
          column: {
            data: 'reference_name',

            renderer: 'html',
          },
        },
        reason: {
          name: 'Reason',
          order_index: 5,
          uid: 'reason',
          type: 'text',
          column: {
            data: 'message',
            renderer: 'html',
          },
        },
      };

      for (
        let index = 0;
        index < this.form_details.sections.length;
        index++
      ) {
        const element = this.form_details.sections[index];
        if (element.type === 'default' || element.type === 'signature')
          for (
            let fieldIndex = 0;
            fieldIndex < element.fields.length;
            fieldIndex++
          ) {
            const field = element.fields[fieldIndex];

            if (
              field.type !== 'check-item'
                && field.type !== 'group'
                && field.properties.type !== 'info'
                && (field.name.length || element.type === 'signature')
                 && this.unique_column_ids.includes(field.uid)
            )
              columns[field.uid] = {
                ...field,
                order_index: Object.keys(columns).length,

                path: '$.value',
                section_name: element.name,
                section_id: element.uid,
                ...(this.form_details.workflow
                  ? {
                      form_block_name: this.form_details.steps[element.step_index].name,
                      form_block_id: `block_${this.form_details.steps[element.step_index].index}`,
                    }
                  : {}),
                ...(element.type === 'signature' && {
                  name: element.name || 'Signature',
                  element_type: 'signature',
                }),
                column: {
                  data: `${field.uid}.value`,
                  ...(field.type === 'date_time' && { type: 'date' }),
                  renderer:
                      field.type === 'date_time'
                        ? 'date-renderer'
                        : field.type === 'attachment'
                          ? 'attachment-renderer'
                          : 'html',
                },
              };
          }
      }

      this.visible_columns = Object.values(cloneDeep(columns));

      const dataSchema = {};

      const hashMap = keyBy(Object.values(columns), 'order_index');

      Object.values(hashMap).forEach((item) => {
        dataSchema[item.uid] = null;
      });

      Handsontable.renderers.registerRenderer(
        'user-renderer',
        this.userNameRenderer,
      );
      Handsontable.renderers.registerRenderer(
        'date-renderer',
        this.dateRenderer,
      );
      Handsontable.renderers.registerRenderer(
        'date-time-renderer',
        this.dateTimeRenderer,
      );
      Handsontable.renderers.registerRenderer(
        'attachment-renderer',
        this.attachmentRenderer,
      );
      const onAfterGetHeader = function (index, TH) {
        TH.setAttribute('title', TH.querySelector('span').textContent);
      };
      const table = new Handsontable(container, {
        renderAllRows: false,

        renderAllColumns: false,

        data: this.data,
        dataSchema,
        editor: false,
        width: '100%',
        height: window.innerHeight - 280,
        autoRowSize: {
          // keep 40% of rows in sync (the rest of rows: async)
          syncLimit: '40%',
        },
        stretchH: 'all',
        fixedColumnsLeft: 1,
        multiColumnSorting: true,
        afterGetColHeader: onAfterGetHeader,

        disableVisualSelection: true,
        columns: (index) => {
          if (index === 0)
            return {
              data: 'select',
              type: 'checkbox',
              columnSorting: {
                indicator: false,
                headerAction: false,
                compareFunctionFactory(sortOrder, columnMeta) {
                  return (value, nextValue) => 0; // Don't sort the first visual column.
                },
              },
            };

          return {
            ...hashMap[index].column,
          };
        },
        colHeaders: (index) => {
          if (index === 0)
            return `<input style="appearance:auto;margin-left:-6px;" type="checkbox" class="checker" ${
                this.isAllChecked ? 'checked="checked"' : ''
              }>`;

          return hashMap[index].name;
        },
        async afterOnCellMouseDown(event, coords) {
          const rowData = this.getSourceDataAtRow(coords.row);
          const selected = { ...self.selected_rows };
          if (coords.col > 0) {
            const meta = self.hands_on_table.getCellMeta(
              coords.row,
              coords.col,
            );

            const data = self.hands_on_table.getSourceDataAtCell(
              coords.row,
              coords.col,
            );
            const columnsHash = keyBy(self.visible_columns, 'uid');
            if (
              columnsHash[meta.prop.split('.value')[0]].type
                  === 'attachment'
                && data
            ) {
              self.onSubmissionClick(rowData, meta.prop.split('.value')[0]);
              // try {
              //   const response = await formsService.getAll({
              //     query: {
              //       submission: rowData.uid
              //     },
              //     attribute: "fieldvalues"
              //   });
              //    logger.log(response);
              //   const fieldValue = response.data.field_values.find(
              //     item => item.field.uid === meta.prop.split(".value")[0]
              //   );
              //    logger.log(fieldValue);
              //   self.$root.$children[0].$data.documentData = {
              //     name: fieldValue.value.meta?.name || "signature.png",
              //     url: fieldValue.value.service?.url,
              //     bookmark: false,

              //     icon: require("@/assets/file-type-icons/" +
              //       prettyFileIcons.getIcon(
              //         fieldValue.value.meta?.name ?? "signature.png"
              //       ) +
              //       ".svg"),
              //     targetElement: undefined,
              //     uid: fieldValue.uid
              //   };
              // } catch (err) {
              //    logger.log(err);
              // }

              return;
            }
            if (rowData?.form_uid)
              self.onSubmissionClick(rowData);
          }
          else {
            if (event.target.nodeName === 'INPUT') {
              const formData = self.data;

              if (event.shiftKey && self.last_checked_index !== -1) {
                const start
                    = self.last_checked_index > coords.row
                      ? coords.row
                      : self.last_checked_index;
                const end
                    = self.last_checked_index > coords.row
                      ? self.last_checked_index
                      : coords.row;
                for (let index = start; index <= end; index++) {
                  const element = formData[index];

                  if (!selected[element?.uid]) {
                    formData[index].select = true;
                    self.data = formData;
                    selected[element.uid] = element;
                    self.selected_rows = { ...selected };
                  }
                }
                self.hands_on_table.loadData(formData);

                return;
              }
              if (coords.row > -1) {
                if (event.target && event.target.checked === false) {
                  formData[coords.row].select = true;
                  self.data = formData;
                  selected[rowData.uid] = rowData;
                  self.selected_rows = { ...selected };
                }
                else {
                  delete selected[rowData.uid];
                  self.selected_rows = { ...selected };
                }

                if (!event.shiftKey)
                  self.last_checked_index = coords.row;
              }
            }
          }
        },
        rowHeights: 60,
        className: 'custom-table htMiddle htCenter',

        allowInsertRow: true,
        licenseKey: import.meta.env.VITE_APP_HOT_LICENSE_KEY,
      });
      this.hands_on_table = table;
      Handsontable.dom.addEvent(container, 'mousedown', (event) => {
        if (
          event.target.nodeName === 'INPUT'
            && event.target.className === 'checker'
        )
          event.stopPropagation();
      });

      Handsontable.dom.addEvent(container, 'mouseup', async (event) => {
        if (
          event.target.nodeName === 'INPUT'
            && event.target.className === 'checker'
        ) {
          this.isAllChecked = !event.target.checked;

          this.data = this.data.map((item) => {
            if (!event.target.checked)
              this.selected_rows = {
                ...this.selected_rows,
                [item.uid]: item,
              };
            else this.selected_rows = {};
            return {
              ...item,
              select: !event.target.checked,
            };
          });
          this.hands_on_table.loadData(this.data);
        }
      });

      if (this.form_details.filters) {
        if (this.form_details.filters.conditional_formatting) {
          this.rule_list = [
            ...this.form_details.filters.conditional_formatting,
          ];
          await this.formatConditionally();
        }
        else {
          this.formatConditionally(true);
        }
        if (
          this.form_details.filters.visible_columns
            && this.form_details.filters.visible_columns.length
        ) {
          const enriched_visible_columns = [];
          this.form_details.filters?.visible_columns?.forEach((item, index) => {
            if (isString(item))
              enriched_visible_columns.push({ ...columns[item], order_index: index });
            else enriched_visible_columns.push({ ...item, order_index: index });
          });
          const visible_columns = [...enriched_visible_columns];

          const index = visible_columns.findIndex(
            item => item.uid === 'reference_name',
          );

          if (index === -1)
            visible_columns.push({
              name: 'Attached to',
              order_index: enriched_visible_columns.length,
              uid: 'reference_name',
              type: 'text',
              column: {
                data: 'reference_name',

                renderer: 'html',
              },
            });

          this.orderColumns(visible_columns);
        }

        const current_available_columns = [...this.hidden_columns, ...this.visible_columns];
        const total_available_columns = Object.keys(columns);
        if (current_available_columns.length !== total_available_columns.length) {
          const current_available_uids = new Set(current_available_columns.map(column => column.uid));
          total_available_columns.forEach((uid) => {
            if (!current_available_uids.has(uid))
              this.hidden_columns.push(columns[uid]);
          });
        }

        if (this.form_details.filters.data_filters) {
          let filterDropdownItems = { ...this.action_items };
          this.form_details.filters.data_filters.forEach((item) => {
            filterDropdownItems = {
              ...filterDropdownItems,
              [item.name]: {
                name: item.name,
                action: 'update',
                value: item,
              },
            };
          });
          this.action_items = filterDropdownItems;
        }
      }
      else {
        this.formatConditionally(true);
      }
    },
    async exportDataToSpreadSheet(csv = false) {
      function numberToLetters(num) {
        let letters = '';
        while (num >= 0) {
          letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters;
          num = Math.floor(num / 26) - 1;
        }
        return letters;
      }
      // Fetching Data
      const data = this.get_selected_rows.length
        ? this.get_selected_rows
        : await this.getFilteredData(this.active_filter);
      const static_columns = [
        'Form name',
        'Submitted by',
        'Submitted at',
        'Attached to',
        'Reason',
      ];
      let json = data.map((item) => {
        const user = this.common_store.get_org_or_internal_user(item.submitted_by);
        const csvRow = {
          'Form name': item.form_name ? item.form_name : '-',
          'Submitted by': user ? getUserFullName(user) : 'NA',
          'Submitted at': item.submitted_at,
          'Attached to':
              item.attached_to?.reference_name
              || item.attached_to?.name
              || item.reference_name
              || '-',
          'Reason': item.message,
        };
        this.visible_columns.forEach((value) => {
          const item_value = item[value.uid] && item[value.uid].value;

          if (
            !static_columns.includes(value.name)
              && value.type !== 'attachment'
          )
            csvRow[value.uid] = item_value;
          else
            if (value.element_type === 'signature')
              csvRow[value.uid] = item_value
                ? `Signed by ${user?.first_name ?? ''} ${user?.last_name
                      ?? ''} ${user?.email ? `<${user.email}>` : ''}`
                : '';
            else if (value.type === 'attachment' && item_value)
              csvRow[value.uid] = `${public_attachment_link_url}/${item.form_uid}/field/${value.uid}/?form=true`;
            else
              csvRow[value.uid] = item_value
                ? `${item_value
                    } ${item_value === 1 ? 'attachment' : 'attachments'}`
                : '';
        });
        return csvRow;
      });

      const order = this.visible_columns;
      // sorting columns according to index
      order.sort((a, b) => a.order_index - b.order_index);
      json = json.map((val) => {
        const temp = val;
        val = {}; // clearing object to reorder according to order_index

        // Updating order of keys
        for (let i = 0; i < order.length; i++)
          if (static_columns.includes(order[i].name))
            val[order[i].uid] = temp[order[i].name];
          else
            val[order[i].uid] = temp[order[i].uid];

        // need to fix this issue in backend or before adding data to backend removing trailing whitespaces
        if (val.hasOwnProperty('Form name'))
          val['Form name'] = val['Form name'].trim();
        delete val.table_checkbox;
        delete val.checkbox_selection;
        return val;
      });

      // object storing details of formating conditions
      const format = {
        entire_row: [],
        columns: [],
      };

      for (
        let ruleIndex = this.rule_list.length - 1;
        ruleIndex >= 0;
        ruleIndex--
      ) {
        const rule = this.rule_list[ruleIndex].value;
        const engine = this.json_engine;
        // creating a rule according to json engine using rule_list rule
        const jsonRule = {
          conditions: {
            [rule.condition.toLowerCase()]: rule.filters.map((item) => {
              return {
                fact: item.fact,
                operator: item.operator,
                value: item.value,
              };
            }),
          },
          event: {
            type: 'filters',
            params: {
              message: 'Filter passed',
            },
          },
        };
        engine.addRule(jsonRule);
        for (let index = 0; index < json.length; index++) {
          // checking the rule by running data object through json engine
          const { results } = await engine.run(json[index]);
          // result length greater than zero means data object passes all rule conditions
          if (results.length > 0) {
            const cell_style = {
              index: index + 2, // specifies row in xlsx sheet
              color: rule.format.text_color,
              background_color: rule.format.background_color,
              font_style: rule.format.font_style || [],
            };

            // checks if formatting is to be done to the row or column
            if (rule.format?.apply_to === 'Entire row')
              format.entire_row.push(cell_style);
            else
              format.columns.push({
                ...cell_style,
                // finding the column using keys
                column: numberToLetters(
                  Number(
                    order.findIndex(
                      val => val.name === rule.format.apply_to,
                    ) - 1,
                  ),
                ),
              });
          }
        }
        engine.rules = [];
      }

      // array for calculating width
      const colWidth = Array.from({ length: Object.keys(json[0]).length }).fill(10);

      json = json.map((val) => {
        // finding width of a column according to values
        for (const key in val) {
          // if the data is array joining it
          if (Array.isArray(val[key]))
            val[key] = val[key].join(', ');

          if (val[key] !== undefined) {
            if (Number.isNaN(colWidth[Object.keys(val).indexOf(key)]))
              colWidth[Object.keys(val).indexOf(key)] = 0;
            colWidth[Object.keys(val).indexOf(key)] = Math.max(
              JSON.stringify(val[key]).length,
              colWidth[Object.keys(val).indexOf(key)],
            );
            val[key] = isNumber(val[key])
              ? val[key]
              : csvInjectionProtector(val[key] ?? '');
          }
        }
        return val;
      });

      // creating exceljs worksheet and workbook
      const ExcelJS = await import('exceljs');

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('My Sheet');
      const values = [];

      Object.values(json).forEach((item) => {
        values.push(Object.values(item));
      });

      const aoa = [
        [...Object.keys(json[0])],
        ...values,
      ];

      worksheet.addRows(aoa);

      // how many separate Excel will open when viewing
      workbook.views = [
        {
          x: 0,
          y: 0,
          width: 10000,
          height: 20000,
          firstSheet: 0,
          activeTab: 1,
          visibility: 'visible',
        },
      ];

      // Changing column uids to name
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber === 1)
          row.eachCell((cell, colNumber) => {
            cell.value = order[colNumber].name;
          });
        else
          row.eachCell((cell) => {
            const utc_regex = /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})(\.\d+)?Z$/;
            if (utc_regex.test(cell.value))
              cell.value = $date(dayjs(cell.value), 'MMM Do YYYY, h:mm a');
          });
      });

      // Assign width to columns
      worksheet.columns = colWidth.map(width => ({ width }));

      // applying styles to row
      format.entire_row.forEach((val) => {
        const rowIndex = val.index;
        const row = worksheet.getRow(rowIndex);

        // Loop through each column in the row and apply styling
        for (let col = 1; col <= Object.keys(json[rowIndex - 2]).length; col++) {
          row.getCell(col).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: val.background_color.substring(1) },
            bgColor: { argb: val.background_color.substring(1) },
          };
          row.getCell(col).font = {
            color: { argb: val.color.substring(1) },
            bold: val.font_style.includes('bold'),
            underline: val.font_style.includes('underline'),
            italic: val.font_style.includes('italic'),
          };
          row.getCell(col).border = {
            top: { style: 'thin', color: { argb: 'D9D9D9' } },
            bottom: { style: 'thin', color: { argb: 'D9D9D9' } },
            left: { style: 'thin', color: { argb: 'D9D9D9' } },
            right: { style: 'thin', color: { argb: 'D9D9D9' } },
          };
        }
      });

      // applying styles to column
      format.columns.forEach((val) => {
        const cell = worksheet.getCell(val.column + val.index);

        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: val.background_color.substring(1) },
          bgColor: { argb: val.background_color.substring(1) },
        };
        cell.font = {
          color: { argb: val.color.substring(1) },
          bold: val.font_style.includes('bold'),
          underline: val.font_style.includes('underline'),
          italic: val.font_style.includes('italic'),
        };
        cell.border = {
          top: { style: 'thin', color: { argb: 'D9D9D9' } },
          bottom: { style: 'thin', color: { argb: 'D9D9D9' } },
          left: { style: 'thin', color: { argb: 'D9D9D9' } },
          right: { style: 'thin', color: { argb: 'D9D9D9' } },
        };
      });

      try {
        let buffer;
        if (csv)
          buffer = await workbook.csv.writeBuffer();

        else
          buffer = await workbook.xlsx.writeBuffer();

        const timezone = this.auth_store.logged_in_user_details?.timezone;
        const format = 'MMMM DD, YYYY h mm A z';
        const formatted_date = dayjs().utc().tz(timezone).format(format);
        const file_name = (`${this.form_details.name.toLowerCase().split(' ').join('_')}-${formatted_date}.${csv ? 'csv' : 'xlsx'}`);

        saveAs(new Blob([buffer]), file_name);
        this.forms_store.forms_track_events('Exported', data[0].uid, { output_format: csv ? 'csv' : 'XLSX', count: data.length, with_summary: false });
      }
      catch (error) {
        logger.log('Error writing excel export', error);
      }
    },
    async exportAllXlsx(options = {}) {
      // Info: To get blob of all available response data with applied filters in xlsx format
      const filter_payload = this.generateFilterPayload(this.active_filter);
      const body = {
        filters: {
          ...filter_payload.default,
          ongoing: this.include_ongoing_submission,
          organization: this.form_details.organization,
          asset_uid: this.$route.params.asset_id || [],
          template: this.$route.params.template_uid,
          advanced_filter: {
            ...filter_payload.advanced,
          },
        },
        fields: this.visible_columns.filter(column_data => !column_data?.hidden_in_settings).map(column_data => ({ key: column_data.uid })),
        ...(options?.payload || {}),
      };

      const { data, headers } = await this.services.forms.get_submission_report({
        body,
      });
      const disposition = headers['content-disposition'];
      if (disposition?.includes('attachment')) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches?.[1])
          data.name = matches[1].replace(/['"]/g, '');
      }
      else {
        const timezone = this.auth_store.logged_in_user_details?.timezone;
        const format = 'MMMM DD, YYYY h mm A z';
        const formatted_date = dayjs().utc().tz(timezone).format(format);
        data.name = (`${this.form_details.name.toLowerCase().split(' ').join('_')}-${formatted_date}.xlsx`);
      }
      saveAs(data);
      if (this.data.length)
        this.forms_store.forms_track_events('Exported', this.data[0].uid, { output_format: 'XLSX', count: this.pagination_config.totalRows, with_summary: false });
    },
    async exportHandler() {
      switch (this.export_type) {
        case 'exportXlsx':
          await this.exportAllXlsx({
            payload: {
              page_number: this.pagination_state.pageIndex + 1,
              page_size: this.pagination_state.pageSize,
            },
          });
          break;
        case 'exportCsv':
          await this.exportDataToSpreadSheet(true);
          break;
        case 'exportAllXlsx':
          await this.exportAllXlsx();
          break;
        default:
          logger.error('Invalid Export type');
      }
    },
    setExport(type) {
      this.export_type = type;
      this.is_exporting = true;
    },
    getProgressText(type) {
      switch (type) {
        case 'exportXlsx':
          return 'Exporting to XLSX';
        case 'exportCsv':
          return 'Exporting to CSV';
        case 'exportAllXlsx':
          return 'Exporting to XLSX';
        default:
          return 'Exporting to PDF';
      }
    },
    getCompletedText(type) {
      switch (type) {
        case 'exportXlsx':
          return 'Exported XLSX';
        case 'exportCsv':
          return 'Exported CSV';
        case 'exportAllXlsx':
          return 'Exported XLSX';
        default:
          return 'Exported PDF';
      }
    },
    debouncedSearchTable: debounce(async function searchTable(val) {
      this.search = val;
      this.is_loading = true;
      this.destroyTable();
      this.$set(this.pagination_state, 'pageIndex', 0);
      await this.getData();
      if (this.data.length) {
        this.loadJsonEngine();
        this.loadHandsOn();
      }

      this.is_loading = false;
      this.hands_on_table.loadData(this.data);
    }, 700),
    async updatePagination(key, value) {
      if (key === 'pageSize') {
        this.$set(this.pagination_state, 'pageIndex', 0);
        this.$set(this.pagination_state, 'pageSize', value);
      }
      else {
        let pageIndex = -1;
        if (key === 'next')
          pageIndex = this.pagination_state.pageIndex + 1;
        else if (key === 'prev')
          pageIndex = this.pagination_state.pageIndex - 1;
        else
          pageIndex = value;
        this.$set(this.pagination_state, 'pageIndex', pageIndex);
      }
      this.destroyTable();
      this.is_loading = true;
      await this.getData();
      if (this.data.length) {
        this.loadJsonEngine();
        this.loadHandsOn();
      }
      this.is_loading = false;
    },
  },
};
</script>

<template>
  <div class="mt-[16px] is-flex has-direction-column relative">
    <FormTemplateResponseOverviewHeader
      class="mb-5"
      :selected_rows="Object.values(selected_rows)"
      :visible_columns="visible_columns"
      :hidden_columns="hidden_columns"
      @handleListVisibility="updateColumnsVisibility"
      @filterTable="filterTable($event)"
      @updateRuleList="updatedRuleList"
      @setExport="setExport"
      @search="debouncedSearchTable($event)"
      @updateOngoingSubmission="include_ongoing_submission = $event"
      @clear="selected_rows = {}, clearCheckbox()"
    />
    <div v-if="is_loading">
      <hawk-loader />
    </div>
    <div v-else-if="has_no_results" class="absolute w-full left-0 top-0 z-2 bg-white">
      <HawkIllustrations type="no-results" for="forms-responses" />
    </div>
    <HawkIllustrations v-else-if="is_boarding" type="no-data" for="forms-responses" />
    <div v-show="data.length && !is_loading" id="checklist-items-handson" />
    <HawkPagination
      v-if="!is_loading"
      :is_pagination_enabled="is_pagination_enabled"
      :pagination_state="pagination_state"
      :pagination_config="pagination_config"
      :data="data"
      :default_pagination_config="default_pagination_config"
      @updatePagination="updatePagination"
    />
  </div>
  <HawkExportToast v-if="is_exporting" :key="export_type" :progress_text="getProgressText(export_type)" :completed_text="getCompletedText(export_type)" :submit="exportHandler" @cancel="is_exporting = false" @close="is_exporting = false" />
</template>

<style scoped lang="scss">
  :deep(.settings-dropdown) {
    right: 15px;
  }
  :deep(.attachment-link) {
    text-decoration: underline;
    color: #1676ee;
    font-size: 14px;
    white-space: nowr;
  }
  .filter {
    background-color: #fcfcfc;
    &__block {
      display: flex;
      align-items: center;
      min-width: 22rem;
      margin-left: 1rem;
      :deep(.sh-input-dropdown) {
        min-width: 22rem;
      }
    }
  }

  .section-navbar {
    padding: 1rem;
    background: #fff;
    min-height: 6.5rem;
    border: 1px solid #23282d26;
    margin-bottom: 5px;
    &__filter-dropdown {
      border: 1px solid  #23282d26;
      padding: 0.6rem;
      margin-left: 0.8rem;
    }
    :deep(.filter-actions-dropdown) {
      min-width: 20rem;
      padding: 5px;
      font-size: 14px;
      .sw-dropdown__options--item {
        font-size: 14px;
        opacity: 0.8;
      }
      .has-base-text {
        color: #1676ee;
      }
    }
  }

  .custom-table {

    border-radius: 8px;
    z-index: 1;
    border:  1px solid rgba(234, 236, 240, 1) ;
    :deep(.handsontable) {
      input {
        appearance: auto;
      }
      tr td {
        vertical-align: middle;
        text-align: center;
        padding: 4px 8px;
        &:first-child {
          min-height: 60px !important;
          font-weight: 500;
        }

      }
      th{
        border: none !important;
      }
      td{
        border-right: none;
        border-left: none ;

      }

    }

:deep(.htCore) {
th {
  vertical-align: middle;
  padding: 6px 8px;
  text-align: center;
  font-weight: 500;
  background-color: rgba(249, 250, 251, 1);
}
td {
  cursor: pointer;
  max-width: 30rem;

}
}
    :deep(.handsontable) {
      input {
        appearance: auto;
      }
    }

  }
</style>
