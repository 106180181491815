<script setup>
import { useModal } from 'vue-final-modal';
import { computed } from 'vue';
import ConditionSettingsModal from './condition-settings-modal.vue';
import CustomFilter from './custom-filter-popup.vue';
import TableSettingModal from './table-setting-modal.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import SecondaryPageHeader from '~/common/components/organisms/hawk-page-secondary-header.vue';
import FormBulkUpdateDropdown from '~/forms/components/form-bulk-update-dropdown.vue';
import { useCommonStore } from '~/common/stores/common.store';

const props = defineProps({
  visible_columns: {
    type: Array,
    default: () => [],
  },
  hidden_columns: {
    type: Array,
    default: () => [],
  },
  selected_rows: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['handleListVisibility', 'filterTable', 'updateRuleList', 'setExport', 'search', 'clear']);

const $t = inject('$t');

const common_store = useCommonStore();
const form_template_detail_store = useFormTemplateDetailStore();
const bulk_update_options = [
  { uid: 0, label: 'Export', on_click: () => emit('setExport', 'exportCsv') },

];
const search = ref('');
const filter_menu_active = ref(false);
const filter_menu = computed(() => {
  return [
    {
      value: 'all_submissions',
      name: $t('All submissions'),
    },
    {
      value: 'read_submissions',
      name: $t('Read submissions'),
    },
    {
      value: 'unread_submissions',
      name: $t('Unread submissions'),
    },
    {
      value: 'rolled_back',
      name: $t('Rolled back'),
    },
  ];
});

const current_submission_type = ref(filter_menu.value[0]);

const export_menu = computed(() => {
  return [
    {
      label: 'Export CSV',
      emit: 'exportCsv',
    },
    {
      label: 'Export XLSX',
      emit: 'exportXlsx',
    },
    {
      label: 'Export all as XLSX',
      emit: 'exportAllXlsx',
    },
  ];
});

const settings_menu = computed(() => {
  return [
    {
      label: $t('Table settings'),
      click: () => handleTableSettings(),
    },
    {
      label: $t('Conditional settings'),
      click: () => handleConditionalSettings(),
    },
  ];
});

const data_filters = computed(() => {
  return (form_template_detail_store.form_template_detail.filters.data_filters || []);
});
function handleTableSettings() {
  const table_settings_popup = useModal({
    component: TableSettingModal,
    attrs: {
      onClose() {
        table_settings_popup.close();
      },
      async onSave(visible, hidden) {
        emit('handleListVisibility', { visible_columns: visible, hidden_columns: hidden });
        table_settings_popup.close();
      },
      visible_columns: props.visible_columns,
      hidden_columns: props.hidden_columns,
    },
  });
  table_settings_popup.open();
}

function handleConditionalSettings() {
  const conditional_settings_popup = useModal({
    component: ConditionSettingsModal,
    attrs: {
      visible_columns: props.visible_columns,
      hidden_columns: props.hidden_columns,
      onClose() {
        conditional_settings_popup.close();
      },
      onSave(data) {
        emit('updateRuleList', data);
      },
    },
  });
  conditional_settings_popup.open();
}

function handleOpenFilter(filter = null) {
  const custom_filter_popup = useModal({
    component: CustomFilter,
    attrs: {
      filter,
      columns: props.visible_columns,
      formatting_enabled: false,
      onClose() {
        custom_filter_popup.close();
      },
      async on_save(payload) {
        await form_template_detail_store.update_form_details({
          body: {
            filters: {
              ...form_template_detail_store.form_template_detail.filters,
              data_filters: filter
                ? [...(form_template_detail_store.form_template_detail.filters?.data_filters.map(item =>
                    item.uid === filter.uid ? payload.value : item,
                  ) ?? [])]
                : [...(form_template_detail_store.form_template_detail.filters.data_filters || []), payload.value],
            },
          },
          suppressToast: true,
        });
        custom_filter_popup.close();
      },
    },
  });
  custom_filter_popup.open();
}

function deleteFilter(filter) {
  const delete_popup = useModal({
    component: HawkDeletePopup,
    attrs: {
      header: filter.name,
      content: 'Are you sure you want to delete the filter?',
      onClose() {
        delete_popup.close();
      },
      async confirm() {
        await form_template_detail_store.update_form_details({
          body: {
            filters: {
              ...form_template_detail_store.form_template_detail.filters,
              data_filters: [
                ...(form_template_detail_store.form_template_detail.filters?.data_filters.filter(
                  item => item.uid !== filter.uid,
                ) ?? []),
              ],
            },
          },
        });
        if (current_submission_type.value?.uid === filter.uid) {
          current_submission_type.value = filter_menu.value[0];
          emit('filterTable', current_submission_type.value);
        }

        delete_popup.close();
      },
    },
  });
  delete_popup.open();
}

function handleSubmissionSelection(e) {
  current_submission_type.value = e;
  search.value = '';
  emit('filterTable', e);
}
</script>

<template>
  <hawk-sticky-header>
    <div class="items-center">
      <div class="flex justify-between">
        <div>
          <div v-if="selected_rows.length">
            <SecondaryPageHeader>
              <template #left>
                <div class="items-center">
                  <FormBulkUpdateDropdown :selected_items="selected_rows" :bulk_action_options="bulk_update_options" @clear="emit('clear')" />
                </div>
              </template>
            </SecondaryPageHeader>
          </div>
          <div v-else class="flex">
            <HawkSearchInput :key="search.length" v-model:search="search" :placeholder="$t('Search')" @update:modelValue="emit('search', $event)" />
          </div>
        </div>
        <div>
          <slot name="top-right-controls">
            <div class="flex justify-end">
              <Vueform>
                <CheckboxElement name="ongoing" :text="$t('Include ongoing submissions')" class="mr-3 mt-1" @change="emit('updateOngoingSubmission', $event)" />
              </Vueform>
              <HawkMenu :items="filter_menu" class="mr-2" @open="filter_menu_active = true" @close="filter_menu_active = false">
                <template #trigger>
                  <HawkButton type="outlined">
                    <div class="flex justify-between min-w-[180px]">
                      <p class="text-gray-900 text-sm font-normal ">
                        {{ current_submission_type.name }}
                      </p>
                      <div class="icons">
                        <IconHawkChevronUp v-if="filter_menu_active" />
                        <IconHawkChevronDown v-else />
                      </div>
                    </div>
                  </HawkButton>
                </template>
                <template #header>
                  <HawkButton type="link" size="xs" @click="handleOpenFilter()">
                    <IconHawkPlus /> Add filter
                  </HawkButton>
                </template>
                <template #content="{ close }">
                  <div class="filter_menu_items min-w-[200px] max-h-48 scrollbar border border-gray-200 shadow-lg">
                    <div v-for="item in filter_menu" :key="item.value" class="py-[2px] px-[6px] cursor-pointer" @click="handleSubmissionSelection(item);close()">
                      <p class="text-gray-700 text-sm font-medium py-[10px] pr-[10px] pl-[8px] rounded-[6px] hover:bg-blue-500 hover:bg-opacity-10">
                        {{ item.name }}
                      </p>
                    </div>
                    <div v-for="item in data_filters" :key="item.uid" class="group py-[2px] px-[6px] cursor-pointer" @click="handleSubmissionSelection(item);close()">
                      <div class="flex items-center justify-between w-full rounded-[6px] hover:bg-blue-500 hover:bg-opacity-10">
                        <p class="text-gray-700 text-sm font-medium py-[10px] pr-[10px] pl-[8px]">
                          {{ item.name }}
                        </p>
                        <div class="flex items-center invisible group-hover:visible">
                          <div
                            class="cursor-pointer"
                            @click.stop="handleOpenFilter(item)"
                          >
                            <IconHawkPencilOne class="text-gray-500 h-4" />
                          </div>
                          <div
                            class="cursor-pointer mx-2"
                            @click.stop="deleteFilter(item)"
                          >
                            <IconHawkTrashOne class="w-4 h-4 text-gray-500" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </HawkMenu>

              <HawkMenu :items="settings_menu" class="flex items-center" :has_bordered_trigger="false">
                <template #trigger>
                  <hawk-button type="outlined">
                    <IconHawkSettingsOne />
                  </hawk-button>
                </template>
                <template #content>
                  <div class="settings_menu_items w-[180px] my-2">
                    <div v-for="item in settings_menu" :key="item.label" class="py-[2px] px-[6px] cursor-pointer w-full" @click="item.click()">
                      <p class="text-gray-700 text-sm font-medium py-[10px] pr-[10px] pl-[8px] rounded-[6px] hover:bg-blue-500 hover:bg-opacity-10">
                        {{ item.label }}
                      </p>
                    </div>
                    <div class="export_menu w-full">
                      <HawkMenu :items="export_menu" additional_trigger_classes="!ring-0 p-0 -mt-1 w-[180px]">
                        <template #trigger>
                          <div class="py-[2px] px-[6px] cursor-pointer text-left w-full">
                            <p class="text-gray-700 text-sm font-medium py-[10px] pr-[10px] pl-[8px] rounded-[6px] hover:bg-blue-500 hover:bg-opacity-10">
                              {{ $t('Export') }}
                            </p>
                          </div>
                        </template>
                        <template #content>
                          <div class="export_menu_items min-w-[180px] my-2">
                            <div v-for="item in export_menu" :key="item.label" class="py-[2px] px-[6px] cursor-pointer" @click="() => emit('setExport', item.emit)">
                              <p class="text-gray-700 text-sm font-medium py-[10px] pr-[10px] pl-[8px] rounded-[6px] hover:bg-blue-500 hover:bg-opacity-10">
                                {{ item.label }}
                              </p>
                            </div>
                          </div>
                        </template>
                      </HawkMenu>
                    </div>
                  </div>
                </template>
              </HawkMenu>
            </div>
          </slot>
        </div>
      </div>
      <div>
        <div class="flex" />
      </div>
    </div>
  </hawk-sticky-header>
</template>
