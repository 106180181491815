<script setup>
import { Validator } from '@vueform/vueform';
import { useCustomFields } from '~/common/components/organisms/hawk-custom-fields/custom-fields.composable.js';

const props = defineProps({
  uid: {
    type: String,
    default: '',
  },
  fields: {
    type: Object,
    default: () => ({}),
  },
  save: {
    type: Function,
    default: _ => _,
  },
});

const emit = defineEmits(['close']);

const loading = ref(false);
const custom_fields = ref(null);

const {
  form,
  is_edit,
  getCustomFieldTypes,
} = useCustomFields(props, emit);

const types = getCustomFieldTypes('dms');

async function save() {
  try {
    await custom_fields.value?.form$?.validate();
    if (!custom_fields.value?.form$?.hasErrors) {
      loading.value = true;
      const data = form.value;
      data.default = data?.default?.value;
      if (data.required && data.rules)
        data.rules.required = true;
      await props.save(data);
      loading.value = false;
      emit('close');
    }
  }
  catch (e) {
    loading.value = false;
    logger.error(e);
  }
}

const name_already_exists = class extends Validator {
  get message() {
    return 'The label already exists in another custom field';
  }

  check(value) {
    return !(props?.fields || []).filter(field => field.uid !== props.uid).find(field => field.label === value);
  }
};

const validators = {
  label: [
    name_already_exists,
  ],
};
</script>

<template>
  <HawkModalContainer>
    <div class="col-span-12">
      <HawkModalHeader @close="emit('close')">
        <template #title>
          <div class="flex flex-col justify-start items-center">
            {{ is_edit ? $t(`Update`) : $t(`Add`) }} {{ $t('Field') }}
          </div>
        </template>
      </HawkModalHeader>
      <HawkModalContent class="max-w-[575px] max-h-[575px]">
        <hawk-custom-fields-form ref="custom_fields" v-model="form" :uid="uid" :types="types" :validators="validators" :options="{ has_description: false }" />
      </HawkModalContent>
      <HawkModalFooter class="flex justify-between items-center">
        <template #right>
          <!-- Footer -->
          <div class="flex justify-end items-center gap-3">
            <hawk-button type="outlined" color="gray" @click="$emit('close')">
              {{ $t('Cancel') }}
            </hawk-button>
            <hawk-button
              :loading="loading"
              :disabled="custom_fields?.form$?.hasErrors"
              @click="save"
            >
              {{ is_edit ? $t('Update') : $t('Save') }}
            </hawk-button>
          </div>
        </template>
      </HawkModalFooter>
    </div>
  </HawkModalContainer>
</template>
