<script setup>
import { useModal } from 'vue-final-modal';
import InventoryItems from '~/inventory/pages/inventory-items.vue';
import InventoryTransactions from '~/inventory/pages/inventory-transactions.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { renderAsTextarea } from '~/common/utils/common.utils.js';
import InventoryWarehouseForm from '~/inventory/components/inventory-warehouse/inventory-warehouse-form.vue';
import InventoryBomAssociateInstances from '~/inventory/components/inventory-bom/inventory-bom-instances-form-modal.vue';
import InventoryWarehouseOverview from '~/inventory/components/inventory-warehouse/inventory-warehouse-overview.vue';
import InventoryItemRegistrationPopup from '~/inventory/components/inventory-items/inventory-item-registration-popup.vue';
import InventoryBom from '~/inventory/pages/inventory-bom.vue';
import HawkText from '~/common/components/atoms/hawk-text.vue';

const { $t, route, router, auth_store } = useCommonImports();
const inventory_store = useInventoryStore();

const warehouse_detail = computed(() => inventory_store.get_active_warehouse_details(route?.params?.warehouse_id));

const tabs = computed(() => {
  return [
    { uid: 'overview', label: $t('Overview') },
    { uid: 'items', label: $t('Items') },
    { uid: 'transactions', label: $t('Transactions') },
    (warehouse_detail.value?.permissions?.view_bom ? { uid: 'bom', label: $t('Bill of Material') } : {}),
  ];
});

const state = reactive({
  active_tab_item: 'overview',
});

const { open: openWarehouseModal, close: closeWarehouseModal, patchOptions } = useModal({
  component: InventoryWarehouseForm,
});

function editWarehouse() {
  patchOptions({
    attrs: {
      warehouse_details: warehouse_detail.value,
      onClose() {
        closeWarehouseModal();
      },
    },
  });
  openWarehouseModal();
}

const { open: openAssociateInstancesModal, close: closeAssociateInstancesModal, patchOptions: patchAssociateInstancesOptions } = useModal({
  component: InventoryBomAssociateInstances,
});

function openAssociateInstancesHandler() {
  patchAssociateInstancesOptions({
    attrs: {
      // warehouse_details: warehouse_detail.value,
      onClose() {
        closeAssociateInstancesModal();
      },
    },
  });
  openAssociateInstancesModal();
}

const { open: openItemRegistrationPopup, close: closeItemRegistrationPopup, patchOptions: patchItemRegistrationPopup } = useModal({
  component: InventoryItemRegistrationPopup,
});

function openRegisterPopup() {
  patchItemRegistrationPopup({
    attrs: {
      onClose() {
        closeItemRegistrationPopup();
      },
      warehouse_details: warehouse_detail.value,
      on_register_items() {
        logger.error('updated');
      },
    },
  });
  openItemRegistrationPopup();
}

function getWarehouseTypeDetails() {
  return warehouse_detail.value?.warehouse_type ? inventory_store.warehouse_types_map[warehouse_detail.value?.warehouse_type] : null;
}
</script>

<template>
  <div>
    <div>
      <HawkPageHeader>
        <template #title>
          <div class="flex items-center gap-3">
            <HawkButton type="text" icon @click="router.push({ name: 'inventory-warehouses', params: { ...route.params } })">
              <IconHawkChevronLeft />
            </HawkButton>
            <div>
              <div>{{ warehouse_detail.name }}</div>
              <div class="text-xs font-normal text-gray-500" v-html="renderAsTextarea(warehouse_detail?.description)" />
            </div>
          </div>
        </template>
        <template #right>
          <div class="flex items-center gap-3">
            <HawkButton
              v-if="auth_store.check_permission('modify_warehouses', route.params.asset_id)"
              v-tippy="{ content: $t('Edit warehouse') }" icon type="text" @click="editWarehouse()"
            >
              <IconHawkPencilOne />
            </HawkButton>
          </div>
        </template>
      </HawkPageHeader>
    </div>
    <div class="mx-4 mb-5 grid gap-5">
      <div>
        <div class="grid grid-cols-12 text-xs mx-4 mb-1">
          <div class="col-span-3">
            <div class="font-semibold text-sm">
              {{ $t('Code') }}
            </div>
          </div>
          <div class="col-span-3">
            <div class="font-semibold text-sm">
              {{ $t('Type') }}
            </div>
          </div>
          <div class="col-span-4">
            <div class="font-semibold text-sm">
              {{ $t('Managers') }}
            </div>
          </div>
        </div>
        <div class="grid grid-cols-12 text-sm mx-4">
          <div class="col-span-3">
            <div>
              <HawkText :content="`#${warehouse_detail.number}`" />
            </div>
          </div>
          <div class="col-span-3">
            <HawkBadge
              v-if="getWarehouseTypeDetails()?.uid"
              :custom_color="getWarehouseTypeDetails()?.color"
              custom_color_background_opacity="0.1"
            >
              {{ getWarehouseTypeDetails()?.name }}
            </HawkBadge>
            <div v-else>
              -
            </div>
          </div>
          <div class="col-span-4">
            <div>
              <HawkMembers size="xs" :members="warehouse_detail.managers" type="badge" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between">
        <HawkTabs :tabs="tabs" :active_item="state.active_tab_item" @tab-click="state.active_tab_item = $event.uid" />
      </div>
      <InventoryWarehouseOverview
        v-if="state.active_tab_item === 'overview'"
        :warehouse_id="route?.params?.warehouse_id"
      />
      <InventoryItems
        v-else-if="state.active_tab_item === 'items'"
        is_list_view :warehouse_id="route?.params?.warehouse_id"
      />
      <InventoryTransactions
        v-else-if="state.active_tab_item === 'transactions'"
        is_list_view :warehouse_id="route?.params?.warehouse_id"
      />
      <InventoryBom
        v-else-if="warehouse_detail?.permissions?.view_bom && state.active_tab_item === 'bom'"
        :is_list_view="false" :warehouse_id="route?.params?.warehouse_id"
      />
    </div>
  </div>
</template>
